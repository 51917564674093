import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IPlacementOptions } from 'src/app/core/models';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { FACEBOOK_SINGLE_PLACEMENT_ENTITY } from 'src/app/state/app.state';
import { AdFormatEnum, NetworkEnum } from 'src/app/presentation/view-models';

@Injectable()
export class FacebookSinglePlacementDataService extends DefaultDataService<IPlacementOptions> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(FACEBOOK_SINGLE_PLACEMENT_ENTITY, http, httpUrlGenerator);
	}

	public getAll(): Observable<IPlacementOptions[]> {
		return this.appCoreServices.socialCampaignApiService.placementApi.getPlacements(
			NetworkEnum.facebook,
			AdFormatEnum.singleImageAndVideo,
		);
	}
}
