<div class="header">
    <ui-svg-icon class="header-left" icon="arrow-left"></ui-svg-icon>
    <div class="header-right">
        <div class="create">
            <ui-svg-icon icon="camera"></ui-svg-icon>
            <span class="create-label">Create</span>
        </div>
        <div class="dot"></div>
    </div>
</div>
