import { Component } from '@angular/core';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { FacebookPlacementCoreBaseDirective } from '../../../shared/directives/facebook-placement-core-base.directive';
import { FacebookSupportedPlacements } from '../../../shared/enums';
import { CtaButtonComponent } from '../../../shared/cta-button/cta-button.component';
import { HeadlineComponent } from '../../../shared/headline/headline.component';
import { FacebookPlacementHeaderComponent } from '../../../shared/placement-header/fb-placement-header.component';
import { CreativeContentComponent } from '../../../../../../../elements/creative-content/creative-content.component';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		CreativeContentComponent,
		HeadlineComponent,
		FacebookPlacementHeaderComponent,
		CtaButtonComponent,
	],
	selector: 'facebook-search-result-core',
	templateUrl: './fb-search-result-core.component.html',
	styleUrls: ['./fb-search-result-core.component.scss'],
})
export class FacebookSearchResultPlacementCoreComponent extends FacebookPlacementCoreBaseDirective {
	constructor(public appFeatureServices: AppFeatureServices) {
		super(
			appFeatureServices,
			FacebookSupportedPlacements.FacebookSearchResults,
		);
	}
}
