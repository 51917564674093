import { Component, Input } from '@angular/core';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { UIModule } from '@bannerflow/ui';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'customized-icon',
	templateUrl: './customized-icon.component.html',
	styleUrls: ['./customized-icon.component.scss'],
	imports: [UIModule, CommonModule, TextPipe],
	standalone: true,
})
export class CustomizedIconComponent {
	@Input() public isCustomized: boolean;
	@Input() public inActive?: boolean;
	@Input() public fill?: 'var(--ui-color-black)' | 'var(--ui-color-active)' =
		'var(--ui-color-black)';

	constructor(public text: TextPipe) {}
}
