<ng-container *ngIf="finalPrimaryTexts?.length">
    <div
        class="amount-of-primary-texts"
        ui-popover-target
        #hoverTarget="ui-popover-target"
        (mouseover)="hoverPopover.open(hoverTarget)"
        text="Hover to open popover">
        +{{number}}
    </div>
</ng-container>

<ui-popover
    #hoverPopover="ui-popover"
    [config]="{ arrowPosition: 'top', position: 'bottom', openOnHover: true, width: 'auto', maxWidth: '30rem', panelClass: 'panel'}">
    <ng-template ui-popover-template>
        <div class="primary-texts-list" *ngIf="finalPrimaryTexts?.length > 0">
            <div class="primary-texts-list-item" *ngFor="let url of finalPrimaryTexts">
                {{url}}
            </div>
        </div>
    </ng-template>
</ui-popover>
