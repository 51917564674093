<ng-container>
    <warning-message class="warning">
        <ul [ngClass]="{'no-bullet': messages.length <= 1}">
            <li *ngFor="let message of messages">{{ message }}</li>
        </ul>
    </warning-message>

    <div class="centered-text">{{ config?.text || '' }}</div>

    <ui-dialog-buttons>
        <ui-button
            *ngIf="!!config?.discardText"
            (click)="cancel()"
            [text]="config?.discardText || 'cancel'">
        </ui-button>

        <ui-button
            (click)="confirm()"
            type="primary"
            [text]="config?.confirmText || 'ok'">
        </ui-button>
    </ui-dialog-buttons>
</ng-container>
