import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { Observable } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';

@Component({
	selector: 'creative-deactivation-warning',
	templateUrl: './creative-deactivation-warning.component.html',
	styleUrls: ['./creative-deactivation-warning.component.scss'],
	imports: [UIModule, CommonModule, TextPipe],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreativeDeactivationWarningComponent implements OnChanges {
	@Input() public creativeSetId: string;
	@Input() public creativeId: string;

	public isCreativeDeactivitated$: Observable<boolean>;

	constructor(private appFeatureServices: AppFeatureServices) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.creativeId || changes.creativeSetId) {
			this.isCreativeDeactivitated$ =
				this.appFeatureServices.studioFeature.creativeSetService.isCreativeDeactivated(
					this.creativeSetId,
					this.creativeId,
				);
		}
	}
}
