import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ConfigService } from 'src/app/core/config/config.service';
import { Observable, catchError } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { IAd } from 'src/app/core/models';
import { ApiService } from '../../internal/api.service';
import { IDuplicateAd } from 'src/app/core/models/ad/ad/duplicate-ad.model';

@Injectable({
	providedIn: 'root',
})
export class AdApiService extends BaseApiService {
	constructor(public http: HttpClient) {
		super(http, `${ConfigService?.config?.SCS_URL}/api/ads`);
	}

	/**
	 * Create Ad
	 * @param ad
	 */
	public addAd(ad: IAd): Observable<IAd> {
		const body: any = {
			ads: [
				{
					adGroupId: ad.adGroupId,
					name: ad.name,
					languageId: ad.languageId,
					adFormatId: ad.adFormatId,
					default: ad?.default,
				},
			],
		};
		return this.http
			.post<any>(this.baseUrl, body, { observe: 'response' })
			.pipe(
				flatMap((res: HttpResponse<any>) => {
					const location = res.headers.get('location');
					return this.http.get<IAd>(location);
				}),
				map((ads) => ({
					...ads[0],
				})),
				catchError(ApiService.handleError),
			);
	}

	/**
	 * Delete ad
	 * @param adId
	 */
	public delete(adId: string): Observable<string> {
		const url = `${this.baseUrl}/${adId}`;

		return this.http.delete<string>(url).pipe(
			map((ad) => ad),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Update Ad Name
	 * @param adId
	 * @param adName
	 */
	public rename(adId: string, adName: string): Observable<IAd> {
		const body = [
			{
				op: 'replace',
				path: '/name',
				value: adName,
			},
		];

		const url = `${this.baseUrl}/${adId}`;
		return this.http.patch<any>(url, body).pipe(
			map((ad) => ad),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Update Ad
	 * @param ad
	 */
	public put(ad: IAd): Observable<IAd> {
		const url = `${this.baseUrl}/${ad.id}`;
		const body = {
			languageId: ad.languageId,
			content: ad?.default?.content,
			creatives: ad?.default?.creatives,
			cards: ad?.default.cards,
		};
		return this.http.put<any>(url, body).pipe(
			map(() => Object.assign(ad)),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Get ads by ad group id
	 * @param adIds
	 */
	public getByAdGroupId(adGroupId: string): Observable<IAd[]> {
		const queryParams = `?adGroupId=${adGroupId}`;
		const url = `${this.baseUrl}/${queryParams}`;

		return this.http.get<IAd[]>(url).pipe(
			map((ads) => ads),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Get Ad
	 * @param adId
	 */
	public getById(adId: string): Observable<IAd> {
		const url = `${this.baseUrl}/${adId}`;

		return this.http.get<IAd>(url).pipe(
			map((ad) => ad),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Get Ads
	 * @param adsIds
	 */
	public getAds(adIds: string[]): Observable<IAd[]> {
		let queryParamMainPart = '';
		adIds.forEach((adId) => {
			queryParamMainPart = `${queryParamMainPart}ids=${adId}&`;
		});
		const queryParameters = `?${queryParamMainPart.substring(
			0,
			queryParamMainPart.length - 1,
		)}`;
		const url = `${this.baseUrl}/${queryParameters}`;

		return this.http.get<IAd[]>(url).pipe(
			map((ads) => ads),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Duplicate ads
	 * @param ads
	 */
	public duplicate(duplicateAds: IDuplicateAd[]): Observable<string[]> {
		const url = `${this.baseUrl}/duplicate`;
		const body = {
			ads: duplicateAds,
		};

		return this.http.post<any>(url, body, { observe: 'response' }).pipe(
			map((res: HttpResponse<any>) =>
				this.extractAdIdsFromUrl(res.headers.get('location')),
			),
			catchError(ApiService.handleError),
		);
	}

	private extractAdIdsFromUrl(url: string): string[] {
		const urlParams = new URLSearchParams(url);
		const ids: string[] = [];

		urlParams.forEach((value) => {
			ids.push(value);
		});

		return ids;
	}
}
