import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { CREATIVE_SET_FOLDER_ENTITY } from 'src/app/state/app.state';
import { Observable } from 'rxjs';
import { ICreativeSetFolder } from 'src/app/core/models';
import { AppCoreServices } from 'src/app/core/app-core-service';

@Injectable()
export class CreativeSetFolderDataService extends DefaultDataService<ICreativeSetFolder> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(CREATIVE_SET_FOLDER_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: {
		accountSlug?: string;
		brandSlug?: string;
		brandId?: string;
		search?: boolean;
		queryValue?: string;
		folderId?: string;
	}): Observable<ICreativeSetFolder[]> {
		return this.appCoreServices.commonListViewApiService.getCreativeSetsWithFolders(
			params['brandId'],
			params['search'],
			params['queryValue'],
			params['folderId'],
		);
	}
}
