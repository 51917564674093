<div class="card">
    <div class="card-header">
        <div class="card-header-icon">
            <ui-svg-icon icon="arrow-left"></ui-svg-icon>
        </div>
        <fb-header-inbox [isExpanded]="true"> </fb-header-inbox>

        <fb-menu-dots-icon class="card-header-icon" />
    </div>

    <div class="card-primary-text">
        <fb-primary-text [primaryTextValue]="placementMetaData.primaryTextValue" />
    </div>

    <carousel-slider
        [cards]="placementMetaData?.cards"
        [sliderType]="sliderTypeEnum.clickable"
        [initialOffset]="10"
        [cardWidth]="205">
        <ng-template let-card="card">
            <carousel-card-inbox [card]="card"></carousel-card-inbox>
        </ng-template>
    </carousel-slider>
</div>
