import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IPublishLogItemVM } from 'src/app/presentation/view-models';
import { LogSummaryComponent } from './log-summary/log-summary.component';
import { LogBodyComponent } from './log-body/log-body.component';
import { ExpandableItemComponent } from 'src/app/presentation/pages/shared/components/expandable-item/expandable-item.component';

@Component({
	selector: 'publish-log-item',
	templateUrl: './publish-log-item.component.html',
	styleUrls: ['./publish-log-item.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		LogSummaryComponent,
		LogBodyComponent,
		ExpandableItemComponent,
	],
})
export class PublishLogItemComponent {
	@Input() public isExpanded: boolean;
	@Input() public publishLogItem: IPublishLogItemVM;
}
