import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { IAdStatus, IConnection } from 'src/app/core/models';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import {
	ILocalizationVM,
	IPublishSummaryAdVM,
	IAdVM,
	IAdGroupVM,
} from 'src/app/presentation/view-models';
import { PublishSummaryMappersService } from './publish-summary.mapper';

@Injectable({
	providedIn: 'root',
})
export class PublishSummaryService {
	constructor(
		private appFeatureServices: AppFeatureServices,
		private publishSummaryMapper: PublishSummaryMappersService,
	) {}

	public loadIntegrations(adIds: string[]): Observable<string[]> {
		return this.appFeatureServices.adFeature.ad.loadAdGroupIds(adIds).pipe(
			switchMap((adGroupIds) =>
				this.appFeatureServices.adFeature.adGroup.loadIntegrationIds(
					adGroupIds,
				),
			),
			map((integrationIds) => integrationIds.filter((id) => !!id)),
		);
	}

	public loadAdsSummary(adIds: string[]): Observable<IPublishSummaryAdVM[]> {
		const localizations$: Observable<ILocalizationVM[]> =
			this.appFeatureServices.accountFeature.localization.loadAll().pipe(
				filter((localizations) => !!localizations),
				map((localizations) => localizations),
			);

		const ads$: Observable<IAdVM[]> = combineLatest(
			adIds.map((adId) =>
				this.appFeatureServices.adFeature.ad.loadById(adId),
			),
		);

		const adGroups$: Observable<IAdGroupVM[]> =
			this.appFeatureServices.adFeature.adGroup
				.loadAdGroups()
				.pipe(filter((adGroups) => !!adGroups));

		const adStatuses$: Observable<IAdStatus[]> = combineLatest(
			adIds.map((adId) =>
				this.appFeatureServices.publishFeature.publish.loadAdStatusByAdId(
					adId,
				),
			),
		);

		const adGroupConnections$: Observable<IConnection[]> =
			this.appFeatureServices.adFeature.adGroupConnection.loadAll();

		return combineLatest([
			ads$,
			adGroups$,
			localizations$,
			adStatuses$,
			adGroupConnections$,
		]).pipe(
			map(([ads, adGroups, languages, adStatuses, adGroupConnections]) =>
				this.publishSummaryMapper.mapAdToPublishSummaryAd(
					ads,
					adGroups,
					languages,
					adStatuses,
					adGroupConnections,
				),
			),
		);
	}

	public isLoading(): Observable<boolean> {
		const isLoadingLocalizations$ =
			this.appFeatureServices.accountFeature.localization.isLoadingLocalizations();
		const isLoadingAds$ =
			this.appFeatureServices.adFeature.ad.isLoadingAds();
		const isLoadingAdGroups$ =
			this.appFeatureServices.adFeature.adGroup.isLoadingAdGroups();
		const isLoadingAdStatuses$ =
			this.appFeatureServices.publishFeature.publish.isLoadingAdStatuses();

		return combineLatest([
			isLoadingLocalizations$,
			isLoadingAds$,
			isLoadingAdGroups$,
			isLoadingAdStatuses$,
		]).pipe(
			map((isSomeLoading) =>
				isSomeLoading.some((isLoading) => !!isLoading),
			),
		);
	}
}
