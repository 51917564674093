import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { last, map, mergeAll, take } from 'rxjs/operators';
import { ICampaign } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { BaseFeatureService } from 'src/app/features/base-feature.service';
import { ICampaignVM } from 'src/app/presentation/view-models';
import { CampaignMappersService } from '../../mappers/campaign.mappers';

@Injectable({
	providedIn: 'root',
})
export class CampaignBaseService extends BaseFeatureService<ICampaignVM> {
	constructor(
		public appEntityServices: AppEntityServices,
		public campaignMappersService: CampaignMappersService,
	) {
		super();
	}

	public getById(campaignId: string): void {
		this.appEntityServices.campaignEntity.campaign.getByKey(campaignId);
	}

	public load(): Observable<ICampaignVM> {
		return this.appEntityServices.campaignEntity.campaign.entities$.pipe(
			mergeAll(),
			take(1),
			last(),
			map((campaign: ICampaign) =>
				this.campaignMappersService.mapToCampaignVM(campaign),
			),
		);
	}

	public deleteById(id: string): Observable<string | number> {
		return this.appEntityServices.campaignEntity.campaign.delete(id);
	}

	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected getWithQuery(_params: any): void {
		throw new Error('Method not implemented.');
	}
	protected loadAll(): Observable<ICampaignVM[]> {
		throw new Error('Method not implemented.');
	}
	protected loadById(_id: string): Observable<ICampaignVM> {
		throw new Error('Method not implemented.');
	}
	protected loadByIds(_ids: string[]): Observable<ICampaignVM[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
