import { Injectable } from '@angular/core';
import { Observable, filter, map } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { TiktokIdentityMapperService } from '../../mappers/tt-identity.mapper';
import { TiktokIdentityBaseService } from './tt-ad-identity-base.service';
import { ITiktokIdentity } from 'src/app/core/models';
import { ITiktokIdentityVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class TiktokIdentityService extends TiktokIdentityBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public tiktokIdentityMapper: TiktokIdentityMapperService,
	) {
		super(appEntityServices, tiktokIdentityMapper);
	}

	public loadAllByAdAccountId(
		adAccountId: string,
	): Observable<ITiktokIdentityVM[]> {
		const campaigns$: Observable<ITiktokIdentity[]> =
			this.appEntityServices.tiktokEntity.identity.entities$;

		return campaigns$.pipe(
			filter((identities) => !!identities),
			map((identities) =>
				identities.filter(
					(identity) => identity.adAccountId === adAccountId,
				),
			),
			map((identities) =>
				this.tiktokIdentityMapper.toIdentitiesVM(identities),
			),
		);
	}

	public isLoadingIdentities(): Observable<boolean> {
		return this.appEntityServices.tiktokEntity.identity.loading$;
	}
}
