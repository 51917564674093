import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
} from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { Observable } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { ILocalizationVM } from 'src/app/presentation/view-models';

@Component({
	selector: 'language-item',
	templateUrl: './language-item.component.html',
	styleUrls: ['./language-item.component.scss'],
	imports: [UIModule, CommonModule, TextPipe],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageItemComponent implements OnChanges {
	@Input() public languageId: string;
	@Input() public inActive: boolean;
	@Input() public isChildValue: boolean;

	public language$: Observable<ILocalizationVM>;

	constructor(private appFeatureServices: AppFeatureServices) {}

	public ngOnChanges(): void {
		this.language$ =
			this.appFeatureServices.accountFeature.localization.loadById(
				this.languageId,
			);
	}
}
