import { Component } from '@angular/core';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { FacebookPlacementCoreBaseDirective } from '../../../shared/directives/facebook-placement-core-base.directive';
import { FacebookSupportedPlacements } from '../../../shared/enums';
import { CommonModule } from '@angular/common';
import { CreativeContentComponent } from '../../../../../../../elements/creative-content/creative-content.component';
import { HeadlineComponent } from '../../../shared/headline/headline.component';

@Component({
	standalone: true,
	imports: [CommonModule, CreativeContentComponent, HeadlineComponent],
	selector: 'facebook-right-column-placement-core',
	templateUrl: './fb-right-column-placement-core.component.html',
	styleUrls: ['./fb-right-column-placement-core.component.scss'],
})
export class FacebookRightColumnPlacementCoreComponent extends FacebookPlacementCoreBaseDirective {
	constructor(public appFeatureServices: AppFeatureServices) {
		super(
			appFeatureServices,
			FacebookSupportedPlacements.FacebookRightColumn,
		);
	}
}
