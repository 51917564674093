<ng-container>
    <div class="title-container" *ngIf="placement$ | async as placement">
        <creative-deactivation-warning
            class="creative-deactivated-icon"
            [creativeSetId]="assignedCreative?.setId"
            [creativeId]="assignedCreative?.id" />
        <customized-icon [isCustomized]="isCustomized" />
        <ng-container *ngIf="placementCategory$ | async as placementCategory">
            <div [ngSwitch]="placementCategory" class="category">
                <ui-svg-icon
                    *ngSwitchCase="placementCategoryEnum.facebook"
                    class="image-icon"
                    icon="facebook" />
                <ui-svg-icon
                    *ngSwitchCase="placementCategoryEnum.instagram"
                    class="image-icon"
                    icon="instagram" />
                <ui-svg-icon
                    *ngSwitchCase="placementCategoryEnum.messenger"
                    class="image-icon"
                    icon="messenger" />
                <ui-svg-icon
                    *ngSwitchCase="placementCategoryEnum.audience"
                    class="image-icon"
                    icon="audience-network" />
            </div>
        </ng-container>
        <div>{{ placement.name }}</div>
    </div>
</ng-container>
