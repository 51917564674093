import { Injectable } from '@angular/core';
import { IAdGroup } from 'src/app/core/models';
import { IAdGroupVM } from 'src/app/presentation/view-models';
import { IConnection } from 'src/app/core/models/ad/ad-group/connection.model';
import { AdMappersService } from '../ad-mapper/ad.mapper';

@Injectable({
	providedIn: 'root',
})
export class AdGroupMappersService {
	constructor(private adMapper: AdMappersService) {}

	public toGroup(groupViewModel: IAdGroupVM): IAdGroup {
		const {
			integrationId,
			adAccountId,
			adSetId,
			pageId,
			instagramAccountId,
			adGroupId,
			identityId,
		} = groupViewModel;

		const connection: IConnection = {
			integrationId,
			adAccountId,
			adSetId,
			pageId,
			instagramAccountId,
			adGroupId,
			identityId,
		};

		return {
			...groupViewModel,
			connection,
		};
	}

	public toGroupViewModel(group: IAdGroup): IAdGroupVM {
		return {
			id: group?.id,
			name: group?.name,
			socialCampaignId: group?.socialCampaignId,
			networkId: group?.networkId,
			supportedPlacementsIds: group?.supportedPlacementsIds,
			connection: group?.connection,
		};
	}
}
