import { Injectable, Injector } from '@angular/core';
import { AdGroupService } from './services/ad-group/ad-group.service';
import { AdGroupConnectionService } from './services/ad-group-connection/ad-group-connection.service';
import { AdValidationService } from './services/ad-validation/ad-validation.service';
import { AdService } from './services/ad/ad.service';
import { AdPlacementCustomizedValidationService } from './services/ad-placement-customization/ad-placement-customize-validation.service';
import { AdScheduleService } from './services/ad-schedule/ad-schedule.service';
import { AdPlacementTemplateService } from './services/ad-placement-template/ad-placement-template.service';
@Injectable()
export class SocialAdFeatureService {
	public ad: AdService;
	public adGroup: AdGroupService;
	public adGroupConnection: AdGroupConnectionService;
	public adValidation: AdValidationService;
	public adCustomizedValidation: AdPlacementCustomizedValidationService;
	public adSchedule: AdScheduleService;
	public adPlacementTemplate: AdPlacementTemplateService;

	constructor(private injector: Injector) {
		this.ad = this.injector.get(AdService);
		this.adGroup = this.injector.get(AdGroupService);
		this.adGroupConnection = this.injector.get(AdGroupConnectionService);
		this.adValidation = this.injector.get(AdValidationService);
		this.adCustomizedValidation = this.injector.get(
			AdPlacementCustomizedValidationService,
		);
		this.adSchedule = this.injector.get(AdScheduleService);
		this.adPlacementTemplate = this.injector.get(
			AdPlacementTemplateService,
		);
	}
}
