import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, mergeAll, take } from 'rxjs/operators';
import { IUser } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { IBrandVM } from 'src/app/presentation/view-models';
import { BaseFeatureService } from '../../../base-feature.service';
import { UserMappersService } from '../../mappers';

@Injectable({
	providedIn: 'root',
})
export class BrandBaseService extends BaseFeatureService<IBrandVM> {
	constructor(
		public appEntityServices: AppEntityServices,
		public userMapper: UserMappersService,
	) {
		super();
	}

	public load(): Observable<IBrandVM> {
		const user$: Observable<IUser> =
			this.appEntityServices.accountEntity.user.entities$.pipe(
				mergeAll(),
				take(1),
			);

		return user$.pipe(
			filter((user) => !!user),
			map((user: IUser) => this.userMapper.mapToBrandVM(user)),
		);
	}

	protected getById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected getWithQuery(_params: any): void {
		throw new Error('Method not implemented.');
	}
	protected loadAll(): Observable<IBrandVM[]> {
		throw new Error('Method not implemented.');
	}
	protected loadById(_id: string): Observable<IBrandVM> {
		throw new Error('Method not implemented.');
	}
	protected loadByIds(_ids: string[]): Observable<IBrandVM[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
