<ng-container>
    <ng-container [ngSwitch]="placementId">
        <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookGroupsFeed">
            <ng-container *ngTemplateOutlet="sharedFeed"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookMessengerInbox">
            <ng-container *ngIf="isExpanded">
                <ng-container
                    *ngTemplateOutlet="facebookMessengerInboxExpanded"></ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookNewsFeed">
            <ng-container *ngTemplateOutlet="sharedFeed"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookVideoFeed">
            <ng-container *ngTemplateOutlet="sharedFeed"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookAdsOnReels">
            <ng-container *ngTemplateOutlet="adsOnReels"></ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #sharedFeed>
    <div class="description" [ngClass]="placementId">
        <div *ngIf="descriptionValue; else descriptionDefault">
            <div>{{ descriptionValue }}</div>
        </div>
    </div>
</ng-template>

<ng-template #facebookMessengerInboxExpanded>
    <div class="description" [ngClass]="placementId">
        <div *ngIf="descriptionValue; else descriptionDefault">
            <div class="text-info">{{ descriptionValue }}</div>
        </div>
    </div>
</ng-template>

<ng-template #adsOnReels>
    <div class="description" [ngClass]="placementId">
        <div *ngIf="descriptionValue; else descriptionDefault">
            <div class="text-info">{{ descriptionValue }}</div>
        </div>
    </div>
</ng-template>

<ng-template #descriptionDefault>
    <div class="default"></div>
</ng-template>
