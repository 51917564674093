import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { IFacebookCampaign } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { IFacebookCampaignVM } from 'src/app/presentation/view-models';
import { FacebookAccountMappersService } from '../../mappers/facebook-account.mappers';
import { FacebookAdCampaignBaseService } from './facebook-ad-campaign-base.service';

@Injectable({
	providedIn: 'root',
})
export class FacebookAdCampaignService extends FacebookAdCampaignBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public facebookAccountMappers: FacebookAccountMappersService,
	) {
		super(appEntityServices, facebookAccountMappers);
	}

	public loadAllByAccountId(
		adAccountId: string,
	): Observable<IFacebookCampaignVM[]> {
		const campaigns$: Observable<IFacebookCampaign[]> =
			this.appEntityServices.facebookEntity.campaign.entities$;

		return campaigns$.pipe(
			filter((campaigns) => !!campaigns),
			map((campaigns) =>
				campaigns.filter(
					(campaign) => campaign.adAccountId === adAccountId,
				),
			),
			map((campaigns) =>
				this.facebookAccountMappers.toCampaignsViewModel(campaigns),
			),
		);
	}

	public isLoadingCampaigns(): Observable<boolean> {
		return this.appEntityServices.facebookEntity.campaign.loading$;
	}
}
