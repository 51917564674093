import { Injectable } from '@angular/core';
import { ITiktokAdGroup } from 'src/app/core/models';
import { ITiktokAdGroupVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class TiktokAdGroupMapperService {
	public toAdGroupVM(adGroup: ITiktokAdGroup): ITiktokAdGroupVM {
		const adGroupVM: ITiktokAdGroupVM = {
			id: adGroup.id,
			name: adGroup.name,
		};

		return adGroupVM;
	}

	public toAdGroupsVM(adGroups: ITiktokAdGroup[]): ITiktokAdGroupVM[] {
		return adGroups.map((adGroup) => this.toAdGroupVM(adGroup));
	}
}
