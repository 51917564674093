import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { ITiktokAdFormat } from 'src/app/core/models/tiktok/tt-ad-format.model';
import { TIKTOK_AD_FORMAT_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class TiktokAdFormatDataService extends DefaultDataService<ITiktokAdFormat> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(TIKTOK_AD_FORMAT_ENTITY, http, httpUrlGenerator);
	}

	public getAll(): Observable<ITiktokAdFormat[]> {
		return this.appCoreServices.socialCampaignApiService.tiktok.adFormatApi.getAdFormats();
	}
}
