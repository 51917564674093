<div class="header">
    <div class="left">
        <ui-svg-icon icon="arrow-left" />
    </div>
    <div class="right">
        <ui-svg-icon icon="search" />
        <ui-svg-icon icon="camera" />
        <div class="profile-elipse"></div>
    </div>
</div>
