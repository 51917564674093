import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CommonFeatureActions } from '../states/actions';
import { State } from '../states/app-state';
import {
	AppWindowStatusEnum,
	ISocialAppNotification,
} from '../states/app-state/common-feature.reducer';
import {
	selectAppWindowStatus,
	selectAppNotification,
} from '../states/selectors/common-feature.selectors';

@Injectable({
	providedIn: 'root',
})
export class CommonService {
	constructor(private store: Store<State>) {}

	public dispatchErrorOccurred(error: any): void {
		this.store.dispatch(
			CommonFeatureActions.appErrorOccurred({ error: error }),
		);
	}

	public dispatchCloseAppWindow(): void {
		this.store.dispatch(CommonFeatureActions.onWindowClosed());
	}

	public dispatchAppNotification(
		socialAppNotification: ISocialAppNotification,
	): void {
		this.store.dispatch(
			CommonFeatureActions.onAppNotificationDispatched({
				notification: socialAppNotification,
			}),
		);
	}

	public appWindowStatus(): Observable<AppWindowStatusEnum> {
		return this.store.pipe(select(selectAppWindowStatus));
	}

	public appNotifications(): Observable<ISocialAppNotification> {
		return this.store.pipe(select(selectAppNotification));
	}
}
