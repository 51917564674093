import { Injectable } from '@angular/core';
import { IIntegration } from 'src/app/core/models';
import { IIntegrationConnectionStatus } from 'src/app/core/models/account/integration/integration-connection-status.model';
import {
	IIntegrationConnectionStatusVM,
	IIntegrationVM,
} from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class IntegrationMappersService {
	public toIntegrationVM(integration: IIntegration): IIntegrationVM {
		return integration as IIntegrationVM;
	}

	public toIntegrationsVM(integrations: IIntegration[]): IIntegrationVM[] {
		return integrations.map((integration) =>
			this.toIntegrationVM(integration),
		);
	}

	public toIntegrationConnectionStatusVM(
		connectionStatus: IIntegrationConnectionStatus,
	): IIntegrationConnectionStatusVM {
		return connectionStatus as IIntegrationConnectionStatusVM;
	}
}
