import { Component } from '@angular/core';
import { FacebookSupportedPlacements } from '../../../shared/enums';
import { BrandTemplateFragment } from '../../../shared/enums/brand-template-fragment';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { FacebookPlacementCoreBaseDirective } from '../../../shared/directives/facebook-placement-core-base.directive';
import { CommonModule } from '@angular/common';
import { CreativeContentComponent } from '../../../../../../../elements/creative-content/creative-content.component';
import { CtaButtonComponent } from '../../../shared/cta-button/cta-button.component';
import { HeadlineComponent } from '../../../shared/headline/headline.component';
import { FacebookPlacementHeaderComponent } from '../../../shared/placement-header/fb-placement-header.component';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		CreativeContentComponent,
		HeadlineComponent,
		FacebookPlacementHeaderComponent,
		CtaButtonComponent,
	],
	selector: 'inbox-collapsed',
	templateUrl: './inbox-collapsed.component.html',
	styleUrls: ['./inbox-collapsed.component.scss'],
})
export class InboxCollapsedComponent extends FacebookPlacementCoreBaseDirective {
	public templateFragments: typeof BrandTemplateFragment =
		BrandTemplateFragment;

	constructor(public appFeatureServices: AppFeatureServices) {
		super(
			appFeatureServices,
			FacebookSupportedPlacements.FacebookMessengerInbox,
		);
	}
}
