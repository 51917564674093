import { Directive, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { TiktokSupportedPlacements } from '../../enums/tt-supported-placement.enum';
import { ITikokPlacementMetaDataVM } from 'src/app/presentation/view-models/tiktok/placement/tt-placement-metadata.vm';
import { TiktokSinglePlacementsComponentStore } from '../tt-single-placements.component.store';
import { SoundEnum } from 'src/app/presentation/view-models/shared/sound.enum';

@Directive()
export abstract class TiktokPlacementBaseDirective implements OnInit {
	public placementId: TiktokSupportedPlacements;
	public placementMetaData$: Observable<ITikokPlacementMetaDataVM>;
	public placementSound$: Observable<SoundEnum>;

	constructor(
		public tiktokSinglePlacementsComponentStore: TiktokSinglePlacementsComponentStore,
		placementId: TiktokSupportedPlacements,
	) {
		this.placementId = placementId;
	}

	public ngOnInit(): void {
		this.placementSound$ = this.tiktokSinglePlacementsComponentStore
			.loadPlacementsSound()
			.pipe(map((placementsSound) => placementsSound[this.placementId]));
		this.placementMetaData$ =
			this.tiktokSinglePlacementsComponentStore.loadPlacementMetaData(
				this.placementId,
			);
	}
}
