import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ConfigService } from 'src/app/core/config/config.service';
import { IPlacementOptions } from 'src/app/core/models';
import { AdFormatEnum, NetworkEnum } from 'src/app/presentation/view-models';
import { ApiService } from '../../internal/api.service';

@Injectable({
	providedIn: 'root',
})
export class PlacementOptionsApiService extends BaseApiService {
	constructor(public http: HttpClient) {
		super(http, `${ConfigService?.config?.SCS_URL}/api/placements`);
	}

	/**
	 * Gets placements based on networkId and adFormatId
	 */
	public getPlacements(
		networkId: NetworkEnum,
		adFormatId?: AdFormatEnum,
	): Observable<IPlacementOptions[]> {
		let params = new HttpParams();

		params = params.append('networkId', networkId);
		params = params.append('adFormatId', adFormatId);

		const url = this.baseUrl;

		return this.http.get<IPlacementOptions[]>(url, { params: params }).pipe(
			map((placements) => placements),
			catchError(ApiService.handleError),
		);
	}
}
