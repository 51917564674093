import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { MergeStrategy } from '@ngrx/data';
import { filter, map, switchMap } from 'rxjs/operators';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import {
	IAssignedCreativeVM,
	IAdLogVM,
	ICreativeVM,
	IAdWithPlacementsVM,
	IAdVM,
	IAdGroupVM,
	IDetailedAdVM,
	IAdContentTemplateVM,
	IDetailedBulkAdVM,
} from 'src/app/presentation/view-models';
import { AdMappersService } from '../../mappers/ad-mapper/ad.mapper';
import { AdValidationService } from '../ad-validation/ad-validation.service';
import {
	IAd,
	IAdContentDictionary,
	IAdLog,
	IConnection,
} from 'src/app/core/models';
import { IAdPlacement } from 'src/app/core/models/ad/ad/ad-placement.model';
import { AdBaseService } from './ad-base.service';
import { BulkAdMapperService } from '../../mappers/ad-mapper/bulk-ad-mapper';
import { IDuplicateAdVM } from 'src/app/presentation/view-models/ad/duplicate-ad.vm';
import { IMixedAdDefaultContentVM } from 'src/app/presentation/view-models/ad/ad-mixed-content.vm';

@Injectable({
	providedIn: 'root',
})
export class AdService extends AdBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public adValidationService: AdValidationService,
		public adMapper: AdMappersService,
		public bulkMapper: BulkAdMapperService,
	) {
		super(appEntityServices, adValidationService, adMapper, bulkMapper);
	}

	public getAdsByAdGroupId(adGroupId: string): Observable<IAd[]> {
		return this.appEntityServices.adEntity.ad.getWithQuery({ adGroupId });
	}

	public getAdsLogByCampaignId(campaignId: string): void {
		this.appEntityServices.adEntity.adLog.getWithQuery({ campaignId });
	}

	public loadAdGroupIdFromAdId(adId: string): Observable<string> {
		const ads$: Observable<IAd[]> =
			this.appEntityServices.adEntity.ad.entities$;

		return ads$.pipe(
			filter((ads) => !!ads.length),
			map((ads) => ads.find((ad) => ad.id === adId).adGroupId),
		);
	}

	public loadAdGroupIds(adIds: string[]): Observable<string[]> {
		return of(adIds).pipe(
			switchMap((ids) =>
				combineLatest(ids.map((id) => this.loadAdGroupIdFromAdId(id))),
			),
		);
	}

	public loadAdsLog(): Observable<IAdLogVM[]> {
		const adsLog$: Observable<IAdLog[]> =
			this.appEntityServices.adEntity.adLog.entities$.pipe(
				filter((ads) => !!ads.length),
			);

		return adsLog$;
	}

	public loadAdsIdsByAdGroupId(adGroupId: string): Observable<string[]> {
		return this.appEntityServices.adEntity.ad.entities$.pipe(
			map((ads) => ads.filter((ad) => ad.adGroupId === adGroupId)),
			map((ads) => ads.map((ad) => ad.id)),
		);
	}

	public loadAdsByAdGroupId(adGroupId: string): Observable<IAdVM[]> {
		return this.appEntityServices.adEntity.ad.entities$.pipe(
			filter((ads) => !!ads.length),
			map((ads) => ads.filter((ad) => ad.adGroupId === adGroupId)),
			map((ads) => ads.map((ad) => this.adMapper.toAdVM(ad))),
		);
	}

	public loadAdNetworkIdByAdId(adId: string): Observable<string> {
		return this.loadAdGroupIdFromAdId(adId).pipe(
			switchMap((adGroupId) =>
				this.appEntityServices.adEntity.adGroup.entities$.pipe(
					filter((adGroups) => !!adGroups.length),
					map((adGroups) =>
						adGroups.filter((adGroup) => adGroup.id === adGroupId),
					),
				),
			),
			map((adGroup) => adGroup[0].networkId),
		);
	}

	public loadAdCreativePerPlacement(
		adId: string,
		placementId: string,
	): Observable<IAssignedCreativeVM> {
		return this.appEntityServices.adEntity.ad.entities$.pipe(
			map((ads) => ads.find((ad) => ad.id === adId)),
			map((ad) =>
				ad.placements.find(
					(placement) => placement.placementId === placementId,
				),
			),
			map((placement) =>
				placement?.creative
					? this.adMapper.creativesMapper.toCreativeVM(
							placement.creative,
						)
					: null,
			),
		);
	}

	public loadDetailedById(
		adId: string,
		contentTemplates: IAdContentTemplateVM[],
	): Observable<IDetailedAdVM> {
		const ads$: Observable<IAd[]> =
			this.appEntityServices.adEntity.ad.entities$;

		const ad$: Observable<IAd> = ads$.pipe(
			filter((ads) => !!ads.length),
			map((ads) => ads.find((ad) => ad.id === adId)),
			filter((ad) => !!ad),
		);

		return ad$.pipe(
			map((ad) => this.adMapper.toDetailedAdVM(ad, contentTemplates)),
		);
	}

	public loadDetailedBulkAd(
		adsIds: string[],
		contentTemplates: IAdContentTemplateVM[],
	): Observable<IDetailedBulkAdVM> {
		const ads$: Observable<IAd[]> =
			this.appEntityServices.adEntity.ad.entities$.pipe(
				map((ads) => ads?.filter((ad) => adsIds.includes(ad.id))),
				filter((ads) => !!ads.length),
			);

		return ads$.pipe(
			map((ads) => this.toCombinedAdVM(ads, contentTemplates)),
		);
	}

	public toCombinedAdVM(
		ads: IAd[],
		contentTemplates: IAdContentTemplateVM[],
	): IDetailedBulkAdVM {
		const ad = this.bulkMapper.toCombinedAd(ads);
		const mixedDefaultContent: IMixedAdDefaultContentVM =
			this.bulkMapper.mapMixedAdDefaultContent(ads);

		const adVM = this.adMapper.toDetailedAdVM(ad, contentTemplates);

		return { ...adVM, mixedDefaultContent };
	}

	public isLoadingAds(): Observable<boolean> {
		return this.appEntityServices.adEntity.ad.loading$;
	}

	public saveAdPlacement(
		adId: string,
		placement: IAdPlacement,
	): Observable<IAd> {
		return this.appEntityServices.adEntity.ad.updatePlacement(
			adId,
			placement,
		);
	}

	public revertAdPlacement(
		adId: string,
		placementId: string,
	): Observable<IAd> {
		const ad: Partial<IAd> = { id: adId, placementId: placementId };
		return this.appEntityServices.adEntity.ad.update(ad);
	}

	public saveAdName(adId: string, name: string): Observable<IAd> {
		const ad: Partial<IAd> = { id: adId, name };

		return this.appEntityServices.adEntity.ad.update(ad);
	}

	public saveDetailedAd(
		adDetailed: IDetailedAdVM,
		contentTemplates: IAdContentTemplateVM[],
	): Observable<IAd> {
		const ad = this.adMapper.fromDetailedAdVM(adDetailed, contentTemplates);

		return this.appEntityServices.adEntity.ad.update(ad);
	}

	public saveDetailedBulkAd(
		updatedAdVM: IDetailedBulkAdVM,
		savedAdVM: IDetailedAdVM,
		contentTemplates: IAdContentTemplateVM[],
	): Observable<IAd> {
		const ad: IAd = this.adMapper.fromDetailedBulkAdVM(
			updatedAdVM,
			savedAdVM,
			contentTemplates,
		);

		return this.appEntityServices.adEntity.ad.update(ad);
	}

	public deleteAdGroup(adGroupId: string): void {
		this.appEntityServices.adEntity.adGroup.delete(adGroupId);
	}

	protected checkIsAnyAdContent(adContent: IAdContentDictionary): boolean {
		if (!adContent) {
			return false;
		}
		if (!Object.keys(adContent).length) {
			return false;
		}
		return true;
	}

	public getPrioritizedUrl(creatives: ICreativeVM[]): string {
		let targetUrl;
		let versionTargetUrl;

		creatives.forEach((creative) => {
			targetUrl = targetUrl || creative?.targetUrl;
			versionTargetUrl = versionTargetUrl || creative?.version?.targetUrl;
		});

		return targetUrl || versionTargetUrl;
	}

	public loadByIdsWithPlacements(
		adIds: string[],
	): Observable<IAdWithPlacementsVM[]> {
		return this.appEntityServices.adEntity.ad.entities$.pipe(
			filter((ads) => !!ads.length),
			map((ads) => ads.filter(({ id }) => adIds.includes(id))),
			map((ads) =>
				ads.map((ad) => this.adMapper.toAdWithPlacementsVM(ad)),
			),
		);
	}

	public duplicateAds(ads: IDuplicateAdVM[]): Observable<void> {
		return this.appEntityServices.adEntity.ad.duplicate(ads).pipe(
			map((duplicateAdsIds) =>
				this.appEntityServices.adEntity.adStatus.addManyToCache(
					this.adMapper.toAdsStatusList(duplicateAdsIds),
					{ mergeStrategy: MergeStrategy.IgnoreChanges },
				),
			),
			map(() => void 0),
		);
	}

	public validateAdsForBulkEditing(
		ads: IAdVM[],
		adGroups: IAdGroupVM[],
		connections: IConnection[],
	): Observable<boolean> {
		const objectives = connections.map(
			(connection) => connection?.objective,
		);

		const promotedObjectTypes = connections.map(
			(connection) => connection?.promotedObjectType,
		);
		const promotedTypesAreDefined = promotedObjectTypes.every(
			(obj) => obj !== undefined,
		);

		const networkIds = adGroups.map((adGroup) => adGroup.networkId);
		const languageIds = ads.map((ad) => ad.languageId);
		const adFormatIds = ads.map((ad) => ad.adFormatId);

		const matchingObjectiveIds =
			new Set(objectives).size === 1 && !objectives.some((obj) => !obj);

		const matchingPromotedObjectTypesIds = promotedTypesAreDefined
			? new Set(promotedObjectTypes).size === 1 &&
				!promotedObjectTypes.some((obj) => !obj)
			: true;

		const matchingNetworkIds = new Set(networkIds).size === 1;
		const matchingLanguagesIds = new Set(languageIds).size === 1;
		const matchingAdFormatIds = new Set(adFormatIds).size === 1;

		const allConditionsTrue =
			matchingNetworkIds &&
			matchingLanguagesIds &&
			matchingObjectiveIds &&
			matchingPromotedObjectTypesIds &&
			matchingAdFormatIds;

		return of(allConditionsTrue);
	}
}
