import { Directive, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import {
	IAssignedCreativeVM,
	IFacebookPlacementSingleMetaDataVM,
} from 'src/app/presentation/view-models';
import { FacebookSupportedPlacements } from '../enums';
import { SoundEnum } from 'src/app/presentation/view-models/shared/sound.enum';

@Directive()
export class FacebookPlacementCoreBaseDirective {
	public forPlacementId: FacebookSupportedPlacements;
	public placementId: typeof FacebookSupportedPlacements =
		FacebookSupportedPlacements;

	@Input() public placementSound$: Observable<SoundEnum>;
	@Input({ required: true })
	public placementMetaData: IFacebookPlacementSingleMetaDataVM;
	@Input() public selectedCustomizedCreative: IAssignedCreativeVM;
	public placementMetaData$: Observable<IFacebookPlacementSingleMetaDataVM>;

	constructor(
		public appFeatureService: AppFeatureServices,
		forPlacementId: FacebookSupportedPlacements,
	) {
		this.forPlacementId = forPlacementId;
	}
}
