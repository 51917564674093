import { Injectable } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { ConfigService } from '../../config/config.service';

@Injectable({ providedIn: 'root' })
export class IntercomService {
	private logger = new Logger('IntercomService');
	private static app_id = 'vc7jxmzv';

	constructor() {
		if (ConfigService.isDevelopment() || ConfigService.isCypress()) {
			return;
		}

		if (!window.Intercom) {
			this.logger.verbose('Intercom not detected');
			return;
		}

		this.logger.verbose('Booting Intercom');

		window.Intercom('boot', {
			app_id: IntercomService.app_id,
			alignment: 'right',
			horizontal_padding: 20,
			vertical_padding: 20,
		});
	}

	public updatePosition(
		horizontalPadding: number,
		verticalPadding: number,
		alignment = 'right',
	): void {
		if (window.Intercom) {
			window.Intercom('update', {
				app_id: IntercomService.app_id,
				alignment: alignment,
				horizontal_padding: horizontalPadding,
				vertical_padding: verticalPadding,
			});
		}
	}

	public showNewMessage(message?: string): void {
		if (window['Intercom']) {
			this.logger.verbose('showing message');
			window.Intercom('showNewMessage', message);
		}
	}

	public showIntercomNews(): void {
		if (window['Intercom']) {
			this.logger.verbose('showing news');
			window.Intercom('showSpace', 'news');
		}
	}
}
