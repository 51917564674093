import { Injectable } from '@angular/core';
import { UINotificationService } from '@bannerflow/ui';
import { EntityAction, ofEntityOp, OP_ERROR } from '@ngrx/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs/operators';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { CommonFeatureActions } from '../actions';

@Injectable()
export class SocialEffects {
	constructor(
		private actions$: Actions,
		private notificationService: UINotificationService,
		private textPipe: TextPipe,
	) {}

	public showNotificationOnError$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommonFeatureActions.appErrorOccurred),
			map(() => {
				this.notificationService.open(
					this.textPipe.transform('Shared.SOMETHING_WENT_WRONG'),
					{
						type: 'error',
						placement: 'top',
						width: 31.6,
						autoCloseDelay: 5000,
						icon: 'warning',
					},
				);
				return CommonFeatureActions.notificationShown();
			}),
		),
	);

	public showEntityNotificationOnError$ = createEffect(() =>
		this.actions$.pipe(
			ofEntityOp(),
			filter((entityAction: EntityAction) =>
				entityAction.payload.entityOp.endsWith(OP_ERROR),
			),
			map((action) =>
				CommonFeatureActions.appErrorOccurred({
					error: action.payload.data.error,
				}),
			),
		),
	);
}
