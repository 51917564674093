import { QueryParams } from '@ngrx/data';
import { Observable } from 'rxjs';

export abstract class BaseFeatureService<T> {
	protected abstract getById(id: string): void;
	protected abstract getByIds(ids: string[]): void;
	protected abstract getWithQuery(params: QueryParams | any): void;

	protected abstract load(): Observable<T>;
	protected abstract loadAll(): Observable<T[]>;

	protected abstract loadById(id: string): Observable<T>;
	protected abstract loadByIds(ids: string[]): Observable<T[]>;

	protected abstract deleteById(id: string): void;
	protected abstract deleteByIds(ids: string[]): void;
}
