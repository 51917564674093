import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ConfigService } from 'src/app/core/config/config.service';
import { ApiService } from '../../../internal/api.service';
import { IStudioCreativeSet } from 'src/app/core/models';
import { ICreativeSetSCSResponse } from './creative-set-scs-response.model';
import { CreaitveSetApiResponseMapperService } from './creative-set-api-response.mapper.service';

@Injectable({
	providedIn: 'root',
})
export class CreaitveSetApiService extends BaseApiService {
	constructor(
		public http: HttpClient,
		public scsCreativeSetMapper: CreaitveSetApiResponseMapperService,
	) {
		super(http, `${ConfigService?.config?.SCS_URL}/api/creative-sets`);
	}

	public getCreativeSetsByIds(
		creativeSetIds: string[],
	): Observable<IStudioCreativeSet[]> {
		const ids = creativeSetIds.filter((id) => !!id);

		if (!ids || ids.length === 0) {
			return of([]);
		}

		const url = `${this.baseUrl}?creativeSetIds=${ids[0]}`;
		return this.http.get<ICreativeSetSCSResponse[]>(url).pipe(
			map((creativeSets) =>
				creativeSets.map((creativeSet) =>
					this.scsCreativeSetMapper.mapSCSReponseToStudioCreativeSet(
						creativeSet,
					),
				),
			),
			catchError(ApiService.handleError),
		);
	}
}
