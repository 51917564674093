import { Component } from '@angular/core';
import { TextPipe } from '../shared/pipes/text.pipe';
import { UIModule } from '@bannerflow/ui';

@Component({
	selector: 'not-found-page',
	templateUrl: './not-found-page.component.html',
	styleUrls: ['./not-found-page.component.scss'],
	standalone: true,
	imports: [TextPipe, UIModule],
})
export class NotFoundPageComponent {}
