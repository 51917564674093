import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { UIModule, UINotificationType } from '@bannerflow/ui';

@Component({
	selector: 'warning-message',
	templateUrl: './warning-message.component.html',
	styleUrls: ['./warning-message.component.scss'],
	standalone: true,
	imports: [CommonModule, UIModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningMessageComponent {
	@Input() public type: UINotificationType;
	@Input() public noIcon: boolean;
	@Input() public iconPosition: 'top' | 'center' = 'center';
}
