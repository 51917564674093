<div class="card">
    <ng-container
        *ngIf="(card?.creative?.setId && card?.creative?.id) || card?.creative?.creativeUrl">
        <creative-content
            [creativeSetId]="card?.creative.setId"
            [creativeId]="card?.creative.id"
            [creativeUrl]="card?.creative?.creativeUrl"
            [selectedMediaType]="card?.mediaType"
            [sound]="card.sound" />
    </ng-container>
</div>
