import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IFacebookCarouselCardMetaDataVM } from 'src/app/presentation/view-models';
import { CreativeContentComponent } from '../../../../../../../elements/creative-content/creative-content.component';
import { FbCtaButtonComponent } from '../../../../../placement-elements/cta/cta-button/fb-cta-button.component';
import { FbHeadlineComponent } from '../../../../../placement-elements/headline/fb-headline.component';
import { FbDescriptionComponent } from '../../../../../placement-elements/description/fb-description.component';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		CreativeContentComponent,
		FbCtaButtonComponent,
		FbHeadlineComponent,
		FbDescriptionComponent,
	],
	selector: 'carousel-card-inbox',
	templateUrl: './carousel-card-inbox.component.html',
	styleUrls: ['./carousel-card-inbox.component.scss'],
})
export class CarouselCardInboxComponent {
	@Input() public card: IFacebookCarouselCardMetaDataVM;
}
