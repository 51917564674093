<ng-container>
    <fb-audience-header [primaryTextValue]="primaryTextValue"></fb-audience-header>
    <div class="creative">
        <creative-content
            *ngIf="(card?.creative?.id && card?.creative?.setId)|| card?.creative?.creativeUrl"
            [creativeSetId]="card?.creative?.setId"
            [creativeId]="card?.creative?.id"
            [creativeUrl]="card?.creative?.creativeUrl"
            [selectedMediaType]="card?.mediaType"
            [sound]="card.sound" />
    </div>
    <div class="footer">
        <fb-headline class="headline" [headLineValue]="card?.headLineValue" [width]="80">
        </fb-headline>
        <fb-cta-button [ctaValue]="card?.callToActionValue" [isPrimary]="true" />
    </div>
</ng-container>
