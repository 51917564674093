import { createAction, props } from '@ngrx/store';
import { ISocialAppNotification } from '../app-state/common-feature.reducer';

export enum CommonFeatureActionTypes {
	AppErrorOccurred = '[Social] App error occurred',
	NotificationShown = '[Social] Notification shown',
	OnWindowClosed = '[Social] On window closed',
	AppNotificationDispatched = '[Social] App notification dispatched',
}

export const appErrorOccurred = createAction(
	CommonFeatureActionTypes.AppErrorOccurred,
	props<{ error: any }>(),
);

export const notificationShown = createAction(
	CommonFeatureActionTypes.NotificationShown,
);

export const onWindowClosed = createAction(
	CommonFeatureActionTypes.OnWindowClosed,
);

export const onAppNotificationDispatched = createAction(
	CommonFeatureActionTypes.AppNotificationDispatched,
	props<{ notification: ISocialAppNotification }>(),
);
