import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { ConfigService } from 'src/app/core/config/config.service';
import { ApiService } from '../../../internal/api.service';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ITiktokAdGroup, ITiktokAdGroupPlacements } from 'src/app/core/models';

@Injectable({
	providedIn: 'root',
})
export class TiktokAdGroupApiService extends BaseApiService {
	constructor(public http: HttpClient) {
		super(http, `${ConfigService?.config?.SCS_URL}/api/tiktok`);
	}

	/**
	 * Get ad groups
	 * @param integrationId
	 * @param adAccountId
	 * @param campaignId
	 */
	public getAdGroups(
		integrationId: string,
		adAccountId: string,
		campaignId: string,
	): Observable<ITiktokAdGroup[]> {
		const endpoint = `integrations/${integrationId}/ad-accounts/${adAccountId}/campaigns/${campaignId}/ad-groups`;
		const url = `${this.baseUrl}/${endpoint}`;

		return this.http.get<ITiktokAdGroup[]>(url).pipe(
			map((adGroups) =>
				adGroups.map((adGroup) => ({
					...adGroup,
					integrationId,
					adAccountId,
					campaignId,
				})),
			),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Get ad group placements
	 * @param integrationId
	 * @param adAccountId
	 * @param campaignId
	 * @param adGroupId
	 */
	public getAdGroupPlacements(
		integrationId: string,
		adAccountId: string,
		campaignId: string,
		adGroupId: string,
	): Observable<ITiktokAdGroupPlacements[]> {
		const endpoint = `integrations/${integrationId}/ad-accounts/${adAccountId}/campaigns/${campaignId}/ad-groups/${adGroupId}/placements`;
		const url = `${this.baseUrl}/${endpoint}`;

		return this.http.get<string[]>(url).pipe(
			map((placements) => [
				{
					id: adGroupId,
					placementsIds: placements,
				},
			]),
			catchError(ApiService.handleError),
		);
	}
}
