<ng-container>
    <div class="buttons-container">
        <button class="menu" id="interaction-click-menu" (click)="openMenu()">
            <ui-svg-icon icon="kebab" [uiDropdownTarget]="dropDownMenu"> </ui-svg-icon>
        </button>
        <ui-dropdown #dropDownMenu [offset]="{x: 18, y: 0}">
            <customize-option
                [adId]="adId"
                [placementId]="placementId"
                [supportedPlacements]="supportedPlacements"
                (click)="onCustomizeClick()">
            </customize-option>
            <revert-customization-option
                (click)="onRevertClick()"
                [adId]="adId"
                [placementId]="placementId">
                <ui-dropdown-item
                    id="interaction-revert-customization"
                    svgIcon="discard-changes">
                    Revert to original
                </ui-dropdown-item>
            </revert-customization-option>
        </ui-dropdown>
    </div>
</ng-container>
