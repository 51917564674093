import { Injectable, Injector } from '@angular/core';
import { CreativePickerService } from './services/creative-picker/creative-picker.service';
import { FilterCreativeService } from './services/filter-creative/filter-creative.service';
import { StudioCreativeSetService } from './services/studio-creative-set/studio-creative-set.service';
import { CreativeAspectRatioService } from './services/creatives-aspect-ratio/creatives-aspect-ratio.service';
import { DesignMatchingService } from './services/design-matching/design-matching.service';

@Injectable()
export class StudioFeatureService {
	public creativeSetService: StudioCreativeSetService;
	public filterCreativeService: FilterCreativeService;
	public creativePickerService: CreativePickerService;
	public creativeAspectRatioService: CreativeAspectRatioService;
	public designMatchingService: DesignMatchingService;

	constructor(private injector: Injector) {
		this.creativeSetService = this.injector.get(StudioCreativeSetService);
		this.filterCreativeService = this.injector.get(FilterCreativeService);
		this.creativePickerService = this.injector.get(CreativePickerService);
		this.creativeAspectRatioService = this.injector.get(
			CreativeAspectRatioService,
		);
		this.designMatchingService = this.injector.get(DesignMatchingService);
	}
}
