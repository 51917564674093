@if (urlMessage) {
<div
    [ngClass]="{'target-url-child-inactive': inActive, 'target-url-child': isChildValue}">
    {{ urlMessage }}
</div>
} @else {
<ng-container *ngIf="!!targetUrl; else empty">
    <a
        *ngIf="!isChildValue; else targetUrlChild"
        [ngClass]="isAdList ? 'target-url-ad-list' : 'target-url'"
        [href]="targetUrl"
        target="_blank"
        (click)="$event.stopPropagation()"
        rel="noopener"
        [uiTooltip]="targetUrl"
        uiTooltipTrigger="hover"
        uiTooltipPosition="bottom"
        [uiTooltipHideArrow]="true"
        [uiTooltipDelay]="0">
        <span class="target-url-text"> {{targetUrl }} </span>
        <ui-svg-icon class="ad-icon" icon="go-to-url"></ui-svg-icon>
    </a>
</ng-container>

<ng-template #targetUrlChild>
    <div
        class="target-url-child"
        [ngClass]="{'target-url-child-inactive':  inActive}"
        [uiTooltipDisabled]="!inActive"
        [uiTooltip]="'Shared.PLACEMENT_IS_MISSING' | text"
        uiTooltipTrigger="hover"
        uiTooltipPosition="bottom"
        [uiTooltipHideArrow]="true"
        [uiTooltipDelay]="0">
        {{ targetUrl }}
    </div>
</ng-template>

<ng-template #empty>
    <div
        [ngClass]="{'target-url-child-inactive': inActive, 'target-url-child': isChildValue}"
        [uiTooltipDisabled]="!inActive"
        [uiTooltip]="'Shared.PLACEMENT_IS_MISSING' | text"
        uiTooltipTrigger="hover"
        uiTooltipPosition="bottom"
        [uiTooltipHideArrow]="true"
        [uiTooltipDelay]="0">
        {{ "Shared.FALLBACK_EMPTY" | text }}
    </div>
</ng-template>
}
