<ng-container *ngIf="placementMetaData$ | async as placementMetaData">
    <div class="placement">
        <div class="placement-creative">
            <carousel-slider
                [cards]="placementMetaData.cards"
                (indexChanged)="onSlideChange($event)">
                <ng-template let-card="card">
                    <creative-content
                        *ngIf="(card.creative?.id && card.creative.setId) || card?.creative?.creativeUrl"
                        [creativeSetId]="card.creative.setId"
                        [creativeId]="card.creative.id"
                        [creativeUrl]="card?.creative?.creativeUrl"
                        [selectedMediaType]="card.mediaType"
                        [sound]="card.sound" />
                </ng-template>
            </carousel-slider>
        </div>

        <div class="placement-content">
            <div class="header">
                <fb-reels-header />
            </div>

            <div class="footer">
                <div class="footer-content">
                    <div class="footer-content-brand">
                        <fb-stories-reels-brand />
                    </div>
                    <fb-primary-text
                        [primaryTextValue]="placementMetaData.primaryTextValue" />
                    <div class="footer-content-cta">
                        <fb-reels-cta [ctaValue]="placementMetaData.callToAction" />
                    </div>
                </div>
                <div class="footer-content-icons">
                    <fb-reels-icons />
                </div>
            </div>
        </div>
    </div>
</ng-container>
