import {
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SocialPublishFeatureService } from './social-publish-feature.service';
@NgModule({
	declarations: [],
	imports: [],
	providers: [
		SocialPublishFeatureService,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class SocialPublishFeatureModule {}
