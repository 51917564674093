import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { CreativeContentComponent } from '../../../../../../elements/creative-content/creative-content.component';
import { FbPrimaryTextComponent } from '../../../../placement-elements/primary-text/fb-primary-text.component';
import { CarouselSliderComponent } from '../../carousel-elements/carousel-slider/carousel-slider.component';
import { FacebookCarouselBaseDirective } from '../../directives/fb-carousel-base.directive';
import { FacebookCarouselSupportedPlacements } from '../../enums';
import { FacebookCarouselPlacementsComponentStore } from '../../fb-carousel-placements.component.store';
import { StoriesReelsBrandComponent } from '../elements/stories-reels-brand/stories-reels-brand.component';
import { FacebookReelsIconsComponent } from './fb-reels-icons/fb-reels-icons.component';
import { FacebookReelsHeaderComponent } from './fb-reels-header/fb-reels-header.component';
import { FacebookReelsCtaComponent } from './fb-reels-cta/fb-reels-cta.component';

@Component({
	selector: 'fb-carousel-reels',
	templateUrl: './fb-reels.component.html',
	styleUrls: ['./fb-reels.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		UIModule,
		CarouselSliderComponent,
		CreativeContentComponent,
		FbPrimaryTextComponent,
		StoriesReelsBrandComponent,
		FacebookReelsIconsComponent,
		FacebookReelsHeaderComponent,
		FacebookReelsCtaComponent,
	],
})
export class FacebookCarouselReelsComponent extends FacebookCarouselBaseDirective {
	constructor(
		carouselPlacementsStore: FacebookCarouselPlacementsComponentStore,
	) {
		super(
			carouselPlacementsStore,
			FacebookCarouselSupportedPlacements.FacebookReels,
		);
	}
}
