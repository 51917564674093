import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { TIKTOK_CAMPAIGN_ENTITY } from 'src/app/state/app.state';
import { ITiktokCampaign } from 'src/app/core/models';

@Injectable()
export class TiktokCampaignDataService extends DefaultDataService<ITiktokCampaign> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(TIKTOK_CAMPAIGN_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: {
		integrationId: string;
		adAccountId: string;
	}): Observable<ITiktokCampaign[]> {
		return this.appCoreServices.socialCampaignApiService.tiktok.campaignApi.getCampaigns(
			params.integrationId,
			params.adAccountId,
		);
	}
}
