import {
	Component,
	ComponentRef,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';

@Component({
	selector: 'placements-wrapper',
	templateUrl: './placements-wrapper.component.html',
	styleUrls: ['./placements-wrapper.component.scss'],
	standalone: true,
})
export class PlacementsWrapperComponent implements OnChanges, OnDestroy {
	@Input() public placements: any;
	@Input() public placementId: string;
	@Input() public adFormatId: string;
	@ViewChild('placement', { read: ViewContainerRef, static: true })
	public placementContainer: ViewContainerRef;
	public cmpRef: ComponentRef<any>;

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.placementId) {
			this.buildPlacementComponent(this.placements);
		}
	}

	private buildPlacementComponent(placements: any[]): void {
		this.placementContainer.clear();

		const componentClass = placements[this.placementId];

		if (componentClass) {
			this.cmpRef =
				this.placementContainer.createComponent<Component>(
					componentClass,
				);
		}
	}

	public ngOnDestroy(): void {
		if (this.cmpRef) {
			this.cmpRef.destroy();
		}
	}
}
