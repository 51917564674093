import { FACEBOOK_PAGE_ENTITY } from './../../../state/app.state';
import { IFacebookPage } from '../../../core/models/facebook/fb-page.model';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppCoreServices } from 'src/app/core/app-core-service';

@Injectable()
export class FacebookPageDataService extends DefaultDataService<IFacebookPage> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(FACEBOOK_PAGE_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: {
		integrationId: string;
	}): Observable<IFacebookPage[]> {
		return this.appCoreServices.socialCampaignApiService.facebook.pageApi.getPages(
			params['integrationId'],
		);
	}
}
