import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { ConfigService } from 'src/app/core/config/config.service';
import { ApiService } from '../../../internal/api.service';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ITiktokIdentity } from 'src/app/core/models';

@Injectable({
	providedIn: 'root',
})
export class TiktokIdentityApiService extends BaseApiService {
	constructor(public http: HttpClient) {
		super(http, `${ConfigService?.config?.SCS_URL}/api/tiktok`);
	}

	/**
	 * Get campaigns
	 * @param integrationId
	 * @param adAccountId
	 */
	public getIdentities(
		integrationId: string,
		adAccountId: string,
	): Observable<ITiktokIdentity[]> {
		const endpoint = `integrations/${integrationId}/ad-accounts/${adAccountId}/identities`;
		const url = `${this.baseUrl}/${endpoint}`;

		return this.http.get<ITiktokIdentity[]>(url).pipe(
			map((identities) =>
				identities.map((identity) => ({
					...identity,
					integrationId,
					adAccountId,
				})),
			),
			catchError(ApiService.handleError),
		);
	}
}
