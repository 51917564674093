<div class="content">
    <div class="logo">
        <ui-logo [small]="true"></ui-logo>
    </div>
    <div class="title">
        <h1>{{ "Shared.SOMETHING_WENT_WRONG" | text }}</h1>
    </div>
    <div class="text">
        <p>{{ "NotFoundPageComponent.IT_SEEMS_LIKE_SOMETHING" | text }}</p>
        <p class="second-ph">
            {{ "NotFoundPageComponent.IF_YOU_NEED_ANY_ASSISTANCE" | text }}<br />{{
            "NotFoundPageComponent.PLEASE_CONTACT" | text }}
            <a href="mailto:support@bannerflow.com"
                >{{"NotFoundPageComponent.support@bannerflow.com" | text }}</a
            >
        </p>
        <p class="third-ph">{{"NotFoundPageComponent.THE_BANNERFLOW_TEAM" | text }}</p>
    </div>
</div>
