<ng-container>
    <div class="creative">
        <ng-container
            *ngIf="(card?.creative?.id && card?.creative?.setId) || card?.creative?.creativeUrl">
            <creative-content
                [creativeSetId]="card.creative.setId"
                [creativeId]="card.creative.id"
                [creativeUrl]="card?.creative?.creativeUrl"
                [selectedMediaType]="card.mediaType"
                [sound]="card.sound" />
        </ng-container>
    </div>

    <div class="footer">
        <fb-headline
            [headLineValue]="card.headLineValue"
            [fontWeight]="400"
            [width]="165">
        </fb-headline>
    </div>
</ng-container>
