<ng-container>
    <div class="content-container">
        <facebook-placement-header
            [placementId]="placementId.FacebookMessengerInbox"
            [isExpanded]="true"></facebook-placement-header>

        <primary-text
            [primaryTextValue]="placementMetaData.primaryTextValue"
            [placementId]="placementId.FacebookMessengerInbox"
            [isExpanded]="true"></primary-text>
        <div class="creative-content">
            <ng-container
                *ngIf="selectedCustomizedCreative?.id || placementMetaData?.creative?.id || placementMetaData?.creativeUrl">
                <creative-content
                    [sound]="placementSound$ | async"
                    [creativeUrl]="placementMetaData?.creativeUrl"
                    [isCustomized]="placementMetaData?.isCustomized"
                    [creativeId]="selectedCustomizedCreative?.id || placementMetaData?.creative?.id"
                    [creativeSetId]="selectedCustomizedCreative?.setId || placementMetaData?.creative?.setId"
                    [selectedMediaType]="placementMetaData?.selectedMediaType"></creative-content>
            </ng-container>
        </div>
        <div class="headline">
            <headline
                [headLineValue]="placementMetaData.headLineValue"
                [placementId]="placementId.FacebookMessengerInbox"
                [isExpanded]="true"></headline>
        </div>
        <description
            [descriptionValue]="placementMetaData.descriptionValue"
            [placementId]="placementId.FacebookMessengerInbox"
            [isExpanded]="true">
        </description>

        <display-link
            [displayLinkValue]="placementMetaData.displayLinkValue"
            [placementId]="placementId.FacebookMessengerInbox">
        </display-link>
        <cta-button
            [ctaValue]="placementMetaData.callToAction"
            [placementId]="placementId.FacebookMessengerInbox"
            [isExpanded]="true"></cta-button>
    </div>
</ng-container>
