import { Injectable } from '@angular/core';
import { IAdSchedule } from 'src/app/core/models/ad/ad-schedule/ad-schedule';
import { IAdScheduleVM } from 'src/app/presentation/view-models/ad/ad-schedule.vm';

@Injectable({
	providedIn: 'root',
})
export class AdScheduleMappersService {
	public mapAdScheduleToVM(adSchedule: IAdSchedule): IAdScheduleVM {
		if (!adSchedule) {
			return null;
		}

		return {
			adId: adSchedule.id,
			timezoneId: adSchedule.timezone,
			startDate: adSchedule.startDate,
			endDate: adSchedule.endDate,
		};
	}

	public mapAdVMToAdSchedule(adScheduleVM: IAdScheduleVM): IAdSchedule {
		return {
			id: adScheduleVM.adId,
			timezone: adScheduleVM.timezoneId,
			startDate: adScheduleVM.startDate,
			endDate: adScheduleVM.endDate,
		};
	}
}
