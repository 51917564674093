import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { IFacebookInstagramAccount } from 'src/app/core/models/facebook/fb-instagram-account';
import { FACEBOOK_INSTAGRAM_ACCOUNT_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class FacebookInstagramAccountDataService extends DefaultDataService<IFacebookInstagramAccount> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(FACEBOOK_INSTAGRAM_ACCOUNT_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: {
		integrationId: string;
		adAccountId: string;
		pageId: string;
	}): Observable<IFacebookInstagramAccount[]> {
		return this.appCoreServices.socialCampaignApiService.facebook.pageApi.getInstagramAccounts(
			params['integrationId'],
			params['adAccountId'],
			params['pageId'],
		);
	}
}
