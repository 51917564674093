<ng-container>
    <div class="search-container">
        <div
            *ngIf="creativeSetPickerVM.showFolderNav"
            style="cursor: pointer;"
            (click)="onBackInList()">
            <div *ngIf="creativeSetPickerVM?.currentNode" class="node-name">
                <ui-svg-icon fill="#b5b5b5" icon="direction-left" />
                {{ creativeSetPickerVM.currentNode?.name }}
            </div>
        </div>

        <div *ngIf="creativeSetPickerVM.showFolderNav" style="cursor: default;">
            <div *ngIf="!creativeSetPickerVM?.currentNode">
                <ui-svg-icon fill="#b5b5b5" icon="banner-set" />
                Creative sets
            </div>
        </div>

        <span *ngIf="!isSelectedCreative; else backToCreativeSet">
            <ui-input
                [ngClass]="{'search-expanded' : creativeSetPickerVM.searchExpanded}"
                class="search-input"
                iconPosition="left"
                icon="search"
                [superDiscrete]="true"
                [value]="creativeSetPickerVM.searchInput?.value"
                (valueChange)="onSearchValueChange($event)"
                (click)="onToggleSearch(true)">
            </ui-input>

            <ui-svg-icon
                icon="close"
                fill="#b5b5b5"
                [ngClass]="{'search-expanded' : creativeSetPickerVM?.searchExpanded}"
                (click)="onToggleSearch(false)">
            </ui-svg-icon>
        </span>
    </div>

    <div
        *ngIf="creativeSetPickerVM.isLoading$ | async as isLoading; else creativeSetList"
        class="loader-container">
        <ui-loader />
    </div>

    <ng-template #creativeSetList>
        <div class="creativeset-list">
            <div *ngFor="let item of (creativeSetPickerVM.listItems$ | async)">
                <div
                    class="list-row folder"
                    *ngIf="item?.isFolder && !isSelectedCreative"
                    (click)="onCurrentNodeChange(item)">
                    <ui-svg-icon class="list-row-icon" icon="folder" />
                    <span class="ellipsis">{{ item?.name }}</span>
                </div>

                <div
                    class="list-row item"
                    [ngClass]="{'selected': selectedCreativeSetId === item.id}"
                    *ngIf="!item?.isFolder"
                    (click)="onSelectionChange(item?.id)">
                    <div
                        class="image"
                        [ngStyle]="{'background-image': item ? 'url(' + item?.thumb + ')' : 'none' }"></div>
                    <span
                        class="ellipsis"
                        [uiTooltip]="item?.name"
                        uiTooltipTrigger="hover"
                        uiTooltipPosition="top">
                        {{ item?.name }}
                    </span>
                    <ui-svg-icon
                        *ngIf="(brand$ | async) as brand"
                        (click)="navigateToCreativeSet(brand?.id, item?.id)"
                        [uiTooltip]="'Shared.GO_TO_CREATIVESET' | text"
                        uiTooltipTrigger="hover"
                        uiTooltipPosition="top"
                        class="go-to-url-icon"
                        icon="go-to-url" />
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #backToCreativeSet>
        <div style="cursor: pointer;" (click)="onToggleSearch(false)">
            <div class="arrow-back node-name">
                <ui-svg-icon fill="#b5b5b5" icon="direction-left" />
                {{"CreativeSetPickerComponent.BACK_TO_CREATIVE_SET" | text}}
            </div>
        </div>
    </ng-template>
</ng-container>
