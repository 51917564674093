import { Injectable, Injector } from '@angular/core';
import { TiktokAdFormatsEntityService } from './ad-format/tt-ad-formats-entity.service';
import { TiktokAdAccountsEntityService } from './ad-account/tt-ad-accounts-entity.service';
import { TiktokAdGroupsEntityService } from './ad-group/tt-ad-group-entity.service';
import { TiktokAdGroupPlacementsEntityService } from './ad-group-placement/tt-ad-group-placement-entity.service';
import { TiktokCampaignEntityService } from './campaign/tt-campaign-entity.service';
import { TiktokIdentityEntityService } from './identity/tt-identity-entity.service';
import { TiktokSingleImagePlacementsEntityService } from './single-image-placement/tt-single-image-placements-entity.service';
import { TiktokSingleVideoPlacementsEntityService } from './single-video-placement/tt-single-video-placements-entity.service';

@Injectable()
export class TiktokEntityServices {
	public adFormat: TiktokAdFormatsEntityService;
	public adAccount: TiktokAdAccountsEntityService;
	public adGroup: TiktokAdGroupsEntityService;
	public adGroupPlacements: TiktokAdGroupPlacementsEntityService;
	public campaign: TiktokCampaignEntityService;
	public identity: TiktokIdentityEntityService;
	public singleImagePlacement: TiktokSingleImagePlacementsEntityService;
	public singleVideoPlacement: TiktokSingleVideoPlacementsEntityService;

	constructor(private injector: Injector) {
		this.adFormat = this.injector.get(TiktokAdFormatsEntityService);
		this.adAccount = this.injector.get(TiktokAdAccountsEntityService);
		this.adGroup = this.injector.get(TiktokAdGroupsEntityService);
		this.adGroupPlacements = this.injector.get(
			TiktokAdGroupPlacementsEntityService,
		);
		this.campaign = this.injector.get(TiktokCampaignEntityService);
		this.identity = this.injector.get(TiktokIdentityEntityService);

		this.singleImagePlacement = this.injector.get(
			TiktokSingleImagePlacementsEntityService,
		);
		this.singleVideoPlacement = this.injector.get(
			TiktokSingleVideoPlacementsEntityService,
		);
	}
}
