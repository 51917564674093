<ng-container>
    <div *ngIf="primaryTextValue; else skeleton">
        <div class="primary-text-value">{{primaryTextValue}}</div>
    </div>
    <ng-template #skeleton>
        <div class="primary-text-default">
            <div *ngFor="let line of [].constructor(numberOfLines)" class="line"></div>
        </div>
    </ng-template>
</ng-container>
