<div class="slider-container" (click)="$event.stopPropagation()">
    <div class="slider-content" [style.width.px]="cardWidth">
        <div
            *ngFor="let card of cards; let i = index; trackBy: trackByIndex"
            class="slide"
            [ngClass]="{'slide-active': currentIndex === i, 'slide-offset': initialOffset > 0}"
            [@slide]="{value: slideDirection, params:{offset: offset}}">
            <div
                class="slide-content"
                (click)="onSlideClick(i)"
                [style.width.px]="cardWidth">
                <ng-container
                    [ngTemplateOutlet]="cardTemplate"
                    [ngTemplateOutletContext]="{ card: card }"></ng-container>
            </div>
            <div
                *ngIf="sliderType === sliderTypeEnum.arrows"
                class="slider-content-arrows">
                <slider-arrow-navigation
                    [direction]="'left'"
                    (click)="prevCard()"></slider-arrow-navigation>
                <slider-arrow-navigation
                    [direction]="'right'"
                    (click)="nextCard()"></slider-arrow-navigation>
            </div>
        </div>
    </div>

    <slider-default-navigation
        *ngIf="sliderType === sliderTypeEnum.default"
        (onNextClick)="nextCard()"
        (onPrevClick)="prevCard()"></slider-default-navigation>
</div>
