<div class="details" *ngIf="isExpanded">
    <div class="details-section">
        <div class="details-title">
            {{ "LogBodyComponent.TITLE_STATUS_MESSAGE" | text }}
        </div>
        <div class="details-message">
            {{ publishLogItem.statusMessage || ("LogBodyComponent.NO_MESSAGE" | text) }}
        </div>
    </div>

    <div class="details-section">
        <div class="details-title">
            {{ "LogBodyComponent.TITLE_PLACEMENT_DETAILS" | text }}
        </div>
        <div [ngSwitch]="publishLogItem.networkId">
            <div *ngSwitchCase="networkEnum.facebook">
                <fb-ad-placements-log [publishLogItem]="publishLogItem" />
            </div>
            <div *ngSwitchCase="networkEnum.tiktok">
                <tt-ad-placements-log [publishLogItem]="publishLogItem" />
            </div>
        </div>
    </div>
</div>
