import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SocialCampaignPageComponent } from './scm-page.component';

const routes: Routes = [
	{
		path: '',
		component: SocialCampaignPageComponent,
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'list',
			},
			{
				path: 'list',
				title: 'Social Campaign Manager - Ad List',
				loadChildren: () =>
					import('./pages/ad-list/ad-list.module').then(
						(m) => m.AdListModule,
					),
			},
			{
				path: 'preview',
				title: 'Social Campaign Manager - Ad Preview',
				loadChildren: () =>
					import('./pages/ad-preview/ad-preview.module').then(
						(m) => m.AdPreviewModule,
					),
			},
			{
				path: 'adgroup/:adGroupId/ad/:adId/editor',
				title: 'Social Campaign Manager - Ad Editor',
				loadChildren: () =>
					import('./pages/ad-editor/ad-editor.module').then(
						(m) => m.AdEditorModule,
					),
			},
			{
				path: 'editor',
				title: 'Social Campaign Manager - Ads Editor',
				loadChildren: () =>
					import('./pages/ads-editor/ads-editor.module').then(
						(m) => m.AdsEditorModule,
					),
			},
			{
				path: 'publish',
				title: 'Social Campaign Manager - Publish Summary',
				loadChildren: () =>
					import(
						'./pages/publish-summary/publish-summary.module'
					).then((m) => m.PublishSummaryModule),
			},
			{
				path: 'ad/:adId/publish-logs',
				title: 'Social Campaign Manager - Publish Logs',
				loadChildren: () =>
					import('./pages/publish-logs/publish-logs.module').then(
						(m) => m.PublishLogsModule,
					),
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class SocialCampaignPageRoutingModule {}
