import {
	ChangeDetectionStrategy,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ConfigService } from 'src/app/core/config/config.service';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import {
	ModalType,
	SocialConfirmDialogService,
} from 'src/app/presentation/pages/shared/services/social-confirm-dialog.service';
import { ICampaignVM, IUserVM } from 'src/app/presentation/view-models';
import { CampaignEditableNameComponent } from './campaign-editable-name/campaign-editable-name.component';
import { UIModule } from '@bannerflow/ui';
import { CommonModule } from '@angular/common';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';

@Component({
	selector: 'campaign-header',
	templateUrl: './campaign-header.component.html',
	styleUrls: ['./campaign-header.component.scss'],
	imports: [UIModule, CommonModule, CampaignEditableNameComponent, TextPipe],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignHeaderComponent implements OnInit, OnDestroy {
	@ViewChild(CampaignEditableNameComponent)
	public renameCampaign: CampaignEditableNameComponent;
	public user$: Observable<IUserVM | null>;
	public campaign$: Observable<ICampaignVM | null>;
	public campaignEntityLoaded$: Observable<boolean>;
	private deleteSubscription: Subscription;

	constructor(
		private appFeatureServices: AppFeatureServices,
		private socialEntityServices: AppEntityServices,
		private socialConfirmDialogService: SocialConfirmDialogService,
	) {}

	public ngOnInit(): void {
		this.user$ = this.appFeatureServices.accountFeature.user.load();
		this.campaignEntityLoaded$ =
			this.socialEntityServices.campaignEntity.campaign.loading$;
		this.campaign$ =
			this.appFeatureServices.campaignFeature.campaign.load();
	}

	public exitCampaign(user: IUserVM): void {
		window.location.href = `${ConfigService.config.B2_URL}/v2/${user.accountSlug}/${user.brandSlug}/campaigns`;
	}
	public async deleteCampaign(
		campaignId: string,
		user: IUserVM,
	): Promise<void> {
		const confirmResult = await this.socialConfirmDialogService.showDialog(
			ModalType.deleteCampaign,
		);

		if (confirmResult === 'confirm') {
			this.deleteSubscription =
				this.appFeatureServices.campaignFeature.campaign
					.deleteById(campaignId)
					.subscribe((deletedCampaignId) => {
						if (deletedCampaignId === campaignId) {
							this.exitCampaign(user);
						}
					});
		}
	}
	public triggerFocusCampaignInput(): void {
		this.renameCampaign.focusCampaignInput();
	}

	public ngOnDestroy(): void {
		if (this.deleteSubscription) {
			this.deleteSubscription.unsubscribe();
		}
	}
}
