import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class BaseApiService {
	constructor(
		public http: HttpClient,
		public baseUrl: string,
	) {}
}
