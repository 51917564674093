import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FacebookCarouselBaseDirective } from '../../directives/fb-carousel-base.directive';
import { FacebookCarouselSupportedPlacements } from '../../enums';
import { FacebookCarouselPlacementsComponentStore } from '../../fb-carousel-placements.component.store';
import { UIModule } from '@bannerflow/ui';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { AdsOnFacebookReelsHeaderComponent } from './ads-on-fb-reels-header/ads-on-fb-reels-header.component';
import { AdsOnFacebookReelsFooterComponent } from './ads-on-fb-reels-footer/ads-on-fb-reels-footer.component';
import { AdsOnFbReelsCardComponent } from './ads-on-fb-reels-card/ads-on-fb-reels-card.component';
import { CarouselSliderComponent } from '../../carousel-elements/carousel-slider/carousel-slider.component';
import { Observable } from 'rxjs';
import { IBrandVM } from 'src/app/presentation/view-models';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { CarouselDotsComponent } from '../../carousel-elements/carousel-dots/carousel-dots.component';

@Component({
	selector: 'fb-carousel-ads-on-reels',
	templateUrl: './ads-on-fb-reels.component.html',
	styleUrls: ['./ads-on-fb-reels.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		UIModule,
		TextPipe,
		CarouselSliderComponent,
		CarouselDotsComponent,
		AdsOnFacebookReelsHeaderComponent,
		AdsOnFacebookReelsFooterComponent,
		AdsOnFbReelsCardComponent,
	],
})
export class FacebookCarouselAdsOnFacebookReelsComponent
	extends FacebookCarouselBaseDirective
	implements OnInit
{
	public brand$: Observable<IBrandVM>;

	constructor(
		private appFeatureServices: AppFeatureServices,
		carouselPlacementsStore: FacebookCarouselPlacementsComponentStore,
	) {
		super(
			carouselPlacementsStore,
			FacebookCarouselSupportedPlacements.FacebookAdsOnFacebookReels,
		);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.brand$ = this.appFeatureServices.accountFeature.brand.load();
	}
}
