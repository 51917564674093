import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/core/config/config.service';
import { Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { ApiService } from '../internal/api.service';
import { IUser } from '../../models';

@Injectable({
	providedIn: 'root',
})
export class AccountAccessApiService {
	public baseUrl = `${ConfigService?.config?.ACA_URL}/api`;

	constructor(private http: HttpClient) {}

	/**
	 * Gets logged in user info from account access api
	 * @param accountSlug
	 * @param brandSlug
	 */
	public getUser(accountSlug: string, brandSlug: string): Observable<IUser> {
		const url = `${this.baseUrl}/${accountSlug}/${brandSlug}/users/current`;

		return this.http.get<IUser>(url).pipe(
			filter((user) => !!user),
			map((user) => user),
			catchError(ApiService.handleError),
		);
	}
}
