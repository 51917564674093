import { Injectable } from '@angular/core';
import { ASPECT_GROUP_KEY_ANY } from 'src/app/presentation/view-models/shared/aspect-group-key-any';

@Injectable({
	providedIn: 'root',
})
export class PlacementDesignRecommendationsService {
	public isDesignMatchingMinimumDimentions(
		creativeWidth: number,
		creativeHeight: number,
		recommendationMinimumWidth: number,
		recommendationMinimumHeight: number,
	): boolean {
		return !!(
			creativeWidth >= recommendationMinimumWidth &&
			creativeHeight >= recommendationMinimumHeight
		);
	}

	public isDesignMatchingAspectRatio(
		width: number,
		height: number,
		placementAspectRatio: string,
	): boolean {
		if (placementAspectRatio === ASPECT_GROUP_KEY_ANY) {
			return true;
		}

		const aspectRatio = (width / height).toFixed(2);

		const placementRatioHeight = this.heightPart(placementAspectRatio);
		const placementRatioWidth = this.widthPart(placementAspectRatio);

		const placementRatio = (
			placementRatioWidth / placementRatioHeight
		).toFixed(2);

		return aspectRatio === placementRatio;
	}

	private heightPart(aspectRatio: string): number {
		return Number(
			aspectRatio.substring(
				aspectRatio.indexOf(':') + 1,
				aspectRatio.length,
			),
		);
	}

	private widthPart(aspectRatio: string): number {
		return Number(aspectRatio.substring(0, aspectRatio.indexOf(':')));
	}
}
