import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { BaseFeatureService } from '../../../base-feature.service';
import { AdFormatsMappersService } from '../mappers/ad-formats.mappers';
import { ITiktokAdFormat } from 'src/app/core/models/tiktok/tt-ad-format.model';
import { ITiktokAdFormatVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class AdFormatsBaseService extends BaseFeatureService<ITiktokAdFormatVM> {
	constructor(
		public appEntityServices: AppEntityServices,
		public adFormatsMappers: AdFormatsMappersService,
	) {
		super();
	}

	public loadAll(): Observable<ITiktokAdFormatVM[]> {
		const adFormats$: Observable<ITiktokAdFormatVM[]> =
			this.appEntityServices.tiktokEntity.adFormat.entities$;

		return adFormats$.pipe(
			filter((adFormats) => !!adFormats),
			map((adFormats: ITiktokAdFormat[]) =>
				adFormats.map((adFormat) =>
					this.adFormatsMappers.mapAdFormat(adFormat),
				),
			),
		);
	}

	protected getById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected getWithQuery(_params: any): void {
		throw new Error('Method not implemented.');
	}
	protected load(): Observable<ITiktokAdFormat> {
		throw new Error('Method not implemented.');
	}
	protected loadById(_id: string): Observable<ITiktokAdFormat> {
		throw new Error('Method not implemented.');
	}
	protected loadByIds(_ids: string[]): Observable<ITiktokAdFormat[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
