import { Injectable } from '@angular/core';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { ITiktokAdVM } from 'src/app/presentation/view-models';
import { Observable, filter, map } from 'rxjs';
import { TiktokAdMappersService } from '../../mappers/ad-mapper/tt-ad.mapper';
import { ITiktokAdBulkVM } from 'src/app/presentation/view-models/tiktok/ad/tt-ad-bulk.vm';
import { TiktokPlacementService } from '../../../placement/services/tt-placement.service';
import { TiktokAdContentTemplatesService } from './tt-ad-content-templates.service';
import { IAd } from 'src/app/core/models';

@Injectable({
	providedIn: 'root',
})
export class TiktokBulkAdService {
	constructor(
		public appEntityServices: AppEntityServices,
		public ttAdMapper: TiktokAdMappersService,
		public tiktokPlacementService: TiktokPlacementService,
		public adContentTemplates: TiktokAdContentTemplatesService,
	) {}

	public loadBulkAd(adsIds: string[]): Observable<ITiktokAdBulkVM> {
		const ads$ = this.appEntityServices.adEntity.ad.entities$.pipe(
			map((ads) => ads?.filter((ad) => adsIds.includes(ad.id))),
			filter((ads) => !!ads.length),
		);

		return ads$.pipe(map((ads) => this.ttAdMapper.toCombinedAdVM(ads)));
	}

	public saveBulkAd(
		adVM: ITiktokAdBulkVM,
		savedAdVM: ITiktokAdVM,
	): Observable<IAd> {
		const ad: IAd = this.ttAdMapper.toBulkAd(adVM, savedAdVM);
		return this.appEntityServices.adEntity.ad.update(ad);
	}
}
