import { ChangeDetectorRef, Component } from '@angular/core';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { FacebookSinglePlacementsComponentStore } from '../../fb-single-placements.component.store';
import { FacebookPlacementComponentBaseDirective } from '../../shared/directives/facebook-placement-component-base.directive';
import { FacebookSupportedPlacements } from '../../shared/enums';
import { FacebookInStreamVideosCoreComponent } from './core/fb-in-stream-videos-core.component';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	imports: [CommonModule, FacebookInStreamVideosCoreComponent],
	selector: 'facebook-in-stream-videos',
	templateUrl: './fb-in-stream-videos.component.html',
})
export class FacebookInStreamVideosComponent extends FacebookPlacementComponentBaseDirective {
	constructor(
		public appFeatureServices: AppFeatureServices,
		public changeDetectorRef: ChangeDetectorRef,
		public facebookPlacementsComponentStore: FacebookSinglePlacementsComponentStore,
	) {
		super(
			appFeatureServices,
			FacebookSupportedPlacements.FacebookInStreamVideos,
			changeDetectorRef,
			facebookPlacementsComponentStore,
		);
	}
}
