<ng-container>
    <ng-container *ngIf="ad$ | async as ad">
        <ng-container *ngIf="adGroup$ | async as adGroup">
            <ng-container
                *ngIf="hasAdSavedContent$ | async as hasAdSavedContent; else customizeDisabledOption">
                <div (click)="openPlacementCustomizationWizard(ad, adGroup)">
                    <ui-dropdown-item
                        id="interaction-customized-placement"
                        svgIcon="customized">
                        {{ (hasCustomization$ | async ) ? 'Edit customization...' :
                        'Customize...' }}
                    </ui-dropdown-item>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #customizeDisabledOption>
    <div
        (click)="$event.stopPropagation()"
        uiTooltipTrigger="hover"
        uiTooltipPosition="left"
        [uiTooltip]="'CustomizeOptionComponent.NO_CUSTOMIZED_TOOLTIP' | text"
        [uiTooltipDelay]="0">
        <ui-dropdown-item
            id="interaction-customized-placement"
            svgIcon="customized"
            [disabled]="true">
            Customize
        </ui-dropdown-item>
    </div>
</ng-template>
