import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UIModule } from '@bannerflow/ui';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/app-core.module';
import { EntityStoreModule } from './entities/entity-store.module';
import { AppFeatureModule } from './features/app-feature.module';
import { SocialCampaignPageModule } from './presentation/scm-page.module';
import { AppComponent } from './app.component';
import { NotFoundPageComponent } from './presentation/pages/not-found/not-found-page.component';
import { IntercomService } from './core/services/internal/intercom.service';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
	public parse(url: string): UrlTree {
		return super.parse(url.toLowerCase());
	}
}
@NgModule({
	declarations: [AppComponent],
	imports: [
		CommonModule,
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		UIModule,
		CoreModule,
		StoreModule.forRoot({}),
		EffectsModule.forRoot([]),
		EntityStoreModule,
		StoreDevtoolsModule.instrument({
			maxAge: 25,
		}),
		SocialCampaignPageModule,
		AppFeatureModule,
		NotFoundPageComponent,
	],
	providers: [
		{
			provide: UrlSerializer,
			useClass: LowerCaseUrlSerializer,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(private _intercom: IntercomService) {}
}
