import { CommonModule } from '@angular/common';
import {
	Component,
	ContentChild,
	EventEmitter,
	Input,
	OnInit,
	Output,
	TemplateRef,
} from '@angular/core';
import { animationSlider } from './slider.animation';

import { SliderTypeEnum } from './slider-type';
import { IFacebookCarouselCardMetaDataVM } from 'src/app/presentation/view-models';
import { CarouselSliderService } from './carousel-slider.service';
import { SliderArrowNavigationComponent } from './navigations/arrow-navigation/arrow-navigation.component';
import { SliderDefaultNavigationComponent } from './navigations/default-navigation/default-navigation.component';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		SliderArrowNavigationComponent,
		SliderDefaultNavigationComponent,
	],
	selector: 'carousel-slider',
	templateUrl: './carousel-slider.component.html',
	styleUrls: ['./carousel-slider.component.scss'],
	animations: [animationSlider],
})
export class CarouselSliderComponent implements OnInit {
	constructor(private carouselSliderService: CarouselSliderService) {}
	@Input() public cards: IFacebookCarouselCardMetaDataVM[] = [];
	@Input() public sliderType: SliderTypeEnum = SliderTypeEnum.default;
	@Input() public initialOffset = 0;
	@Input() public cardWidth = 255;
	@Output() public indexChanged = new EventEmitter<number>();
	@ContentChild(TemplateRef) public cardTemplate: TemplateRef<any>;
	public sliderTypeEnum: typeof SliderTypeEnum = SliderTypeEnum;
	public currentIndex = 0;
	public slideDirection: string;
	public offset = 0;

	public ngOnInit(): void {
		this.emitCurrentIndex();
	}

	public trackByIndex(index: number): number {
		return index;
	}

	public onSlideClick(index: number): void {
		if (this.sliderType !== this.sliderTypeEnum.clickable) {
			return;
		}

		this.changeCard(index);
	}

	public prevCard(): void {
		const index =
			this.currentIndex === 0
				? this.cards.length - 1
				: this.currentIndex - 1;
		this.changeCard(index);
	}

	public nextCard(): void {
		const maxIndex = this.cards.length - 1;
		const index =
			this.currentIndex + 1 > maxIndex ? 0 : this.currentIndex + 1;
		this.changeCard(index);
	}

	private changeCard(index: number): void {
		if (index !== this.currentIndex) {
			this.slideDirection =
				index > this.currentIndex ? 'next' : 'previous';
			this.currentIndex = index;
			this.offset = this.carouselSliderService.getSliderOffset(
				this.sliderType,
				this.initialOffset,
				this.cardWidth,
				this.currentIndex,
				this.cards.length,
			);

			this.emitCurrentIndex();
		}
	}

	private emitCurrentIndex(): void {
		this.indexChanged.emit(this.currentIndex);
	}
}
