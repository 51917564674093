<ng-container *ngIf="(placementMetaData$ | async) as placementMetaData">
    <div class="placement">
        <div class="grid">
            <div class="grid-tile">
                <div class="creative">
                    <div class="creative-header">
                        <fb-instagram-explore-home-placement-header />
                    </div>

                    <div class="creative-content">
                        <carousel-slider
                            [cards]="placementMetaData.cards"
                            [cardWidth]="168">
                            <ng-template let-card="card">
                                <fb-instagram-explore-home-carousel-card [card]="card" />
                            </ng-template>
                        </carousel-slider>
                    </div>

                    <div class="creative-footer">
                        <fb-instagram-explore-home-cta-button
                            [ctaValue]="placementMetaData.callToAction" />
                    </div>
                </div>
            </div>
            <div class="grid-tile"></div>
            <div class="grid-tile"></div>
            <div class="grid-tile"></div>
            <div class="grid-tile"></div>
            <div class="grid-tile"></div>
            <div class="grid-tile"></div>
            <div class="grid-tile"></div>
            <div class="grid-tile"></div>
            <div class="grid-tile"></div>
            <div class="grid-tile"></div>
            <div class="grid-tile"></div>
            <div class="grid-tile"></div>
        </div>
    </div>
</ng-container>
