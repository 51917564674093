import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AdContentIdEnum } from '../enums';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	imports: [CommonModule],
	selector: 'headline',
	templateUrl: './headline.component.html',
	styleUrls: ['./headline.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadlineComponent {
	@Input() public placementId: string;
	@Input() public headLineValue: string;
	@Input() public isExpanded?: boolean;
	@Input() public twoDefaultLines: boolean;

	public adContentId: typeof AdContentIdEnum = AdContentIdEnum;
}
