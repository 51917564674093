export enum CallToActionIdEnum {
	APPLY_NOW = 'Apply now',
	CONTACT_US = 'Contact us',
	DOWNLOAD = 'Download',
	GET_QUOTE = 'Get quote',
	GET_SHOWTIMES = 'Get showtimes',
	LEARN_MORE = 'Learn more',
	LISTEN_NOW = 'Listen now',
	ORDER_NOW = 'Order now',
	REQUEST_TIME = 'Request time',
	SEE_MENU = 'See menu',
	SHOP_NOW = 'Show now',
	SIGN_UP = 'Sign up',
	SUBSCRIBE = 'Subscribe',
	WATCH_MORE = 'Watch more',
}
