import { Injectable } from '@angular/core';
import { MergeStrategy } from '@ngrx/data';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {
	IAdContentDictionary,
	IAdContentValidation,
	ICarouselAdContentValidation,
} from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { AdContentIdEnum } from 'src/app/presentation/features/integrations/facebook/placement/single/placements/shared/enums';
import {
	AdContentTemplateScopeEnum,
	AdFormatEnum,
	IAdContentTemplateVM,
	IAdContentValidationVM,
	ICarouselAdValidationVM,
	IFacebookAdVM,
	IFacebookCarouselTemplateVM,
	IValidationResultVM,
	ValidationTypeEnum,
} from 'src/app/presentation/view-models';
import { AdMappersService } from '../../mappers/ad-mapper/ad.mapper';
import { IMixedAdDefaultContentVM } from 'src/app/presentation/view-models/ad/ad-mixed-content.vm';

@Injectable({
	providedIn: 'root',
})
export class AdValidationService {
	constructor(
		private appEntityServices: AppEntityServices,
		private adMapper: AdMappersService,
	) {}

	public isAdSupportCallToActions(
		ad: IFacebookAdVM,
		adGroupSupportedPlacements: string[],
	): boolean {
		let isAdSupportCallToAction: boolean;

		adGroupSupportedPlacements?.forEach((adGroupPlacementId) => {
			if (ad?.content?.[adGroupPlacementId]) {
				ad?.content[adGroupPlacementId].adContentTemplates.forEach(
					(adContentTemplate) => {
						if (
							adContentTemplate.id ===
								AdContentIdEnum.callToAction &&
							adContentTemplate.allowedValues.length
						) {
							isAdSupportCallToAction = true;
						}
					},
				);
			}
		});

		return !!isAdSupportCallToAction;
	}

	public getAdContentValidation(
		placementId: string,
		adContentTemplates: IAdContentTemplateVM[] | IAdContentDictionary,
		adFormatId: AdFormatEnum,
		adId?: string,
	): Observable<void> {
		if (adContentTemplates) {
			this.appEntityServices.adEntity.adContentValidation.getWithQuery({
				adId,
				placementId,
				adFormatId,
				adContent: Array.isArray(adContentTemplates)
					? this.adMapper.validationMapper.mapAdContentToValidateAllProperties(
							adContentTemplates,
						)
					: JSON.stringify(adContentTemplates),
			});
		}

		return new Observable<void>();
	}

	public getCarouselAdContentValidation(
		placementId: string,
		carouselContent: IFacebookCarouselTemplateVM,
		adFormatId: AdFormatEnum,
		adId: string,
	): Observable<ICarouselAdContentValidation[]> {
		const adContent =
			this.adMapper.validationMapper.mapAdContentToValidateAllProperties(
				carouselContent.commonContentTemplates,
			);
		const cardsContents =
			this.adMapper.validationMapper.mapCarouselCardsToValidateAllProperties(
				carouselContent.carouselCards,
			);

		return this.appEntityServices.adEntity.adContentCarouselValidation.getWithQuery(
			{
				placementId,
				adFormatId,
				adContent,
				cardsContents,
				adId,
			},
		);
	}

	public loadAdContentValidation(): Observable<IAdContentValidationVM> {
		const adContentValidation$: Observable<IAdContentValidation[]> =
			this.appEntityServices.adEntity.adContentValidation.entities$;

		return adContentValidation$.pipe(
			filter((adContentsValidation) => !!adContentsValidation),
			map((adContentsValidaiton: IAdContentValidation[]) =>
				this.adMapper.validationMapper.toAdContentValidationVM(
					adContentsValidaiton,
				),
			),
		);
	}

	public loadCarouselAdContentValidation(): Observable<ICarouselAdValidationVM> {
		const carouselAdContentValidation$: Observable<
			ICarouselAdContentValidation[]
		> =
			this.appEntityServices.adEntity.adContentCarouselValidation
				.entities$;

		return carouselAdContentValidation$.pipe(
			filter((adContentValidation) => !!adContentValidation),
			map((adContentValidaiton: ICarouselAdContentValidation[]) =>
				this.adMapper.validationMapper.toCarouselAdContentValidationVM(
					adContentValidaiton,
				),
			),
		);
	}

	public clearAdContentValidationCache(): void {
		this.appEntityServices.adEntity.adContentValidation.clearCache();
	}

	public clearAdContentValidationById(templateId: string): void {
		this.appEntityServices.adEntity.adContentValidation.removeOneFromCache(
			templateId,
			{ mergeStrategy: MergeStrategy.IgnoreChanges },
		);
	}

	public validateWebsiteUrlForPlacements(
		ad: IFacebookAdVM,
		url: string,
	): void {
		Object.keys(ad.content).forEach((key) => {
			this.getPlacementPropertyValidation(
				ad.content[key].id,
				url,
				AdContentIdEnum.websiteUrl,
				ad.adFormatId,
				ad.id,
			);
		});
	}

	public getPlacementPropertyValidation(
		placementId: string,
		value: any,
		adContentPropertyId: string,
		adFormatId: AdFormatEnum,
		adId?: string,
	): Observable<void> {
		this.appEntityServices.adEntity.adContentValidation.getWithQuery({
			adId,
			placementId,
			adContentPropertyId,
			value,
			adFormatId,
			scope: AdContentTemplateScopeEnum.ad,
		});

		return new Observable<void>();
	}

	public loadPlacementPropertyCarouselValidation(
		adId: string,
		propertyId: string,
		cardIndex: number | null,
	): Observable<IValidationResultVM> {
		const carouselAdContentValidation$: Observable<ICarouselAdValidationVM> =
			this.loadCarouselAdContentValidation();

		return carouselAdContentValidation$.pipe(
			map((adsContentValidation) => adsContentValidation?.[adId]),
			filter((adContentValidation) => !!adContentValidation),
			map((adContentValidation) =>
				adContentValidation?.[propertyId]?.find(
					({ card }) => card === null || card === cardIndex,
				),
			),
		);
	}

	public isAdContentValid(): Observable<boolean> {
		const adContentValidation$ = this.loadAdContentValidation();

		return adContentValidation$.pipe(
			map((adContentValidaiton: IAdContentValidationVM) =>
				this.checkContentValidity(adContentValidaiton),
			),
		);
	}

	public isCarouselAdContentValid(
		adId: string,
		carouselContent: IFacebookCarouselTemplateVM,
		mixedContent?: IMixedAdDefaultContentVM,
	): Observable<boolean> {
		const adContentValidation$ = this.loadCarouselAdContentValidation();

		return adContentValidation$.pipe(
			map(
				(adContentValidation: ICarouselAdValidationVM) =>
					adContentValidation?.[adId],
			),
			filter((adContentValidaiton) => !!adContentValidaiton),
			map((adContentValidaiton: IAdContentValidationVM) => {
				const filteredAdContentValidation = {};

				Object.keys(adContentValidaiton).forEach((templateId) => {
					const templateExistsInCommonContent =
						carouselContent?.commonContentTemplates?.some(
							({ id }) => id === templateId,
						);
					const templateExistsInCards =
						carouselContent?.carouselCards?.some((card) =>
							card.cardContentTemplates.some(
								({ id }) => id === templateId,
							),
						);

					if (
						templateExistsInCards ||
						templateExistsInCommonContent
					) {
						filteredAdContentValidation[templateId] =
							adContentValidaiton[templateId];
					}
				});

				return this.checkContentValidity(
					filteredAdContentValidation,
					mixedContent,
				);
			}),
		);
	}

	public areAllCardsAssigned(
		ad: IFacebookAdVM,
		mixedContent?: IMixedAdDefaultContentVM,
	): boolean {
		return ad.carouselContent.carouselCards.every((card, index) => {
			const isMixed = mixedContent?.cards?.[index]?.creatives;

			return !!card?.adCreatives?.length || isMixed;
		});
	}

	public isValidationLoading(): Observable<boolean> {
		return this.appEntityServices.adEntity.adContentValidation.loading$;
	}

	public isCarouselValidationLoading(): Observable<boolean> {
		return this.appEntityServices.adEntity.adContentCarouselValidation
			.loading$;
	}

	private checkContentValidity(
		adContentValidaiton: IAdContentValidationVM,
		mixedContent?: IMixedAdDefaultContentVM,
	): boolean {
		let isValid = true;

		if (!adContentValidaiton) {
			return isValid;
		}

		isValid = !Object.entries(adContentValidaiton).some(([id, error]) => {
			const isMixed =
				mixedContent?.content[id] ||
				mixedContent?.cards?.some((card) => card.content[id]);

			return error.some(
				({ type }) => !isMixed && type === ValidationTypeEnum.error,
			);
		});

		return isValid;
	}
}
