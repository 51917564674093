import { Injectable } from '@angular/core';
import { ICreativeSetFolder } from 'src/app/core/models';
import { IB2CreativeSetVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class CreativeSetMappersService {
	public mapToCreativeSetVM(
		creativeSetFolder: ICreativeSetFolder,
	): IB2CreativeSetVM {
		return {
			...creativeSetFolder,
			children: creativeSetFolder.children,
		};
	}
}
