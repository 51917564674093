import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SocialCampaignPageRoutingModule } from './scm-page-routing.module';
import { SocialCampaignPageComponent } from './scm-page.component';
import { PublishLogsModule } from './pages/publish-logs/publish-logs.module';
import { HeaderComponent } from './features/layout/header/header.component';
import { PublishSummaryhHeaderComponent } from './features/publish/publish-summary-header/publish-summary-header.component';

@NgModule({
	imports: [
		CommonModule,
		SocialCampaignPageRoutingModule,
		PublishSummaryhHeaderComponent,
		PublishLogsModule,
		HeaderComponent,
	],
	exports: [],
	declarations: [SocialCampaignPageComponent],
	providers: [],
})
export class SocialCampaignPageModule {}
