import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { Update } from '@ngrx/entity';
import { CAMPAIGN_ENTITY } from 'src/app/state/app.state';
import { ICampaign } from 'src/app/core/models';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { ApiService } from 'src/app/core/services/internal/api.service';

@Injectable()
export class CampaignDataService extends DefaultDataService<ICampaign> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(CAMPAIGN_ENTITY, http, httpUrlGenerator);
	}

	public add(campaign: ICampaign): Observable<ICampaign> {
		return this.appCoreServices.socialCampaignApiService.createCampaign(
			campaign,
		);
	}

	public getById(campaignId: string): Observable<ICampaign> {
		return this.appCoreServices.socialCampaignApiService
			.getCampaign(campaignId)
			.pipe(catchError((error) => ApiService.handleFeatureError(error)));
	}

	public update(
		newUpdatedCampaign: Update<ICampaign>,
	): Observable<ICampaign> {
		return this.appCoreServices.socialCampaignApiService.renameCampaign(
			newUpdatedCampaign.changes.id,
			newUpdatedCampaign.changes.name,
		);
	}

	public delete(campaignId: string): Observable<string> {
		return this.appCoreServices.socialCampaignApiService.deleteCampaign(
			campaignId,
		);
	}
}
