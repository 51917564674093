import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { IAdSchedule } from 'src/app/core/models/ad/ad-schedule/ad-schedule';
import { AD_SCHEDULE_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class AdScheduleEntityService extends EntityCollectionServiceBase<IAdSchedule> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(AD_SCHEDULE_ENTITY, serviceElementsFactory);
	}
}
