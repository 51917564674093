<ng-container *ngIf="(creative$ | async) as creative">
    <div
        [ngSwitch]="selectedMediaType"
        class="content"
        [ngClass]="{'content-fit-to-height': size?.fitToHeight, 'content-fit-to-size': size?.fitToSize}"
        [ngStyle]="{'width.px': size?.width, 'height.px': size?.height}">
        <div *ngSwitchCase="mediaType.Image" class="content">
            <img
                *ngIf="!!creative?.preloadImageUrl"
                class="preload-image"
                [ngClass]="{'fit-to-height': size?.fitToHeight, 'fit-to-size': size?.fitToSize}"
                [src]="creative.preloadImageUrl"
                alt="preload-image" />
        </div>
        <div *ngSwitchCase="mediaType.Video" class="preview">
            <div *ngIf="(previewUrl$ | async) as previewUrl">
                <creative-preview [previewUrl]="previewUrl" [sound]="sound" />
            </div>
        </div>
    </div>
</ng-container>
