import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { LocalizationMappersService } from '../../mappers';
import { LocalizationBaseService } from './localization-base.service';

@Injectable({
	providedIn: 'root',
})
export class LocalizationService extends LocalizationBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public localizationsMapper: LocalizationMappersService,
	) {
		super(appEntityServices, localizationsMapper);
	}

	public isLoadingLocalizations(): Observable<boolean> {
		return this.appEntityServices.accountEntity.localization.loading$;
	}
}
