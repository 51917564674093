import { Injectable } from '@angular/core';
import { IAdContentDictionary } from 'src/app/core/models';
import { IPublishLogEntry } from 'src/app/core/models/publish/publish-log-entry';
import { FacebookAdContentIdEnum } from 'src/app/presentation/features/integrations/facebook/ad/enums/fb-ad-content-id.enum';
import {
	AdFormatEnum,
	IPublishLogItemVM,
	NetworkEnum,
} from 'src/app/presentation/view-models';
import { PublishLogMappersService } from '../../../publish/mappers/publish-log.mapper';

@Injectable({
	providedIn: 'root',
})
export class FacebookPublishLogMappersService extends PublishLogMappersService {
	public toPublishLogItemViewModel(
		publishLog: IPublishLogEntry,
	): IPublishLogItemVM {
		const adContent = this.extractAdContent(publishLog?.ad);

		return {
			id: publishLog.id,
			adId: publishLog.adId,
			networkId: NetworkEnum.facebook,
			adFormat: this.toAdFormatVM(publishLog.ad?.format),
			adName: publishLog.ad?.name,
			date: publishLog.publishingStarted,
			languageId: publishLog.ad?.language,
			publishedBy: publishLog.publishedBy,
			status: publishLog.status,
			statusMessage: publishLog.statusMessage,
			creativeAssigned: publishLog.ad?.assignedCreativeSet,
			url: publishLog.ad
				? this.mapUrl(publishLog.ad?.format, adContent)
				: undefined,
			texts: this.mapTexts(adContent),
			placements: publishLog.ad
				? this.toPlacementsVM(publishLog.ad?.placements)
				: [],
		};
	}

	private mapUrl(
		adFormatId: string,
		adContent: IAdContentDictionary,
	): string | undefined {
		if (!adContent) {
			return undefined;
		}

		switch (adFormatId) {
			case AdFormatEnum.singleImageAndVideo:
				return (
					adContent[FacebookAdContentIdEnum.appUrl] ??
					adContent[FacebookAdContentIdEnum.websiteUrl]
				);
			case AdFormatEnum.carousel:
				return (
					adContent[FacebookAdContentIdEnum.appUrl] ??
					adContent[FacebookAdContentIdEnum.seeMoreUrl]
				);
			default:
				return undefined;
		}
	}

	private mapTexts(adContent: IAdContentDictionary): string[] | undefined {
		if (!adContent) {
			return undefined;
		}

		const primaryText = adContent[FacebookAdContentIdEnum.primaryText];
		const primaryTexts = adContent[FacebookAdContentIdEnum.primaryTexts];

		return primaryText ? [primaryText] : primaryTexts;
	}
}
