import { AppStage } from 'src/app/presentation/view-models';
import { IConfig } from './config.type';
import { config } from './config';
import { CYPRESS_FLAG_KEY } from 'cypress/support/cypress-flag.const';

export class ConfigService {
	private static _config: IConfig;

	public static get config(): IConfig {
		// Cypress is setting CYPRESS_FLAG_KEY in init command
		const isCypress = localStorage.getItem(CYPRESS_FLAG_KEY);

		this._config = Object.freeze({
			...config,
			...(isCypress && { STAGE: AppStage.cypress }),
		});

		return this._config;
	}

	public static isProduction(): boolean {
		return this.config.STAGE === AppStage.production;
	}

	public static isSandbox(): boolean {
		return this.config.STAGE === AppStage.sandbox;
	}

	public static isDevelopment(): boolean {
		return this.config.STAGE === AppStage.development;
	}

	public static isCypress(): boolean {
		return this.config.STAGE === AppStage.cypress;
	}

	public static getStage(): AppStage {
		return this.config.STAGE;
	}
}
