import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { ITiktokAdAccount } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { TiktokAdAccountBaseService } from './tt-ad-account-base.service';

@Injectable({
	providedIn: 'root',
})
export class TiktokAdAccountService extends TiktokAdAccountBaseService {
	constructor(public appEntityServices: AppEntityServices) {
		super(appEntityServices);
	}

	public loadAllByIntegrationId(
		integrationId: string,
	): Observable<ITiktokAdAccount[]> {
		const adAccounts$: Observable<ITiktokAdAccount[]> =
			this.appEntityServices.tiktokEntity.adAccount.entities$;

		return adAccounts$.pipe(
			filter((accounts) => !!accounts),
			map((accounts) =>
				accounts.filter(
					(account) => account.integrationId === integrationId,
				),
			),
		);
	}

	public isLoadingAdAccounts(): Observable<boolean> {
		return this.appEntityServices.tiktokEntity.adAccount.loading$;
	}
}
