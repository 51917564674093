import { Injectable, Pipe, PipeTransform } from '@angular/core';
import texts from '../../../../../assets/texts/en.json';

@Injectable({
	providedIn: 'root',
})
@Pipe({
	name: 'text',
	standalone: true,
})
export class TextPipe implements PipeTransform {
	private textDictionary: { [key: string]: string } = texts;

	public transform(key: string): any {
		return this.textDictionary[key] || this.missingKeyWarning(key);
	}

	public missingKeyWarning(key: string): undefined {
		console.warn(`could not find static text from key: ${key}`);
		return undefined;
	}
}
