import { Injectable, Injector } from '@angular/core';
import { CampaignService } from './services/campaign/campaign.service';

@Injectable()
export class SocialCampaignFeatureService {
	public campaign: CampaignService;

	constructor(private injector: Injector) {
		this.campaign = this.injector.get(CampaignService);
	}
}
