import { ComponentType } from '@angular/cdk/portal';

// Single placements
import { FacebookPlacementComponentBaseDirective } from '../single/placements/shared/directives/facebook-placement-component-base.directive';
import { FacebookGroupsFeedPlacementComponent } from '../single/placements/facebook-feed-placements/facebook-groups-feed-placement/fb-groups-feed-placement.component';
import { FacebookInstagramExploreHomePlacementComponent } from '../single/placements/facebook-feed-placements/facebook-instagram-explore-home-placement/fb-instagram-explore-home-placement.component';
import { FacebookInstagramExplorePlacementComponent } from '../single/placements/facebook-feed-placements/facebook-instagram-explore-placement/fb-instagram-explore-placement.component';
import { FacebookInstagramFeedPlacementComponent } from '../single/placements/facebook-feed-placements/facebook-instagram-feed-placement/fb-instagram-feed-placement.component';
import { FacebooMarketplaceFeedPlacementComponent } from '../single/placements/facebook-feed-placements/facebook-marketplace-feed-placement/fb-marketplace-feed-placement.component';
import { FacebookMessengerInboxComponent } from '../single/placements/facebook-feed-placements/facebook-messenger-inbox/fb-messenger-inbox.component';
import { FacebookNewsFeedPlacementComponent } from '../single/placements/facebook-feed-placements/facebook-news-feed-placement/fb-news-feed-placement.component';
import { FacebookRightColumnPlacementComponent } from '../single/placements/facebook-feed-placements/facebook-right-column-placement/fb-right-column-placement.component';
import { FacebookVideoFeedPlacementComponent } from '../single/placements/facebook-feed-placements/facebook-video-feed-placement/fb-video-feed-placement.component';
import { FacebookAudienceNetworkNativeComponent } from '../single/placements/other-placements/facebook-audience-network-native/fb-audience-network-native.component';
import { FacebookInStreamVideosComponent } from '../single/placements/other-placements/facebook-in-stream-videos/fb-in-stream-videos.component';
import { FacebookSearchResultPlacementComponent } from '../single/placements/other-placements/facebook-search-result/fb-search-result.component';
import { FacebookAdsOnReelsPlacementComponent } from '../single/placements/stories-reels-placements/reels/facebook-ads-on-reels/facebook-ads-on-reels-placement.component';
import { FacebookInstagramReelsPlacementComponent } from '../single/placements/stories-reels-placements/reels/facebook-instagram-reels-placement/fb-instagram-reels-placement.component';
import { FacebookReelsPlacementComponent } from '../single/placements/stories-reels-placements/reels/facebook-reels/fb-reels-placement.component';
import { FacebookInstagramStoriesPlacementComponent } from '../single/placements/stories-reels-placements/stories/facebook-instagram-stories-placement/fb-instagram-stories-placement.component';
import { FacebookMessengerStoriesPlacementComponent } from '../single/placements/stories-reels-placements/stories/facebook-messenger-stories-placement/fb-messenger-stories-placement.component';
import { FacebookStoriesPlacementComponent } from '../single/placements/stories-reels-placements/stories/facebook-stories-placement/fb-stories-placement.component';

// Carousel placements
import { FacebookCarouselBaseDirective } from '../carousel/placements/directives/fb-carousel-base.directive';
import { FacebookCarouselSupportedPlacements } from '../carousel/placements/enums';
import { FacebookCarouselInstagramExploreHomeComponent } from '../carousel/placements/feeds/fb-instagram-explore-home/fb-instagram-explore-home.component';
import { FacebookCarouselInstagramExplorePlacementComponent } from '../carousel/placements/feeds/fb-instagram-explore/fb-instagram-explore.component';
import { FacebookCarouselInstagramFeedPlacementComponent } from '../carousel/placements/feeds/fb-instagram-feed/fb-instagram-feed.component';
import { FacebookCarouselMarketPlaceComponent } from '../carousel/placements/feeds/fb-marketplace/fb-marketplace.component';
import { FacebookCarouselMessengerInboxComponent } from '../carousel/placements/feeds/fb-messenger-inbox/fb-messenger-inbox.component';
import { FacebookCarouselNewsFeedPlacementComponent } from '../carousel/placements/feeds/fb-news-feed/fb-news-feed.component';
import { FacebookCarouselRightColumnComponent } from '../carousel/placements/feeds/fb-right-column/fb-right-column.component';
import { FacebookCarouselVideoFeedComponent } from '../carousel/placements/feeds/fb-video-feed/fb-video-feed.component';
import { FacebookCarouselReelsComponent } from '../carousel/placements/stories-reels/reels/fb-reels.component';
import { FacebookCarouselAudienceNetworkNativeComponent } from '../carousel/placements/other/fb-audience-network-native/fb-audience-network-native.component';
import { FacebookCarouselInStreamVideosComponent } from '../carousel/placements/in-stream/in-stream-videos/fb-in-stream-videos.component';
import { FacebookCarouselStoriesComponent } from '../carousel/placements/stories-reels/facebook-stories/facebook-stories.component';
import { FacebookCarouselInstagramReelsComponent } from '../carousel/placements/stories-reels/instagram-reels/instagram-reels.component';
import { FacebookCarouselInstagramStoriesComponent } from '../carousel/placements/stories-reels/instagram-stories/instagram-stories.component';
import { FacebookCarouselSearchResultsComponent } from '../carousel/placements/search-results/fb-search-results.component';
import { FacebookCarouselAdsOnFacebookReelsComponent } from '../carousel/placements/in-stream/ads-on-fb-reels/ads-on-fb-reels.component';

export const SinglePlacements: Record<
	string,
	ComponentType<FacebookPlacementComponentBaseDirective>
> = {
	'facebook-news-feed': FacebookNewsFeedPlacementComponent,
	'facebook-groups-feed': FacebookGroupsFeedPlacementComponent,
	'facebook-reels': FacebookReelsPlacementComponent,
	'facebook-instagram-explore': FacebookInstagramExplorePlacementComponent,
	'facebook-marketplace': FacebooMarketplaceFeedPlacementComponent,
	'facebook-messenger-inbox': FacebookMessengerInboxComponent,
	'facebook-right-column': FacebookRightColumnPlacementComponent,
	'facebook-video-feed': FacebookVideoFeedPlacementComponent,
	'facebook-instagram-stories': FacebookInstagramStoriesPlacementComponent,
	'facebook-messenger-stories': FacebookMessengerStoriesPlacementComponent,
	'facebook-stories': FacebookStoriesPlacementComponent,
	'facebook-instagram-reels': FacebookInstagramReelsPlacementComponent,
	'facebook-audience-network-native': FacebookAudienceNetworkNativeComponent,
	'facebook-instagram-feed': FacebookInstagramFeedPlacementComponent,
	'facebook-in-stream-videos': FacebookInStreamVideosComponent,
	'facebook-search-results': FacebookSearchResultPlacementComponent,
	'facebook-instagram-explore-home':
		FacebookInstagramExploreHomePlacementComponent,
	'facebook-ads-on-facebook-reels': FacebookAdsOnReelsPlacementComponent,
};

export const CarouselPlacements: Record<
	FacebookCarouselSupportedPlacements,
	ComponentType<FacebookCarouselBaseDirective>
> = {
	'facebook-news-feed': FacebookCarouselNewsFeedPlacementComponent,
	'facebook-video-feed': FacebookCarouselVideoFeedComponent,
	'facebook-instagram-feed': FacebookCarouselInstagramFeedPlacementComponent,
	'facebook-instagram-explore':
		FacebookCarouselInstagramExplorePlacementComponent,
	'facebook-instagram-explore-home':
		FacebookCarouselInstagramExploreHomeComponent,
	'facebook-marketplace': FacebookCarouselMarketPlaceComponent,
	'facebook-messenger-inbox': FacebookCarouselMessengerInboxComponent,
	'facebook-right-column': FacebookCarouselRightColumnComponent,
	'facebook-stories': FacebookCarouselStoriesComponent,
	'facebook-instagram-reels': FacebookCarouselInstagramReelsComponent,
	'facebook-reels': FacebookCarouselReelsComponent,
	'facebook-instagram-stories': FacebookCarouselInstagramStoriesComponent,
	'facebook-audience-network-native':
		FacebookCarouselAudienceNetworkNativeComponent,
	'facebook-ads-on-facebook-reels':
		FacebookCarouselAdsOnFacebookReelsComponent,
	'facebook-in-stream-videos': FacebookCarouselInStreamVideosComponent,
	'facebook-search-results': FacebookCarouselSearchResultsComponent,
};
