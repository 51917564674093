<ng-container *ngIf="brand$ | async as brand">
    <div class="stories-header">
        <div class="progress-bar">
            <div
                *ngFor="let card of [].constructor(cardsLength); let i = index;"
                class="bar"
                [ngClass]="{'is-active': i === currentIndex}"></div>
        </div>
        <div class="container">
            <fb-stories-reels-brand />
            <div class="icons">
                <fb-menu-dots-icon></fb-menu-dots-icon>
                <ui-svg-icon class="icons-close" icon="close"></ui-svg-icon>
            </div>
        </div>
    </div>
</ng-container>
