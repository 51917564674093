import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/core/config/config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	ICreativeSetFolder,
	IClvCreativeSetsResponse,
	IClvItem,
} from '../../models/creative/core-creative-set/ex-creativeset';
import { CreativeSetFolderAdapter } from '../../utilities/folderable-creativeset.adapter';
import { ExObjectItem } from '../../models/creative/core-creative-set/ex-object-item.model';
import { ExObjectResult } from '../../models/creative/core-creative-set/ex-object-result.model';
import { ICoreCreativeSetData } from '../../models/creative/core-creative-set/core-creative-set-data';
import { ClvObjectQuery } from '../../models';

@Injectable({
	providedIn: 'root',
})
export class CommonListViewApiService {
	public baseUrl = `${ConfigService?.config?.CLV_URL}/api`;

	constructor(private http: HttpClient) {}

	/**
	 * Gets brand creatives from bannerflow api
	 */
	public getCreativeSetsWithFolders(
		brandId: string,
		search?: boolean,
		queryValue?: string,
		folderId?: string,
	): Observable<ICreativeSetFolder[]> {
		const apiUrl = `${this.baseUrl}/${brandId}/creativesets`;

		const query = JSON.stringify(
			this.getCreativeSetQueryCLV(search, queryValue, folderId),
		);

		const url = query.includes('filters')
			? `${apiUrl}/FilterCreativeSetsAndFolders`
			: `${apiUrl}/GetCreativeSetAndFolders`;

		return this.http
			.post<IClvCreativeSetsResponse>(url, JSON.parse(query))
			.pipe(
				map((clvResponse: IClvCreativeSetsResponse) =>
					this.deserializeCreativeSetResponse(clvResponse),
				),
			);
	}

	private deserializeCreativeSetResponse(
		response: IClvCreativeSetsResponse,
	): ICreativeSetFolder[] {
		const result: ExObjectResult<ICoreCreativeSetData> =
			new ExObjectResult<ICoreCreativeSetData>();

		const items: Array<ExObjectItem<ICoreCreativeSetData>> =
			response.items.map((item: IClvItem) => {
				const creativeSet: ICoreCreativeSetData = {
					creativesetId: item.externalId,
					folder: item.parentFolderId,
					id: item.id,
					name: item.name,
					thumb: item.thumb,
					created: new Date(item.createdAt),
					modified: new Date(item.modifiedAt),
					createdBy: {
						name: item.createdBy,
						email: '',
						id: '',
						profile: '',
					},
					publicId: '',
					slug: '',
				};
				const exItem: ExObjectItem<ICoreCreativeSetData> =
					new ExObjectItem<ICoreCreativeSetData>();
				exItem.data = creativeSet;
				exItem.isFolder = !!(item.type === 'Folder');

				return exItem;
			});

		result.items = items;

		const creativeSets: ICreativeSetFolder[] = result.items.map(
			(item: any) =>
				CreativeSetFolderAdapter.mapExCreativeSetToFolder(item),
		);

		const sortFn = (a: ICreativeSetFolder, b: ICreativeSetFolder): number =>
			a.name.localeCompare(b.name);

		// sort A-Z
		const folders = creativeSets
			.filter((item) => item.isFolder)
			.sort(sortFn);
		const notFolders = creativeSets
			.filter((item) => !item.isFolder)
			.sort(sortFn);

		return [...folders, ...notFolders];
	}

	private getCreativeSetQueryCLV(
		search: boolean = false,
		queryValue?: string,
		folderId?: string,
	): ClvObjectQuery {
		const query: ClvObjectQuery = new ClvObjectQuery();
		query.folderId = this.getSearchFolderValue(search, folderId);
		query.filters = search ? { name: queryValue } : undefined;
		query.paging = { pageNumber: 1, pageSize: 50000 };
		query.sort = { fieldName: 'name', order: 'ASC' };

		return query;
	}

	private getSearchFolderValue(search: boolean, folderId: string): string {
		if (search) {
			return undefined;
		}

		return folderId ? folderId : undefined;
	}
}
