import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ConfigService } from 'src/app/core/config/config.service';
import { IFacebookAdSet } from 'src/app/core/models';
import { ApiService } from '../../../internal/api.service';
import { IFacebookAdSetPlacements } from 'src/app/core/models/facebook/fb-ad-set-placement';

@Injectable({
	providedIn: 'root',
})
export class FacebookAdSetApiService extends BaseApiService {
	constructor(public http: HttpClient) {
		super(
			http,
			`${ConfigService?.config?.SCS_URL}/api/facebook/integrations/`,
		);
	}

	/**
	 * Gets Facebook adSets
	 * @param integrationId
	 * @param campaignId
	 */
	public getAdSets(
		integrationId: string,
		campaignId: string,
	): Observable<IFacebookAdSet[]> {
		const url = `${this.baseUrl}${integrationId}/campaigns/${campaignId}/ad-sets`;

		return this.http.get<IFacebookAdSet[]>(url).pipe(
			map((adSets) =>
				adSets.map((adSet) => ({
					...adSet,
					integrationId,
					campaignId,
				})),
			),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Gets adSet placements
	 * @param campaignId
	 * @param integrationId
	 * @param adSetId
	 */
	public getAdSetPlacements(
		integrationId: string,
		campaignId: string,
		adSetId: string,
	): Observable<IFacebookAdSetPlacements[]> {
		const url = `${this.baseUrl}${integrationId}/campaigns/${campaignId}/ad-sets/${adSetId}/placements`;

		return this.http.get<string[]>(url).pipe(
			map((placements) => [
				{
					placementsIds: placements,
					id: adSetId,
				},
			]),
			catchError(ApiService.handleError),
		);
	}
}
