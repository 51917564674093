import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { PUBLISHLOG_ENTITY } from 'src/app/state/app.state';
import { IPublishLogEntry } from 'src/app/core/models/publish/publish-log-entry';
import { Observable } from 'rxjs';
@Injectable()
export class PublishLogDataService extends DefaultDataService<IPublishLogEntry> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(PUBLISHLOG_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: {
		adId: string;
	}): Observable<IPublishLogEntry[]> {
		return this.appCoreServices.socialPublishApiService.getPublishLogs(
			params['adId'],
		);
	}
}
