import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, tap } from 'rxjs';
import {
	IFacebookCarouselPlacementsMetaDataMap,
	IFacebookPlacementCarouselMetaDataVM,
} from 'src/app/presentation/view-models';
import { IPlacementsSoundDictionary } from 'src/app/presentation/view-models/shared/placement-sound.vm';
import { SoundEnum } from 'src/app/presentation/view-models/shared/sound.enum';

export interface IFacebookCarouselPlacementsState {
	placementsMetaDataMap: IFacebookCarouselPlacementsMetaDataMap;
	placementsSound: IPlacementsSoundDictionary;
}

@Injectable()
export class FacebookCarouselPlacementsComponentStore extends ComponentStore<IFacebookCarouselPlacementsState> {
	constructor() {
		super({
			placementsMetaDataMap: {},
			placementsSound: {},
		});
	}
	public readonly setPlacementsMetaData = this.effect(
		(
			placementsMetaData$: Observable<IFacebookCarouselPlacementsMetaDataMap>,
		) =>
			placementsMetaData$.pipe(
				tap(
					(
						placementsMetaDataMap: IFacebookCarouselPlacementsMetaDataMap,
					) => {
						this.updateMetaData(placementsMetaDataMap);
					},
				),
			),
	);

	private readonly updateMetaData = this.updater(
		(
			state: IFacebookCarouselPlacementsState,
			placementsMetaDataMap: IFacebookCarouselPlacementsMetaDataMap,
		) => ({
			...state,
			placementsMetaDataMap: placementsMetaDataMap,
		}),
	);

	public readonly setPlacementsSound = this.effect(
		(placementsSound$: Observable<IPlacementsSoundDictionary>) =>
			placementsSound$.pipe(
				tap((placementsSound: IPlacementsSoundDictionary) => {
					this.updatePlacementsSoundState(placementsSound);
				}),
			),
	);

	public readonly setPlacementSound = this.effect(
		(param$: Observable<{ placementId: string; sound: SoundEnum }>) =>
			param$.pipe(
				tap((param: { placementId: string; sound: SoundEnum }) => {
					this.updatePlacementSoundState(param);
				}),
			),
	);

	public readonly setCardSound = this.effect(
		(
			param$: Observable<{
				placementId: string;
				sound: SoundEnum;
				index: number;
			}>,
		) =>
			param$.pipe(
				tap(
					(param: {
						placementId: string;
						sound: SoundEnum;
						index: number;
					}) => {
						this.updateCardSoundState(param);
					},
				),
			),
	);

	private readonly updatePlacementSoundState = this.updater(
		(
			state: IFacebookCarouselPlacementsState,
			action: { placementId: string; sound: SoundEnum },
		) => ({
			...state,
			placementsSound: {
				...state.placementsSound,
				[action.placementId]: action.sound,
			},
		}),
	);

	private readonly updateCardSoundState = this.updater(
		(
			state: IFacebookCarouselPlacementsState,
			action: { placementId: string; sound: SoundEnum; index: number },
		) => ({
			...state,
			placementsMetaDataMap: this.updateCardSoundByIndex(
				action.placementId,
				action.index,
				action.sound,
				state.placementsMetaDataMap,
			),
		}),
	);

	private readonly updatePlacementsSoundState = this.updater(
		(
			state: IFacebookCarouselPlacementsState,
			action: IPlacementsSoundDictionary,
		) => ({
			...state,
			placementsSound: {
				...action,
			},
		}),
	);

	public loadPlacementsSound(): Observable<IPlacementsSoundDictionary> {
		return this.select((state) => state.placementsSound);
	}

	public loadPlacementMetaData(
		placementId: string,
	): Observable<IFacebookPlacementCarouselMetaDataVM> {
		return this.select((state) => state.placementsMetaDataMap[placementId]);
	}

	public loadPlacementsMetaData(): Observable<IFacebookCarouselPlacementsMetaDataMap> {
		return this.select((state) => state.placementsMetaDataMap);
	}

	public loadPlacementsList(): Observable<string[]> {
		return this.select((state) => Object.keys(state.placementsMetaDataMap));
	}

	private updateCardSoundByIndex(
		placementId: string,
		cardIndex: number,
		updatedSound: SoundEnum,
		metaData: IFacebookCarouselPlacementsMetaDataMap,
	): IFacebookCarouselPlacementsMetaDataMap {
		const updatedMetaData = { ...metaData[placementId] };
		const updatedCards = updatedMetaData.cards.map((card, index) => ({
			...card,
			sound: index === cardIndex ? updatedSound : SoundEnum.Off,
		}));

		updatedMetaData.cards = updatedCards;

		return {
			...metaData,
			[placementId]: updatedMetaData,
		};
	}
}
