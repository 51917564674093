import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FACEBOOK_ADFORMAT_ENTITY } from 'src/app/state/app.state';
import { IAdFormat } from 'src/app/core/models';
import { AppCoreServices } from 'src/app/core/app-core-service';

@Injectable()
export class FacebookAdFormatDataService extends DefaultDataService<IAdFormat> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(FACEBOOK_ADFORMAT_ENTITY, http, httpUrlGenerator);
	}

	public getAll(): Observable<IAdFormat[]> {
		return this.appCoreServices.socialCampaignApiService.facebook.adFormatApi.getAdFormats();
	}
}
