<ng-container *ngIf="brand$ | async as brand">
    <div class="brand" [ngClass]="{'brand-expanded': isExpanded}">
        <div class="brand-logo">
            <brand-logo [brand]="brand"></brand-logo>
        </div>

        <div class="brand-content" [ngClass]="{'brand-content-expanded': isExpanded}">
            <div class="brand-content-name">
                <div class="brand-content-name-text">{{ brand?.name }}</div>
                <div class="brand-content-name-badge">
                    {{"PlacementHeaderComponent.AD" | text}}
                </div>
            </div>

            <div class="brand-content-likes" *ngIf="isExpanded">
                <span>{{ "PlacementHeaderComponent.123_PEOPLE_LIKE" | text}}</span> {{
                brand?.name }}
            </div>

            <div
                [ngClass]="{'brand-content-skeleton': !headlineValue}"
                *ngIf="!isExpanded">
                <fb-headline-inbox [headLineValue]="headlineValue"></fb-headline-inbox>
            </div>

            <fb-cta-link
                [ctaValue]="ctaValue"
                [fontSize]="9"
                [ngClass]="{'brand-content-skeleton': !ctaValue}"
                *ngIf="!isExpanded"></fb-cta-link>
        </div>
    </div>
</ng-container>
