import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'fb-description',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './fb-description.component.html',
	styleUrls: ['./fb-description.component.scss'],
})
export class FbDescriptionComponent {
	@Input() public descriptionValue: string;
	@Input() public width: number;
	@Input() public color: string;
}
