import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Output,
	HostListener,
	Input,
	ViewChild,
	OnChanges,
} from '@angular/core';
import { UIDropdownComponent, UIModule } from '@bannerflow/ui';
import { Observable } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { IPlacementBaseVM, IUserVM } from 'src/app/presentation/view-models';
import { CommonModule } from '@angular/common';
import { CustomizeOptionComponent } from '../../../customization/customize-option/customize-option.component';
import { RevertCustomizationOptionComponent } from '../../../customization/revert-customization-option/revert-customization-option.component';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		UIModule,
		RevertCustomizationOptionComponent,
		CustomizeOptionComponent,
	],
	selector: 'placement-options',
	templateUrl: './placement-options.component.html',
	styleUrls: ['./placement-options.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacementOptionsComponent implements OnChanges {
	@Output() public isOpenMenuEvent = new EventEmitter<any>();
	@Input() public placementId: string;
	@Input() public adId: string;
	@Input() public supportedPlacements: IPlacementBaseVM[];
	@ViewChild('dropDownMenu') public dropDownMenu: UIDropdownComponent;
	public user$: Observable<IUserVM>;

	@HostListener('document:click', ['$event'])
	public click(event): void {
		if (!this.eRef.nativeElement.contains(event.target)) {
			this.isOpenMenuEvent.emit({
				isMenuOpen: false,
				placementId: this.placementId,
			});
		}
	}

	constructor(
		private eRef: ElementRef,
		private appFeatureServices: AppFeatureServices,
	) {}

	public ngOnChanges(): void {
		this.user$ = this.appFeatureServices.accountFeature.user.load();
	}

	public openMenu(): void {
		this.isOpenMenuEvent.emit({
			isMenuOpen: true,
			placementId: this.placementId,
		});
	}

	public onRevertClick(): void {
		this.dropDownMenu.close.emit();
	}

	public onCustomizeClick(): void {
		this.dropDownMenu.close.emit();
	}
}
