import { UINotificationType } from '@bannerflow/ui';
import { createReducer, on } from '@ngrx/store';
import { CommonFeatureActions } from '../actions';
export interface ISocialAppNotification {
	type: UINotificationType;
	message: string;
	width?: number;
}

export enum AppWindowStatusEnum {
	closed = 'closed',
	live = 'live',
}
export interface ISocialState {
	appErrors: any[];
	appNotification: ISocialAppNotification;
	appWindowStatus: AppWindowStatusEnum;
}

export const initialState: ISocialState = {
	appErrors: [],
	appNotification: undefined,
	appWindowStatus: AppWindowStatusEnum.live,
};

export const socialReducer = createReducer<ISocialState>(
	initialState,
	on(
		CommonFeatureActions.appErrorOccurred,
		(state, action): ISocialState => ({
			...state,
			appErrors: [...state.appErrors, action.error],
		}),
	),
	on(
		CommonFeatureActions.onWindowClosed,
		(state): ISocialState => ({
			...state,
			appErrors: [...state.appErrors],
			appWindowStatus: AppWindowStatusEnum.closed,
		}),
	),
	on(
		CommonFeatureActions.onAppNotificationDispatched,
		(state, action): ISocialState => ({
			...state,
			appNotification: action.notification,
		}),
	),
);
