export enum TiktokAdContentIdEnum {
	text = 'text',
	destination = 'destination',
	callToAction = 'call-to-action',
	tracking = 'tracking',
	thirdPartyTracking = 'third-party-tracking',
	tiktokInstantPage = 'instant-page',
	targetUrl = 'target-url',
	standardCallToAction = 'standard-call-to-action',
	dynamicCallToAction = 'dynamic-call-to-action',
	websiteEvents = 'website-events',
	impressionTrackingUrl = 'impression-tracking-url',
	clickTrackingUrl = 'click-tracking-url',
}
