import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	standalone: true,
	imports: [CommonModule],
	selector: 'fb-cta-link',
	templateUrl: './fb-cta-link.component.html',
	styleUrls: ['./fb-cta-link.component.scss'],
})
export class FbCtaLinkComponent {
	@Input() public ctaValue?: string;
	@Input() public fontSize?: number = 10;
}
