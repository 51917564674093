import { Component, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'slider-default-navigation',
	standalone: true,
	templateUrl: './default-navigation.component.html',
	styleUrls: ['./default-navigation.component.scss'],
})
export class SliderDefaultNavigationComponent {
	@Output() public onPrevClick: EventEmitter<void> = new EventEmitter();
	@Output() public onNextClick: EventEmitter<void> = new EventEmitter();

	public previous(): void {
		this.onPrevClick.emit();
	}
	public next(): void {
		this.onNextClick.emit();
	}
}
