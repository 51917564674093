import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { TIKTOK_IDENTITY_ENTITY } from 'src/app/state/app.state';
import { ITiktokIdentity } from 'src/app/core/models';

@Injectable()
export class TiktokIdentityDataService extends DefaultDataService<ITiktokIdentity> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(TIKTOK_IDENTITY_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery({
		integrationId,
		adAccountId,
	}: {
		integrationId: string;
		adAccountId: string;
	}): Observable<ITiktokIdentity[]> {
		return this.appCoreServices.socialCampaignApiService.tiktok.identityApi.getIdentities(
			integrationId,
			adAccountId,
		);
	}
}
