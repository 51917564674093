import { CommonModule } from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { Observable, Subscription, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import {
	IFacebookCarouselPlacementsMetaDataMap,
	MediaTypeEnum,
} from 'src/app/presentation/view-models';
import { FacebookPlacementsWrapperComponent } from '../../placements-wrapper/fb-placements-wrapper.component';
import { FacebookPlacementFooterComponent } from '../../placement-elements/placement-footer/fb-placement-footer.component';
import { FbPlacementHeaderComponent } from '../../placement-elements/placement-header/fb-placement-header.component';
import { FbPrimaryTextComponent } from '../../placement-elements/primary-text/fb-primary-text.component';
import { FacebookSupportedPlacements } from '../../single/placements/shared/enums';
import { FacebookPlacementTitleComponent } from '../../single/placements/shared/facebook-placement-title/facebook-placement-title.component';
import { CarouselSliderComponent } from './carousel-elements/carousel-slider/carousel-slider.component';
import { FacebookCarouselPlacementsComponentStore } from './fb-carousel-placements.component.store';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { AdPlacementPreviewDirective } from 'src/app/presentation/pages/shared/directives/ad-placement-preview.directive';
import {
	AdPreviewDirective,
	IAdPreviewMetaData,
} from 'src/app/presentation/pages/shared/directives/ad-preview.directive';
import { AdTargetUrlComponent } from 'src/app/presentation/features/ad/ad-target-url/ad-target-url.component';
import { BrandLogoComponent } from 'src/app/presentation/features/account/brand-logo/brand-logo.component';
import {
	IPlacementSoundVM,
	IPlacementsSoundDictionary,
} from 'src/app/presentation/view-models/shared/placement-sound.vm';
import { SoundEnum } from 'src/app/presentation/view-models/shared/sound.enum';
import { PlacementHeaderComponent } from '../../../../elements/placement-header/placement-header.component';
import { PlacementSoundComponent } from '../../../../elements/placement-sound/placement-sound.component';
import { LetDirective } from '@ngrx/component';

@Component({
	selector: 'fb-carousel-placements',
	templateUrl: './fb-carousel-placements.component.html',
	styleUrls: ['./fb-carousel-placements.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		UIModule,
		LetDirective,
		TextPipe,
		BrandLogoComponent,
		AdTargetUrlComponent,
		FacebookPlacementsWrapperComponent,
		FacebookPlacementTitleComponent,
		AdPlacementPreviewDirective,
		AdPreviewDirective,
		FbPlacementHeaderComponent,
		FacebookPlacementFooterComponent,
		CarouselSliderComponent,
		FbPrimaryTextComponent,
		PlacementHeaderComponent,
		PlacementSoundComponent,
	],
	providers: [FacebookCarouselPlacementsComponentStore],
})
export class FacebookCarouselPlacementsComponent implements OnInit, OnDestroy {
	@Input() public inPreview: boolean;
	@Input() public placementsSound$: Observable<IPlacementsSoundDictionary>;
	@Input()
	public placementsMetaData$: Observable<IFacebookCarouselPlacementsMetaDataMap>;
	@Input() public adSetPlacementsIds$: Observable<string[]> = of([]);

	@Output() public onPlacementSoundChanged: EventEmitter<IPlacementSoundVM> =
		new EventEmitter();
	public soundEnum: typeof SoundEnum = SoundEnum;

	public placements$: Observable<string[]>;
	public hoveredPlacement: string;
	public adPreviewMetaData: IAdPreviewMetaData;
	public facebookSupportedPlacements: typeof FacebookSupportedPlacements =
		FacebookSupportedPlacements;
	public isMenuActive: boolean;
	public activePlacement: string;
	public data$: Observable<any>;
	public placementsSoundSubscription$: Subscription;
	public someCardsHasVideo$: Observable<boolean>;

	constructor(
		private carouselPlacementsStore: FacebookCarouselPlacementsComponentStore,
	) {}

	public ngOnInit(): void {
		this.carouselPlacementsStore.setPlacementsMetaData(
			this.placementsMetaData$,
		);
		this.placements$ = this.carouselPlacementsStore.loadPlacementsList();
		this.someCardsHasVideo$ = this.isPlacementsCardsHaveAtLeastOneVideo();

		this.placementsSoundSubscription$ = this.placementsSound$
			?.pipe(
				tap((placementsSound) =>
					this.carouselPlacementsStore.setPlacementsSound(
						placementsSound,
					),
				),
			)
			.subscribe();
	}

	public openMenu(value: any): void {
		this.isMenuActive = value.isMenuOpen;

		if (this.isMenuActive) {
			this.activePlacement = value.placementId;
		}

		if (!this.isMenuActive && this.activePlacement === value.placementId) {
			this.activePlacement = null;
		}
	}

	public onSoundChange(sound: SoundEnum, placementId: string): void {
		const placementSound: IPlacementSoundVM = {
			id: placementId,
			sound,
		};

		this.onPlacementSoundChanged.emit(placementSound);
	}

	public showTargetUrl(value: string): void {
		this.hoveredPlacement = value;
	}

	public hideTargetUrl(): void {
		this.hoveredPlacement = undefined;
	}

	public isPlacementMatchedCampaignObjective(
		placementId: string,
	): Observable<boolean> {
		return this.adSetPlacementsIds$.pipe(
			map((campaignPlacementIds) =>
				campaignPlacementIds?.length
					? campaignPlacementIds.includes(placementId)
					: true,
			),
		);
	}

	public ngOnDestroy(): void {
		if (this.placementsSoundSubscription$) {
			this.placementsSoundSubscription$.unsubscribe();
		}
	}

	public isPlacementsCardsHaveAtLeastOneVideo(): Observable<boolean> {
		return this.placements$.pipe(
			switchMap((placements) =>
				this.carouselPlacementsStore.loadPlacementMetaData(
					placements[0],
				),
			),
			map(
				(metaData) =>
					!!metaData.cards.some(
						(card) => card.mediaType === MediaTypeEnum.Video,
					),
			),
		);
	}
}
