import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { IAdCustomizedValidation } from 'src/app/core/models/ad/ad-validation/ad-customized-validation.model';
import { AD_CUSTOMIZED_VALIDATION_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class AdCustomizedValidationEntityService extends EntityCollectionServiceBase<IAdCustomizedValidation> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(AD_CUSTOMIZED_VALIDATION_ENTITY, serviceElementsFactory);
	}
}
