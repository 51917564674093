<div class="card">
    <div class="card-creative-content">
        <ng-container
            *ngIf="(card?.creative?.setId && card?.creative?.id) || card?.creative?.creativeUrl">
            <creative-content
                [creativeSetId]="card?.creative.setId"
                [creativeId]="card?.creative.id"
                [creativeUrl]="card?.creative?.creativeUrl"
                [selectedMediaType]="card?.mediaType"
                [sound]="card.sound" />
        </ng-container>
    </div>

    <div class="card-footer-container">
        <div class="card-footer">
            <div
                class="card-footer-info"
                [ngClass]="{'card-footer-info-skeleton': !card?.headLineValue && !card?.descriptionValue}">
                <fb-headline [headLineValue]="card?.headLineValue" [width]="87" />
                <fb-description
                    [descriptionValue]="card?.descriptionValue"
                    [width]="87" />
            </div>
            <fb-cta-button [ctaValue]="card?.callToActionValue" />
        </div>
    </div>
</div>
