<div class="card">
    <div class="card-creative">
        <ng-container
            *ngIf="(card?.creative?.id && card.creative.setId) || card?.creative?.creativeUrl">
            <creative-content
                [creativeSetId]="card.creative.setId"
                [creativeId]="card.creative.id"
                [creativeUrl]="card?.creative?.creativeUrl"
                [selectedMediaType]="card.mediaType"
                [sound]="card.sound" />
        </ng-container>
    </div>
    <div class="card-content">
        <div class="top-row">
            <fb-headline [headLineValue]="card.headLineValue" [width]="120" />
            <ui-svg-icon class="icon-ui-close" icon="close"></ui-svg-icon>
        </div>
        <div class="bottom-row">
            <div class="brand">{{brandName}}</div>
            <div class="divider">•</div>
            <div class="sponsored">{{"PlacementHeaderComponent.SPONSORED" | text}}</div>
            <fb-menu-dots-icon class="icon"></fb-menu-dots-icon>
        </div>
    </div>
</div>
