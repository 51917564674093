import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { IGroupedCreativesByAspectRatioVM } from 'src/app/presentation/view-models';
import { CustomizePlacementWizardStore } from '../customize-placement.component.store';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { CustomizeCreativeCardComponent } from './customize-creative-card/customize-creative-card.component';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';

@Component({
	standalone: true,
	imports: [CommonModule, UIModule, CustomizeCreativeCardComponent, TextPipe],
	selector: 'customize-creative-set',
	templateUrl: './customize-creative-set.component.html',
	styleUrls: ['./customize-creative-set.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomizeCreativeSetComponent implements OnInit {
	@Input() public ratioGroup: string;
	public isLoadingCreativeSet$: Observable<boolean>;
	public selectedCreativeSet$: Observable<IGroupedCreativesByAspectRatioVM>;

	constructor(
		private readonly customizePlacementWizardStore: CustomizePlacementWizardStore,
	) {}

	public ngOnInit(): void {
		this.isLoadingCreativeSet$ =
			this.customizePlacementWizardStore.isLoadingCreativeSet();
		this.selectedCreativeSet$ =
			this.customizePlacementWizardStore.loadCreativeSet();
	}
}
