import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { delay, filter, map, Observable } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { ICreativeVM, MediaTypeEnum } from 'src/app/presentation/view-models';
import { CreativeContentComponentStore } from '../creative-content.component.store';
import { CreativePreviewComponent } from '../../creative-preview/creative-preview.component';
import { SoundEnum } from 'src/app/presentation/view-models/shared/sound.enum';

@Component({
	standalone: true,
	imports: [CommonModule, CreativePreviewComponent],
	selector: 'live-creative-content',
	templateUrl: './live-creative-content.component.html',
	styleUrls: ['./live-creative-content.component.scss'],
	providers: [CreativeContentComponentStore],
})
export class LiveCreativeContentComponent implements OnChanges {
	@Input() public creativeSetId: string;
	@Input() public creativeId: string;
	@Input() public selectedMediaType: MediaTypeEnum;
	@Input() public size: any;
	@Input() public sound: SoundEnum;
	@Input() public isCustomized: boolean;
	public creative$: Observable<ICreativeVM>;
	public previewUrl$: Observable<string>;
	public mediaType: typeof MediaTypeEnum = MediaTypeEnum;

	constructor(
		private appFeatureServices: AppFeatureServices,
		private creativeContentComponentStore: CreativeContentComponentStore,
	) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.creativeId) {
			this.creative$ = this.setUpCreative();
			this.previewUrl$ = this.setupPreviewUrl();

			if (this.isCustomized) {
				this.appFeatureServices.studioFeature.creativeSetService.getById(
					this.creativeSetId,
				);
			}
		}
	}

	private setUpCreative(): Observable<ICreativeVM> {
		const creativeSet$ =
			this.appFeatureServices.studioFeature.creativeSetService.loadById(
				this.creativeSetId,
			);

		return creativeSet$.pipe(
			filter((creativeSet) => !!creativeSet),
			map((creativeSet) =>
				creativeSet.creatives.find(
					(creative) => creative.id === this.creativeId,
				),
			),
		);
	}

	private setupPreviewUrl(): Observable<string> {
		const previewUrl$ = this.creativeContentComponentStore.loadPreviewUrl(
			this.creativeSetId,
			this.creativeId,
		);

		return previewUrl$.pipe(delay(1));
	}
}
