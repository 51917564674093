import {
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { ConfigService } from '../config/config.service';

@Injectable()
export class Aith0RquestInterceptor implements HttpInterceptor {
	constructor(private authHttpInterceptor: AuthHttpInterceptor) {}

	public intercept(
		request: HttpRequest<HttpHeaders>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		if (ConfigService.isCypress()) {
			return next.handle(request);
		}
		return this.authHttpInterceptor.intercept(request, next);
	}
}
