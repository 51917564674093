import { Injectable } from '@angular/core';
import { SliderTypeEnum } from './slider-type';

@Injectable({
	providedIn: 'root',
})
export class CarouselSliderService {
	public getSliderOffset(
		sliderType: SliderTypeEnum,
		initialOffset: number,
		cardWidth: number,
		currentIndex: number,
		cardsLength: number,
	): number {
		if (sliderType === SliderTypeEnum.clickable) {
			return this.getSliderOffsetForClickable(
				initialOffset,
				cardWidth,
				currentIndex,
				cardsLength,
			);
		}

		return this.getDefaultSliderOffset(cardWidth, currentIndex);
	}

	public getSliderOffsetForClickable(
		initOffset,
		cardWidth: number,
		currentIndex: number,
		cardsLength: number,
	): number {
		const isLastImage = currentIndex === cardsLength - 1;
		let distance = 0;

		if (isLastImage) {
			distance =
				cardWidth -
				2 * initOffset +
				(currentIndex - 1) * (cardWidth + initOffset);
		} else {
			distance =
				cardWidth + (currentIndex - 1) * (cardWidth + initOffset);
		}

		return distance;
	}

	public getDefaultSliderOffset(
		cardWidth: number,
		currentIndex: number,
	): number {
		return currentIndex * cardWidth;
	}
}
