import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';

@Component({
	selector: 'target-url',
	templateUrl: './ad-target-url.component.html',
	styleUrls: ['./ad-target-url.component.scss'],
	imports: [UIModule, CommonModule, TextPipe],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdTargetUrlComponent {
	@Input() public targetUrl: string;
	@Input() public urlMessage: string;
	@Input() public isAdList: boolean;
	@Input() public isChildValue: boolean;
	@Input() public inActive: boolean;
}
