import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AD_PLACEMENT_TEMPLATE_ENTITY } from 'src/app/state/app.state';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { IAdPlacementsTemplates } from 'src/app/core/models/ad/ad/ad-placements-templates.model';

@Injectable()
export class AdPlacementTemplateDataService extends DefaultDataService<IAdPlacementsTemplates> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(AD_PLACEMENT_TEMPLATE_ENTITY, http, httpUrlGenerator);
	}

	public getById(adId: string): Observable<IAdPlacementsTemplates> {
		return this.appCoreServices.socialCampaignApiService.adPlacementTemplateApi.getPlacementTemplates(
			adId,
		);
	}
}
