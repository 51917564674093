import {
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SocialAccountFeatureService } from './social-account-feature.service';

@NgModule({
	declarations: [],
	imports: [],
	providers: [
		SocialAccountFeatureService,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class SocialAccountFeatureModule {}
