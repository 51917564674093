import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { SentinelService } from '@bannerflow/sentinel';
import { AuthService, GenericError } from '@auth0/auth0-angular';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { mergeMap, Observable, Subject } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	public title = 'social-campaign-manager';
	public isLoaded = false;
	public isAuthenticated$: Observable<boolean>;
	private sentinelService = inject(SentinelService);
	private readonly destroyed$ = new Subject<void>();

	constructor(
		private router: Router,
		private authService: AuthService,
	) {
		this.isAuthenticated$ = this.authService.isAuthenticated$;

		this.router.events
			.pipe(
				filter((e) => e instanceof NavigationEnd),
				map(() => this.isAuthenticated$),
				filter((isAuthenticated) => !!isAuthenticated),
				takeUntilDestroyed(),
			)
			.subscribe(() => {
				this.isLoaded = true;
			});

		this.authService.error$
			.pipe(
				filter(
					(e) =>
						e instanceof GenericError &&
						(e.error === 'login_required' ||
							e.error === 'invalid_grant'),
				),
				mergeMap(() => this.authService.logout()),
				takeUntilDestroyed(),
			)
			.subscribe();
	}

	public ngOnInit(): void {}

	public ngOnDestroy(): void {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
