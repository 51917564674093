import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FacebookCarouselBaseDirective } from '../../directives/fb-carousel-base.directive';
import { FacebookCarouselSupportedPlacements } from '../../enums';
import { FacebookCarouselPlacementsComponentStore } from '../../fb-carousel-placements.component.store';
import { CarouselSliderComponent } from '../../carousel-elements/carousel-slider/carousel-slider.component';
import { CreativeContentComponent } from '../../../../../../elements/creative-content/creative-content.component';
import { FacebookStoriesHeaderComponent } from '../elements/stories-header/stories-header.component';
import { FacebookStoriesFooterComponent } from '../elements/stories-footer/stories-footer.component';
import { FbPrimaryTextComponent } from '../../../../placement-elements/primary-text/fb-primary-text.component';

@Component({
	selector: 'fb-carousel-instagram-stories',
	templateUrl: './instagram-stories.component.html',
	styleUrls: ['./instagram-stories.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		CarouselSliderComponent,
		CreativeContentComponent,
		FacebookStoriesHeaderComponent,
		FacebookStoriesFooterComponent,
		FbPrimaryTextComponent,
	],
})
export class FacebookCarouselInstagramStoriesComponent extends FacebookCarouselBaseDirective {
	constructor(
		carouselPlacementsStore: FacebookCarouselPlacementsComponentStore,
	) {
		super(
			carouselPlacementsStore,
			FacebookCarouselSupportedPlacements.FacebookInstagramStories,
		);
	}
}
