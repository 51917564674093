import { Injectable, Injector } from '@angular/core';
import { CommonService } from './services/common.service';
import { UtilitiesService } from './services/utilities.service';

@Injectable()
export class CommonFeatureService {
	public common: CommonService;
	public utilities: UtilitiesService;

	constructor(private injector: Injector) {
		this.common = this.injector.get(CommonService);
		this.utilities = this.injector.get(UtilitiesService);
	}
}
