import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AD_CONTENT_CAROUSEL_VALIDATION_ENTITY } from 'src/app/state/app.state';
import { ICarouselAdContentValidation } from 'src/app/core/models';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { AdFormatEnum } from 'src/app/presentation/view-models';

@Injectable()
export class AdContentCarouselValidationDataService extends DefaultDataService<ICarouselAdContentValidation> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(AD_CONTENT_CAROUSEL_VALIDATION_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery({
		placementId,
		adContent,
		cardsContents,
		adId,
		adFormatId,
	}: {
		placementId: string;
		adContent: string;
		cardsContents: string;
		adId: string;
		adFormatId: AdFormatEnum;
		adContentPropertyId?: string;
	}): Observable<ICarouselAdContentValidation[]> {
		return this.appCoreServices.socialCampaignApiService.adPlacementTemplateApi.validateCarouselAdContent(
			adId,
			placementId,
			adFormatId,
			adContent,
			cardsContents,
		);
	}
}
