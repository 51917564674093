import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { IConnection } from 'src/app/core/models';
import { ADGROUP_CONNECTION_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class AdGroupConnectionEntityService extends EntityCollectionServiceBase<IConnection> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(ADGROUP_CONNECTION_ENTITY, serviceElementsFactory);
	}
}
