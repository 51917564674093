import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { IAdGroupVM } from 'src/app/presentation/view-models';
import { AdGroupMappersService } from '../../mappers/ad-group-mapper/ad-group.mapper';
import { IAdGroup } from 'src/app/core/models';
import { AdGroupBaseService } from './ad-group-base.service';
import { FacebookAccountFeatureService } from 'src/app/features/facebook/account/facebook-account-feature.service';
import { IntegrationService } from 'src/app/features/account/services/integration/integration.service';

@Injectable({
	providedIn: 'root',
})
export class AdGroupService extends AdGroupBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public adGroupMapper: AdGroupMappersService,
		public facebookAccountService: FacebookAccountFeatureService,
		public integrationService: IntegrationService,
	) {
		super(appEntityServices, adGroupMapper, facebookAccountService);
	}

	public getAdGroupsByCampaignId(campaignId: string): void {
		this.appEntityServices.adEntity.adGroup.getWithQuery({
			campaignId: campaignId,
		});
	}

	public loadIntegrationIdFrom(adGroupId: string): Observable<string> {
		const groups$: Observable<IAdGroup[]> =
			this.appEntityServices.adEntity.adGroup.entities$;
		const adGroupIntegrationId$: Observable<string> = groups$.pipe(
			filter((groups) => !!groups),
			map(
				(groups) =>
					groups.find((group) => group?.id === adGroupId)?.connection
						?.integrationId,
			),
		);
		return adGroupIntegrationId$;
	}

	public loadIntegrationIds(adGroupIds: string[]): Observable<string[]> {
		return of(adGroupIds).pipe(
			switchMap((ids) =>
				combineLatest(ids.map((id) => this.loadIntegrationIdFrom(id))),
			),
		);
	}

	public loadAdGroups(): Observable<IAdGroupVM[]> {
		const groups$: Observable<IAdGroup[]> =
			this.appEntityServices.adEntity.adGroup.entities$;
		const adGroupIds$: Observable<string[]> = groups$.pipe(
			map((groups) => groups.map((group) => group.id)),
		);

		return adGroupIds$.pipe(
			switchMap((ids) => {
				if (ids.length === 0) {
					return of([]);
				}
				return combineLatest(
					ids.map((adGroupId) => this.loadById(adGroupId)),
				);
			}),
		);
	}

	public addAdGroup(fromViewModel: IAdGroupVM): Observable<IAdGroup> {
		const group: IAdGroup = this.adGroupMapper.toGroup(fromViewModel);
		const group$ = this.appEntityServices.adEntity.adGroup.add(group);

		group$.subscribe(() => {
			this.appEntityServices.adEntity.adGroup.setLoaded(true);
		});

		return group$;
	}

	public isLoadingAdGroups(): Observable<boolean> {
		return this.appEntityServices.adEntity.adGroup.loading$;
	}

	public isAdGroupsLoaded(): Observable<boolean> {
		return this.appEntityServices.adEntity.adGroup.loaded$;
	}

	public isAdGroupConnected(adGroupId: string): Observable<boolean> {
		return this.appEntityServices.adEntity.adGroup.entities$.pipe(
			filter((groups) => !!groups),
			map((groups) => groups.find((group) => group.id === adGroupId)),
			map((adGroup) => !!adGroup?.connection),
		);
	}

	public loadIsAdGroupConnectionValid(
		adGroupId: string,
	): Observable<boolean> {
		return this.appEntityServices.adEntity.adGroup.entities$.pipe(
			filter((groups) => !!groups),
			map((groups) => groups.find((group) => group.id === adGroupId)),
			switchMap((adGroup) => {
				if (adGroup?.connection?.integrationId) {
					return this.integrationService.loadIsIntegrationValid(
						adGroup.connection.integrationId,
					);
				}

				return of(false);
			}),
		);
	}

	public renameAdGroup(name: string, adGroupId: string): Observable<void> {
		const adGroup: Partial<IAdGroup> = {
			id: adGroupId,
			name,
		};

		this.appEntityServices.adEntity.adGroup.update(adGroup);

		return new Observable<void>();
	}

	public updateSelectedPlacements(
		selectedPlacementsIds: string[],
		adGroupId: string,
	): Observable<void> {
		const adGroup: Partial<IAdGroup> = {
			id: adGroupId,
			supportedPlacementsIds: selectedPlacementsIds,
		};

		this.appEntityServices.adEntity.adGroup.update(adGroup);
		return new Observable<void>();
	}

	public isAdGroupPlacementsMatchAdSetSupportedPlacements(
		adGroupId,
		adSetId,
	): Observable<boolean> {
		const adGroup$ = this.loadById(adGroupId);
		const facebookAdSetPlacements$ =
			this.facebookAccountService.adSetPlacement.loadAdSetPlacements(
				adSetId,
			);

		return combineLatest([adGroup$, facebookAdSetPlacements$]).pipe(
			map(([adGroup, facebookAdSetPlacements]) => {
				if (!adGroup.supportedPlacementsIds) {
					return true;
				} else {
					return adGroup.supportedPlacementsIds.every((placementId) =>
						facebookAdSetPlacements.includes(placementId),
					);
				}
			}),
		);
	}
}
