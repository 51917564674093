import {
	Component,
	Input,
	ChangeDetectionStrategy,
	OnInit,
	OnDestroy,
} from '@angular/core';
import {
	combineLatest,
	Observable,
	Subject,
	switchMap,
	withLatestFrom,
} from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { ICreativeSize } from 'src/app/core/models';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import {
	IAssignedCreativeVM,
	ICreativeVM,
	IFacebookPlacementVM,
	IGroupedCreativesByAspectRatioVM,
	ISizeVM,
} from 'src/app/presentation/view-models';
import { CustomizePlacementWizardStore } from '../../customize-placement.component.store';
import { LetDirective } from '@ngrx/component';
import { UIModule } from '@bannerflow/ui';
import { CommonModule } from '@angular/common';
import { CreativeDeactivationWarningComponent } from 'src/app/presentation/features/studio/creative-deactivation-warning/creative-deactivation-warning.component';
import { ASPECT_GROUP_KEY_ANY } from 'src/app/presentation/view-models/shared/aspect-group-key-any';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		UIModule,
		LetDirective,
		CreativeDeactivationWarningComponent,
	],
	selector: 'customize-creative-card',
	templateUrl: 'customize-creative-card.component.html',
	styleUrls: ['customize-creative-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomizeCreativeCardComponent implements OnInit, OnDestroy {
	@Input() public creativeChoice: ICreativeVM;
	@Input() public aspectRatioGroupKey: string;
	@Input() public previousCreativeSetId: string;
	public selectedCreative$: Observable<IAssignedCreativeVM>;
	public selectedCreativeSet$: Observable<IGroupedCreativesByAspectRatioVM>;
	public adCreatives$: Observable<IAssignedCreativeVM[]>;
	public previousCreative$: Observable<ICreativeVM>;
	public creativeSetIds$: Observable<string[]>;
	public creative$: Observable<ICreativeVM>;
	public placements$: Observable<IFacebookPlacementVM[]>;
	public resultAspectRatio: number;

	private unsubscribe$ = new Subject<void>();

	constructor(
		private readonly customizePlacementWizardStore: CustomizePlacementWizardStore,
		private appFeatureServices: AppFeatureServices,
	) {}

	public ngOnInit(): void {
		this.getCreativeSet(this.previousCreativeSetId);
		this.adCreatives$ = this.setupAdCreatives();
		this.selectedCreativeSet$ = this.setupSelectedCreativeSets();
		this.selectedCreative$ = this.setupSelectedCreative();

		this.creativeSetIds$ = this.setupCreativeSetsIds();

		this.creative$ = this.setupCreative();

		this.placements$ =
			this.customizePlacementWizardStore.loadSupportedPlacements();

		combineLatest([this.creative$, this.selectedCreativeSet$])
			.pipe(
				filter(
					([creative, selectedCreativeSet]) =>
						!!creative && !!selectedCreativeSet,
				),
				takeUntil(this.unsubscribe$),
				tap(([creative]) => {
					this.resultAspectRatio =
						this.aspectRatioSize(
							this.aspectRatioGroupKey,
							creative.size,
						) * 120;
				}),
			)
			.subscribe();
	}

	private setupCreativeSetsIds(): Observable<string[]> {
		return this.adCreatives$.pipe(
			map((adCreatives) => adCreatives.map((creative) => creative.setId)),
			map((creativeSetIds) => [...new Set(creativeSetIds)]),
		);
	}

	private setupCreative(): Observable<ICreativeVM> {
		const placements$ =
			this.customizePlacementWizardStore.loadSupportedPlacements();

		const placementId$ =
			this.customizePlacementWizardStore.loadSelectedPlacement();

		const creativeSets$ = this.creativeSetIds$.pipe(
			switchMap((creativeSetIds) =>
				this.appFeatureServices.studioFeature.creativeSetService.loadByIds(
					creativeSetIds,
				),
			),
		);

		const sizes$ = this.creativeSetIds$.pipe(
			withLatestFrom(this.adCreatives$),
			map(([creativeSetIds, adCreatives]) =>
				this.appFeatureServices.studioFeature.creativeSetService.loadCreativeSizesAcrossSets(
					creativeSetIds,
					adCreatives,
				),
			),
			switchMap((sizes) => sizes),
		);

		return placements$.pipe(
			withLatestFrom(
				this.adCreatives$,
				sizes$,
				placementId$,
				creativeSets$,
			),
			map(
				([
					placements,
					adCreatives,
					sizes,
					placementId,
					creativeSets,
				]) => {
					const placement = placements.find(
						(placementItem) => placementItem.id === placementId,
					);
					const creativesPerCreativeSet = [
						...creativeSets.map(
							(creativeSet) => creativeSet.creatives,
						),
					];
					const creativesAcrossCreativeSets: ICreativeVM[] =
						Array.prototype.concat(...creativesPerCreativeSet);
					return creativesAcrossCreativeSets.find(
						(someCreative) =>
							someCreative.id ===
							this.getAdCreativeForPlacement(
								placement,
								adCreatives,
								sizes,
							),
					);
				},
			),
		);
	}

	private setupAdCreatives(): Observable<IAssignedCreativeVM[]> {
		return this.customizePlacementWizardStore.loadSelectedAd().pipe(
			map((ad) => ad?.adCreatives),
			take(1),
		);
	}

	private setupSelectedCreativeSets(): Observable<IGroupedCreativesByAspectRatioVM> {
		return this.customizePlacementWizardStore.loadCreativeSet();
	}

	private setupSelectedCreative(): Observable<IAssignedCreativeVM> {
		return this.customizePlacementWizardStore.loadSelectedCreative();
	}

	private toCreative(
		creativeSet: IGroupedCreativesByAspectRatioVM,
		creativeId: string,
	): IAssignedCreativeVM {
		const [firstKey] = Object.keys(creativeSet);
		const creativeSetId = creativeSet[firstKey]?.id;
		return {
			setId: creativeSetId,
			id: creativeId,
		};
	}

	public selectCreative(
		selectedCreativeSet: IGroupedCreativesByAspectRatioVM,
		prevSelectedCreative?: IAssignedCreativeVM,
	): void {
		const creative = this.toCreative(
			selectedCreativeSet,
			this.creativeChoice?.id,
		);
		this.customizePlacementWizardStore.setSelectedCreative(creative);
		this.getCreativeSet(creative?.setId);

		const isPristine = this.creativeChoice?.id === prevSelectedCreative?.id;
		if (!isPristine) {
			this.customizePlacementWizardStore.updateIsDirtyState(true);
		}
	}

	private getCreativeSet(creativeSetId: string): void {
		this.appFeatureServices.studioFeature.creativeSetService.getById(
			creativeSetId,
		);
	}

	private getAdCreativeForPlacement(
		placement: IFacebookPlacementVM,
		adCreatives: IAssignedCreativeVM[],
		creativeSizes: ICreativeSize[],
	): string {
		for (const adCreative of adCreatives) {
			const size = creativeSizes.find(
				(creativeSize) => creativeSize.id === adCreative.id,
			).size;
			if (
				this.appFeatureServices.studioFeature.designMatchingService.isDesignMatchingPlacementRecommendations(
					size?.width,
					size?.height,
					placement,
				)
			) {
				return adCreative.id;
			}
		}
	}

	public aspectRatioSize(aspectRatio: string, size: ISizeVM): number {
		if (aspectRatio === ASPECT_GROUP_KEY_ANY) {
			return +(size.height / size.width).toFixed(2);
		}

		const aspectRatioArray = aspectRatio.split(':');
		return +aspectRatioArray[1] / +aspectRatioArray[0];
	}

	public ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
