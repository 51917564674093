import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { IBrandVM } from 'src/app/presentation/view-models';
import { CommonModule } from '@angular/common';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { UIModule } from '@bannerflow/ui';
import { FbMenuDotsIconComponent } from '../menu-dots-icon/fb-menu-dots-icon.component';
import { BrandLogoComponent } from 'src/app/presentation/features/account/brand-logo/brand-logo.component';

@Component({
	selector: 'fb-placement-header',
	templateUrl: './fb-placement-header.component.html',
	styleUrls: ['./fb-placement-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		TextPipe,
		UIModule,
		BrandLogoComponent,
		FbMenuDotsIconComponent,
	],
})
export class FbPlacementHeaderComponent {
	@Input() public hasCloseCross?: boolean;
	@Input() public hasArrowIcon?: boolean;
	@Input() public hasDots?: boolean = true;
	@Input() public isSmallBrand?: boolean;

	public brand$: Observable<IBrandVM> =
		this.appFeatureServices.accountFeature.brand.load();
	constructor(private appFeatureServices: AppFeatureServices) {}
}
