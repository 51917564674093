import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import {
	catchError,
	filter,
	map,
	switchMap,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import {
	IAssignedCreativeVM,
	IAdGroupVM,
	IFacebookAdVM,
	IFacebookPlacementSingleMetaDataVM,
	IGroupedCreativesByAspectRatioVM,
	IPlacementBaseVM,
} from 'src/app/presentation/view-models';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { FbSinglePlacementMetaDataMappersService } from '../placements/services/fb-placement-meta.mappers';

export interface CustomizePlacementWizardState {
	selectedCreativeSet?: IGroupedCreativesByAspectRatioVM;
	creativeSetIsLoading?: boolean;
	selectedCreative?: IAssignedCreativeVM;
	selectedAd?: IFacebookAdVM;
	selectedAGroup?: IAdGroupVM;
	selectedPlacementId?: string;
	customizedPlacementMetaData?: IFacebookPlacementSingleMetaDataVM;
	isDirty: boolean;
	supportedPlacements?: IPlacementBaseVM[];
}
@Injectable()
export class CustomizePlacementWizardStore extends ComponentStore<CustomizePlacementWizardState> {
	constructor(
		private appFeatureServices: AppFeatureServices,
		private fbPlacementMetaDataMappers: FbSinglePlacementMetaDataMappersService,
	) {
		super({
			creativeSetIsLoading: false,
			isDirty: false,
		});
	}

	public readonly getCreativeSet = this.effect(
		(creativeSetId$: Observable<string>) =>
			creativeSetId$.pipe(
				tap(() => this.setCreativeSetLoading(true)),
				withLatestFrom(
					this.loadSelectedAd(),
					this.loadSelectedPlacement(),
					this.select((state) => state.supportedPlacements),
				),
				switchMap(
					([
						id,
						selectedAd,
						selectedPlacement,
						supportedPlacements,
					]) =>
						this.appFeatureServices.studioFeature.creativeSetService
							.getCompatibleCreativeSet(
								id,
								selectedAd?.languageId,
								[selectedPlacement],
								supportedPlacements,
							)
							.pipe(
								tap((creativeSet) => {
									this.updateCreativeSetState(creativeSet);
								}),
								catchError(() => {
									this.setCreativeSetLoading(false);
									return EMPTY;
								}),
							),
				),
			),
	);

	public readonly initPlacementContentMetaData = this.effect(
		(selectedAd$: Observable<IFacebookAdVM>) =>
			selectedAd$.pipe(
				map((selectedAd) =>
					this.fbPlacementMetaDataMappers.fromAdToPlacementsMetaData(
						selectedAd,
					),
				),
				withLatestFrom(this.loadSelectedPlacement()),
				tap(([placementContentMetaDataMap, selectedPlacementId]) => {
					this.updatePlacementContentState(
						placementContentMetaDataMap[selectedPlacementId],
					);
				}),
			),
	);

	public readonly setSelectedCreativeSet = this.effect(
		(creativeSet$: Observable<IGroupedCreativesByAspectRatioVM>) =>
			creativeSet$.pipe(
				tap((selectedCreativeSet: IGroupedCreativesByAspectRatioVM) => {
					this.updateCreativeSetState(selectedCreativeSet);
				}),
			),
	);

	public readonly setSelectedCreative = this.effect(
		(assignedCreativeVM$: Observable<IAssignedCreativeVM>) =>
			assignedCreativeVM$.pipe(
				tap((selectedCreative: IAssignedCreativeVM) => {
					this.updateSelectedCreativeState(selectedCreative);
				}),
			),
	);

	public readonly updateCreativeSetState = this.updater(
		(
			state: CustomizePlacementWizardState,
			creativeSet: IGroupedCreativesByAspectRatioVM,
		) => ({
			...state,
			selectedCreativeSet: creativeSet,
			selectedCreative: state.selectedCreative,
			creativeSetIsLoading: false,
		}),
	);

	public readonly updateIsDirtyState = this.updater(
		(state: CustomizePlacementWizardState, isDirty: boolean) => ({
			...state,
			isDirty,
		}),
	);

	public readonly updateSelectedCreativeState = this.updater(
		(
			state: CustomizePlacementWizardState,
			creative: IAssignedCreativeVM,
		) => ({
			...state,
			selectedCreative: creative,
		}),
	);

	public readonly updatePlacementContentState = this.updater(
		(
			state: CustomizePlacementWizardState,
			customizedPlacementContent: IFacebookPlacementSingleMetaDataVM,
		) => ({
			...state,
			customizedPlacementMetaData: customizedPlacementContent,
		}),
	);

	public readonly updatePlacementContentPartialState = this.updater(
		(
			state: CustomizePlacementWizardState,
			customizedPlacementContent: Partial<IFacebookPlacementSingleMetaDataVM>,
		) => ({
			...state,
			customizedPlacementMetaData: {
				...state.customizedPlacementMetaData,
				...customizedPlacementContent,
			},
		}),
	);

	public readonly updateAssignedCreativesState = this.updater(
		(
			state: CustomizePlacementWizardState,
			selectedAd: Partial<IFacebookAdVM>,
		) => ({
			...state,
			selectedAd: { ...state.selectedAd, ...selectedAd },
		}),
	);

	public readonly setCreativeSetLoading = this.updater(
		(
			state: CustomizePlacementWizardState,
			creativeSetIsLoading: boolean,
		) => ({
			...state,
			creativeSetIsLoading,
		}),
	);

	public readonly setSelectedAd = this.updater(
		(state: CustomizePlacementWizardState, selectedAd: IFacebookAdVM) => ({
			...state,
			selectedAd,
		}),
	);

	public readonly setSupportedPlacements = this.updater(
		(
			state: CustomizePlacementWizardState,
			supportedPlacements: IPlacementBaseVM[],
		) => ({
			...state,
			supportedPlacements,
		}),
	);

	public readonly setSelectedPlacement = this.updater(
		(
			state: CustomizePlacementWizardState,
			selectedPlacementId: string,
		) => ({
			...state,
			selectedPlacementId: selectedPlacementId,
		}),
	);

	public readonly setSelectedAdGroup = this.updater(
		(
			state: CustomizePlacementWizardState,
			selectedAdGroup: IAdGroupVM,
		) => ({
			selectedCreative: { ...state.selectedCreative },
			selectedAd: { ...state.selectedAd },
			selectedCreativeSet: { ...state.selectedCreativeSet },
			selectedPlacementId: state.selectedPlacementId,
			isDirty: state.isDirty,
			selectedAGroup: selectedAdGroup,
			supportedPlacements: state.supportedPlacements,
		}),
	);

	public loadSelectedCreative(): Observable<IAssignedCreativeVM> {
		return this.select((state) => {
			if (state.selectedCreative) {
				return state.selectedCreative;
			}
		});
	}

	public loadIsDirty(): Observable<boolean> {
		return this.select((state) => state.isDirty);
	}

	public loadCreativeSet(): Observable<IGroupedCreativesByAspectRatioVM> {
		return this.select((state) => state.selectedCreativeSet);
	}

	public isLoadingCreativeSet(): Observable<boolean> {
		return this.select((state) => state.creativeSetIsLoading);
	}

	public loadSelectedAd(): Observable<IFacebookAdVM> {
		return this.select((state) => state.selectedAd);
	}

	public loadSelectedAdGroup(): Observable<IAdGroupVM> {
		return this.select((state) => state.selectedAGroup);
	}

	public loadSelectedPlacement(): Observable<string> {
		return this.select((state) => state.selectedPlacementId);
	}

	public loadCustomizPlacementContent(): Observable<IFacebookPlacementSingleMetaDataVM> {
		return this.select((state) => state.customizedPlacementMetaData);
	}

	public loadSupportedPlacements(): Observable<IPlacementBaseVM[]> {
		return this.select((state) => state.supportedPlacements);
	}

	public loadIsSelectedAdDirty(): Observable<boolean> {
		const adVM$ = this.loadSelectedAd();
		const savedAd$ = adVM$.pipe(
			filter((ad) => !!ad.id),
			switchMap((ad) =>
				this.appFeatureServices.facebookFeature.ad.loadById(ad.id),
			),
		);

		return combineLatest([savedAd$, adVM$]).pipe(
			map(
				([savedAd, adVM]) =>
					!this.appFeatureServices.commonFeature.utilities.isEqual(
						savedAd,
						adVM,
					),
			),
		);
	}
}
