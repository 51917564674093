<ng-container *ngIf="(placementMetaData$ | async) as metaData">
    <ng-container *ngIf="checkIfCardsHaveVideo(metaData.cards); else imageDesign">
        <fb-carousel-search-results-video
            [placementMetaData]="metaData"></fb-carousel-search-results-video>
    </ng-container>
    <ng-template #imageDesign>
        <fb-carousel-search-results-image
            [placementMetaData]="metaData"></fb-carousel-search-results-image>
    </ng-template>
</ng-container>
