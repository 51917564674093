import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { BaseFeatureService } from 'src/app/features/base-feature.service';
import { IFacebookAdSetPlacementsVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class FacebookAdSetPlacementBaseService extends BaseFeatureService<IFacebookAdSetPlacementsVM> {
	constructor(public appEntityServices: AppEntityServices) {
		super();
	}

	public getWithQuery(params: {
		integrationId: string;
		campaignId: string;
		adSetId: string;
	}): void {
		this.appEntityServices.facebookEntity.adSetPlacement.getWithQuery({
			integrationId: params['integrationId'],
			campaignId: params['campaignId'],
			adSetId: params['adSetId'],
		});
	}

	protected getById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected load(): Observable<IFacebookAdSetPlacementsVM> {
		throw new Error('Method not implemented.');
	}
	protected loadAll(): Observable<IFacebookAdSetPlacementsVM[]> {
		throw new Error('Method not implemented.');
	}
	protected loadById(_id: string): Observable<IFacebookAdSetPlacementsVM> {
		throw new Error('Method not implemented.');
	}
	protected loadByIds(
		_ids: string[],
	): Observable<IFacebookAdSetPlacementsVM[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
