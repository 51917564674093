<ng-container>
    <div class="card-header">
        <facebook-placement-header
            [placementId]="placementId.FacebookMessengerInbox"
            [isExpanded]="false"
            [render]="templateFragments.Logo"></facebook-placement-header>
        <div class="info-container">
            <div class="social-info">
                <facebook-placement-header
                    [placementId]="placementId.FacebookMessengerInbox"
                    [isExpanded]="false"
                    [render]="templateFragments.BrandName"></facebook-placement-header>
                <div class="headline">
                    <headline
                        [headLineValue]="placementMetaData.headLineValue"
                        [placementId]="placementId.FacebookMessengerInbox"
                        [isExpanded]="false"></headline>
                </div>
                <cta-button
                    [ctaValue]="placementMetaData.callToAction"
                    [placementId]="placementId.FacebookMessengerInbox"
                    [isExpanded]="false">
                </cta-button>
            </div>
            <div class="creative-content">
                <ng-container
                    *ngIf="selectedCustomizedCreative?.id || placementMetaData?.creative?.id || placementMetaData?.creativeUrl">
                    <creative-content
                        [sound]="placementSound$ | async"
                        [creativeUrl]="placementMetaData?.creativeUrl"
                        [isCustomized]="placementMetaData?.isCustomized"
                        [creativeId]="selectedCustomizedCreative?.id || placementMetaData?.creative?.id"
                        [creativeSetId]="selectedCustomizedCreative?.setId || placementMetaData?.creative?.setId"
                        [selectedMediaType]="placementMetaData?.selectedMediaType"></creative-content>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
