import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SliderTypeEnum } from '../../carousel-elements/carousel-slider/slider-type';
import { FacebookCarouselSupportedPlacements } from '../../enums';
import { FacebookCarouselBaseDirective } from '../../directives/fb-carousel-base.directive';
import { FacebookCarouselPlacementsComponentStore } from '../../fb-carousel-placements.component.store';
import { FbPlacementHeaderComponent } from '../../../../placement-elements/placement-header/fb-placement-header.component';
import { FbPrimaryTextComponent } from '../../../../placement-elements/primary-text/fb-primary-text.component';
import { CarouselSliderComponent } from '../../carousel-elements/carousel-slider/carousel-slider.component';
import { FbFeedCardComponent } from '../fb-feed-elements/feed-card/fb-feed-card.component';
import { FacebookPlacementFooterComponent } from '../../../../placement-elements/placement-footer/fb-placement-footer.component';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'fb-carousel-news-feed-placement',
	templateUrl: './fb-news-feed.component.html',
	styleUrls: ['./fb-news-feed.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		FbPlacementHeaderComponent,
		FbPrimaryTextComponent,
		CarouselSliderComponent,
		FbFeedCardComponent,
		FacebookPlacementFooterComponent,
	],
})
export class FacebookCarouselNewsFeedPlacementComponent extends FacebookCarouselBaseDirective {
	public sliderTypeEnum: typeof SliderTypeEnum = SliderTypeEnum;
	constructor(
		carouselPlacementsStore: FacebookCarouselPlacementsComponentStore,
	) {
		super(
			carouselPlacementsStore,
			FacebookCarouselSupportedPlacements.FacebookNewsFeed,
		);
	}
}
