<ng-container *ngIf="placementMetaData$ | async as placementMetaData">
    <div class="card-container">
        <ui-button-group
            ui-theme="tiny"
            class="buttons-container"
            [options]="viewOptions"
            [value]="selectedButton"
            (valueChange)="onViewChange($event);"
            (click)="$event.stopPropagation()">
        </ui-button-group>
        <ng-container
            *ngIf="selectedButton === viewOptionsEnum.Collapsed; else expandedView">
            <fb-inbox-collapsed [placementMetaData]="placementMetaData" />
        </ng-container>
        <ng-template #expandedView>
            <fb-inbox-expanded [placementMetaData]="placementMetaData" />
        </ng-template>
    </div>
</ng-container>
