<ng-container *ngIf="brand$ | async as brand">
    <div class="placement-header">
        <div class="brand">
            <div class="brand-name">{{ brand?.name }}</div>
            <div class="brand-sponsor">
                {{"PlacementHeaderComponent.SPONSORED" | text}}
            </div>
        </div>
        <div class="icons">
            <ui-svg-icon class="icons-carousel" icon="copy-s"></ui-svg-icon>
            <fb-menu-dots-icon></fb-menu-dots-icon>
        </div>
    </div>
</ng-container>
