import { Component } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { FbMenuDotsIconComponent } from '../../../../../placement-elements/menu-dots-icon/fb-menu-dots-icon.component';

@Component({
	selector: 'instagram-reels-icons',
	templateUrl: './instagram-reels-icons.component.html',
	styleUrls: ['./instagram-reels-icons.component.scss'],
	standalone: true,
	imports: [UIModule, FbMenuDotsIconComponent],
})
export class InstagramReelsIconsComponent {}
