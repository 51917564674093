import { Injectable, Injector } from '@angular/core';
import { SocialAccountFeatureService } from './account/social-account-feature.service';
import { SocialAdFeatureService } from './ad/social-ad-feature.service';
import { SocialCampaignFeatureService } from './campaign/social-campaign-feature.service';
import { CommonFeatureService } from './common/common-feature.service';
import { FacebookFeatureService } from './facebook/facebook-feature.service';
import { SocialPublishFeatureService } from './publish/social-publish-feature.service';
import { StudioFeatureService } from './studio/studio-feature.service';
import { TiktokFeatureService } from './tiktok/tt-feature.service';

@Injectable()
export class AppFeatureServices {
	public accountFeature: SocialAccountFeatureService;
	public adFeature: SocialAdFeatureService;
	public campaignFeature: SocialCampaignFeatureService;
	public commonFeature: CommonFeatureService;
	public facebookFeature: FacebookFeatureService;
	public tiktokFeature: TiktokFeatureService;
	public publishFeature: SocialPublishFeatureService;
	public studioFeature: StudioFeatureService;

	constructor(private injector: Injector) {
		this.accountFeature = this.injector.get(SocialAccountFeatureService);
		this.adFeature = this.injector.get(SocialAdFeatureService);
		this.campaignFeature = this.injector.get(SocialCampaignFeatureService);
		this.commonFeature = this.injector.get(CommonFeatureService);
		this.facebookFeature = this.injector.get(FacebookFeatureService);
		this.publishFeature = this.injector.get(SocialPublishFeatureService);
		this.studioFeature = this.injector.get(StudioFeatureService);
		this.tiktokFeature = this.injector.get(TiktokFeatureService);
	}
}
