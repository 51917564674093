import { Injectable } from '@angular/core';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { UserMappersService } from '../../mappers';
import { BrandBaseService } from './brand-base.service';

@Injectable({
	providedIn: 'root',
})
export class BrandService extends BrandBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public userMapper: UserMappersService,
	) {
		super(appEntityServices, userMapper);
	}
}
