import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	standalone: true,
	imports: [CommonModule],
	selector: 'fb-headline-inbox',
	templateUrl: './fb-headline-inbox.component.html',
	styleUrls: ['./fb-headline-inbox.component.scss'],
})
export class FbHeadlineInboxComponent {
	@Input() public headLineValue: string;
}
