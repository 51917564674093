import { Component } from '@angular/core';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { FacebookPlacementCoreBaseDirective } from '../../../shared/directives/facebook-placement-core-base.directive';
import { FacebookSupportedPlacements } from '../../../shared/enums';
import { PrimaryTextComponent } from '../../../shared/primary-text/primary-text.component';
import { FacebookPlacementFooterComponent } from '../../../../../placement-elements/placement-footer/fb-placement-footer.component';
import { FacebookPlacementHeaderComponent } from '../../../shared/placement-header/fb-placement-header.component';
import { HeadlineComponent } from '../../../shared/headline/headline.component';
import { CreativeContentComponent } from '../../../../../../../elements/creative-content/creative-content.component';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		CreativeContentComponent,
		HeadlineComponent,
		FacebookPlacementHeaderComponent,
		PrimaryTextComponent,
		FacebookPlacementFooterComponent,
	],
	selector: 'facebook-in-stream-videos-core',
	templateUrl: './fb-in-stream-videos-core.component.html',
	styleUrls: ['./fb-in-stream-videos-core.component.scss'],
})
export class FacebookInStreamVideosCoreComponent extends FacebookPlacementCoreBaseDirective {
	constructor(public appFeatureServices: AppFeatureServices) {
		super(
			appFeatureServices,
			FacebookSupportedPlacements.FacebookInStreamVideos,
		);
	}
}
