<ng-container *ngIf="placementMetaData$ | async as placementMetaData">
    <div class="placement-container">
        <fb-placement-header [hasCloseCross]="true"></fb-placement-header>
        <div class="placement-container-primary-text">
            <fb-primary-text
                [primaryTextValue]="placementMetaData.primaryTextValue"
                [numberOfLines]="3"></fb-primary-text>
        </div>
        <carousel-slider
            [cards]="placementMetaData.cards"
            [sliderType]="sliderTypeEnum.clickable"
            [initialOffset]="10"
            [cardWidth]="205"
            (indexChanged)="onSlideChange($event)">
            <ng-template let-card="card">
                <fb-feed-card [card]="card" />
            </ng-template>
        </carousel-slider>
        <div class="placement-container-footer">
            <facebook-placement-footer></facebook-placement-footer>
        </div>
    </div>
</ng-container>
