import { Injectable } from '@angular/core';
import { ILocalization } from 'src/app/core/models/common/localization/localization.model';
import { ILocalizationVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class LocalizationMappersService {
	public mapToLocalizationVM(localization: ILocalization): ILocalizationVM {
		return {
			id: localization?.id,
			name: localization?.name,
			cultureCode: localization?.culture?.cultureCode,
		};
	}
}
