import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { INetwork } from '../../../core/models/account/network.model';
import { NETWORK_ENTITY } from '../../../state/app.state';

@Injectable()
export class NetworkDataService extends DefaultDataService<INetwork> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(NETWORK_ENTITY, http, httpUrlGenerator);
	}

	public getAll(): Observable<INetwork[]> {
		return this.appCoreServices.socialAccountApiService.getNetworks();
	}
}
