import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ConfigService } from 'src/app/core/config/config.service';
import { IAdFormat } from 'src/app/core/models';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../../../internal/api.service';

@Injectable({
	providedIn: 'root',
})
export class FacebookAdFormatApiService extends BaseApiService {
	constructor(public http: HttpClient) {
		super(http, `${ConfigService?.config?.SCS_URL}/api/ad-formats`);
	}

	/**
	 * Gets adFormats based on facebook networkId
	 */
	public getAdFormats(): Observable<any[]> {
		const url = `${this.baseUrl}?networkId=facebook`;

		return this.http.get<IAdFormat[]>(url).pipe(
			map((facebookAdFormats) => facebookAdFormats),
			catchError(ApiService.handleError),
		);
	}
}
