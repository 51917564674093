import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { TiktokBrandNameComponent } from '../elements/brand-name/tt-brand-name.component';
import { TiktokBrandLogoComponent } from '../elements/brand-logo/tt-brand-logo.component';
import { CreativeContentComponent } from '../../../../elements/creative-content/creative-content.component';
import { TiktokMessageIconComponent } from '../elements/message-icon/tt-message-icon.component';
import { TiktokCtaComponent } from '../elements/cta/tt-cta.component';
import { TiktokSinglePlacementsComponentStore } from '../tt-single-placements.component.store';
import { TiktokSupportedPlacements } from '../../enums/tt-supported-placement.enum';
import { TiktokPlacementBaseDirective } from '../directives/tt-placement-base.directive';

@Component({
	selector: 'tt-global-app-bundle',
	templateUrl: './tt-global-app-bundle.component.html',
	styleUrls: ['./tt-global-app-bundle.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		UIModule,
		CreativeContentComponent,
		TiktokBrandLogoComponent,
		TiktokBrandNameComponent,
		TiktokMessageIconComponent,
		TiktokCtaComponent,
	],
})
export class TiktokGlobalAppBundleComponent extends TiktokPlacementBaseDirective {
	constructor(
		tiktokSinglePlacementsComponentStore: TiktokSinglePlacementsComponentStore,
	) {
		super(
			tiktokSinglePlacementsComponentStore,
			TiktokSupportedPlacements.TiktokGlobalAppBundle,
		);
	}
}
