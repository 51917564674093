<div class="skeleton">
    <div class="skeleton-content">
        <div class="brand">
            <div class="brand-icon">
                <div class="skeleton-icon"></div>
            </div>
            <div class="brand-info">
                <div class="first-line"></div>
                <div class="second-line"></div>
            </div>
        </div>
        <div class="text">
            <div class="first-line"></div>
            <div class="second-line"></div>
        </div>
        <div class="music"></div>
    </div>
    <div class="skeleton-icons">
        <div class="icon"></div>
        <div class="icon"></div>
        <div class="icon"></div>
        <div class="icon"></div>
    </div>
</div>
