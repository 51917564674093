<ng-container *ngIf="headLineValue; else skeleton">
    <div
        class="headline-text"
        [style.font-weight]="fontWeight"
        [style.max-width.px]="width">
        {{ headLineValue }}
    </div>
</ng-container>
<ng-template #skeleton>
    <div class="headline-default" [style.width.px]="width"></div>
</ng-template>
