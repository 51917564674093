import { Injectable, Injector } from '@angular/core';
import { AdsEntityService } from './ad/ads-entity.service';
import { AdContentValidationEntityService } from './ad-content-validation/ad-content-validation-entity.service';
import { AdContentCarouselValidationEntityService } from './ad-content-carousel-validation/ad-content-carousel-validation-entity.service';
import { AdCustomizedValidationEntityService } from './ad-customized-validation/ad-customized-validation-entity.service';
import { AdGroupsEntityService } from './ad-group/ad-groups-entity.service';
import { AdGroupConnectionEntityService } from './ad-group-connection/ad-group-connection-entity.service';
import { AdsLogEntityService } from './ad-log/ads-log-entity.service';
import { AdsStatusEntityService } from './ad-status/ads.status-entity.service';
import { AdScheduleEntityService } from './ad-schedule/ads-schedule-entity.service';
import { AdPlacementTemplateEntityService } from './ad-placement-template/ad-placement-template-entity.service';

@Injectable()
export class AdEntityServices {
	public ad: AdsEntityService;
	public adContentValidation: AdContentValidationEntityService;
	public adContentCarouselValidation: AdContentCarouselValidationEntityService;
	public adCustomizatedValidation: AdCustomizedValidationEntityService;
	public adGroup: AdGroupsEntityService;
	public adGroupConnection: AdGroupConnectionEntityService;
	public adLog: AdsLogEntityService;
	public adStatus: AdsStatusEntityService;
	public adSchedule: AdScheduleEntityService;
	public adPlacementTemplate: AdPlacementTemplateEntityService;

	constructor(private injector: Injector) {
		this.ad = this.injector.get(AdsEntityService);
		this.adContentValidation = this.injector.get(
			AdContentValidationEntityService,
		);
		this.adContentCarouselValidation = this.injector.get(
			AdContentCarouselValidationEntityService,
		);
		this.adCustomizatedValidation = this.injector.get(
			AdCustomizedValidationEntityService,
		);
		this.adGroup = this.injector.get(AdGroupsEntityService);
		this.adGroupConnection = this.injector.get(
			AdGroupConnectionEntityService,
		);
		this.adLog = this.injector.get(AdsLogEntityService);
		this.adStatus = this.injector.get(AdsStatusEntityService);
		this.adSchedule = this.injector.get(AdScheduleEntityService);
		this.adPlacementTemplate = this.injector.get(
			AdPlacementTemplateEntityService,
		);
	}
}
