import { NgModule } from '@angular/core';
import { EntityDataModule } from '@ngrx/data';
import { AppEntityServices } from './app-entity-service';
import { entityMetadata } from './entity-meta-data';
import { EntityFacebookModule } from './facebook/entity-facebook.module';
import { EntityTiktokModule } from './tiktok/entity-tiktok.module';
import { EntityAccountModule } from './account/entity-account.module';
import { EntityAdModule } from './ad/entity-ad.module';
import { EntityCampaignModule } from './campaign/entity-campaign.module';
import { EntityPublishModule } from './publish/entity-publish.module';
import { AccountEntityServices } from './account/entity-account.services';
import { AdEntityServices } from './ad/entity-ad.service';
import { PublishEntityServices } from './publish/entity-publish.service';
import { TiktokEntityServices } from './tiktok/entity-tiktok.service';
import { CampaignEntityServices } from './campaign/entity-campaign.service';
import { FacebookEntityServices } from './facebook/entity-facebook.service';
@NgModule({
	imports: [
		EntityDataModule.forRoot({ entityMetadata: entityMetadata }),
		EntityAccountModule,
		EntityAdModule,
		EntityCampaignModule,
		EntityFacebookModule,
		EntityTiktokModule,
		EntityPublishModule,
	],
	providers: [
		AppEntityServices,
		AccountEntityServices,
		AdEntityServices,
		PublishEntityServices,
		TiktokEntityServices,
		CampaignEntityServices,
		FacebookEntityServices,
	],
})
export class EntityStoreModule {}
