import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TIMEZONE_ENTITY } from 'src/app/state/app.state';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { ITimezone } from 'src/app/core/models/account/timezone.model';

@Injectable()
export class TimeZoneDataService extends DefaultDataService<ITimezone> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(TIMEZONE_ENTITY, http, httpUrlGenerator);
	}

	public getAll(): Observable<ITimezone[]> {
		return this.appCoreServices.brandManagerApiService.getTimeZones();
	}
}
