import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IFacebookCarouselCardMetaDataVM } from 'src/app/presentation/view-models';
import { CreativeContentComponent } from '../../../../../../../elements/creative-content/creative-content.component';
import { FbHeadlineComponent } from '../../../../../placement-elements/headline/fb-headline.component';
import { FbDescriptionComponent } from '../../../../../placement-elements/description/fb-description.component';
import { FbCtaButtonComponent } from '../../../../../placement-elements/cta/cta-button/fb-cta-button.component';

@Component({
	selector: 'fb-in-stream-videos-carousel-card',
	templateUrl: './carousel-card.component.html',
	styleUrls: ['./carousel-card.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		CreativeContentComponent,
		FbHeadlineComponent,
		FbDescriptionComponent,
		FbCtaButtonComponent,
	],
})
export class FacebookInStreamVideosCarouselCardComponent {
	@Input() public card: IFacebookCarouselCardMetaDataVM;
}
