export interface IAdPreviewMetaData {
	inPreview: boolean;
	targetUrl: string;
}

import { Directive, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Directive({
	selector: '[in-ad-preview]',
	host: {
		'(mouseenter)': 'mouseEnter()',
		'(mouseleave)': 'mouseLeave()',
		'[class.item-hoverable]': 'adPreviewMetaData.inPreview',
	},
	standalone: true,
})
export class AdPreviewDirective implements OnInit {
	@Input('in-ad-preview') public adPreviewMetaData?: IAdPreviewMetaData;
	@Output('in-ad-preview-enter') public adPreviewEnter =
		new EventEmitter<void>();
	@Output('in-ad-preview-leave') public adPreviewLeave =
		new EventEmitter<void>();

	public targetUrl?: string;

	public ngOnInit(): void {
		this.targetUrl = this.adPreviewMetaData?.targetUrl;
	}

	public mouseEnter(): void {
		if (!this.adPreviewMetaData.inPreview || !this.targetUrl) {
			return;
		}
		this.adPreviewEnter.emit();
	}

	public mouseLeave(): void {
		if (!this.adPreviewMetaData.inPreview || !this.targetUrl) {
			return;
		}
		this.adPreviewLeave.emit();
	}
}
