import { ChangeDetectorRef, Component } from '@angular/core';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { FacebookSinglePlacementsComponentStore } from '../../../fb-single-placements.component.store';

import { FacebookPlacementComponentBaseDirective } from '../../../shared/directives/facebook-placement-component-base.directive';
import { FacebookSupportedPlacements } from '../../../shared/enums';
import { FacebookStoriesPlacementCoreComponent } from './core/fb-stories-placement-core.component';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	imports: [CommonModule, FacebookStoriesPlacementCoreComponent],
	selector: 'facebook-stories-placement',
	templateUrl: './fb-stories-placement.component.html',
})
export class FacebookStoriesPlacementComponent extends FacebookPlacementComponentBaseDirective {
	constructor(
		public appFeatureServices: AppFeatureServices,
		public changeDetectorRef: ChangeDetectorRef,
		public facebookPlacementsComponentStore: FacebookSinglePlacementsComponentStore,
	) {
		super(
			appFeatureServices,
			FacebookSupportedPlacements.FacebookStories,
			changeDetectorRef,
			facebookPlacementsComponentStore,
		);
	}
}
