import { Injectable } from '@angular/core';
import {
	IPlacementBaseVM,
	IStudioCreativeSetVM,
} from 'src/app/presentation/view-models';
import { DesignMatchingService } from '../design-matching/design-matching.service';

@Injectable({
	providedIn: 'root',
})
export class FilterCreativeService {
	constructor(private designMatchingService: DesignMatchingService) {}

	public filterCreativesBasedOnPlacementRecommendationsAndLocalization(
		creativeSet: IStudioCreativeSetVM,
		placement: IPlacementBaseVM,
		selectedAdLocalizationId: string,
	): IStudioCreativeSetVM {
		return {
			...creativeSet,
			creatives: creativeSet.creatives.filter(
				(creative) =>
					creative?.version?.localization.id ===
						selectedAdLocalizationId &&
					this.designMatchingService.isDesignMatchingPlacementRecommendations(
						creative?.size?.width,
						creative?.size?.height,
						placement,
					),
			),
			inCompatibleCreatives: creativeSet.creatives.filter(
				(creative) =>
					!(
						creative?.version?.localization.id ===
							selectedAdLocalizationId &&
						this.designMatchingService.isDesignMatchingPlacementRecommendations(
							creative?.size?.width,
							creative?.size?.height,
							placement,
						)
					),
			),
		};
	}
}
