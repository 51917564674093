<ng-container>
    <ng-container>
        <div class="footer">
            <div class="line"></div>
            <div class="icons">
                <div class="group-1">
                    <ui-svg-icon icon="heart-outline"></ui-svg-icon>
                    <ui-svg-icon icon="comments"></ui-svg-icon>
                    <ui-svg-icon icon="send"></ui-svg-icon>
                </div>
                <div class="group-2">
                    <ui-svg-icon icon="bookmark-outline"></ui-svg-icon>
                </div>
            </div>

            <div class="interactions">
                <primary-text
                    [primaryTextValue]="primaryTextValue"
                    [placementId]="placementId"></primary-text>
            </div>
        </div>
    </ng-container>
</ng-container>
