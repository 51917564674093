import { Injectable, Injector } from '@angular/core';
import { PublishService, PublishValidationService } from './services';
import { PublishLogsService } from './services/publish-logs/publish-logs.service';

@Injectable()
export class SocialPublishFeatureService {
	public publish: PublishService;
	public publishValidation: PublishValidationService;
	public publishLogs: PublishLogsService;

	constructor(private injector: Injector) {
		this.publish = this.injector.get(PublishService);
		this.publishValidation = this.injector.get(PublishValidationService);
		this.publishLogs = this.injector.get(PublishLogsService);
	}
}
