<ng-container>
    <div class="placement-title" *ngIf="placement$ | async as placement">
        <div [ngSwitch]="placement.id" class="category-icon">
            <ui-svg-icon *ngSwitchCase="placementEnum.TiktokTiktok" icon="tiktok" />
            <ui-svg-icon *ngSwitchCase="placementEnum.TiktokPangle" icon="pangle" />
            <ui-svg-icon
                *ngSwitchCase="placementEnum.TiktokGlobalAppBundle"
                icon="global-app-bundle" />
        </div>
        <div>{{ placement.name }}</div>
    </div>
</ng-container>
