<ng-container [ngSwitch]="validationResult?.type">
    <div class="text">
        <div
            *ngSwitchCase="validationType.error"
            class="error-text"
            [uiTooltip]="validationResult?.message"
            [uiTooltipWidth]="200"
            uiTooltipTrigger="hover"
            uiTooltipPosition="top">
            {{ validationResult?.message }}
        </div>
        <div
            *ngSwitchCase="validationType.warning"
            class="warning-text"
            [uiTooltip]="validationResult?.message"
            [uiTooltipWidth]="200"
            uiTooltipTrigger="hover"
            uiTooltipPosition="top">
            {{ validationResult?.message }}
        </div>
    </div>
</ng-container>
