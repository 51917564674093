import { Injectable } from '@angular/core';
import { TiktokSingleImagePlacementService } from './single-image-placement/tt-single-image-placement.service';
import { TiktokSingleVideoPlacementService } from './single-video-placement/tt-single-video-placement.service';
import {
	IAdContentTemplateVM,
	IAdGroupVM,
	IPlacementBaseVM,
	IPlacementOptionsVM,
	ITiktokAdVM,
	ITiktokPlacementVM,
} from 'src/app/presentation/view-models';
import { TiktokPlacementMappersService } from '../mappers/tt-placement.mappers';
import { Observable, filter, map, of } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { IPlacementOptions } from 'src/app/core/models';
import { AdPlacementTemplateService } from 'src/app/features/ad/services/ad-placement-template/ad-placement-template.service';

@Injectable({
	providedIn: 'root',
})
export class TiktokPlacementService {
	constructor(
		public singleImage: TiktokSingleImagePlacementService,
		public singleVideo: TiktokSingleVideoPlacementService,
		private tiktokPlacementMapper: TiktokPlacementMappersService,
		public adPlacementTemplates: AdPlacementTemplateService,
		public appEntityServices: AppEntityServices,
	) {}

	public loadCombinedContentTemplates(
		placements: ITiktokPlacementVM[],
	): Observable<IAdContentTemplateVM[]> {
		return of(
			this.tiktokPlacementMapper.mapCombinedContentTemplates(placements),
		);
	}

	public getPlacements(): void {
		this.appEntityServices.tiktokEntity.singleImagePlacement.getAll();
	}

	public loadPlacements(): Observable<IPlacementOptionsVM[]> {
		return this.appEntityServices.tiktokEntity.singleVideoPlacement.entities$.pipe(
			filter((placements) => !!placements),
			map((placements: IPlacementOptions[]) =>
				this.tiktokPlacementMapper.mapPlacementsWithRenderingOptions(
					placements,
				),
			),
		);
	}

	public loadPlacementsByAdId(
		adId: string,
	): Observable<ITiktokPlacementVM[]> {
		return this.adPlacementTemplates.loadPlacementsByAdId(adId).pipe(
			filter((placements) => !!placements),
			map((placements: IPlacementBaseVM[]) =>
				this.tiktokPlacementMapper.mapPlacements(placements),
			),
		);
	}

	public getAspectRatioGroupName(aspectGroupKey: string): string {
		if (aspectGroupKey === '9:16') {
			return 'Select creative (9.16) for TikTok, Global app bundle and Pangle';
		} else if (aspectGroupKey === '16:9') {
			return 'Select creative (16:9) for TikTok';
		} else if (aspectGroupKey === '1:1') {
			return 'Select creative (1:1) for TikTok and Pangle';
		} else if (aspectGroupKey === '(1.91:1') {
			return 'Select creative (1.91:1) for Pangle';
		} else {
			return '';
		}
	}

	public getSupportedPlacements(
		adgroup: IAdGroupVM,
		ad: ITiktokAdVM,
	): Observable<ITiktokPlacementVM[]> {
		const adPlacements$ = this.loadPlacementsByAdId(ad.id);

		return adPlacements$.pipe(
			map((placements: [ITiktokPlacementVM]) =>
				placements.filter((placement) =>
					adgroup?.supportedPlacementsIds?.includes(placement.id),
				),
			),
		);
	}
}
