import { Injectable } from '@angular/core';
import { IFacebookCampaign } from 'src/app/core/models';
import { IFacebookCampaignVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class FacebookAccountMappersService {
	public toCampaignViewModel(
		campaign: IFacebookCampaign,
	): IFacebookCampaignVM {
		return {
			id: campaign.id,
			name: campaign.name,
		} as IFacebookCampaignVM;
	}

	public toCampaignsViewModel(
		campaigns: IFacebookCampaign[],
	): IFacebookCampaignVM[] {
		return campaigns.map((campaign) => this.toCampaignViewModel(campaign));
	}
}
