import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, mergeAll, take } from 'rxjs/operators';
import { IUser } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { UserMappersService } from '../../mappers';
import { BannerflowFeaturesEnum, UserBaseService } from './user-base.service';

@Injectable({
	providedIn: 'root',
})
export class UserService extends UserBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public userMapper: UserMappersService,
	) {
		super(appEntityServices, userMapper);
	}

	public hasFeature(
		knownFeature: BannerflowFeaturesEnum | string,
	): Observable<boolean> {
		const user$: Observable<IUser> =
			this.appEntityServices.accountEntity.user.entities$.pipe(
				mergeAll(),
				take(1),
			);

		return user$.pipe(
			filter((user) => !!user),
			map((user: IUser) => user.account.features.includes(knownFeature)),
		);
	}
}
