import { Injectable } from '@angular/core';
import { TextPipe } from '../../../pages/shared/pipes/text.pipe';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { take } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class OpenAdEditorService {
	constructor(
		private appFeatureServices: AppFeatureServices,
		private router: Router,
		private textPipe: TextPipe,
	) {}

	public openAdEditor(
		adId: string,
		adGroupId: string,
		navigationOrigin: string,
		route: ActivatedRoute,
	): void {
		this.appFeatureServices.adFeature.adGroup
			.loadIsAdGroupConnectionValid(adGroupId)
			.pipe(take(1))
			.subscribe((isValid) => {
				if (isValid) {
					this.router.navigate(
						['../adgroup', adGroupId, 'ad', adId, 'editor'],
						{
							relativeTo: route,
							state: { navigationOrigin },
						},
					);
				} else {
					this.showNotification();
				}
			});
	}

	public showNotification(): void {
		this.appFeatureServices.commonFeature.common.dispatchAppNotification({
			message: this.textPipe.transform('Notifications.CANNOT_EDIT_AD'),
			type: 'error',
			width: 34.5,
		});
	}
}
