<div class="card">
    <div class="card-creative-content">
        <ng-container
            *ngIf="(card?.creative?.setId && card?.creative?.id)|| card?.creative?.creativeUrl">
            <creative-content
                [creativeSetId]="card?.creative.setId"
                [creativeId]="card?.creative.id"
                [creativeUrl]="card?.creative?.creativeUrl"
                [selectedMediaType]="card?.mediaType"
                [sound]="card.sound"></creative-content>
        </ng-container>
    </div>

    <div class="card-footer">
        <div class="card-footer-info">
            <fb-headline
                [headLineValue]="card?.headLineValue"
                [width]="183"
                [ngClass]="{'skeleton': !card?.headLineValue}" />
            <fb-description
                [descriptionValue]="card?.descriptionValue"
                [width]="166"
                [color]="'--ui-color-text-second'"
                [ngClass]="{'skeleton': !card?.descriptionValue}" />
        </div>
        <div class="card-footer-cta">
            <fb-cta-button [ctaValue]="card?.callToActionValue" [isPrimary]="true" />
        </div>
    </div>
</div>
