<div class="card">
    <div class="card-creative">
        <ng-container
            *ngIf="(card?.creative?.setId && card?.creative?.id) || card?.creative?.creativeUrl">
            <creative-content
                [creativeSetId]="card?.creative.setId"
                [creativeId]="card?.creative.id"
                [creativeUrl]="card?.creative?.creativeUrl"
                [selectedMediaType]="card?.mediaType"
                [sound]="card.sound" />
        </ng-container>
    </div>

    <div class="card-content">
        <fb-headline
            [headLineValue]="card?.headLineValue"
            [width]="75"
            [ngClass]="{'skeleton': !card?.headLineValue}" />
        <fb-description
            [descriptionValue]="card?.descriptionValue"
            [width]="75"
            [ngClass]="{'skeleton': !card?.descriptionValue}" />
        <div class="card-cta">
            <fb-cta-button [ctaValue]="card?.callToActionValue" />
        </div>
    </div>
</div>
