import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { IB2CreativeSetVM } from 'src/app/presentation/view-models';
import { BaseFeatureService } from '../../../base-feature.service';
import { CreativeSetMappersService } from '../../mappers';

@Injectable({
	providedIn: 'root',
})
export class CreativeSetBaseService extends BaseFeatureService<IB2CreativeSetVM> {
	constructor(
		public appEntityServices: AppEntityServices,
		public creativeSetMapper: CreativeSetMappersService,
	) {
		super();
	}

	public getWithQuery(params: {
		brandId?: string;
		accountSlug?: string;
		brandSlug?: string;
		search?: boolean;
		queryValue?: string;
		folderId?: string;
	}): void {
		this.appEntityServices.accountEntity.creativeSetFolder.clearCache();

		this.appEntityServices.accountEntity.creativeSetFolder.getWithQuery({
			brandId: params['brandId'],
			accountSlug: params['accountSlug'],
			brandSlug: params['brandSlug'],
			search: params['search'],
			queryValue: params['queryValue'],
			folderId: params['folderId'],
		});
	}

	public loadAll(): Observable<IB2CreativeSetVM[]> {
		return this.appEntityServices.accountEntity.creativeSetFolder.entities$.pipe(
			map((creativeSetFolder: IB2CreativeSetVM[]) =>
				creativeSetFolder.map((creativeSet) =>
					this.creativeSetMapper.mapToCreativeSetVM(creativeSet),
				),
			),
		);
	}

	protected getById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected load(): Observable<IB2CreativeSetVM> {
		throw new Error('Method not implemented.');
	}
	protected loadById(_id: string): Observable<IB2CreativeSetVM> {
		throw new Error('Method not implemented.');
	}
	protected loadByIds(_ids: string[]): Observable<IB2CreativeSetVM[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
