import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AD_SCHEDULE_ENTITY } from 'src/app/state/app.state';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { IAdSchedule } from 'src/app/core/models/ad/ad-schedule/ad-schedule';

@Injectable()
export class AdScheduleDataService extends DefaultDataService<IAdSchedule> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(AD_SCHEDULE_ENTITY, http, httpUrlGenerator);
	}

	public getById(adId: string): Observable<IAdSchedule> {
		return this.appCoreServices.socialPublishApiService.adScheduleApi.getSchedule(
			adId,
		);
	}

	public delete(adId: string): Observable<string> {
		return this.appCoreServices.socialPublishApiService.adScheduleApi.delete(
			adId,
		);
	}

	public add(adSchedule: IAdSchedule): Observable<IAdSchedule> {
		return this.appCoreServices.socialPublishApiService.adScheduleApi.putSchedule(
			adSchedule,
		);
	}
}
