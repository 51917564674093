<div class="header-text">
    <ui-svg-icon icon="campaigns-l"></ui-svg-icon>
    <ui-input
        #campaignNameInput
        [discrete]="true"
        [(value)]="campaignName"
        (focusout)="onNameChange()"
        (keyup.esc)="cancelEdit(campaignNameInput)"
        (keyup.enter)="pressEnter(campaignNameInput); onNameChange()">
    </ui-input>
</div>
