import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { IFacebookAdAccount } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { FacebookAccountMappersService } from '../../mappers/facebook-account.mappers';
import { FacebookAdAccountBaseService } from './facebook-ad-account-base.service';

@Injectable({
	providedIn: 'root',
})
export class FacebookAdAccountService extends FacebookAdAccountBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public facebookAccountMappers: FacebookAccountMappersService,
	) {
		super(appEntityServices, facebookAccountMappers);
	}

	public getByIntegrationId(integrationId: string): void {
		this.appEntityServices.facebookEntity.adAccount.getWithQuery({
			integrationId: integrationId,
		});
	}

	public loadAllByIntegrationId(
		integrationId: string,
	): Observable<IFacebookAdAccount[]> {
		const adAccounts$: Observable<IFacebookAdAccount[]> =
			this.appEntityServices.facebookEntity.adAccount.entities$;

		return adAccounts$.pipe(
			filter((accounts) => !!accounts),
			map((accounts) =>
				accounts.filter(
					(account) => account.integrationId === integrationId,
				),
			),
		);
	}

	public isLoadingAdAccounts(): Observable<boolean> {
		return this.appEntityServices.facebookEntity.adAccount.loading$;
	}
}
