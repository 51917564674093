import { Component, Input } from '@angular/core';
import { AdContentIdEnum, FacebookSupportedPlacements } from '../enums';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';

@Component({
	standalone: true,
	imports: [CommonModule, UIModule],
	selector: 'cta-button',
	templateUrl: './cta-button.component.html',
	styleUrls: ['./cta-button.component.scss'],
})
export class CtaButtonComponent {
	public adContentId: typeof AdContentIdEnum = AdContentIdEnum;
	public supportedPlacementId: typeof FacebookSupportedPlacements =
		FacebookSupportedPlacements;
	@Input() public ctaValue: any;
	@Input() public placementId: FacebookSupportedPlacements;
	@Input() public isExpanded?: boolean;
}
