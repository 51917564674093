<ng-container *ngIf="placementMetaData$ | async as metaData">
    <div class="placement">
        <fb-placement-header [hasCloseCross]="true" />

        <div class="placement__slider">
            <carousel-slider
                [cards]="metaData.cards"
                [sliderType]="sliderTypeEnum.arrows"
                (indexChanged)="onSlideChange($event)">
                <ng-template #cardTemplate let-card="card">
                    <creative-content
                        *ngIf="(card?.creative?.id && card?.creative?.setId) || card?.creative?.creativeUrl; else skeleton"
                        [creativeSetId]="card.creative.setId"
                        [creativeId]="card.creative.id"
                        [creativeUrl]="card?.creative?.creativeUrl"
                        [selectedMediaType]="card.mediaType"
                        [sound]="card.sound" />
                    <ng-template #skeleton>
                        <div class="placement__skeleton"></div>
                    </ng-template>
                </ng-template>
            </carousel-slider>
        </div>

        <div class="placement__cta">
            <fb-instagram-feed-cta [ctaValue]="metaData.callToAction" />
        </div>

        <div class="placement__social-bar">
            <fb-instagram-feed-social-bar>
                <carousel-dots
                    [cardsLength]="metaData.cards.length"
                    [currentIndex]="currentSlide" />
            </fb-instagram-feed-social-bar>
        </div>

        <div class="placement__primary-text">
            <fb-primary-text [primaryTextValue]="metaData.primaryTextValue" />
        </div>
    </div>
</ng-container>
