import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { UIButtonGroupOption, UIModule } from '@bannerflow/ui';
import {
	map,
	tap,
	Observable,
	Subject,
	debounceTime,
	distinctUntilChanged,
	Subscription,
} from 'rxjs';
import { IAllowedValue } from 'src/app/core/models';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import {
	AdFormatEnum,
	IAssignedCreativeVM,
	IAdContentValidationVM,
	IFacebookPlacementSingleMetaDataVM,
	MediaTypeEnum,
	TemplateTypeEnum,
	IFacebookAdVM,
	IAdContentTemplateVM,
	CollectionElementVM,
} from 'src/app/presentation/view-models';
import { CustomizePlacementWizardStore } from '../customize-placement.component.store';
import { IAdPlacement } from 'src/app/core/models/ad/ad/ad-placement.model';

import { AdContentIdEnum } from '../../placements/shared/enums';
import { CommonModule } from '@angular/common';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { ValidationResultComponent } from 'src/app/presentation/pages/shared/components/validation-result/validation-result.component';
import {
	CollectionContentComponent,
	FocusedInput,
	InputCount,
} from '../../collection-content/collection-content.component';
import { LetDirective } from '@ngrx/component';

@Component({
	selector: 'customize-placement-content',
	templateUrl: './customize-placement-content.component.html',
	styleUrls: ['./customize-placement-content.component.scss'],
	standalone: true,
	imports: [
		UIModule,
		CommonModule,
		LetDirective,
		TextPipe,
		ValidationResultComponent,
		CollectionContentComponent,
	],
})
export class CustomizePlacementContentComponent implements OnInit, OnDestroy {
	@Input() public placementId: string;
	@Input() public ratioGroup: string;
	@Input() public adFormatId: AdFormatEnum;

	@Output() public onSave: EventEmitter<boolean> = new EventEmitter();
	public placementMetaData$: Observable<IFacebookPlacementSingleMetaDataVM>;
	public selectedCreative$: Observable<IAssignedCreativeVM>;
	public ad$: Observable<IFacebookAdVM>;
	public adContentTemplates$: Observable<IAdContentTemplateVM[]>;
	public propertyValidation$: Subject<any> = new Subject();
	public onTabValidation$: Subject<any> = new Subject();
	public adContentValidation$: Observable<IAdContentValidationVM>;
	public templateType: typeof TemplateTypeEnum = TemplateTypeEnum;
	public focusedInput: FocusedInput = {};
	public adContentId: typeof AdContentIdEnum = AdContentIdEnum;
	public inputCount: InputCount = {};
	private validateSubscription: Subscription;
	public isValid$: Observable<boolean>;
	public isAdDirty$: Observable<boolean>;
	public readonly mediaTypeOptions: UIButtonGroupOption[] = [
		{ id: '1', text: 'image', value: MediaTypeEnum.Image },
		{ id: '2', text: 'video', value: MediaTypeEnum.Video },
	];
	public fallbackMediaType = MediaTypeEnum.Image;

	constructor(
		private appFeatureServices: AppFeatureServices,
		private readonly placementWizardStore: CustomizePlacementWizardStore,
	) {}

	public ngOnInit(): void {
		this.isAdDirty$ = this.placementWizardStore.loadIsDirty();

		this.selectedCreative$ =
			this.placementWizardStore.loadSelectedCreative();

		this.placementMetaData$ = this.placementWizardStore
			.loadCustomizPlacementContent()
			.pipe(
				tap((placementMetaData) => {
					this.intializeInputCount(placementMetaData);
				}),
			);
		this.ad$ = this.placementWizardStore.loadSelectedAd();

		this.adContentTemplates$ = this.ad$.pipe(
			tap((ad) => {
				const placement = ad.content[this.placementId];

				this.appFeatureServices.adFeature.adCustomizedValidation.getAdContentValidation(
					this.placementId,
					placement.adContentTemplates,
					placement.customizedContent,
					this.adFormatId,
					ad.id,
				);
			}),
			map((ad) => {
				if (ad.content[this.placementId].customizedContent) {
					const customizedAdContentTemplates = ad.content[
						this.placementId
					].adContentTemplates
						.filter(
							(adContentTemplate) =>
								adContentTemplate.id !==
									AdContentIdEnum.callToAction &&
								adContentTemplate.id !==
									AdContentIdEnum.description,
						)
						.map((adContentTemplate) => {
							if (
								adContentTemplate.id ===
									AdContentIdEnum.headlines ||
								adContentTemplate.id ===
									AdContentIdEnum.primaryTexts
							) {
								adContentTemplate.value = ad.content[
									this.placementId
								].customizedContent.content[
									adContentTemplate.id
								].map((value: string, index: number) => ({
									value,
									isActive: index === 0,
								}));
							} else {
								adContentTemplate.value =
									ad.content[
										this.placementId
									].customizedContent.content[
										adContentTemplate.id
									];
							}

							return adContentTemplate;
						});

					return customizedAdContentTemplates;
				} else {
					return ad.content[
						this.placementId
					].adContentTemplates.filter(
						(adContentTemplate) =>
							adContentTemplate.id !==
								AdContentIdEnum.callToAction &&
							adContentTemplate.id !==
								AdContentIdEnum.description,
					);
				}
			}),
		);

		this.adContentValidation$ =
			this.appFeatureServices.adFeature.adCustomizedValidation.loadAdContentValidation();
		this.validateContent();
		this.isValid$ =
			this.appFeatureServices.adFeature.adCustomizedValidation.isAdCustomizedValid(
				this.adContentValidation$,
			);
	}

	public isExceeded(adContentTemplate: IAdContentTemplateVM): boolean {
		return (
			this.inputCount[adContentTemplate.id] >
			adContentTemplate?.metadata?.recommendedMaximumLength
		);
	}

	private intializeInputCount(
		placementMetaData: IFacebookPlacementSingleMetaDataVM,
	): void {
		this.inputCount[this.adContentId.primaryText] = placementMetaData
			.primaryTextValue?.length
			? placementMetaData.primaryTextValue.length
			: 0;
		this.inputCount[this.adContentId.headline] = placementMetaData
			.headLineValue?.length
			? placementMetaData.headLineValue.length
			: 0;
	}

	public onMediaTypeChange(
		mediaType: MediaTypeEnum,
		placementMetaData: IFacebookPlacementSingleMetaDataVM,
	): void {
		this.placementWizardStore.updateIsDirtyState(true);
		placementMetaData.selectedMediaType = mediaType;
	}

	public onKeyUpChange(
		objectToUpdate: IAdContentTemplateVM,
		event: Event,
		placementMetaData: IFacebookPlacementSingleMetaDataVM,
		adContentTemplates: IAdContentTemplateVM[],
		ad: IFacebookAdVM,
	): void {
		this.placementWizardStore.updateIsDirtyState(true);

		const value = (event.target as HTMLInputElement | HTMLTextAreaElement)
			?.value;

		objectToUpdate.value = value;
		this.inputCount[objectToUpdate.id] = value?.length ? value.length : 0;
		this.propertyValidation$.next({
			id: objectToUpdate.id,
			placementId: this.placementId,
			value,
			adId: ad.id,
		});

		if (
			objectToUpdate.id === AdContentIdEnum.primaryText &&
			!adContentTemplates.some(
				(adContentTemplate) =>
					adContentTemplate.id === AdContentIdEnum.primaryTexts,
			)
		) {
			placementMetaData.primaryTextValue = value;
		} else if (
			objectToUpdate.id === AdContentIdEnum.headline &&
			!adContentTemplates.some(
				(adContentTemplate) =>
					adContentTemplate.id === AdContentIdEnum.headlines,
			)
		) {
			placementMetaData.headLineValue = value;
		}
	}

	public onSelectionChange(
		adContentTemplateId: string,
		event: IAllowedValue,
		placementMetaData: IFacebookPlacementSingleMetaDataVM,
		ad: IFacebookAdVM,
	): void {
		this.placementWizardStore.updateIsDirtyState(true);
		placementMetaData.callToAction = event;
		this.appFeatureServices.adFeature.adCustomizedValidation.getPlacementPropertyValidation(
			this.placementId,
			this.getValueToValidate(event),
			adContentTemplateId,
			ad.id,
		);
	}

	public onTabChange(
		objectToUpdate: IAdContentTemplateVM,
		event: Event,
		ad: IFacebookAdVM,
	): void {
		const value = (event.target as HTMLInputElement | HTMLTextAreaElement)
			?.value;
		this.onTabValidation$.next({
			id: objectToUpdate.id,
			placementId: this.placementId,
			value,
			adId: ad.id,
		});
	}

	public onCollectionChange(
		templateId: string,
		placementMetaData: IFacebookPlacementSingleMetaDataVM,
		value: CollectionElementVM[],
		ad: IFacebookAdVM,
	): void {
		this.placementWizardStore.updateIsDirtyState(true);

		this.propertyValidation$.next({
			id: templateId,
			placementId: this.placementId,
			value: value.map((item) => item.value),
			adId: ad.id,
		});

		if (templateId === AdContentIdEnum.primaryTexts) {
			placementMetaData.primaryTextValue = value.find(
				(collectionValue) => !!collectionValue.isActive,
			).value;
		} else if (templateId === AdContentIdEnum.headlines) {
			placementMetaData.headLineValue = value.find(
				(collectionValue) => !!collectionValue.isActive,
			).value;
		}
	}

	public save(
		ad: IFacebookAdVM,
		selectedCreative: IAssignedCreativeVM,
		selectedMediaType: MediaTypeEnum,
	): void {
		const adPlacement: IAdPlacement = this.mapMetaDataToAdPlacement(
			ad,
			selectedCreative,
			this.placementId,
			selectedMediaType,
		);
		this.appFeatureServices.adFeature.ad
			.saveAdPlacement(ad.id, adPlacement)
			.subscribe(() =>
				this.appFeatureServices.adFeature.ad.getById(ad.id),
			);

		this.onSave.emit(true);
	}

	public cancel(): void {
		this.onSave.emit(false);
	}

	private mapMetaDataToAdPlacement(
		ad: IFacebookAdVM,
		selectedCreative: IAssignedCreativeVM,
		placementId: string,
		selectedMediaType: MediaTypeEnum,
	): IAdPlacement {
		return {
			placementId: placementId,
			creative: selectedCreative?.setId
				? {
						setId: selectedCreative.setId,
						id: selectedCreative.id,
						renderingOption: selectedMediaType,
					}
				: null,
			content: this.mapCustomizedAdContent(
				ad.content[placementId].adContentTemplates,
			),
			isCustomized: ad.placements.find(
				(placement) => placement.placementId === placementId,
			).isCustomized,
			cards: null,
		};
	}

	private mapCustomizedAdContent(
		adContentTemplates: IAdContentTemplateVM[],
	): Record<string, string> {
		const content: Record<string, string> = {};

		adContentTemplates.forEach((template) => {
			if (
				template.type === TemplateTypeEnum.group &&
				template.subTemplates
			) {
				template.subTemplates.forEach((subTemplate) => {
					this.mapCutstomizedTemplate(subTemplate.template, content);
				});
			} else {
				this.mapCutstomizedTemplate(template, content);
			}
		});

		return content;
	}

	private mapCutstomizedTemplate(
		template: IAdContentTemplateVM,
		content: Record<string, string>,
	): void {
		const key = template?.id;
		if (key) {
			const templateValue = template?.value;
			content[key] = Array.isArray(templateValue)
				? templateValue.map(
						(valueArray: CollectionElementVM) => valueArray.value,
					)
				: templateValue;
		}
	}

	public ngOnDestroy(): void {
		if (this.validateSubscription) {
			this.validateSubscription.unsubscribe();
		}
	}

	private validateContent(): void {
		this.validateSubscription = this.propertyValidation$
			.pipe(debounceTime(300), distinctUntilChanged())
			.subscribe((objectToValidate) => {
				this.focusedInput[objectToValidate.id] = false;
				this.appFeatureServices.adFeature.adCustomizedValidation.getPlacementPropertyValidation(
					objectToValidate.placementId,
					this.getValueToValidate(objectToValidate.value),
					objectToValidate.id,
					objectToValidate.adId,
				);
			});
		this.validateSubscription = this.onTabValidation$.subscribe(
			(objectToValidate) => {
				this.focusedInput[objectToValidate.id] = false;
				this.appFeatureServices.adFeature.adCustomizedValidation.getPlacementPropertyValidation(
					objectToValidate.placementId,
					this.getValueToValidate(objectToValidate.value),
					objectToValidate.id,
					objectToValidate.adId,
				);
			},
		);
	}

	private getValueToValidate(value: any): string {
		if (value?.id) {
			return value.id;
		} else {
			return value;
		}
	}
}
