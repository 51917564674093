import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UIModule } from '@bannerflow/ui';

import { Observable, Subscription } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { CookieService } from './cookie.service';
import { ConfigService } from 'src/app/core/config/config.service';

@Component({
	selector: 'environment-picker',
	templateUrl: 'environment-picker.component.html',
	styleUrls: [],
	standalone: true,
	imports: [CommonModule, UIModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentPickerComponent implements OnInit, OnDestroy {
	public pullRequests$: Observable<string[]>;
	public isOnSandbox: boolean;
	public BRANCH_COOKIE_NAME = 'branch';
	public selectedBranch = 'Main';
	private sub: Subscription;

	constructor(
		private environmentService: EnvironmentService,
		private route: ActivatedRoute,
	) {
		this.isOnSandbox = ConfigService.isSandbox();
	}

	public ngOnInit(): void {
		if (!this.isOnSandbox) {
			return;
		}

		this.sub = this.route.queryParamMap.subscribe((params) => {
			const branchName = params.get(this.BRANCH_COOKIE_NAME);
			const branchNameCookie = CookieService.get(this.BRANCH_COOKIE_NAME);

			if (
				(branchName === null || branchName === 'self') &&
				branchNameCookie === 'self'
			) {
				this.selectedBranch = 'Main';
			} else {
				this.selectedBranch = branchNameCookie ?? branchName;
			}
		});
		this.pullRequests$ = this.environmentService.getPullRequests();
	}

	private setUrl(branchName: string): void {
		const url = new URL(window.location.href);

		url.searchParams.set(this.BRANCH_COOKIE_NAME, branchName);
		window.history.pushState({}, '', url);

		if (CookieService.get(this.BRANCH_COOKIE_NAME) !== branchName) {
			location.reload();
		}
	}

	public selectedChanged(branchName: string): void {
		this.selectedBranch = branchName;
		this.setUrl(this.selectedBranch);
	}

	public ngOnDestroy(): void {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}
}
