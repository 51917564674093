import { IFacebookAdSetPlacements } from '../../../core/models/facebook/fb-ad-set-placement';
import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { FACEBOOK_ADSET_PLACEMENT_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class FacebookAdSetPlacementsEntityService extends EntityCollectionServiceBase<IFacebookAdSetPlacements> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(FACEBOOK_ADSET_PLACEMENT_ENTITY, serviceElementsFactory);
	}
}
