import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { IUser } from 'src/app/core/models';
import { USER_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class UserEntityService extends EntityCollectionServiceBase<IUser> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(USER_ENTITY, serviceElementsFactory);
	}
}
