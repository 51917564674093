<ng-container>
    <div class="placement-card">
        <div class="grid">
            <div class="grid-tile"></div>
            <div class="grid-tile grid-tile-small"></div>
            <div class="grid-tile grid-tile-small"></div>
            <div class="grid-tile grid-tile-medium"></div>
            <div class="grid-tile grid-tile-medium"></div>
            <div class="grid-tile grid-tile-medium"></div>
            <div class="grid-tile">
                <div class="creative-wrapper">
                    <div class="creative-brand">
                        <facebook-placement-header
                            [placementId]="placementId.FacebookInstagramExploreHome"></facebook-placement-header>
                    </div>
                    <div
                        class="creative-content"
                        *ngIf="selectedCustomizedCreative?.id || placementMetaData?.creative?.id || placementMetaData?.creativeUrl">
                        <creative-content
                            [sound]="placementSound$ | async"
                            [creativeUrl]="placementMetaData?.creativeUrl"
                            [isCustomized]="placementMetaData?.isCustomized"
                            [creativeId]="selectedCustomizedCreative?.id || placementMetaData?.creative?.id"
                            [creativeSetId]="selectedCustomizedCreative?.setId || placementMetaData?.creative?.setId"
                            [selectedMediaType]="placementMetaData?.selectedMediaType"></creative-content>
                    </div>
                    <div class="creative-footer">
                        <cta-button
                            [ctaValue]="placementMetaData.callToAction"
                            [placementId]="placementId.FacebookInstagramExploreHome">
                        </cta-button>
                    </div>
                </div>
            </div>
            <div class="grid-tile grid-tile-medium"></div>
            <div class="grid-tile"></div>
            <div class="grid-tile grid-tile-small"></div>
            <div class="grid-tile grid-tile-small"></div>
            <div class="grid-tile grid-tile-medium"></div>
            <div class="grid-tile grid-tile-medium"></div>
        </div>
    </div>
</ng-container>
