import { CommonModule } from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UIModule } from '@bannerflow/ui';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { NavigationService } from 'src/app/presentation/pages/shared/services/navigation.service.ts.service';
import {
	IB2CreativeSetVM,
	IBrandVM,
	IStudioCreativeSetVM,
} from 'src/app/presentation/view-models';
import { CreativeSetPickerService } from './creative-set-picker.service';
import { CreativeSetPickerVM } from './models/creative-set-picker.vm';
import { filter, take, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'creative-set-picker',
	templateUrl: 'creative-set-picker.component.html',
	styleUrls: ['./creative-set-picker.component.scss'],
	standalone: true,
	imports: [CommonModule, UIModule, TextPipe],
})
export class CreativeSetPickerComponent implements OnInit, OnDestroy {
	public creativeSetPickerVM: CreativeSetPickerVM;
	public selectedCreativeSetId: string;
	public brand$: Observable<IBrandVM>;
	public isSelectedCreative = false;
	public unsubscribe$ = new Subject<void>();

	@Output() public onCreativeSetSelected = new EventEmitter<string>();
	@Input() public creativeSet: IStudioCreativeSetVM;

	constructor(
		public creativeSetPickerService: CreativeSetPickerService,
		private appFeatureServices: AppFeatureServices,
		private navigationService: NavigationService,
	) {}

	public ngOnInit(): void {
		this.creativeSetPickerVM = this.creativeSetPickerService.init();
		this.brand$ = this.appFeatureServices.accountFeature.brand.load();

		this.brand$.pipe(takeUntil(this.unsubscribe$)).subscribe((brand) =>
			this.appFeatureServices.accountFeature.creativeSet.getWithQuery({
				brandId: brand.id,
				brandSlug: brand.brandSlug,
				accountSlug: brand.accountSlug,
			}),
		);

		this.creativeSetPickerVM.listItems$
			.pipe(
				filter((items) => !!items?.length),
				take(1),
			)
			.subscribe(() => {
				if (this.creativeSet) {
					this.isSelectedCreative = true;
					this.creativeSetPickerService.findSelectedCreative(
						this.creativeSet.name,
					);
					this.onSelectionChange(this.creativeSet.id);
				}
			});
	}

	public onSearchValueChange(value: string): void {
		this.creativeSetPickerService.onSearchValueChange(value);
	}

	public onSelectionChange(selectedCreativeSetId: string): void {
		this.selectedCreativeSetId = selectedCreativeSetId;
		this.onCreativeSetSelected.emit(selectedCreativeSetId);
	}

	public onCurrentNodeChange(node: IB2CreativeSetVM): Observable<void> {
		return this.creativeSetPickerService.onCurrentNodeChange(node);
	}

	public onToggleSearch(expand: boolean): void {
		this.isSelectedCreative = false;
		this.creativeSetPickerService.toggleSearch(expand);
	}

	public onBackInList(): void {
		this.creativeSetPickerService.goBackInList();
	}

	public noListPredicate(): boolean {
		return false;
	}

	public navigateToCreativeSet(brandId: string, creativeSetId: string): void {
		this.navigationService.navigateToCreativeSet(brandId, creativeSetId);
	}

	public ngOnDestroy(): void {
		this.creativeSetPickerService.destroySubscriptions();

		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
