<ng-container *ngIf="placementMetaData$ | async as placementMetaData">
    <div class="placement">
        <div class="skeleton">
            <div class="skeleton-brand">
                <div class="skeleton-brand-logo"></div>
                <div class="skeleton-brand-content">
                    <div class="skeleton-brand-content-text">
                        <div class="skeleton-brand-content-text-name"></div>
                        <div class="skeleton-brand-content-text-sponsor"></div>
                    </div>
                    <div class="skeleton-brand-content-icons">
                        <fb-menu-dots-icon></fb-menu-dots-icon>
                    </div>
                </div>
            </div>
            <div class="skeleton-video-placeholder"></div>
        </div>
        <div class="placement-content">
            <fb-placement-header [hasCloseCross]="true" />
            <carousel-slider
                [cards]="placementMetaData.cards"
                [sliderType]="sliderTypeEnum.clickable"
                [initialOffset]="10"
                [cardWidth]="205">
                <ng-template let-card="card">
                    <fb-in-stream-videos-carousel-card [card]="card" />
                </ng-template>
            </carousel-slider>
        </div>
        <div class="placement-footer">
            <div class="placement-footer-skeleton"></div>
            <facebook-placement-footer />
        </div>
    </div>
</ng-container>
