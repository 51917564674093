import { Injectable } from '@angular/core';
import { combineLatest, filter, map, Observable } from 'rxjs';
import { IPublishLogEntry } from 'src/app/core/models/publish/publish-log-entry';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import {
	IFacebookAdFormatVM,
	IPublishLogItemVM,
	ITiktokAdFormatVM,
	NetworkEnum,
} from 'src/app/presentation/view-models';
import { PublishLogsBaseService } from './publish-logs-base.service';
import { FacebookFeatureService } from 'src/app/features/facebook/facebook-feature.service';
import { TiktokFeatureService } from 'src/app/features/tiktok/tt-feature.service';
import { FacebookPublishLogMappersService } from '../../../facebook/publish/mappers/fb-publish-log.mapper';
import { TiktokPublishLogMappersService } from '../../../tiktok/publish/mappers/tt-publish-log.mapper';
import { PublishLogMappersService } from '../../mappers/publish-log.mapper';

@Injectable({
	providedIn: 'root',
})
export class PublishLogsService extends PublishLogsBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public facebookFeature: FacebookFeatureService,
		public tiktokFeature: TiktokFeatureService,
		public facebookPublishLogMapper: FacebookPublishLogMappersService,
		public tiktokPublishLogMapper: TiktokPublishLogMappersService,
		public publishLogMapper: PublishLogMappersService,
	) {
		super(appEntityServices);
	}

	public loadPublishLogsByAdId(
		adId: string,
	): Observable<IPublishLogItemVM[]> {
		const publishLogs$: Observable<IPublishLogEntry[]> =
			this.appEntityServices.publishEntity.publishLog.entities$.pipe(
				filter((publishLogs) => !!publishLogs.length),
				map((publishLogs) =>
					publishLogs.filter((logs) => logs.adId === adId),
				),
				map((publishLogs) =>
					publishLogs.sort(
						(log, logNext) =>
							new Date(logNext.publishingStarted).getTime() -
							new Date(log.publishingStarted).getTime(),
					),
				),
			);

		const tiktokAdFormats$ = this.tiktokFeature.adformat.loadAll();
		const facebookAdFormats$ = this.facebookFeature.adformat.loadAll();

		return combineLatest({
			publishLogs: publishLogs$,
			tiktokAdFormats: tiktokAdFormats$,
			facebookAdFormats: facebookAdFormats$,
		}).pipe(
			map(({ publishLogs, tiktokAdFormats, facebookAdFormats }) =>
				publishLogs.map((publishLog) => {
					const networkId = this.getNetworkIdByAdFormatId(
						publishLog?.ad?.format,
						tiktokAdFormats,
						facebookAdFormats,
					);

					switch (networkId) {
						case NetworkEnum.facebook:
							return this.facebookPublishLogMapper.toPublishLogItemViewModel(
								publishLog,
							);
						case NetworkEnum.tiktok:
							return this.tiktokPublishLogMapper.toPublishLogItemViewModel(
								publishLog,
							);
						default:
							return this.publishLogMapper.toPublishLogItemViewModelWithEmptyAd(
								publishLog,
							);
					}
				}),
			),
		);
	}

	private getNetworkIdByAdFormatId(
		adFormatId: string,
		tiktokAdFormats: ITiktokAdFormatVM[],
		facebookAdFormats: IFacebookAdFormatVM[],
	): string {
		const isTiktok = tiktokAdFormats.find(
			(format) => format.id === adFormatId,
		);
		const isFacebook = facebookAdFormats.find(
			(format) => format.id === adFormatId,
		);

		if (isTiktok) {
			return NetworkEnum.tiktok;
		} else if (isFacebook) {
			return NetworkEnum.facebook;
		}
	}
}
