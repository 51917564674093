import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { BaseFeatureService } from '../../../base-feature.service';
import { AdScheduleMappersService } from '../../mappers/ad-schedule/ad-schedule.mapper';
import { IAdScheduleVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class AdScheduleBaseService extends BaseFeatureService<IAdScheduleVM> {
	constructor(
		public appEntityServices: AppEntityServices,
		public adScheduleMapper: AdScheduleMappersService,
	) {
		super();
	}

	public getById(adId: string): void {
		this.appEntityServices.adEntity.adSchedule.getByKey(adId);
	}

	public getByIds(adIds: string[]): void {
		adIds.forEach((adId) => this.getById(adId));
	}

	public loadById(adId: string): Observable<IAdScheduleVM> {
		const adSchedules$ =
			this.appEntityServices.adEntity.adSchedule.entities$;

		return adSchedules$.pipe(
			map((adSchedules) =>
				adSchedules?.find((adSchedule) => adSchedule.id === adId),
			),
			map((adSchedule) =>
				this.adScheduleMapper.mapAdScheduleToVM(adSchedule),
			),
		);
	}

	public loadByIds(adIds: string[]): Observable<IAdScheduleVM[]> {
		const adIds$: Observable<string[]> = of(adIds);

		if (adIds.length === 0) {
			return of([]);
		}

		return adIds$.pipe(
			switchMap((ids) =>
				combineLatest(ids.map((adId) => this.loadById(adId))),
			),
		);
	}

	public deleteById(adId: string): Observable<string | number> {
		return this.appEntityServices.adEntity.adSchedule.delete(adId);
	}

	protected getWithQuery(): void {
		throw new Error('Method not implemented.');
	}
	protected load(): Observable<IAdScheduleVM> {
		throw new Error('Method not implemented.');
	}
	protected loadAll(): Observable<IAdScheduleVM[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_adIds: string[]): void {
		throw new Error('Method not implemented.');
	}
}
