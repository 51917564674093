import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UIModule } from '@bannerflow/ui';

@Component({
	selector: 'tt-cta',
	templateUrl: './tt-cta.component.html',
	styleUrls: ['./tt-cta.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, UIModule],
})
export class TiktokCtaComponent {
	@Input({ required: true }) public ctaValue: string;
	@Input() public showArrow: boolean;
}
