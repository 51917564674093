import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
	IPublishLogItemVM,
	NetworkEnum,
} from 'src/app/presentation/view-models';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { FacebookAdPlacementsLogComponent } from '../../../integrations/facebook/ad/ad-placements-log/fb-ad-placements-log.component';
import { TiktokAdPlacementsLogComponent } from '../../../integrations/tiktok/ad/ad-placements-log/tt-ad-placements-log.component';

@Component({
	selector: 'log-body',
	templateUrl: './log-body.component.html',
	styleUrls: ['./log-body.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		TextPipe,
		FacebookAdPlacementsLogComponent,
		TiktokAdPlacementsLogComponent,
	],
})
export class LogBodyComponent {
	@Input() public publishLogItem: IPublishLogItemVM;
	@Input() public isExpanded: boolean;

	public networkEnum: typeof NetworkEnum = NetworkEnum;
}
