import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UIModule } from '@bannerflow/ui';

@Component({
	selector: 'fb-instagram-feed-cta',
	templateUrl: './instagram-feed-cta.component.html',
	styleUrls: ['./instagram-feed-cta.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, UIModule],
})
export class FbInstagramFeedCtaComponent {
	@Input({ required: true }) public ctaValue: string;
}
