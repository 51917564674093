import { ChangeDetectorRef, Component } from '@angular/core';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { FacebookSinglePlacementsComponentStore } from '../../fb-single-placements.component.store';
import { FacebookPlacementComponentBaseDirective } from '../../shared/directives/facebook-placement-component-base.directive';
import { FacebookSupportedPlacements } from '../../shared/enums';
import { FacebookVideoFeedPlacementCoreComponent } from './core/fb-video-feed-placement-core.component';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	imports: [CommonModule, FacebookVideoFeedPlacementCoreComponent],
	selector: 'facebook-video-feed-placement',
	templateUrl: './fb-video-feed-placement.component.html',
})
export class FacebookVideoFeedPlacementComponent extends FacebookPlacementComponentBaseDirective {
	constructor(
		public appFeatureServices: AppFeatureServices,
		public changeDetectorRef: ChangeDetectorRef,
		public facebookPlacementsComponentStore: FacebookSinglePlacementsComponentStore,
	) {
		super(
			appFeatureServices,
			FacebookSupportedPlacements.FacebookVideoFeed,
			changeDetectorRef,
			facebookPlacementsComponentStore,
		);
	}
}
