import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	CanLoad,
	Route,
	RouterStateSnapshot,
	UrlSegment,
} from '@angular/router';
import { AuthGuard as Auth0Guard, AuthService } from '@auth0/auth0-angular';
import { filter, Observable, switchMap, take } from 'rxjs';
import { ConfigService } from 'src/app/core/config/config.service';

@Injectable({
	providedIn: 'root',
})
export class AppAuthGuard implements CanActivate, CanLoad, CanActivateChild {
	constructor(
		private auth0Guard: Auth0Guard,
		private auth: AuthService,
	) {}

	public canLoad(
		route: Route,
		segments: UrlSegment[],
	): Observable<boolean> | boolean {
		if (this.isCypress()) {
			return true;
		}
		return this.auth0Guard.canLoad(route, segments);
	}

	public canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean> | boolean {
		if (this.isCypress()) {
			return true;
		}
		return this.isAuthenticatedAuth0(() =>
			this.auth0Guard.canActivate(route, state),
		);
	}

	public canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean> | boolean {
		if (this.isCypress()) {
			return true;
		}
		return this.isAuthenticatedAuth0(() =>
			this.auth0Guard.canActivateChild(childRoute, state),
		);
	}

	private isCypress(): boolean {
		return ConfigService.isCypress();
	}

	private isAuthenticatedAuth0(
		fn: () => Observable<boolean>,
	): Observable<boolean> {
		return this.auth.isAuthenticated$.pipe(
			filter((isAuthenticated) => isAuthenticated !== null),
			take(1),
			switchMap(fn),
		);
	}
}
