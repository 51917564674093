import { Injectable } from '@angular/core';
import { combineLatest, filter, map, Observable, of, switchMap } from 'rxjs';
import { IConnection } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { BaseFeatureService } from '../../../base-feature.service';

export enum KnownUserFeature {
	SocialCampaignManager = 'SocialCampaignManager',
}

@Injectable({
	providedIn: 'root',
})
export class AdGroupConnectionBaseService extends BaseFeatureService<IConnection> {
	constructor(public appEntityServices: AppEntityServices) {
		super();
	}

	public getById(adGroupId: string): void {
		this.appEntityServices.adEntity.adGroupConnection.getByKey(adGroupId);
	}

	public loadById(adGroupId: string): Observable<IConnection> {
		return this.appEntityServices.adEntity.adGroupConnection.entities$.pipe(
			filter((connections) => !!connections),
			map((connections) =>
				connections.find((connection) => connection.id === adGroupId),
			),
			map((connection) => connection),
		);
	}

	public loadByIds(adGroupIds: string[]): Observable<IConnection[]> {
		const adGroupIds$: Observable<string[]> = of(adGroupIds);

		if (adGroupIds.length === 0) {
			return of([]);
		}

		return adGroupIds$.pipe(
			switchMap((ids) =>
				combineLatest(
					ids.map((adgroupIds) => this.loadById(adgroupIds)),
				),
			),
		);
	}

	public loadAll(): Observable<IConnection[]> {
		return this.appEntityServices.adEntity.adGroupConnection.entities$.pipe(
			filter((connections) => !!connections),
			map((connections) => connections),
		);
	}

	public deleteById(adGroupId: string): Observable<string | number> {
		return this.appEntityServices.adEntity.adGroupConnection.delete(
			adGroupId,
		);
	}

	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected getWithQuery(_params: any): void {
		throw new Error('Method not implemented.');
	}
	protected load(): Observable<IConnection> {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
