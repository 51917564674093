import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import {
	IAdLogVM,
	IPublishLogItemVM,
	PublishLogDefaultFilterEnum,
} from '../../view-models';

export interface IPublishLogsState {
	publishLogs: IPublishLogItemVM[];
	publishLogAds: IAdLogVM[];
	selectedPublishLogAd: IAdLogVM;
	publishLogStatuses: string[];
	selectedPublishLogStatus: string;
	publishLogUsers: string[];
	selectedPublishLogUser: string;
}

@Injectable()
export class PublishLogsStore extends ComponentStore<IPublishLogsState> {
	constructor(
		private activatedRoute: ActivatedRoute,
		private appFeatureServices: AppFeatureServices,
	) {
		super({
			publishLogs: [],
			publishLogStatuses: [PublishLogDefaultFilterEnum.AllStatuses],
			selectedPublishLogStatus: PublishLogDefaultFilterEnum.AllStatuses,
			publishLogUsers: [PublishLogDefaultFilterEnum.AllUsers],
			selectedPublishLogUser: PublishLogDefaultFilterEnum.AllUsers,
			publishLogAds: [],
			selectedPublishLogAd: undefined,
		});

		this.initialize();
	}

	public initialize(): void {
		const adId: string = this.activatedRoute.snapshot.paramMap.get('adId');
		const campaignId: string =
			this.activatedRoute.parent.parent.snapshot.paramMap.get(
				'campaignId',
			);

		this.appFeatureServices.adFeature.ad.getAdsLogByCampaignId(campaignId);
		this.appFeatureServices.publishFeature.publishLogs.getWithQuery(adId);

		this.setPublishLogs(adId);
		this.setPublishLogAds(adId);
	}

	public loadPublishLogs(): Observable<IPublishLogItemVM[]> {
		return this.select((state) => this.filterPublishLogs(state));
	}

	public loadPublishLogAds(): Observable<IAdLogVM[]> {
		return this.select((state) => state.publishLogAds);
	}

	public loadSelectedPublishLogAd(): Observable<IAdLogVM> {
		return this.select((state) => state.selectedPublishLogAd);
	}

	public loadSelectedPublishLogStatus(): Observable<string> {
		return this.select((state) => state.selectedPublishLogStatus);
	}

	public loadSelectedPublishLogUser(): Observable<string> {
		return this.select((state) => state.selectedPublishLogUser);
	}

	public loadPublishLogStatuses(): Observable<string[]> {
		return this.select((state) => [
			PublishLogDefaultFilterEnum.AllStatuses,
			...new Set(state.publishLogs.map((log) => log.status)),
		]);
	}

	public loadPublishLogUsers(): Observable<string[]> {
		return this.select((state) => [
			PublishLogDefaultFilterEnum.AllUsers,
			...new Set(state.publishLogs.map((log) => log.publishedBy)),
		]);
	}

	public readonly setPublishLogs = this.effect((adId$: Observable<string>) =>
		adId$.pipe(
			switchMap((adId) =>
				this.appFeatureServices.publishFeature.publishLogs.loadPublishLogsByAdId(
					adId,
				),
			),
			tap((publishLogs: IPublishLogItemVM[]) => {
				this.updatePublishLogs(publishLogs);
			}),
		),
	);

	public readonly setPublishLogAds = this.effect(
		(initialAdId$: Observable<string>) =>
			initialAdId$.pipe(
				switchMap((initialAdId) =>
					this.appFeatureServices.adFeature.ad.loadAdsLog().pipe(
						tap((publishLogAds: IAdLogVM[]) => {
							this.updatePublishLogAds(publishLogAds);
							this.setSelectedPublishLogAd(
								publishLogAds.find(
									(ad) => ad.id === initialAdId,
								),
							);
						}),
					),
				),
			),
	);

	public readonly setSelectedPublishLogAd = this.effect(
		(publishLogAd$: Observable<IAdLogVM>) =>
			publishLogAd$.pipe(
				tap((selectedPublishLogAd: IAdLogVM) => {
					this.updateSelectedPublishLogAd(selectedPublishLogAd);
				}),
			),
	);

	public readonly setSelectedPublishLogStatus = this.effect(
		(publishLogStatus$: Observable<string>) =>
			publishLogStatus$.pipe(
				tap((selectedPublishLogStatus: string) => {
					this.updateSelectedPublishLogStatus(
						selectedPublishLogStatus,
					);
				}),
			),
	);

	public readonly setSelectedPublishLogUser = this.effect(
		(publishLogUser$: Observable<string>) =>
			publishLogUser$.pipe(
				tap((selectedPublishLogUser: string) => {
					this.updateSelectedPublishLogUser(selectedPublishLogUser);
				}),
			),
	);

	public readonly updatePublishLogs = this.updater(
		(state: IPublishLogsState, publishLogs: IPublishLogItemVM[]) => ({
			...state,
			publishLogs,
		}),
	);

	public readonly updatePublishLogAds = this.updater(
		(state: IPublishLogsState, publishLogAds: IAdLogVM[]) => ({
			...state,
			publishLogAds,
		}),
	);

	public readonly updateSelectedPublishLogAd = this.updater(
		(state: IPublishLogsState, selectedPublishLogAd: IAdLogVM) => ({
			...state,
			selectedPublishLogAd,
		}),
	);

	public readonly updateSelectedPublishLogStatus = this.updater(
		(state: IPublishLogsState, selectedPublishLogStatus: string) => ({
			...state,
			selectedPublishLogStatus,
		}),
	);

	public readonly updateSelectedPublishLogUser = this.updater(
		(state: IPublishLogsState, selectedPublishLogUser: string) => ({
			...state,
			selectedPublishLogUser,
		}),
	);

	private filterPublishLogs(state: IPublishLogsState): IPublishLogItemVM[] {
		return state.publishLogs
			.filter(
				(log) =>
					state.selectedPublishLogStatus ===
						PublishLogDefaultFilterEnum.AllStatuses ||
					log.status === state.selectedPublishLogStatus,
			)
			.filter(
				(log) =>
					state.selectedPublishLogUser ===
						PublishLogDefaultFilterEnum.AllUsers ||
					log.publishedBy === state.selectedPublishLogUser,
			);
	}
}
