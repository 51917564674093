<ng-container *ngIf="placementMetaData$ | async as placementMetaData">
    <div class="placement">
        <div class="placement-content">
            <div class="side-content">
                <div class="side-content-holder">
                    <div class="svg-holder">
                        <ui-svg-icon class="ui-icon" icon="tiktok_heart" />
                        <span>123k</span>
                    </div>

                    <div class="svg-holder">
                        <tt-message-icon class="ui-icon" />
                        <span>12</span>
                    </div>
                    <div class="svg-holder">
                        <ui-svg-icon icon="tiktok_more" />
                    </div>
                </div>
            </div>

            <div class="placement-footer">
                <div class="ad-badge">AD</div>
                <div class="ad-content">
                    <div class="info-content">
                        <div class="brand-logo">
                            <tt-brand-logo />
                        </div>
                        <div class="text-holder">
                            <div class="brand-name">
                                <tt-brand-name />
                            </div>
                            <div class="primary-text">
                                <ng-container
                                    *ngIf="placementMetaData.textValue; else primaryTextSkeleton">
                                    {{ placementMetaData.textValue }}
                                </ng-container>
                                <ng-template #primaryTextSkeleton>
                                    <div class="primary-text-skeleton">
                                        <div class="line"></div>
                                        <div class="line"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <div class="cta">
                        <tt-cta
                            [ctaValue]="placementMetaData.callToAction"
                            [showArrow]="false" />
                    </div>
                </div>
            </div>
        </div>
        <div class="placement-creative">
            <creative-content
                *ngIf="placementMetaData.creative?.id || placementMetaData?.creativeUrl "
                [sound]="placementSound$ | async"
                [creativeSetId]="placementMetaData?.creative?.setId"
                [creativeId]="placementMetaData?.creative?.id"
                [creativeUrl]="placementMetaData.creativeUrl"
                [selectedMediaType]="placementMetaData.creative.renderingOption" />
        </div>
    </div>
</ng-container>
