import { Injectable } from '@angular/core';
import {
	IAdContentValidation,
	ICarouselAdContentValidation,
	IValidationResult,
} from 'src/app/core/models';
import { IAdCustomizedValidation } from 'src/app/core/models/ad/ad-validation/ad-customized-validation.model';
import {
	IAdContentTemplateVM,
	IAdContentValidationVM,
	ICarouselAdValidationVM,
	IFacebookCarouselCardVM,
	IValidationResultVM,
} from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class AdValidationMapperService {
	public toAdContentValidationVM(
		adContentsValidation:
			| IAdContentValidation[]
			| IAdCustomizedValidation[],
	): IAdContentValidationVM {
		const adContentValidaitonVM: IAdContentValidationVM = {};

		adContentsValidation.forEach((adContent) => {
			adContentValidaitonVM[adContent.id] =
				adContent.adContentValidation.map((item) =>
					this.toValidationResultVM(item),
				);
		});

		return adContentValidaitonVM;
	}

	public toCarouselAdContentValidationVM(
		adsValidation: ICarouselAdContentValidation[],
	): ICarouselAdValidationVM {
		const adContentValidaitonVM: ICarouselAdValidationVM = {};

		adsValidation.forEach((adValidation) => {
			adContentValidaitonVM[adValidation.id] = {};

			adValidation.adContentValidation.forEach((adContent) => {
				adContentValidaitonVM[adValidation.id][adContent.id] =
					adContent.adContentValidation.map((validation) =>
						this.toValidationResultVM(validation),
					);
			});
		});

		return adContentValidaitonVM;
	}

	public toValidationResultVM(
		validationResult: IValidationResult,
	): IValidationResultVM {
		const validationResultVM: IValidationResultVM = {
			type: validationResult.type,
			message: validationResult.message,
			card: validationResult.card,
			index: validationResult.index,
		};

		return validationResultVM;
	}

	public mapAdContentToValidateAllProperties(
		adContentTemplates: IAdContentTemplateVM[],
	): string {
		return JSON.stringify(this.mapContentTemplates(adContentTemplates));
	}

	public mapCarouselCardsToValidateAllProperties(
		cards: IFacebookCarouselCardVM[],
	): string {
		const cardsContents = cards.map(({ cardContentTemplates }) =>
			this.mapContentTemplates(cardContentTemplates),
		);

		return JSON.stringify(cardsContents);
	}

	private mapContentTemplates(templates: IAdContentTemplateVM[]): any {
		const content = {};

		templates.forEach((template) => {
			const value = Array.isArray(template.value)
				? template.value.map((item) => item.value)
				: template.value?.id ||
					template.value ||
					template.metadata?.defaultValue ||
					'';

			content[template.id] = value;
		});

		return content;
	}
}
