import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IConnection } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { AdGroupConnectionBaseService } from './ad-group-connection-base.service';

@Injectable({
	providedIn: 'root',
})
export class AdGroupConnectionService extends AdGroupConnectionBaseService {
	constructor(public appEntityServices: AppEntityServices) {
		super(appEntityServices);
	}

	public connectAdGroup(connection: IConnection): Observable<IConnection> {
		return this.appEntityServices.adEntity.adGroupConnection.add(
			connection,
		);
	}
}
