<ng-container *ngIf="(placementMetaData$ | async) as metaData">
    <div class="placement">
        <div class="placement-container">
            <fb-stories-header
                [currentIndex]="currentSlide"
                [cardsLength]="metaData.cards.length" />
            <div class="placement-primary-text">
                <fb-primary-text [primaryTextValue]="metaData.primaryTextValue" />
            </div>
            <fb-stories-footer [ctaValue]="metaData.callToAction" [hasSendIcon]="false" />
        </div>
        <div class="placement-creative">
            <carousel-slider
                [cards]="metaData.cards"
                (indexChanged)="onSlideChange($event)">
                <ng-template let-card="card">
                    <creative-content
                        *ngIf="(card?.creative?.id && card?.creative?.setId) || card?.creative?.creativeUrl"
                        [creativeId]="card.creative.id"
                        [creativeSetId]="card.creative.setId"
                        [creativeUrl]="card?.creative?.creativeUrl"
                        [selectedMediaType]="card.mediaType"
                        [sound]="card.sound" />
                </ng-template>
            </carousel-slider>
        </div>
    </div>
</ng-container>
