import {
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CampaignDataService } from './campaign/campaign-data.service';
import { CampaignEntityService } from './campaign/campaign-entity.service';
import { EntityDataService } from '@ngrx/data';
import { CAMPAIGN_ENTITY } from 'src/app/state/app.state';

@NgModule({
	declarations: [],
	imports: [],
	providers: [
		CampaignDataService,
		CampaignEntityService,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class EntityCampaignModule {
	constructor(
		entityDataService: EntityDataService,
		campaignDataService: CampaignDataService,
	) {
		entityDataService.registerService(CAMPAIGN_ENTITY, campaignDataService);
	}
}
