<ng-container>
    <div class="card">
        <div class="card-header">
            <ui-svg-icon class="arrow-icon" icon="arrow-left"></ui-svg-icon>
            <div class="header-content">
                <div class="create">
                    <ui-svg-icon class="create-icon" icon="camera"></ui-svg-icon>
                    <p>Create</p>
                </div>
                <div class="profile-elipse"></div>
            </div>
        </div>
        <div class="footer">
            <div class="account-info">
                <div class="info-container">
                    <div class="profile-elipse"></div>

                    <div class="text-info">
                        <div class="first-line"></div>
                        <div class="second-line"></div>
                    </div>
                </div>
            </div>
            <div class="text-info">
                <div class="first-line"></div>
                <div class="second-line"></div>
            </div>

            <div class="empty-music-container"></div>

            <ng-container *ngIf="brand$ | async as brand">
                <div class="sponsored-container">
                    <div
                        [ngClass]="{'creative-holder':  !selectedCustomizedCreative?.id || !placementMetaData?.creative?.id || !placementMetaData?.creativeUrl, 'creative-holder-hidden': selectedCustomizedCreative?.id || placementMetaData?.creative?.id || placementMetaData?.creativeUrl}">
                        <ng-container
                            *ngIf="selectedCustomizedCreative?.id || placementMetaData?.creative?.id || placementMetaData?.creativeUrl">
                            <creative-content
                                [sound]="placementSound$ | async"
                                [creativeUrl]="placementMetaData?.creativeUrl"
                                [isCustomized]="placementMetaData?.isCustomized"
                                [creativeId]="selectedCustomizedCreative?.id || placementMetaData?.creative?.id"
                                [creativeSetId]="selectedCustomizedCreative?.setId || placementMetaData?.creative?.setId"
                                [selectedMediaType]="placementMetaData?.selectedMediaType"
                                [fitToHeight]="true"
                                [width]="54"
                                [height]="54">
                            </creative-content>
                        </ng-container>
                    </div>
                    <div class="text-holder">
                        <div class="top-row">
                            <description
                                [descriptionValue]="placementMetaData?.descriptionValue"
                                [placementId]="placementId.FacebookAdsOnReels"></description>
                            <ui-svg-icon class="icon-ui-close" icon="close"></ui-svg-icon>
                        </div>
                        <div class="bottom-row">
                            <div class="brand-name">{{ brand?.name }}</div>
                            <div class="divider">•</div>
                            <div class="sponsored">
                                {{"PlacementHeaderComponent.SPONSORED" | text}}
                            </div>
                            <ui-svg-icon class="icon-kebab" icon="kebab"></ui-svg-icon>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <reels-icons [placementId]="placementId.FacebookAdsOnReels"></reels-icons>
    </div>
</ng-container>
