import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IAdFormat } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { IFacebookAdFormatVM } from 'src/app/presentation/view-models';
import { BaseFeatureService } from '../../../base-feature.service';
import { AdFormatsMappersService } from '../mappers/ad-formats.mappers';
export enum KnownUserFeature {
	SocialCampaignManager = 'SocialCampaignManager',
}

@Injectable({
	providedIn: 'root',
})
export class AdFormatsBaseService extends BaseFeatureService<IFacebookAdFormatVM> {
	constructor(
		public appEntityServices: AppEntityServices,
		public adFormatsMappers: AdFormatsMappersService,
	) {
		super();
	}

	public loadAll(): Observable<IFacebookAdFormatVM[]> {
		const adFormats$: Observable<IFacebookAdFormatVM[]> =
			this.appEntityServices.facebookEntity.adFormat.entities$;

		return adFormats$.pipe(
			filter((adFormats) => !!adFormats),
			map((adFormats: IAdFormat[]) =>
				adFormats.map((adFormat) =>
					this.adFormatsMappers.mapAdFormat(adFormat),
				),
			),
		);
	}

	protected getById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected getWithQuery(_params: any): void {
		throw new Error('Method not implemented.');
	}
	protected load(): Observable<IFacebookAdFormatVM> {
		throw new Error('Method not implemented.');
	}
	protected loadById(_id: string): Observable<IFacebookAdFormatVM> {
		throw new Error('Method not implemented.');
	}
	protected loadByIds(_ids: string[]): Observable<IFacebookAdFormatVM[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
