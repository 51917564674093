import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ConfigService } from 'src/app/core/config/config.service';
import { IFacebookCampaign } from 'src/app/core/models';
import { ApiService } from '../../../internal/api.service';

@Injectable({
	providedIn: 'root',
})
export class FacebookCampaignsApiService extends BaseApiService {
	constructor(public http: HttpClient) {
		super(
			http,
			`${ConfigService?.config?.SCS_URL}/api/facebook/integrations/`,
		);
	}

	/**
	 * Gets campaigns
	 * @param integrationId
	 * @param adAccountId
	 */
	public getCampaigns(
		integrationId: string,
		adAccountId: string,
	): Observable<IFacebookCampaign[]> {
		const url = `${this.baseUrl}${integrationId}/ad-accounts/${adAccountId}/campaigns`;

		return this.http.get<IFacebookCampaign[]>(url).pipe(
			map((campaigns) =>
				campaigns.map((campaign) => ({
					...campaign,
					integrationId,
					adAccountId,
				})),
			),
			catchError(ApiService.handleError),
		);
	}
}
