import { IFacebookPage } from '../../../../../core/models/facebook/fb-page.model';
import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { IFacebookPageVM } from 'src/app/presentation/view-models';
import { FacebookAccountMappersService } from '../../mappers/facebook-account.mappers';
import { FacebookAdPageBaseService } from './facebook-ad-page-base.service';

@Injectable({
	providedIn: 'root',
})
export class FacebookAdPageService extends FacebookAdPageBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public facebookAccountMappers: FacebookAccountMappersService,
	) {
		super(appEntityServices, facebookAccountMappers);
	}

	public loadAllByIntegrationId(
		integrationId: string,
	): Observable<IFacebookPageVM[]> {
		const pages$: Observable<IFacebookPage[]> =
			this.appEntityServices.facebookEntity.page.entities$;
		return pages$.pipe(
			filter((pages) => !!pages.length),
			map((pages) =>
				pages.filter((page) => page.integrationId === integrationId),
			),
		);
	}
	public isLoadingPages(): Observable<boolean> {
		return this.appEntityServices.facebookEntity.page.loading$;
	}
}
