import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'find',
	standalone: true,
})
export class FindPipe implements PipeTransform {
	public transform(array: any[], key: string, value: any): any {
		if (!Array.isArray(array) || !key || value === undefined) {
			return undefined;
		}

		return array.find((item) => item[key] === value);
	}
}
