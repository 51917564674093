import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IFacebookPlacementCarouselMetaDataVM } from 'src/app/presentation/view-models';
import { SearchResultsVideoCardComponent } from '../carousel-cards/search-results-video/search-results-video-card.component';
import { CarouselSliderComponent } from '../../carousel-elements/carousel-slider/carousel-slider.component';
import { CarouselDotsComponent } from '../../carousel-elements/carousel-dots/carousel-dots.component';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		CarouselSliderComponent,
		SearchResultsVideoCardComponent,
		CarouselDotsComponent,
	],
	selector: 'fb-carousel-search-results-video',
	templateUrl: './fb-search-results-video.component.html',
	styleUrls: ['./fb-search-results-video.component.scss'],
})
export class FacebookCarouselSearchResultsVideoComponent {
	@Input() public placementMetaData: IFacebookPlacementCarouselMetaDataVM;
	public currentSlide = 0;
	public onSlideChange(index: number): void {
		this.currentSlide = index;
	}
}
