import { AD_CUSTOMIZED_VALIDATION_ENTITY } from '../../../state/app.state';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAdCustomizedValidation } from 'src/app/core/models/ad/ad-validation/ad-customized-validation.model';
import { AppCoreServices } from 'src/app/core/app-core-service';
import {
	AdContentTemplateScopeEnum,
	AdFormatEnum,
} from 'src/app/presentation/view-models';

@Injectable()
export class AdCustomizedValidationDataService extends DefaultDataService<IAdCustomizedValidation> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(AD_CUSTOMIZED_VALIDATION_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery({
		adId,
		placementId,
		value,
		adContentPropertyId,
		adFormatId,
		scope,
	}: {
		adId: string;
		placementId: string;
		value: string;
		adContentPropertyId?: string;
		adFormatId?: AdFormatEnum;
		scope?: AdContentTemplateScopeEnum;
	}): Observable<IAdCustomizedValidation[]> {
		if (!adContentPropertyId) {
			return this.appCoreServices.socialCampaignApiService.adPlacementTemplateApi.validateAdContent(
				adId,
				placementId,
				adFormatId,
				value,
			);
		}

		return this.appCoreServices.socialCampaignApiService.adPlacementTemplateApi
			.validatePlacementTemplate(
				adId,
				placementId,
				adContentPropertyId,
				adFormatId,
				scope,
				value,
			)
			.pipe(map((adCustomizedValidation) => [adCustomizedValidation]));
	}
}
