import { Injectable } from '@angular/core';
import { ITimezone } from 'src/app/core/models/account/timezone.model';
import { ITimezoneVM } from 'src/app/presentation/view-models/account/timezone.vm';
import { TimeZoneService } from '../services/timezone/timezone.service';

@Injectable({
	providedIn: 'root',
})
export class TimeZoneMappersService {
	public mapToTimeZoneVM(timezone: ITimezone): ITimezoneVM {
		const formatedDisplayName = this.removeTimeZoneOffset(
			timezone.displayName,
		);
		const formatedOffsetSummary =
			TimeZoneService.getTimeZoneOffsetDigitSummary(timezone.offset);

		return {
			currentTimeName: timezone.currentTimeName,
			id: timezone.id,
			displayName: formatedDisplayName,
			offset: timezone.offset,
			offsetSummary: `${formatedOffsetSummary}`,
		};
	}

	private removeTimeZoneOffset(displayName: string): string {
		const pattern = /\(UTC[+-]\d{2}:\d{2}\)/;

		return displayName.replace(pattern, '').trim();
	}
}
