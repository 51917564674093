<div class="card">
    <div class="card-creative-content">
        <ng-container
            *ngIf="(card?.creative?.setId && card?.creative?.id)||card?.creative?.creativeUrl">
            <creative-content
                [creativeSetId]="card?.creative.setId"
                [creativeId]="card?.creative.id"
                [creativeUrl]="card?.creative?.creativeUrl"
                [selectedMediaType]="card?.mediaType"
                [sound]="card.sound" />
        </ng-container>
    </div>

    <div class="card-footer">
        <fb-headline
            [headLineValue]="card?.headLineValue"
            [width]="115"
            [fontWeight]="400">
        </fb-headline>
    </div>
</div>
