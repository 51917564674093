<ng-container *ngrxLet="selectedCreative$ as prevSelectedCreative">
    <div *ngIf="(adCreatives$ | async) as adCreatives">
        <div *ngIf="(creative$ | async) || true as creativeThatCurrentlyTakesRatioSlot">
            <div
                *ngIf="(selectedCreativeSet$ | async) as selectedCreativeSet"
                class="container"
                [ngClass]="{'selected': prevSelectedCreative?.id === creativeChoice?.id, 'container-selectable ' : !!creativeChoice?.design}"
                (click)="selectCreative(selectedCreativeSet, prevSelectedCreative)">
                <div class="language">
                    <ui-flag
                        [culture]="creativeChoice?.version?.localization?.cultureCode"
                        size="tiny">
                    </ui-flag>
                    <div>{{creativeChoice.version.name}}</div>
                </div>
                <div class="size">
                    {{creativeChoice.size.width}} x {{creativeChoice.size.height}}
                </div>
                <div
                    class="content"
                    [ngStyle]="{
                        'height': resultAspectRatio + 'px',
                        'background-image': creativeChoice.preloadImageUrl ? 'url(' + creativeChoice.preloadImageUrl + ')' : 'none'
                    }">
                    <creative-deactivation-warning
                        [creativeSetId]="selectedCreativeSet[aspectRatioGroupKey]?.id"
                        [creativeId]="creativeChoice.id" />
                </div>
            </div>
        </div>
    </div>
</ng-container>
