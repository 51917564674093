<ng-container *ngFor="let placementId of placementsIds">
    <div class="item">
        <placement-header [assignedCreative]="placementsMetaData[placementId].creative">
            <ng-container role="title">
                <tt-placement-title
                    [placementId]="placementId"
                    [adFormatId]="placementsMetaData[placementId]?.adFormatId" />
            </ng-container>
            <ng-container role="actions">
                <ng-container
                    *ngIf="!inPreview && placementsMetaData[placementId]?.adFormatId === adFormatEnum.singleVideo">
                    <placement-sound
                        *ngIf="(placementsSound$ | async) as placementsSound"
                        [ngClass]="placementsSound[placementId] === soundEnum.On ? 'action-visible' : 'action-invisible'"
                        [sound]="placementsSound[placementId]"
                        (onSoundChanged)="onSoundChange($event, placementId)" />
                </ng-container>
            </ng-container>
        </placement-header>

        <div class="placement">
            <tt-placements-wrapper
                [placementId]="placementId"
                [in-ad-placement-preview]="{inPreview, adId: placementsMetaData[placementId].adId, adGroupId: placementsMetaData[placementId].adGroupId}"
                [adFormatId]="placementsMetaData[placementId]?.adFormatId" />
        </div>
    </div>
</ng-container>
