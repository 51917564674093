import { ValidationMessage } from 'src/app/core/models/shared/validation-message';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Dictionary } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { SocialCampaignPageStore } from 'src/app/presentation/scm-page.component.store';
import { SocialConfirmDialogService } from '../../../pages/shared/services/social-confirm-dialog.service';
import { PublishAsEnum } from 'src/app/presentation/view-models';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';

@Component({
	selector: 'publish-summary-header',
	templateUrl: './publish-summary-header.component.html',
	styleUrls: ['./publish-summary-header.component.scss'],
	standalone: true,
	imports: [CommonModule, UIModule, TextPipe],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublishSummaryhHeaderComponent implements OnInit {
	public numberOfSelectedAds$: Observable<number>;
	public selectedAds$: Observable<Dictionary<string[]>>;
	public publishAsEnum: typeof PublishAsEnum = PublishAsEnum;

	constructor(
		private socialCampaignPageStore: SocialCampaignPageStore,
		private appFeatureServices: AppFeatureServices,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private socialConfirmDialogService: SocialConfirmDialogService,
	) {}

	public ngOnInit(): void {
		this.numberOfSelectedAds$ =
			this.socialCampaignPageStore.loadNumberOfSelectedAds();
		this.selectedAds$ =
			this.socialCampaignPageStore.loadSelectedAdsWithAdGroup();
	}

	public resetSelectedAds(): void {
		this.socialCampaignPageStore.resetSelectedAds();
	}

	public publish(
		validationMessages: ValidationMessage[],
		publishAs: PublishAsEnum,
	): void {
		if (validationMessages.length) {
			const prompt =
				this.socialConfirmDialogService.pickPromptFor(
					validationMessages,
				);

			this.socialConfirmDialogService.showDialogWithMessages(
				validationMessages.map(
					(validationMessage) => validationMessage.message,
				),
				prompt,
			);
		} else {
			this.socialCampaignPageStore.setSelectedPublishAs(publishAs);
			this.router.navigate(['publish'], {
				relativeTo: this.activatedRoute,
			});
		}
	}

	public isAdsValidForPublish(
		selectedAds: Dictionary<string[]>,
	): Observable<ValidationMessage[]> {
		const allSelectedAdsIds = this.getAllSelectedAdIds(selectedAds);

		return this.appFeatureServices.publishFeature.publishValidation.isSelectedAdsValidForPublish(
			allSelectedAdsIds,
		);
	}

	private getAllSelectedAdIds(selectedAds: Dictionary<string[]>): string[] {
		let allSelectedAdsIds: string[] = [];

		for (const adGroupId in selectedAds) {
			allSelectedAdsIds = [
				...allSelectedAdsIds,
				...selectedAds[adGroupId],
			];
		}

		return allSelectedAdsIds;
	}
}
