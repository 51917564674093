<ng-container *ngIf="placementMetaData$ | async as placementMetaData">
    <div class="container">
        <fb-placement-header />
        <div class="content">
            <carousel-slider
                [cards]="placementMetaData?.cards"
                [cardWidth]="183"
                (indexChanged)="onSlideChange($event)">
                <ng-template let-card="card">
                    <fb-marketplace-card [card]="card" />
                </ng-template>
            </carousel-slider>

            <div class="dots-wrapper">
                <carousel-dots
                    [cardsLength]="placementMetaData.cards.length"
                    [currentIndex]="currentSlide" />
            </div>
        </div>
    </div>
</ng-container>
