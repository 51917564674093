import { Injectable } from '@angular/core';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { AdPlacementTemplateMapperService } from '../../mappers/ad-mapper/ad-placement-template.mapper';
import {
	Observable,
	combineLatest,
	filter,
	forkJoin,
	map,
	of,
	switchMap,
} from 'rxjs';
import {
	IAdContentTemplateVM,
	IAdGroupVM,
	IAdVM,
	IAllowedValueVM,
	IPlacementBaseVM,
} from 'src/app/presentation/view-models';
import { IAdPlacementsTemplates } from 'src/app/core/models/ad/ad/ad-placements-templates.model';
import { IPlacementBaseDictionary } from 'src/app/presentation/view-models/shared/placement-base-dictionary';
import { ASPECT_GROUP_KEY_ANY } from 'src/app/presentation/view-models/shared/aspect-group-key-any';

@Injectable({
	providedIn: 'root',
})
export class AdPlacementTemplateService {
	constructor(
		public appEntityServices: AppEntityServices,
		public adPlacementTemplateMapper: AdPlacementTemplateMapperService,
	) {}

	public getPlacementsByAdId(
		adId: string,
	): Observable<IAdPlacementsTemplates> {
		return this.appEntityServices.adEntity.adPlacementTemplate.getByKey(
			adId,
		);
	}

	public getPlacementsByAdsIds(
		adsIds: string[],
	): Observable<IAdPlacementsTemplates[]> {
		return forkJoin(adsIds.map((adId) => this.getPlacementsByAdId(adId)));
	}

	public isLoadingAdPlacementTemplates(): Observable<boolean> {
		return this.appEntityServices.adEntity.adPlacementTemplate.loading$;
	}

	public areAllAdsPlacementTemplatesLoaded(
		adsIds: string[],
	): Observable<boolean> {
		if (adsIds.length === 0) {
			return of(true);
		}

		const adsPlacementsTemplates$: Observable<IAdPlacementsTemplates[]> =
			this.appEntityServices.adEntity.adPlacementTemplate.entities$;

		return adsPlacementsTemplates$.pipe(
			filter(
				(adsPlacementsTemplates) => !!adsPlacementsTemplates?.length,
			),
			map((adsPlacementsTemplates) =>
				adsPlacementsTemplates.map(({ id }) => id),
			),
			map((ids) => adsIds.every((id) => ids.includes(id))),
		);
	}

	public loadPlacementsByAdId(adId: string): Observable<IPlacementBaseVM[]> {
		const adsPlacementsTemplates$: Observable<IAdPlacementsTemplates[]> =
			this.appEntityServices.adEntity.adPlacementTemplate.entities$;

		return adsPlacementsTemplates$.pipe(
			filter((adsPlacementsTemplates) => !!adsPlacementsTemplates.length),
			map((adsPlacementsTemplates) =>
				adsPlacementsTemplates.filter(
					(adPlacements) => adPlacements.id === adId,
				),
			),
			filter((adPlacements) => !!adPlacements?.length),
			map((adPlacements) =>
				this.adPlacementTemplateMapper.mapPlacements(
					adPlacements[0].placementsTemplates,
				),
			),
		);
	}

	public loadPlacementsByAdsIds(
		adsIds: string[],
	): Observable<IPlacementBaseVM[][]> {
		const adsIds$: Observable<string[]> = of(adsIds);

		if (adsIds.length === 0) {
			return of([]);
		}

		return adsIds$.pipe(
			switchMap((ids) =>
				combineLatest(
					ids.map((adId) => this.loadPlacementsByAdId(adId)),
				),
			),
		);
	}

	public loadCombinedPlacementsByAdsIds(
		adsIds: string[],
	): Observable<IPlacementBaseVM[]> {
		const placementsArrays$ = this.loadPlacementsByAdsIds(adsIds);

		const combinedPlacements$ = placementsArrays$.pipe(
			map((placementsArrays) => {
				const combinedPlacementsDictionary: IPlacementBaseDictionary =
					{};
				const flatPlacements = placementsArrays.flat();

				flatPlacements.forEach((placement) => {
					if (
						combinedPlacementsDictionary.hasOwnProperty(
							placement.id,
						)
					) {
						return;
					}

					const allPlacementsById = flatPlacements.filter(
						({ id }) => id === placement.id,
					);

					combinedPlacementsDictionary[placement.id] =
						this.combinePlacement(allPlacementsById);
				});

				return Object.values(combinedPlacementsDictionary);
			}),
		);

		return combinedPlacements$;
	}

	private combinePlacement(placements: IPlacementBaseVM[]): IPlacementBaseVM {
		if (placements.length === 1) {
			return placements[0];
		}

		const combinedTemplates = [];
		const flatTemplates = placements
			.map(({ adContentTemplates }) => adContentTemplates)
			.flat();

		flatTemplates.forEach((template) => {
			if (combinedTemplates.find(({ id }) => id === template.id)) {
				return;
			}

			const allTemplatesById = flatTemplates.filter(
				({ id }) => id === template.id,
			);
			const firstTemplate = allTemplatesById[0];

			const combinedTemplate = {
				...firstTemplate,
				metadata: {
					...firstTemplate.metadata,
				},
				allowedValues: firstTemplate.allowedValues
					? this.intersectArrays(
							allTemplatesById.map(
								({ allowedValues }) => allowedValues,
							),
						)
					: null,
				subTemplates: allTemplatesById[0].subTemplates
					? this.combineSubTemplates(
							allTemplatesById.map(
								({ subTemplates }) => subTemplates,
							),
						)
					: null,
			};

			combinedTemplates.push(combinedTemplate);
		});

		const renderingOptions =
			placements.find(
				(placement) =>
					placement.renderingOptions.aspectRatioGroup ===
					ASPECT_GROUP_KEY_ANY,
			)?.renderingOptions || placements[0].renderingOptions;

		return {
			...placements[0],
			adContentTemplates: combinedTemplates,
			renderingOptions,
		};
	}

	private combineSubTemplates(subTemplates: any[]): any {
		const flatSubTemplates = subTemplates.flat();
		const combinedSubTemplates = [];

		flatSubTemplates.forEach((subTemplate) => {
			if (
				combinedSubTemplates.find(
					(t) => t.template.id === subTemplate.template.id,
				)
			) {
				return;
			}

			const allSubTemplatesById = flatSubTemplates.filter(
				(t) => t.template.id === subTemplate.template.id,
			);

			const newTemplate = {
				...subTemplate,
				template: {
					...subTemplate.template,
					metadata: {
						...subTemplate.template.metadata,
					},
					allowedValues: subTemplate.template.allowedValues
						? this.intersectArrays(
								allSubTemplatesById.map(
									(t) => t.template.allowedValues,
								),
							)
						: null,
				},
			};

			combinedSubTemplates.push(newTemplate);
		});

		return combinedSubTemplates;
	}

	private intersectArrays(arrays: IAllowedValueVM[][]): IAllowedValueVM[] {
		if (!arrays || arrays.length === 0) {
			return [];
		}

		const arrayOfIdSets = arrays.map(
			(arr) => new Set(arr.map(({ id }) => id)),
		);
		const smallestArray = arrays.reduce(
			(smallest, arr) => (arr.length < smallest.length ? arr : smallest),
			arrays[0],
		);

		const commonObjects = smallestArray.filter((val) =>
			arrayOfIdSets.every((set) => set.has(val.id)),
		);

		return commonObjects;
	}

	public loadCombinedContentTemplatesByAdId(
		adId: string,
	): Observable<IAdContentTemplateVM[]> {
		const adPlacementTemplates$ = this.loadPlacementsByAdId(adId);

		return adPlacementTemplates$.pipe(
			map((placements) =>
				this.adPlacementTemplateMapper.mapCombinedContentTemplates(
					placements,
				),
			),
		);
	}

	public loadCombinedContentTemplatesByAdsIds(
		adsIds: string[],
	): Observable<IAdContentTemplateVM[]> {
		const placements$ = this.loadCombinedPlacementsByAdsIds(adsIds);

		return placements$.pipe(
			map((placements) =>
				this.adPlacementTemplateMapper.mapCombinedContentTemplates(
					placements,
				),
			),
		);
	}

	public getSupportedAdPlacements(
		adGroup: IAdGroupVM,
		ad: IAdVM,
	): Observable<IPlacementBaseVM[]> {
		const adPlacements$ = this.loadPlacementsByAdId(ad.id);

		return adPlacements$.pipe(
			map((placements: [IPlacementBaseVM]) =>
				placements.filter((placement) =>
					adGroup?.supportedPlacementsIds?.includes(placement.id),
				),
			),
		);
	}
}
