import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class CookieService {
	public static get(name: string): string {
		name += '=';
		const decodedCookie = decodeURIComponent(document.cookie);
		const ca = decodedCookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return '';
	}

	public static set(name: string, value: string, days: number = 30): void {
		const d = new Date();
		d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
		const expires = `expires=${d.toUTCString()}`;
		document.cookie = `${name}=${value};${expires};path=/`;
	}
}
