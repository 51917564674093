import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiService {
	public static handleError(err: Error): Observable<never> {
		const errorMessage = `An error occurred: ${err.message}`;
		return throwError(errorMessage);
	}

	public static handleFeatureError(err: Error): Observable<never> {
		const errorMessage = `An error occurred: ${err.message}`;
		return throwError(errorMessage);
	}

	public static hasResource(
		error: HttpErrorResponse,
	): Observable<never | boolean> {
		if (error?.status === 404) {
			return of(false);
		}
	}
}
