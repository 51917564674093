import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { IIntegrationConnectionStatus } from 'src/app/core/models/account/integration/integration-connection-status.model';
import { INTEGRATION_CONNECTION_STATUS_ENTITY } from 'src/app/state/app.state';

@Injectable()
// eslint-disable-next-line max-len
export class IntegrationConnectionStatusEntityService extends EntityCollectionServiceBase<IIntegrationConnectionStatus> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(INTEGRATION_CONNECTION_STATUS_ENTITY, serviceElementsFactory);
	}
}
