import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FacebookCarouselSupportedPlacements } from '../../../../carousel/placements/enums';
import { FacebookCarouselBaseDirective } from '../../directives/fb-carousel-base.directive';
import { FacebookCarouselPlacementsComponentStore } from '../../fb-carousel-placements.component.store';
import { CreativeContentComponent } from '../../../../../../elements/creative-content/creative-content.component';

@Component({
	selector: 'fb-carousel-right-column-placement',
	templateUrl: './fb-right-column.component.html',
	styleUrls: ['./fb-right-column.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, CreativeContentComponent],
})
export class FacebookCarouselRightColumnComponent extends FacebookCarouselBaseDirective {
	constructor(
		carouselPlacementsStore: FacebookCarouselPlacementsComponentStore,
	) {
		super(
			carouselPlacementsStore,
			FacebookCarouselSupportedPlacements.FacebookRightColumn,
		);
	}
}
