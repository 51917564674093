import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { USER_ENTITY } from 'src/app/state/app.state';
import { IUser } from 'src/app/core/models';
import { AppCoreServices } from 'src/app/core/app-core-service';

@Injectable()
export class UserDataService extends DefaultDataService<IUser> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(USER_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: {
		accountSlug: string;
		brandSlug: string;
	}): Observable<IUser[]> {
		return this.appCoreServices.accountAccessApiService
			.getUser(params['accountSlug'], params['brandSlug'])
			.pipe(
				filter((user) => !!user),
				map((user) => [user]),
			);
	}
}
