<div class="footer">
    <div class="footer-line"></div>
    <div
        class="footer-interactions"
        [ngClass]="{'footer-interactions-center': interactions.length===1}">
        <div class="footer-interaction" *ngFor="let interaction of interactions">
            <ui-svg-icon [icon]="interaction.icon"></ui-svg-icon>
            <div class="footer-interaction-label">
                {{"PlacementFooterComponent." + interaction.name | text }}
            </div>
        </div>
    </div>
</div>
