import { Injectable } from '@angular/core';
import { AppEntityServices } from 'src/app/entities/app-entity-service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TiktokPlacementMappersService } from '../../mappers/tt-placement.mappers';
import { IPlacementOptionsVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class TiktokSingleVideoPlacementService {
	constructor(
		private appEntityServices: AppEntityServices,
		private tiktokPlacementMappers: TiktokPlacementMappersService,
	) {}

	public getPlacements(): void {
		this.appEntityServices.tiktokEntity.singleVideoPlacement.getAll();
	}

	public loadPlacements(): Observable<IPlacementOptionsVM[]> {
		return this.appEntityServices.tiktokEntity.singleVideoPlacement.entities$.pipe(
			map((placements) =>
				this.tiktokPlacementMappers.mapPlacementsWithRenderingOptions(
					placements,
				),
			),
		);
	}
}
