import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { iif, Observable, switchMap, tap } from 'rxjs';
import { AppFeatureServices } from '../../../../../features/app-feature.service';

export interface Dictionary<T> {
	[Key: string]: T;
}

export interface ICreativeContentState {
	previewUrls: Dictionary<string>;
}

@Injectable()
export class CreativeContentComponentStore extends ComponentStore<ICreativeContentState> {
	constructor(private appFeatureServices: AppFeatureServices) {
		super({
			previewUrls: {},
		});
	}

	public loadPreviewUrl(
		creativeSetId: string,
		creativeId: string,
	): Observable<string> {
		const storedUrl$: Observable<string | undefined> = this.select(
			(state) => state.previewUrls[creativeId],
		);
		const remoteUrl$: Observable<string> =
			this.appFeatureServices.studioFeature.creativeSetService
				.getPreviewUrl({ setId: creativeSetId, id: creativeId })
				.pipe(
					tap((remoteUrl) => {
						this.updatePreviewUrl({
							creativeId,
							previewUrl: remoteUrl,
						});
					}),
				);

		return storedUrl$.pipe(
			switchMap((storedUrl) =>
				iif(() => !!storedUrl, storedUrl$, remoteUrl$),
			),
		);
	}

	public readonly updatePreviewUrl = this.updater(
		(
			state: ICreativeContentState,
			action: { creativeId: string; previewUrl: string },
		) => ({
			...state,
			previewUrls: {
				...state.previewUrls,
				[action.creativeId]: action.previewUrl,
			},
		}),
	);
}
