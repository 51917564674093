import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MediaTypeEnum } from '../../../../../view-models';

@Component({
	standalone: true,
	imports: [CommonModule],
	selector: 'static-creative-content',
	templateUrl: './static-creative-content.component.html',
	styleUrls: ['./static-creative-content.component.scss'],
})
export class StaticCreativeContentComponent {
	@Input() public selectedMediaType: MediaTypeEnum;
	@Input() public creativeUrl: string;
	@Input() public size: any;
	public mediaType: typeof MediaTypeEnum = MediaTypeEnum;
}
