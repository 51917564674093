<ng-container>
    <div class="card-container">
        <ui-button-group
            class="view-button"
            [options]="viewOptions"
            [value]="selectedButton"
            (valueChange)="onViewChange($event)"
            (click)="$event.stopPropagation()">
        </ui-button-group>
        <div *ngIf="selectedButton === 'ad-view'; else expandedView">
            <div class="inbox-collapsed">
                <div class="placement-card collapsed-card-width">
                    <inbox-collapsed
                        [placementMetaData]="placementMetaData"
                        [selectedCustomizedCreative]="selectedCustomizedCreative"
                        [in-ad-placement-preview]="{inPreview, adId, adGroupId}"></inbox-collapsed>
                </div>
            </div>
        </div>
        <ng-template #expandedView>
            <div class="inbox-expanded">
                <div class="placement-card expanded-card-width">
                    <inbox-expanded
                        [placementMetaData]="placementMetaData"
                        [selectedCustomizedCreative]="selectedCustomizedCreative"
                        [in-ad-placement-preview]="{inPreview, adId, adGroupId}"></inbox-expanded>
                </div>
            </div>
        </ng-template>
    </div>
</ng-container>
