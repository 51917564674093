import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublishLogsComponent } from './publish-logs.component';
import { PublishLogsStore } from './publish-logs.component.store';

const routes: Routes = [
	{
		path: '',
		component: PublishLogsComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes), RouterModule],
	exports: [RouterModule],
	providers: [PublishLogsStore],
})
export class PublishLogsRoutingModule {}
