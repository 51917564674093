<ng-container *ngIf="(language$ | async) as language">
    <div
        class="language"
        [ngClass]="[inActive ? 'language-inactive': '', isChildValue ? 'language-child' : '' ]"
        [uiTooltipDisabled]="!inActive"
        [uiTooltip]="'Shared.PLACEMENT_IS_MISSING' | text"
        uiTooltipTrigger="hover"
        uiTooltipPosition="bottom"
        [uiTooltipHideArrow]="true"
        [uiTooltipDelay]="0">
        <ui-flag
            [culture]="language?.cultureCode"
            size="tiny"
            [ngClass]="{'inactive': inActive}"></ui-flag>
        <div class="language-name">{{language?.name}}</div>
    </div>
</ng-container>
