import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	AdFormatEnum,
	IPublishLogItemVM,
	ITiktokSinglePlacementsMetaDataMap,
} from '../../../../../view-models';
import { TiktokSinglePlacementsComponent } from '../../placement/placements/tt-single-placements.component';
import { TiktokPlacementMetaDataMappersService } from '../../placement/placements/services/tt-placement-meta.mappers';

@Component({
	selector: 'tt-ad-placements-log',
	templateUrl: './tt-ad-placements-log.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, TiktokSinglePlacementsComponent],
})
export class TiktokAdPlacementsLogComponent implements OnInit {
	@Input() public publishLogItem: IPublishLogItemVM | undefined;
	public adFormatEnum: typeof AdFormatEnum = AdFormatEnum;
	public placementsMetaData: ITiktokSinglePlacementsMetaDataMap;

	constructor(
		private tiktokPlacementMetaDataMappersService: TiktokPlacementMetaDataMappersService,
	) {}

	public ngOnInit(): void {
		this.placementsMetaData =
			this.tiktokPlacementMetaDataMappersService.fromAdLogtoPlacementsMetaData(
				this.publishLogItem,
			);
	}
}
