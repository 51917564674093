import { Injectable } from '@angular/core';
import { ICampaign } from 'src/app/core/models';
import { ICampaignVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class CampaignMappersService {
	public mapToCampaignVM(campaign: ICampaign): ICampaignVM {
		return {
			id: campaign.id,
			name: campaign.name,
		};
	}
}
