import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Observable } from 'rxjs';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { IIntegrationConnectionStatus } from 'src/app/core/models/account/integration/integration-connection-status.model';
import { INTEGRATION_CONNECTION_STATUS_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class IntegrationConnectionStatusDataService extends DefaultDataService<IIntegrationConnectionStatus> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(INTEGRATION_CONNECTION_STATUS_ENTITY, http, httpUrlGenerator);
	}

	public getById(
		integrationId: string,
	): Observable<IIntegrationConnectionStatus> {
		return this.appCoreServices.socialAccountApiService.getConnectionStatus(
			integrationId,
		);
	}
}
