import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { ITiktokAdFormat } from 'src/app/core/models/tiktok/tt-ad-format.model';
import { TIKTOK_AD_FORMAT_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class TiktokAdFormatsEntityService extends EntityCollectionServiceBase<ITiktokAdFormat> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(TIKTOK_AD_FORMAT_ENTITY, serviceElementsFactory);
	}
}
