<ng-container *ngIf="brand$ | async as brand">
    <div class="header-container">
        <div class="brand-container">
            <div class="brand-logo">
                <brand-logo [brand]="brand"></brand-logo>
            </div>
            <div class="brand-content">
                <div class="brand-content-text">
                    <div class="brand-content-text-name">{{ brand?.name }}</div>
                    <div
                        class="brand-content-text-primary"
                        *ngIf="primaryTextValue; else skeleton">
                        {{primaryTextValue}}
                    </div>
                    <ng-template #skeleton>
                        <div class="brand-content-text-default"></div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-container>
