import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { SoundEnum } from 'src/app/presentation/view-models/shared/sound.enum';

@Component({
	selector: 'placement-sound',
	styleUrls: ['./placement-sound.component.scss'],
	templateUrl: './placement-sound.component.html',
	standalone: true,
	imports: [CommonModule, UIModule],
})
export class PlacementSoundComponent {
	@Input() public sound: SoundEnum;
	@Output() public onSoundChanged: EventEmitter<SoundEnum> =
		new EventEmitter();

	public soundEnum: typeof SoundEnum = SoundEnum;

	public soundChanged(): void {
		const newSound =
			this.sound === SoundEnum.On ? SoundEnum.Off : SoundEnum.On;

		this.onSoundChanged.emit(newSound);
	}
}
