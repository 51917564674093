import {
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StudioFeatureService } from './studio-feature.service';
@NgModule({
	declarations: [],
	imports: [],
	providers: [
		StudioFeatureService,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class StudioFeatureModule {}
