<ng-container *ngIf="creativeUrl; else liveCreativeContent">
    <static-creative-content
        [creativeUrl]="creativeUrl"
        [selectedMediaType]="selectedMediaType"
        [size]="{width, height, fitToHeight, fitToSize}" />
</ng-container>

<ng-template #liveCreativeContent>
    <live-creative-content
        [sound]="sound"
        [creativeSetId]="creativeSetId"
        [creativeId]="creativeId"
        [selectedMediaType]="selectedMediaType"
        [size]="{width, height, fitToHeight, fitToSize}"
        [isCustomized]="isCustomized" />
</ng-template>
