import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { IFacebookInstagramAccountVM } from 'src/app/presentation/view-models';
import { FacebookAccountMappersService } from '../../mappers/facebook-account.mappers';
import { FacebookInstagramAccountBaseService } from './facebook-instagram-account-base.service';
import { IFacebookInstagramAccount } from 'src/app/core/models/facebook/fb-instagram-account';

@Injectable({
	providedIn: 'root',
})
export class FacebookInstagramAccountService extends FacebookInstagramAccountBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public facebookAccountMappers: FacebookAccountMappersService,
	) {
		super(appEntityServices, facebookAccountMappers);
	}

	public loadAllByPageId(
		pageId: string,
	): Observable<IFacebookInstagramAccountVM[]> {
		const instagramAccounts$: Observable<IFacebookInstagramAccount[]> =
			this.appEntityServices.facebookEntity.instagramAcccount.entities$;

		return instagramAccounts$.pipe(
			filter((accounts) => !!accounts.length),
			map((accounts) =>
				accounts.filter((account) => account.pageId === pageId),
			),
		);
	}

	public isLoadingInstagramAccounts(): Observable<boolean> {
		return this.appEntityServices.facebookEntity.instagramAcccount.loading$;
	}
}
