import {
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { AdContentValidationDataService } from './ad-content-validation/ad-content-validation-data.service';
import { AdContentValidationEntityService } from './ad-content-validation/ad-content-validation-entity.service';
import { AdContentCarouselValidationDataService } from './ad-content-carousel-validation/ad-content-carousel-validation-data.service';
import { AdContentCarouselValidationEntityService } from './ad-content-carousel-validation/ad-content-carousel-validation-entity.service';
import { AdCustomizedValidationDataService } from './ad-customized-validation/ad-customized-validation-data.service';
import { AdCustomizedValidationEntityService } from './ad-customized-validation/ad-customized-validation-entity.service';
import { AdGroupDataService } from './ad-group/ad-group-data.service';
import { AdGroupsEntityService } from './ad-group/ad-groups-entity.service';
import { AdGroupConnectionDataService } from './ad-group-connection/ad-group-connection-data.service';
import { AdGroupConnectionEntityService } from './ad-group-connection/ad-group-connection-entity.service';
import { AdLogDataService } from './ad-log/ad-log-data.service';
import { AdsLogEntityService } from './ad-log/ads-log-entity.service';
import { AdStatusDataService } from './ad-status/ad-status-data.service';
import { AdsStatusEntityService } from './ad-status/ads.status-entity.service';
import { AdDataService } from './ad/ad-data.service';
import { AdsEntityService } from './ad/ads-entity.service';
import { EntityDataService } from '@ngrx/data';
import {
	ADGROUP_ENTITY,
	ADGROUP_CONNECTION_ENTITY,
	AD_CONTENT_VALIDATION_ENTITY,
	AD_CONTENT_CAROUSEL_VALIDATION_ENTITY,
	AD_CUSTOMIZED_VALIDATION_ENTITY,
	AD_ENTITY,
	AD_LOG_ENTITY,
	AD_STATUS_ENTITY,
	AD_SCHEDULE_ENTITY,
	AD_PLACEMENT_TEMPLATE_ENTITY,
} from 'src/app/state/app.state';
import { AdScheduleDataService } from './ad-schedule/ad-schedule-data.service';
import { AdScheduleEntityService } from './ad-schedule/ads-schedule-entity.service';
import { AdPlacementTemplateEntityService } from './ad-placement-template/ad-placement-template-entity.service';
import { AdPlacementTemplateDataService } from './ad-placement-template/ad-placement-template-data.service';

@NgModule({
	declarations: [],
	imports: [],
	providers: [
		AdDataService,
		AdsEntityService,
		AdContentValidationDataService,
		AdContentValidationEntityService,
		AdContentCarouselValidationDataService,
		AdContentCarouselValidationEntityService,
		AdCustomizedValidationDataService,
		AdCustomizedValidationEntityService,
		AdGroupDataService,
		AdGroupsEntityService,
		AdGroupConnectionDataService,
		AdGroupConnectionEntityService,
		AdStatusDataService,
		AdsStatusEntityService,
		AdLogDataService,
		AdsLogEntityService,
		AdScheduleDataService,
		AdScheduleEntityService,
		AdPlacementTemplateEntityService,
		AdPlacementTemplateDataService,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class EntityAdModule {
	private adDataService: AdDataService;
	private adContentValidationDataService: AdContentValidationDataService;
	private adContentCarouselValidationDataService: AdContentCarouselValidationDataService;
	private adCustomizedValidationDataService: AdCustomizedValidationDataService;
	private adGroupDataService: AdGroupDataService;
	private adGroupConnectionDataService: AdGroupConnectionDataService;
	private adStatusDataService: AdStatusDataService;
	private adLogDataService: AdLogDataService;
	private adScheduleDataService: AdScheduleDataService;
	private adPlacementDataTemplateService: AdPlacementTemplateDataService;

	constructor(
		entityDataService: EntityDataService,
		private injector: Injector,
	) {
		this.adDataService = this.injector.get(AdDataService);
		this.adContentValidationDataService = this.injector.get(
			AdContentValidationDataService,
		);
		this.adContentCarouselValidationDataService = this.injector.get(
			AdContentCarouselValidationDataService,
		);
		this.adCustomizedValidationDataService = this.injector.get(
			AdCustomizedValidationDataService,
		);
		this.adGroupDataService = this.injector.get(AdGroupDataService);
		this.adGroupConnectionDataService = this.injector.get(
			AdGroupConnectionDataService,
		);
		this.adStatusDataService = this.injector.get(AdStatusDataService);
		this.adLogDataService = this.injector.get(AdLogDataService);
		this.adScheduleDataService = this.injector.get(AdScheduleDataService);
		this.adPlacementDataTemplateService = this.injector.get(
			AdPlacementTemplateDataService,
		);

		entityDataService.registerService(AD_ENTITY, this.adDataService);
		entityDataService.registerService(
			AD_CONTENT_VALIDATION_ENTITY,
			this.adContentValidationDataService,
		);
		entityDataService.registerService(
			AD_CONTENT_CAROUSEL_VALIDATION_ENTITY,
			this.adContentCarouselValidationDataService,
		);
		entityDataService.registerService(
			AD_CUSTOMIZED_VALIDATION_ENTITY,
			this.adCustomizedValidationDataService,
		);
		entityDataService.registerService(
			ADGROUP_ENTITY,
			this.adGroupDataService,
		);
		entityDataService.registerService(
			ADGROUP_CONNECTION_ENTITY,
			this.adGroupConnectionDataService,
		);
		entityDataService.registerService(
			AD_STATUS_ENTITY,
			this.adStatusDataService,
		);
		entityDataService.registerService(AD_LOG_ENTITY, this.adLogDataService);
		entityDataService.registerService(
			AD_SCHEDULE_ENTITY,
			this.adScheduleDataService,
		);
		entityDataService.registerService(
			AD_PLACEMENT_TEMPLATE_ENTITY,
			this.adPlacementDataTemplateService,
		);
	}
}
