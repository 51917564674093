import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { INetwork } from '../../../core/models/account/network.model';
import { NETWORK_ENTITY } from '../../../state/app.state';

@Injectable()
export class NetworksEntityService extends EntityCollectionServiceBase<INetwork> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(NETWORK_ENTITY, serviceElementsFactory);
	}
}
