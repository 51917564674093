import { Injectable } from '@angular/core';
import { IUser } from 'src/app/core/models';
import { IBrandVM, IUserVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class UserMappersService {
	public mapToUserVM(user: IUser): IUserVM {
		return {
			id: user.id,
			accountSlug: user.account.slug,
			brandSlug: user.brand.slug,
			brandId: user.brand.id,
			isEmployee: user.isEmployee,
			accountId: user.account.id,
			email: user.email,
			timezoneId: user.timezone,
		};
	}

	public mapToBrandVM(user: IUser): IBrandVM {
		return {
			id: user.brand.id,
			logoUrl: user.brand.logo,
			name: user.brand.name,
			accountSlug: user.brand.accountSlug,
			brandSlug: user.brand.slug,
		};
	}
}
