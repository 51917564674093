import { Component } from '@angular/core';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { FacebookPlacementCoreBaseDirective } from '../../../../shared/directives/facebook-placement-core-base.directive';
import { FacebookSupportedPlacements } from '../../../../shared/enums';
import { IBrandVM } from 'src/app/presentation/view-models';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { CreativeContentComponent } from '../../../../../../../../elements/creative-content/creative-content.component';
import { ReelsIconsComponent } from '../../../shared/reels-icons/reels-icons.component';
import { UIModule } from '@bannerflow/ui';
import { DescriptionComponent } from '../../../../shared/description/description.component';
@Component({
	standalone: true,
	imports: [
		CommonModule,
		TextPipe,
		CreativeContentComponent,
		ReelsIconsComponent,
		UIModule,
		DescriptionComponent,
	],
	selector: 'facebook-ads-on-reels-placement-core',
	templateUrl: './facebook-ads-on-reels-placement-core.component.html',
	styleUrls: ['./facebook-ads-on-reels-placement-core.component.scss'],
})
export class FacebookAdsOnReelsPlacementCoreComponent extends FacebookPlacementCoreBaseDirective {
	public brand$: Observable<IBrandVM>;
	constructor(public appFeatureServices: AppFeatureServices) {
		super(
			appFeatureServices,
			FacebookSupportedPlacements.FacebookAdsOnReels,
		);
		this.brand$ = this.appFeatureServices.accountFeature.brand.load();
	}
}
