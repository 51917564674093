import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';

@Component({
	selector: 'fb-reels-cta',
	templateUrl: './fb-reels-cta.component.html',
	styleUrls: ['./fb-reels-cta.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, UIModule],
})
export class FacebookReelsCtaComponent {
	@Input() public ctaValue = '';
}
