import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { IAdCustomizedValidation } from 'src/app/core/models/ad/ad-validation/ad-customized-validation.model';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import {
	AdContentTemplateScopeEnum,
	AdFormatEnum,
	IAdContentTemplateVM,
	IAdContentValidationVM,
	IAdPlacementCustomizationVM,
	ValidationTypeEnum,
} from 'src/app/presentation/view-models';
import { AdMappersService } from '../../mappers/ad-mapper/ad.mapper';
import { FacebookAdMappersService } from 'src/app/features/facebook/ad/mappers/ad-mapper/fb-ad.mapper';

@Injectable({
	providedIn: 'root',
})
export class AdPlacementCustomizedValidationService {
	constructor(
		private appEntityServices: AppEntityServices,
		private adMapper: AdMappersService,
		private facebookAdMapper: FacebookAdMappersService,
	) {}

	public isAdCustomizedValid(
		adContentValidation$: Observable<IAdContentValidationVM>,
	): Observable<boolean> {
		return adContentValidation$.pipe(
			map((adContentValidaiton: IAdContentValidationVM) => {
				let isValid = true;

				if (!adContentValidaiton) {
					return isValid;
				}

				isValid = !Object.values(adContentValidaiton).some((error) =>
					error.some(({ type }) => type === ValidationTypeEnum.error),
				);

				return isValid;
			}),
		);
	}

	public getAdContentValidation(
		placementId: string,
		adContentTemplates: IAdContentTemplateVM[],
		customizedContent: IAdPlacementCustomizationVM,
		adFormatId: AdFormatEnum,
		adId: string,
	): Observable<void> {
		this.appEntityServices.adEntity.adCustomizatedValidation.getWithQuery({
			adId,
			placementId,
			adFormatId,
			value: this.facebookAdMapper.mapCustomizedContentToValidateAllProperties(
				adContentTemplates,
				customizedContent,
			),
		});

		return new Observable<void>();
	}

	public loadAdContentValidation(): Observable<IAdContentValidationVM> {
		const adCustomizedValidation$: Observable<IAdCustomizedValidation[]> =
			this.appEntityServices.adEntity.adCustomizatedValidation.entities$;

		return adCustomizedValidation$.pipe(
			filter((adValidation) => !!adValidation),
			map((adCustomizeValidaiton: IAdCustomizedValidation[]) =>
				this.adMapper.validationMapper.toAdContentValidationVM(
					adCustomizeValidaiton,
				),
			),
		);
	}

	public clearAdCustomizedValidationCache(): void {
		this.appEntityServices.adEntity.adCustomizatedValidation.clearCache();
	}

	public getPlacementPropertyValidation(
		placementId: string,
		value: any,
		adContentPropertyId: string,
		adId: string,
	): Observable<void> {
		this.appEntityServices.adEntity.adCustomizatedValidation.getWithQuery({
			adId,
			placementId,
			adContentPropertyId,
			value,
			adFormatId: AdFormatEnum.singleImageAndVideo,
			scope: AdContentTemplateScopeEnum.ad,
		});

		return new Observable<void>();
	}
}
