import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { UIButtonGroupOption, UIModule } from '@bannerflow/ui';
import { FbInboxExpandedComponent } from './fb-inbox-expanded/fb-inbox-expanded.component';
import { FbInboxCollapsedComponent } from './fb-inbox-collapsed/fb-inbox-collapsed.component';
import { FacebookCarouselBaseDirective } from '../../directives/fb-carousel-base.directive';
import { FacebookCarouselPlacementsComponentStore } from '../../fb-carousel-placements.component.store';

import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { FacebookCarouselSupportedPlacements } from '../../enums/carousel-supported-placements.enum';

export enum ViewOptionsEnum {
	Collapsed = 'collapsed',
	Expanded = 'expanded',
}
@Component({
	selector: 'fb-carousel-messenger-inbox',
	templateUrl: './fb-messenger-inbox.component.html',
	styleUrls: ['./fb-messenger-inbox.component.scss'],
	standalone: true,
	imports: [
		UIModule,
		CommonModule,
		FbInboxExpandedComponent,
		FbInboxCollapsedComponent,
	],
})
export class FacebookCarouselMessengerInboxComponent extends FacebookCarouselBaseDirective {
	public viewOptionsEnum: typeof ViewOptionsEnum = ViewOptionsEnum;
	public readonly viewOptions: UIButtonGroupOption[] = [
		{
			id: 'left-button',
			text: this.textPipe.transform(
				'FacebookMessengerInboxComponent.AD_VIEW',
			),
			value: ViewOptionsEnum.Collapsed,
		},
		{
			id: 'right-button',
			text: this.textPipe.transform(
				'FacebookMessengerInboxComponent.EXPANDED_VIEW',
			),
			value: ViewOptionsEnum.Expanded,
		},
	];

	public selectedButton: string = ViewOptionsEnum.Collapsed;

	constructor(
		carouselPlacementsStore: FacebookCarouselPlacementsComponentStore,
		private textPipe: TextPipe,
	) {
		super(
			carouselPlacementsStore,
			FacebookCarouselSupportedPlacements.FacebookMessengerInbox,
		);
	}

	public onViewChange(viewName: string): void {
		this.selectedButton = viewName;
	}
}
