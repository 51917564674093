import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AdFormatEnum } from 'src/app/presentation/view-models';
import * as Placements from './fb-placements-components.const';
import { PlacementsWrapperComponent } from '../../../elements/placements-wrapper/placements-wrapper.component';

@Component({
	selector: 'fb-placements-wrapper',
	templateUrl: './fb-placements-wrapper.component.html',
	standalone: true,
	imports: [PlacementsWrapperComponent],
})
export class FacebookPlacementsWrapperComponent implements OnChanges {
	@Input() public placementId: string;
	@Input() public adFormatId: string;

	public placements: any;

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.placementId) {
			switch (this.adFormatId) {
				case AdFormatEnum.singleImageAndVideo:
					this.placements = Placements.SinglePlacements;
					break;
				case AdFormatEnum.carousel:
					this.placements = Placements.CarouselPlacements;
					break;
				default:
					break;
			}
		}
	}
}
