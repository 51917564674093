<div class="social">
    <div class="social__line"></div>
    <div class="social__icons">
        <div class="social__icons-left">
            <ui-svg-icon class="icon" icon="heart-outline"></ui-svg-icon>
            <ui-svg-icon class="icon" icon="comments"></ui-svg-icon>
            <ui-svg-icon class="icon" icon="send"></ui-svg-icon>
        </div>

        <div class="social__icons-center">
            <ng-content></ng-content>
        </div>

        <div class="social__icons-right">
            <ui-svg-icon icon="bookmark-outline"></ui-svg-icon>
        </div>
    </div>
</div>
