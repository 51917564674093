import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class UtilitiesService {
	public isEqual(obj1: any, obj2: any): boolean {
		const visited = new Map();
		return this.compare(obj1, obj2, visited);
	}

	private compare(obj1: any, obj2: any, visited: Map<any, any>): boolean {
		const key1 = JSON.stringify(obj1);
		const key2 = JSON.stringify(obj2);

		if (key1 === key2) {
			return true;
		}

		if (visited.has(obj1) || visited.has(obj2)) {
			return visited.get(obj1) === obj2 && visited.get(obj2) === obj1;
		}

		visited.set(obj1, obj2);
		visited.set(obj2, obj1);

		if (typeof obj1 !== typeof obj2) {
			return false;
		}

		if (typeof obj1 !== 'object' || obj1 === null || obj2 === null) {
			return obj1 === obj2;
		}

		if (Array.isArray(obj1)) {
			return this.compareArrays(obj1, obj2, visited);
		}

		return this.compareObjects(obj1, obj2, visited);
	}

	private compareArrays(
		arr1: any[],
		arr2: any[],
		visited: Map<any, any>,
	): boolean {
		if (!Array.isArray(arr2) || arr1.length !== arr2.length) {
			return false;
		}

		for (let i = 0; i < arr1.length; i++) {
			if (!this.compare(arr1[i], arr2[i], visited)) {
				return false;
			}
		}

		return true;
	}

	private compareObjects(
		obj1: any,
		obj2: any,
		visited: Map<any, any>,
	): boolean {
		const keys1 = Object.keys(obj1).sort((a, b) => a.localeCompare(b));
		const keys2 = Object.keys(obj2).sort((a, b) => a.localeCompare(b));

		if (keys1.length !== keys2.length) {
			return false;
		}

		for (const key of keys1) {
			if (!this.compare(obj1[key], obj2[key], visited)) {
				return false;
			}
		}

		return true;
	}
}
