import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
	IBrandVM,
	IFacebookPlacementCarouselMetaDataVM,
} from 'src/app/presentation/view-models';

import { CreativeContentComponent } from '../../../../../../../elements/creative-content/creative-content.component';

import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { Observable } from 'rxjs';

import { FbHeaderInboxComponent } from '../fb-header-inbox/fb-header-inbox.component';

@Component({
	standalone: true,
	imports: [CommonModule, FbHeaderInboxComponent, CreativeContentComponent],
	selector: 'fb-inbox-collapsed',
	templateUrl: './fb-inbox-collapsed.component.html',
	styleUrls: ['./fb-inbox-collapsed.component.scss'],
})
export class FbInboxCollapsedComponent {
	@Input() public placementMetaData: IFacebookPlacementCarouselMetaDataVM;
	public brand$: Observable<IBrandVM> =
		this.appFeatureServices.accountFeature.brand.load();
	constructor(private appFeatureServices: AppFeatureServices) {}
}
