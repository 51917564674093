import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import {
	IAdGroupVM,
	IPlacementOptionsVM,
} from 'src/app/presentation/view-models';
import { FacebookPlacementMappersService } from '../../mappers/fb-placement.mappers';
import { UserService } from 'src/app/features/account/services/user/user.service';
import { IPlacementOptions } from 'src/app/core/models';
import { CACHEBUSTERS_TEAM } from '@assets/cachebustersTeam';
import { AdPlacementTemplateService } from 'src/app/features/ad/services/ad-placement-template/ad-placement-template.service';

@Injectable({
	providedIn: 'root',
})
export class FacebookSinglePlacementService {
	private readonly cacheBustersEmails = CACHEBUSTERS_TEAM;

	constructor(
		private appEntityServices: AppEntityServices,
		public userService: UserService,
		public adPlacementTemplates: AdPlacementTemplateService,
		private facebookPlacementMappers: FacebookPlacementMappersService,
	) {}

	public getPlacements(): void {
		this.appEntityServices.facebookEntity.singlePlacement.getAll();
	}

	public loadPlacements(): Observable<IPlacementOptionsVM[]> {
		return this.appEntityServices.facebookEntity.singlePlacement.entities$.pipe(
			filter((placements) => !!placements),
			map((placements: IPlacementOptions[]) =>
				this.facebookPlacementMappers.mapPlacementsWithRenderingOptions(
					placements,
				),
			),
		);
	}

	/**
	 * Loads placements by ad format id that exist in the ad group
	 */
	public loadSupportedPlacements(
		ids: IAdGroupVM['supportedPlacementsIds'],
	): Observable<IPlacementOptionsVM[]> {
		return this.loadPlacements().pipe(
			map((placements) =>
				placements.filter((placement) => ids?.includes(placement.id)),
			),
		);
	}
}
