import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { ValidationTypeEnum } from 'src/app/core/models';
import { IValidationResultVM } from 'src/app/presentation/view-models';

@Component({
	selector: 'validation-result',
	templateUrl: './validation-result.component.html',
	styleUrls: ['./validation-result.component.scss'],
	imports: [CommonModule, UIModule],
	standalone: true,
})
export class ValidationResultComponent {
	@Input() public validationResult: IValidationResultVM;
	public validationType: typeof ValidationTypeEnum = ValidationTypeEnum;
}
