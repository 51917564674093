import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { IBrandVM } from 'src/app/presentation/view-models';
import { FbHeadlineInboxComponent } from '../fb-headline-inbox/fb-headline-inbox.component';
import { FbCtaLinkComponent } from '../../../../../placement-elements/cta/cta-link/fb-cta-link.component';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { BrandLogoComponent } from 'src/app/presentation/features/account/brand-logo/brand-logo.component';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		BrandLogoComponent,
		FbHeadlineInboxComponent,
		FbCtaLinkComponent,
		TextPipe,
	],
	selector: 'fb-header-inbox',
	templateUrl: './fb-header-inbox.component.html',
	styleUrls: ['./fb-header-inbox.component.scss'],
})
export class FbHeaderInboxComponent {
	@Input() public headlineValue?: string;
	@Input() public ctaValue?: string;
	@Input() public isExpanded?: boolean;
	public brand$: Observable<IBrandVM> =
		this.appFeatureServices.accountFeature.brand.load();
	constructor(private appFeatureServices: AppFeatureServices) {}
}
