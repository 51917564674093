import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { BaseFeatureService } from 'src/app/features/base-feature.service';
import { IFacebookPageVM } from '../../../../../presentation/view-models';
import { FacebookAccountMappersService } from '../../mappers/facebook-account.mappers';

@Injectable({
	providedIn: 'root',
})
export class FacebookAdPageBaseService extends BaseFeatureService<IFacebookPageVM> {
	constructor(
		public appEntityServices: AppEntityServices,
		public facebookAccountMappers: FacebookAccountMappersService,
	) {
		super();
	}

	public getWithQuery(params: { integrationId: string }): void {
		this.appEntityServices.facebookEntity.page.clearCache();
		this.appEntityServices.facebookEntity.page.getWithQuery({
			integrationId: params['integrationId'],
		});
	}
	public loadById(id: string): Observable<IFacebookPageVM> {
		return this.appEntityServices.facebookEntity.page.entities$.pipe(
			filter((pages) => !!pages.length),
			map((pages) => pages.find((page) => page.id === id)),
		);
	}

	protected getById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected load(): Observable<IFacebookPageVM> {
		throw new Error('Method not implemented.');
	}
	protected loadAll(): Observable<IFacebookPageVM[]> {
		throw new Error('Method not implemented.');
	}
	protected loadByIds(_ids: string[]): Observable<IFacebookPageVM[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
