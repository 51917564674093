import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { BrandLogoComponent } from 'src/app/presentation/features/account/brand-logo/brand-logo.component';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { IBrandVM } from 'src/app/presentation/view-models';

@Component({
	selector: 'fb-stories-reels-brand',
	templateUrl: './stories-reels-brand.component.html',
	styleUrls: ['./stories-reels-brand.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [BrandLogoComponent, CommonModule, TextPipe],
})
export class StoriesReelsBrandComponent {
	public brand$: Observable<IBrandVM> =
		this.appFeatureServices.accountFeature.brand.load();
	constructor(private appFeatureServices: AppFeatureServices) {}
}
