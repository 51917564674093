import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
} from '@angular/core';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { FacebookSinglePlacementsComponentStore } from '../../fb-single-placements.component.store';
import { FacebookPlacementComponentBaseDirective } from '../../shared/directives/facebook-placement-component-base.directive';
import { FacebookSupportedPlacements } from '../../shared/enums';
import { CommonModule } from '@angular/common';
import { FacebookNewsFeedPlacementCoreComponent } from './core/fb-news-feed-placement-core.component';

@Component({
	standalone: true,
	imports: [CommonModule, FacebookNewsFeedPlacementCoreComponent],
	selector: 'facebook-news-feed-placement',
	templateUrl: './fb-news-feed-placement.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookNewsFeedPlacementComponent extends FacebookPlacementComponentBaseDirective {
	constructor(
		public appFeatureServices: AppFeatureServices,
		public changeDetectorRef: ChangeDetectorRef,
		public facebookPlacementsComponentStore: FacebookSinglePlacementsComponentStore,
	) {
		super(
			appFeatureServices,
			FacebookSupportedPlacements.FacebookNewsFeed,
			changeDetectorRef,
			facebookPlacementsComponentStore,
		);
	}
}
