import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ConfigService } from 'src/app/core/config/config.service';
import { ApiService } from '../../internal/api.service';
import { IAd } from 'src/app/core/models';
import { IAdPlacement } from 'src/app/core/models/ad/ad/ad-placement.model';

@Injectable({
	providedIn: 'root',
})
export class CustomizationApiService extends BaseApiService {
	constructor(public http: HttpClient) {
		super(http, `${ConfigService?.config?.SCS_URL}/api/ads`);
	}

	/**
	 * Update Ad placement
	 * @param ad
	 */
	public updateAdPlacement(
		adId: string,
		placement: IAdPlacement,
	): Observable<IAd> {
		const url = `${this.baseUrl}/${adId}/placements/${placement.placementId}`;

		const body = {
			content: placement.content,
			creative: placement.creative,
			cards: placement.cards,
		};

		return this.http.put<any>(url, body).pipe(
			map((ad) => ad),
			catchError(ApiService.handleError),
		);
	}

	public deleteAdPlacement(
		adId: string,
		placementId: string,
	): Observable<IAd> {
		const url = `${this.baseUrl}/${adId}/placements/${placementId}`;
		return this.http.delete<any>(url).pipe(
			map((response) => response),
			catchError(ApiService.handleError),
		);
	}
}
