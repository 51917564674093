import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { IPublishLogEntry } from 'src/app/core/models/publish/publish-log-entry';
import { PUBLISHLOG_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class PublishLogEntityService extends EntityCollectionServiceBase<IPublishLogEntry> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(PUBLISHLOG_ENTITY, serviceElementsFactory);
	}
}
