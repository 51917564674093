import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';

@Component({
	selector: 'fb-menu-dots-icon',
	templateUrl: './fb-menu-dots-icon.component.html',
	styleUrls: ['./fb-menu-dots-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, UIModule],
})
export class FbMenuDotsIconComponent {}
