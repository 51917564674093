import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { TiktokAdGroupPlacementBaseService } from './tt-ad-group-placement-base.service';
import { ITiktokAdGroupPlacements } from 'src/app/core/models';

@Injectable({
	providedIn: 'root',
})
export class TiktokAdGroupPlacementService extends TiktokAdGroupPlacementBaseService {
	constructor(public appEntityServices: AppEntityServices) {
		super(appEntityServices);
	}

	public loadAdGroupPlacements(adGroupId: string): Observable<string[]> {
		const adGroupPlacements$: Observable<ITiktokAdGroupPlacements[]> =
			this.appEntityServices.tiktokEntity.adGroupPlacements.entities$;
		return adGroupPlacements$.pipe(
			map((adGroupPlacements) =>
				adGroupPlacements.find(
					(adGroupPlacement) => adGroupPlacement.id === adGroupId,
				),
			),
			filter((adGroupPlacements) => !!adGroupPlacements),
			map((adGroupPlacement) => adGroupPlacement.placementsIds),
		);
	}
}
