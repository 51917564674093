import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { BaseFeatureService } from 'src/app/features/base-feature.service';
import { IFacebookInstagramAccountVM } from '../../../../../presentation/view-models';
import { FacebookAccountMappersService } from '../../mappers/facebook-account.mappers';

@Injectable({
	providedIn: 'root',
})
export class FacebookInstagramAccountBaseService extends BaseFeatureService<IFacebookInstagramAccountVM> {
	constructor(
		public appEntityServices: AppEntityServices,
		public facebookAccountMappers: FacebookAccountMappersService,
	) {
		super();
	}

	public getWithQuery(params: {
		integrationId: string;
		adAccountId: string;
		pageId: string;
	}): void {
		this.appEntityServices.facebookEntity.instagramAcccount.getWithQuery({
			integrationId: params['integrationId'],
			adAccountId: params['adAccountId'],
			pageId: params['pageId'],
		});
	}

	public loadById(id: string): Observable<IFacebookInstagramAccountVM> {
		return this.appEntityServices.facebookEntity.instagramAcccount.entities$.pipe(
			filter((pages) => !!pages.length),
			map((pages) => pages.find((page) => page.id === id)),
		);
	}

	protected getById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected load(): Observable<IFacebookInstagramAccountVM> {
		throw new Error('Method not implemented.');
	}
	protected loadAll(): Observable<IFacebookInstagramAccountVM[]> {
		throw new Error('Method not implemented.');
	}
	protected loadByIds(
		_ids: string[],
	): Observable<IFacebookInstagramAccountVM[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
