import { ComponentType } from '@angular/cdk/portal';
import { TiktokPlacementBaseDirective } from '../placements/directives/tt-placement-base.directive';
import { TiktokSupportedPlacements } from '../enums/tt-supported-placement.enum';
import { TiktokGlobalAppBundleComponent } from '../placements/global-app-bundle/tt-global-app-bundle.component';
import { TiktokPangleComponent } from '../placements/pangle/tt-pangle.component';
import { TiktokTiktokComponent } from '../placements/tiktok/tt-tiktok.component';

export const SinglePlacements: Record<
	string,
	ComponentType<TiktokPlacementBaseDirective>
> = {
	[TiktokSupportedPlacements.TiktokGlobalAppBundle]:
		TiktokGlobalAppBundleComponent,
	[TiktokSupportedPlacements.TiktokPangle]: TiktokPangleComponent,
	[TiktokSupportedPlacements.TiktokTiktok]: TiktokTiktokComponent,
};
