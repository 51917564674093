<ng-container *ngIf="(selectedAd$ | async) as ad">
    <div class="container">
        <div class="panel-creative panel-creative-set">
            <creative-set-picker
                (onCreativeSetSelected)="onSelectedCreativeSet($event)"></creative-set-picker>
        </div>
        <div
            class="panel-creative panel-creative-select"
            *ngIf="(placementAspectRatioGroup$ | async) as ratioGroup ">
            <customize-creative-set [ratioGroup]="ratioGroup"></customize-creative-set>
        </div>
        <div class="panel-placement-preview">
            <p class="panel-title">Placement preview</p>
            <customize-placement-preview
                class="panel-preview-content"
                *ngIf="(placementAspectRatioGroup$ | async) as ratioGroup "
                [ratioGroup]="ratioGroup"
                [placementId]="placementId"></customize-placement-preview>
        </div>
        <div class="panel-placement-content">
            <customize-placement-content
                [placementId]="placementId"
                [ratioGroup]="(placementAspectRatioGroup$ | async)"
                [adFormatId]="ad.adFormatId"
                (onSave)="onSave()">
            </customize-placement-content>
        </div>
    </div>
</ng-container>
