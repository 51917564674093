import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { BaseFeatureService } from '../../../base-feature.service';
import { ITimezoneVM } from 'src/app/presentation/view-models/account/timezone.vm';
import { TimeZoneMappersService } from '../../mappers/timezone.mapper';
import { ITimezone } from 'src/app/core/models/account/timezone.model';

@Injectable({
	providedIn: 'root',
})
export class TimeZoneBaseService extends BaseFeatureService<ITimezoneVM> {
	constructor(
		public appEntityServices: AppEntityServices,
		public timeZoneMapper: TimeZoneMappersService,
	) {
		super();
	}

	public loadById(timeZoneId: string): Observable<ITimezoneVM> {
		const timeZones$: Observable<ITimezone[]> =
			this.appEntityServices.accountEntity.timezone.entities$;

		return timeZones$.pipe(
			filter((timeZones) => !!timeZones),
			map((timeZones) =>
				timeZones.find((timeZone) => timeZone.id === timeZoneId),
			),
			filter((timeZone) => !!timeZone),
			map((timeZone: ITimezone) =>
				this.timeZoneMapper.mapToTimeZoneVM(timeZone),
			),
		);
	}

	public loadAll(): Observable<ITimezoneVM[]> {
		const timeZones$: Observable<ITimezone[]> =
			this.appEntityServices.accountEntity.timezone.entities$;

		return timeZones$.pipe(
			filter((timeZones) => !!timeZones),
			map((timeZones: ITimezone[]) =>
				timeZones.map((timeZone) =>
					this.timeZoneMapper.mapToTimeZoneVM(timeZone),
				),
			),
		);
	}
	protected getWithQuery(): void {
		throw new Error('Method not implemented.');
	}
	protected getById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected load(): Observable<ITimezoneVM> {
		throw new Error('Method not implemented.');
	}
	protected loadByIds(_ids: string[]): Observable<ITimezoneVM[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
