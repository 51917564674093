<ng-container>
    <div class="placement-card">
        <div class="card-text">
            <div class="solid-circle"></div>
            <div class="text-info default-container justify-content">
                <div class="first-line"></div>
                <div class="second-line"></div>
            </div>
            <div class="dots-container">
                <div class="more-dots">...</div>
            </div>
        </div>

        <div class="creative-content">
            <ng-container
                *ngIf="selectedCustomizedCreative?.id || placementMetaData?.creative?.id || placementMetaData?.creativeUrl">
                <creative-content
                    [sound]="placementSound$ | async"
                    [creativeUrl]="placementMetaData?.creativeUrl"
                    [isCustomized]="placementMetaData?.isCustomized"
                    [creativeId]="selectedCustomizedCreative?.id || placementMetaData?.creative?.id"
                    [creativeSetId]="selectedCustomizedCreative?.setId || placementMetaData?.creative?.setId"
                    [selectedMediaType]="placementMetaData?.selectedMediaType"></creative-content>
            </ng-container>
        </div>

        <div class="body">
            <facebook-placement-header
                [placementId]="placementId.FacebookInStreamVideos"></facebook-placement-header>
            <div class="info-container">
                <div class="social-info">
                    <div class="display-link">
                        <div
                            *ngIf="placementMetaData.displayLinkValue; else displayDefault">
                            <div class="text-info">
                                {{ placementMetaData.displayLinkValue }}
                            </div>
                        </div>
                        <ng-template #displayDefault>
                            <div class="default"></div>
                        </ng-template>
                    </div>
                    <div class="headline">
                        <headline
                            [headLineValue]="placementMetaData.headLineValue"
                            [placementId]="placementId.FacebookInStreamVideos"></headline>
                    </div>
                    <primary-text
                        [primaryTextValue]="placementMetaData.primaryTextValue"
                        [placementId]="placementId.FacebookInStreamVideos"></primary-text>
                </div>
            </div>
            <div class="right-side">
                <div class="more-dots">...</div>
            </div>
        </div>

        <facebook-placement-footer></facebook-placement-footer>
    </div>
</ng-container>
