import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrandLogoComponent } from 'src/app/presentation/features/account/brand-logo/brand-logo.component';
import { Observable } from 'rxjs';
import { IBrandVM } from 'src/app/presentation/view-models';
import { AppFeatureServices } from 'src/app/features/app-feature.service';

@Component({
	selector: 'tt-brand-logo',
	templateUrl: './tt-brand-logo.component.html',
	styleUrls: ['./tt-brand-logo.component.scss'],
	standalone: true,
	imports: [CommonModule, BrandLogoComponent],
})
export class TiktokBrandLogoComponent {
	@Input() public isSmall: boolean;
	public brand$: Observable<IBrandVM>;

	constructor(private appFeatureServices: AppFeatureServices) {
		this.brand$ = this.appFeatureServices.accountFeature.brand.load();
	}
}
