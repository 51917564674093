import { Injectable } from '@angular/core';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { NetworkBaseService } from './network-base.service';
import { NetworkMappersService } from '../../mappers/network.mapper';
import { UserService } from '../user/user.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class NetworkService extends NetworkBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public networkMapper: NetworkMappersService,
		public userService: UserService,
	) {
		super(appEntityServices, networkMapper, userService);
	}

	public isLoadingNetworks(): Observable<boolean> {
		return this.appEntityServices.accountEntity.network.loading$;
	}
}
