import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FacebookCarouselSearchResultsVideoComponent } from './fb-search-results-video/fb-search-results-video.component';
import { FacebookCarouselSearchResultsImageComponent } from './fb-search-results-image/fb-search-results-image.component';

import {
	IFacebookCarouselCardMetaDataVM,
	MediaTypeEnum,
} from 'src/app/presentation/view-models';
import { FacebookCarouselBaseDirective } from '../directives/fb-carousel-base.directive';
import { FacebookCarouselPlacementsComponentStore } from '../fb-carousel-placements.component.store';
import { FacebookCarouselSupportedPlacements } from '../enums';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		FacebookCarouselSearchResultsVideoComponent,
		FacebookCarouselSearchResultsImageComponent,
	],
	selector: 'fb-carousel-search-results',
	templateUrl: './fb-search-results.component.html',
})
export class FacebookCarouselSearchResultsComponent extends FacebookCarouselBaseDirective {
	constructor(
		carouselPlacementsStore: FacebookCarouselPlacementsComponentStore,
	) {
		super(
			carouselPlacementsStore,
			FacebookCarouselSupportedPlacements.FacebookSearchResults,
		);
	}
	public checkIfCardsHaveVideo(
		cards: IFacebookCarouselCardMetaDataVM[],
	): boolean {
		return cards.some((card) => card.mediaType === MediaTypeEnum.Video);
	}
}
