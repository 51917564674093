import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { IBrandVM } from 'src/app/presentation/view-models';
import { AppFeatureServices } from 'src/app/features/app-feature.service';

@Component({
	selector: 'tt-brand-name',
	templateUrl: './tt-brand-name.component.html',
	styleUrls: ['./tt-brand-name.component.scss'],
	standalone: true,
	imports: [CommonModule],
})
export class TiktokBrandNameComponent {
	public brand$: Observable<IBrandVM>;

	constructor(private appFeatureServices: AppFeatureServices) {
		this.brand$ = this.appFeatureServices.accountFeature.brand.load();
	}
}
