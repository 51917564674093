import { Injectable } from '@angular/core';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { FacebookPlacementService } from '../../../placement/services/fb-placement.service';
import {
	AdFormatEnum,
	IFacebookAdVM,
	IFacebookPlacementVM,
	IPlacementOptionsVM,
} from 'src/app/presentation/view-models';
import { FacebookAdMappersService } from '../../mappers/ad-mapper/fb-ad.mapper';
import { Observable, combineLatest, filter, map } from 'rxjs';
import { IAd } from 'src/app/core/models';
import { IFacebookAdBulkVM } from 'src/app/presentation/view-models/facebook/fb-ad-bulk.vm';
import { AdPlacementTemplateService } from 'src/app/features/ad/services/ad-placement-template/ad-placement-template.service';
import { FacebookPlacementMappersService } from '../../../placement/mappers/fb-placement.mappers';

@Injectable({
	providedIn: 'root',
})
export class FacebookBulkAdService {
	constructor(
		public appEntityServices: AppEntityServices,
		public fbAdMapper: FacebookAdMappersService,
		public facebookPlacementService: FacebookPlacementService,
		public adPlacementTemplateService: AdPlacementTemplateService,
		private facebookPlacementMappers: FacebookPlacementMappersService,
	) {}

	public loadBulkAd(adsIds: string[]): Observable<IFacebookAdBulkVM> {
		const placements$: Observable<IFacebookPlacementVM[]> =
			this.adPlacementTemplateService
				.loadCombinedPlacementsByAdsIds(adsIds)
				.pipe(
					map((placements) =>
						this.facebookPlacementMappers.mapPlacements(placements),
					),
				);

		const ads$ = this.appEntityServices.adEntity.ad.entities$.pipe(
			map((ads) => ads?.filter((ad) => adsIds.includes(ad.id))),
			filter((ads) => !!ads.length),
		);

		return combineLatest([ads$, placements$]).pipe(
			map(([ads, placements]) =>
				this.fbAdMapper.toCombinedAdVM(ads, placements),
			),
		);
	}

	public saveBulkAd(
		adVM: IFacebookAdBulkVM,
		savedAdVM: IFacebookAdVM,
		placements: IPlacementOptionsVM[] = null,
	): Observable<IAd> {
		const ad: IAd =
			savedAdVM.adFormatId === AdFormatEnum.carousel
				? this.fbAdMapper.carousel.toBulkAd(adVM, savedAdVM)
				: this.fbAdMapper.single.toBulkAd(adVM, savedAdVM, placements);

		return this.appEntityServices.adEntity.ad.update(ad);
	}
}
