import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IFacebookAdSet } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { IFacebookAdSetVM } from 'src/app/presentation/view-models';
import { FacebookAdSetBaseService } from './facebook-ad-set-base.service';
import { FacebookAdSetMapperService } from '../../mappers/facebook-adset.mapper';

@Injectable({
	providedIn: 'root',
})
export class FacebookAdSetService extends FacebookAdSetBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public facebookAdSetMapperService: FacebookAdSetMapperService,
	) {
		super(appEntityServices);
	}

	public loadAllByCampaignId(
		campaignId: string,
	): Observable<IFacebookAdSetVM[]> {
		const adSets$: Observable<IFacebookAdSet[]> =
			this.appEntityServices.facebookEntity.adSet.entities$;

		return adSets$.pipe(
			map((adSets) =>
				adSets.filter((adSet) => adSet?.campaignId === campaignId),
			),
			map((adSets) =>
				this.facebookAdSetMapperService.toAdSetsViewModel(adSets),
			),
		);
	}

	public isLoadingAdSets(): Observable<boolean> {
		return this.appEntityServices.facebookEntity.adSet.loading$;
	}
}
