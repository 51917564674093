import { Injectable } from '@angular/core';
import { combineLatest, filter, map, Observable, of, switchMap } from 'rxjs';
import { IAdGroup } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { FacebookAccountFeatureService } from 'src/app/features/facebook/account/facebook-account-feature.service';
import { IAdGroupVM } from 'src/app/presentation/view-models';
import { BaseFeatureService } from '../../../base-feature.service';
import { AdGroupMappersService } from '../../mappers/ad-group-mapper/ad-group.mapper';

@Injectable({
	providedIn: 'root',
})
export class AdGroupBaseService extends BaseFeatureService<IAdGroupVM> {
	constructor(
		public appEntityServices: AppEntityServices,
		public adGroupMapper: AdGroupMappersService,
		public facebookAccountService: FacebookAccountFeatureService,
	) {
		super();
	}

	public getById(groupId: string): void {
		this.appEntityServices.adEntity.adGroup.getByKey(groupId);
	}

	public loadById(adGroupId: string): Observable<IAdGroupVM> {
		const groups$: Observable<IAdGroup[]> =
			this.appEntityServices.adEntity.adGroup.entities$;
		const adGroup$: Observable<IAdGroup> = groups$.pipe(
			filter((groups) => !!groups),
			map((groups) => groups.find((group) => group.id === adGroupId)),
		);
		return adGroup$.pipe(
			map((adGroup) => this.adGroupMapper.toGroupViewModel(adGroup)),
		);
	}

	public loadByIds(adGroupIds: string[]): Observable<IAdGroupVM[]> {
		const adGroupIds$: Observable<string[]> = of(adGroupIds);

		if (adGroupIds.length === 0) {
			return of([]);
		}

		return adGroupIds$.pipe(
			switchMap((ids) =>
				combineLatest(ids.map((adGroupId) => this.loadById(adGroupId))),
			),
		);
	}

	public deleteById(adGroupId: string): void {
		this.appEntityServices.adEntity.adGroup.delete(adGroupId);
	}

	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected getWithQuery(_params: any): void {
		throw new Error('Method not implemented.');
	}
	protected load(): Observable<IAdGroupVM> {
		throw new Error('Method not implemented.');
	}
	protected loadAll(): Observable<IAdGroupVM[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
