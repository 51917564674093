import { Injectable } from '@angular/core';
import { INetwork } from '../../../core/models/account/network.model';
import { INetworkVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class NetworkMappersService {
	public mapToNetworkVM(network: INetwork): INetworkVM {
		return {
			id: network.id,
			name: network.name,
		};
	}
}
