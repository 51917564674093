<ng-container *ngIf="placementMetaData$ | async as placementMetaData">
    <div class="placement">
        <div class="placement-content">
            <div class="header">
                <tt-header-tabs />
            </div>

            <div class="footer">
                <div class="brand-name">
                    <tt-brand-name />
                </div>
                <div class="primary-text">
                    <ng-container *ngIf="placementMetaData.textValue; else textSkeleton">
                        {{ placementMetaData.textValue | truncate }}
                        <span class="sponsored">Sponsored</span>
                    </ng-container>
                    <ng-template #textSkeleton>
                        <div class="primary-text-skeleton">
                            <div class="line"></div>
                            <div class="line"></div>
                            <span class="sponsored">Sponsored</span>
                        </div>
                    </ng-template>
                </div>
                <div class="music">
                    <svg
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            d="M3.15 1.6 9.8.55v7.525C9.8 8.75 8.86 9.3 7.7 9.3c-1.16 0-2.1-.549-2.1-1.225 0-.677.94-1.225 2.1-1.225.382 0 .74.06 1.05.164v-4.9l-4.55.71v5.95C4.2 9.452 3.26 10 2.1 10 .94 10 0 9.451 0 8.774 0 8.098.94 7.55 2.1 7.55c.383 0 .741.06 1.05.164V1.6Z"
                            clip-rule="evenodd" />
                    </svg>
                    Promoted music
                </div>
                <tt-cta [ctaValue]="placementMetaData.callToAction" [showArrow]="true" />
            </div>

            <div class="sidebar">
                <div class="brand">
                    <tt-brand-logo />
                </div>

                <div class="sidebar-icons">
                    <div class="sidebar-icon shadow">
                        <ui-svg-icon icon="tiktok_heart" />
                        <span>123k</span>
                    </div>
                    <div class="sidebar-icon shadow">
                        <tt-message-icon />
                        <span>12</span>
                    </div>
                    <div class="sidebar-icon">
                        <ui-svg-icon icon="tiktok_saved" />
                        <span>45</span>
                    </div>
                    <div class="sidebar-icon">
                        <ui-svg-icon icon="tiktok_share" />
                        <span>3</span>
                    </div>
                </div>

                <div class="brand-bottom">
                    <tt-brand-logo [isSmall]="true" />
                </div>
            </div>
        </div>

        <div class="placement-creative">
            <creative-content
                *ngIf="placementMetaData.creative?.id || placementMetaData?.creativeUrl"
                [sound]="placementSound$ | async"
                [creativeSetId]="placementMetaData?.creative?.setId"
                [creativeId]="placementMetaData?.creative?.id"
                [creativeUrl]="placementMetaData.creativeUrl"
                [selectedMediaType]="placementMetaData.creative.renderingOption" />
        </div>
    </div>
</ng-container>
