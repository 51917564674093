import { ConfigService } from '../config/config.service';
import { ICoreCreativeSetData } from '../models/creative/core-creative-set/core-creative-set-data';
import { ICreativeSetFolder } from '../models/creative/core-creative-set/ex-creativeset';
import { ExObjectItem } from '../models/creative/core-creative-set/ex-object-item.model';

/**
 * Represents B2 creative set
 */
export class CreativeSetFolderAdapter {
	public static mapExCreativeSetToFolder(
		exBannerSet: ExObjectItem<ICoreCreativeSetData>,
	): ICreativeSetFolder {
		const creativeSetFolder: ICreativeSetFolder = {
			id: exBannerSet.data.creativesetId
				? exBannerSet.data.creativesetId
				: exBannerSet.data.id,
			name: exBannerSet.data.name,
			legacyId: exBannerSet.data.id,
			thumb: `${ConfigService.config.B2_URL}/Content/img/logotypes/logo_butterfly_blue.svg`,
			isFolder: exBannerSet.isFolder,
			data: exBannerSet.data,
			created: exBannerSet.isFolder
				? undefined
				: new Date(exBannerSet.data.created),
			modified: exBannerSet.isFolder
				? undefined
				: new Date(exBannerSet.data.modified),
			children: exBannerSet.isFolder ? [] : undefined,
			totalCount: exBannerSet.isFolder ? -1 : undefined,
		};

		return creativeSetFolder;
	}
}
