import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UIInputComponent, UIModule } from '@bannerflow/ui';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { ICampaignVM } from 'src/app/presentation/view-models';

@Component({
	selector: 'campaign-editable-name',
	templateUrl: './campaign-editable-name.component.html',
	styleUrls: ['./campaign-editable-name.component.scss'],
	imports: [UIModule, CommonModule],
	standalone: true,
})
export class CampaignEditableNameComponent implements OnInit {
	@Input() public campaign: ICampaignVM;
	@ViewChild('campaignNameInput') public campaignNameInput: UIInputComponent;
	public campaignName: string;
	private isCancelled = false;
	private isEntered = false;

	constructor(private appFeatureServices: AppFeatureServices) {}

	public ngOnInit(): void {
		this.campaignName = this.campaign.name;
	}

	public focusCampaignInput(): void {
		this.campaignNameInput.focus();
	}

	public cancelEdit(input: UIInputComponent): void {
		this.isCancelled = true;
		input.blurInput();
		this.isCancelled = false;
	}

	public pressEnter(input: UIInputComponent): void {
		this.isEntered = true;
		input.blurInput();
		this.isEntered = false;
	}

	public onNameChange(): void {
		if (this.isCancelled || !this.campaignName.length) {
			this.campaignName = this.campaign.name;
			return;
		}

		if (this.campaignName === this.campaign.name || this.isEntered) {
			return;
		}

		this.campaign = { ...this.campaign, name: this.campaignName };
		this.appFeatureServices.campaignFeature.campaign.renameCampaign(
			this.campaign,
		);
	}
}
