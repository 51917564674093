import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'expandable-item',
	templateUrl: 'expandable-item.component.html',
	styleUrls: ['./expandable-item.component.scss'],
	animations: [
		trigger('toggle', [
			transition('void => *', [
				style({ height: 0 }),
				animate('.3s ease', style({ height: '*' })),
			]),
			transition('* => void', [
				style({ height: '*' }),
				animate('.3s ease', style({ height: 0 })),
			]),
		]),
	],
	imports: [CommonModule],
	standalone: true,
})
export class ExpandableItemComponent {
	@Input() public isExpanded = false;
	@Output() public onToggle: EventEmitter<boolean> = new EventEmitter();

	public toggle(): void {
		this.isExpanded = !this.isExpanded;
		this.onToggle.emit(this.isExpanded);
	}
}
