<ng-container>
    <div
        class="placement-card"
        [ngClass]="{'fill-background': !placementMetaData.creative && !selectedCustomizedCreative?.id}">
        <facebook-placement-header
            [placementId]="placementId.FacebookAudienceNetworkNative"></facebook-placement-header>
        <div class="tail">
            <div class="headline">
                <headline
                    [headLineValue]="placementMetaData.headLineValue"
                    [placementId]="placementId.FacebookAudienceNetworkNative"></headline>
            </div>
            <primary-text
                [primaryTextValue]="placementMetaData.primaryTextValue"
                [placementId]="placementId.FacebookAudienceNetworkNative"></primary-text>
            <cta-button
                [ctaValue]="placementMetaData.callToAction"
                [placementId]="placementId.FacebookAudienceNetworkNative">
            </cta-button>
        </div>
        <div class="background">
            <ng-container
                *ngIf="selectedCustomizedCreative?.id || placementMetaData?.creative?.id || placementMetaData?.creativeUrl">
                <creative-content
                    [sound]="placementSound$ | async"
                    [creativeUrl]="placementMetaData?.creativeUrl"
                    [isCustomized]="placementMetaData?.isCustomized"
                    [creativeId]="selectedCustomizedCreative?.id || placementMetaData?.creative?.id"
                    [creativeSetId]="selectedCustomizedCreative?.setId || placementMetaData?.creative?.setId"
                    [selectedMediaType]="placementMetaData?.selectedMediaType"
                    [width]="254"
                    [height]="455"></creative-content>
            </ng-container>
        </div>
    </div>
</ng-container>
