export class ExObjectQuery {
	public folder: string = null;
	public folderOrder: any = { field: 'name', ascending: true };
	public itemOrder: any = { field: 'modified', ascending: false };
	public includeArchived: any = false;
	public users: any = new Array();
	public labels: any = new Array();
	public query: any = '';
	public localizations: any = new Array();
	public sizeFormats: any = new Array();
	public showLabels: any = false;
	public page: any = '1';
	public sorto: any = 'desc';
	public sortf: any = 'modified';
	public count: any = 50;
	public networkId: any = '';
	public includeSubFolders: any = false;
	public brand: string;
	public onlyStudio: boolean;
	public onlyActive: boolean;
}
export class ClvObjectQuery {
	public paging: Paging;
	public folderId: string;
	public filters: Filters;
	public sort: Sort;
}

export interface Paging {
	pageNumber: number;
	pageSize: number;
}

export interface Sort {
	fieldName: string;
	order: string;
}

export interface Filters {
	name: string;
}
