<ng-container>
    <app-header
        [showCampaignHeader]="true"
        [showPublishButtons]="false"
        [showAdViews]="false" />

    <div ui-theme="default" class="container">
        <div class="top">
            <ui-button
                id="interaction-back-to-ad-list"
                type="primary"
                svgIcon="arrow-left"
                [text]="'PublishLogsComponent.BACK_AD_LIST' | text"
                (click)="backToAdList()">
            </ui-button>
            <h1 class="title">{{ "PublishLogsComponent.TITLE" | text }}</h1>
        </div>
        <div class="filters">
            <div class="single">
                <ui-select
                    id="interaction-select-ad"
                    width="42rem"
                    (selectedChange)="selectedPublishLogAd($event)"
                    [selected]="(selectedPublishLogAd$ | async)"
                    placeholder="All Ads">
                    <ng-container ui-select-label>
                        <ad-log-inline
                            *ngIf="(selectedPublishLogAd$ | async) as selectedAd"
                            [adLog]="selectedAd" />
                    </ng-container>
                    <ui-option
                        class="dropdown-container"
                        *ngFor="let ad of publishLogAds$ | async"
                        [value]="ad">
                        <ad-log-inline *ngIf="ad" [adLog]="ad" />
                    </ui-option>
                </ui-select>
            </div>
            <div class="grouped">
                <ui-select
                    id="interaction-select-status"
                    width="20rem"
                    (selectedChange)="selectedPublishLogStatus($event)"
                    [selected]="(selectedPublishLogStatus$ | async)"
                    placeholder="All Statuses">
                    <ng-container ui-select-label>
                        {{ (selectedPublishLogStatus$ | async) }}
                    </ng-container>
                    <ui-option
                        *ngFor="let status of publishLogStatuses$ | async"
                        [value]="status">
                        {{ status }}
                    </ui-option>
                </ui-select>
                <ui-select
                    id="interaction-select-user"
                    width="20rem"
                    (selectedChange)="selectedPublishLogUser($event)"
                    [selected]="(selectedPublishLogUser$ | async)"
                    placeholder="All Statuses">
                    <ng-container ui-select-label>
                        {{ (selectedPublishLogUser$ | async) }}
                    </ng-container>
                    <ui-option
                        *ngFor="let user of publishLogUsers$ | async"
                        [value]="user">
                        {{ user }}
                    </ui-option>
                </ui-select>
            </div>
        </div>
        <div class="content">
            <ng-container
                *ngIf="(publishLogs$ | async) as publishLogs; else noPublishLogs">
                <div class="logs-list" *ngIf="publishLogs.length; else noPublishLogs">
                    <publish-log-item
                        class="log-item"
                        *ngFor="let log of publishLogs"
                        [publishLogItem]="log" />
                </div>
            </ng-container>
            <ng-template #noPublishLogs>
                <div class="no-logs-message">
                    {{'PublishLogsComponent.NO_LOGS' | text}}
                </div>
            </ng-template>
        </div>
    </div>
</ng-container>
