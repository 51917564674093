import {
	AfterViewInit,
	ChangeDetectorRef,
	Directive,
	OnInit,
} from '@angular/core';
import { Observable, map } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import {
	IFacebookPlacementSingleMetaDataVM,
	MediaTypeEnum,
} from 'src/app/presentation/view-models';
import { FacebookSinglePlacementsComponentStore } from '../../fb-single-placements.component.store';
import { AdContentIdEnum, FacebookSupportedPlacements } from '../enums';
import { SoundEnum } from 'src/app/presentation/view-models/shared/sound.enum';

@Directive()
export class FacebookPlacementComponentBaseDirective
	implements AfterViewInit, OnInit
{
	public forPlacementId: FacebookSupportedPlacements;
	public adContentId: typeof AdContentIdEnum = AdContentIdEnum;
	public placementId: typeof FacebookSupportedPlacements =
		FacebookSupportedPlacements;
	public mediaType: typeof MediaTypeEnum = MediaTypeEnum;
	public placementMetaData$: Observable<IFacebookPlacementSingleMetaDataVM>;
	public placementSound$: Observable<SoundEnum>;

	constructor(
		public appFeatureService: AppFeatureServices,
		forPlacementId: FacebookSupportedPlacements,
		public changeDetectorRef: ChangeDetectorRef,
		public facebookPlacementsComponentStore: FacebookSinglePlacementsComponentStore,
	) {
		this.forPlacementId = forPlacementId;
	}

	public ngOnInit(): void {
		this.placementSound$ = this.facebookPlacementsComponentStore
			.loadPlacementsSound()
			.pipe(
				map((placementsSound) => placementsSound[this.forPlacementId]),
			);
	}

	public ngAfterViewInit(): void {
		this.placementMetaData$ =
			this.facebookPlacementsComponentStore.loadPlacementMetaData(
				this.forPlacementId,
			);
		this.changeDetectorRef.detectChanges();
	}
}
