import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
	AdContentIdEnum,
	FacebookSupportedPlacements,
} from '../../../../shared/enums';
import { UIModule } from '@bannerflow/ui';
import { PrimaryTextComponent } from '../../../../shared/primary-text/primary-text.component';

@Component({
	standalone: true,
	imports: [UIModule, PrimaryTextComponent],
	selector: 'instagram-feed-footer',
	templateUrl: './instagram-feed-footer.component.html',
	styleUrls: ['./instagram-feed-footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstagramFeedFooterComponent {
	public adContentId: typeof AdContentIdEnum = AdContentIdEnum;

	@Input() public primaryTextValue: string;
	@Input() public placementId: FacebookSupportedPlacements;
}
