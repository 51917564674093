import {
	Component,
	ChangeDetectionStrategy,
	Input,
	ViewChild,
	AfterViewInit,
	ElementRef,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { SoundEnum } from 'src/app/presentation/view-models/shared/sound.enum';

@Component({
	standalone: true,
	selector: 'creative-preview',
	templateUrl: './creative-preview.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreativePreviewComponent implements AfterViewInit, OnChanges {
	@Input() public previewUrl: string;
	@Input() public sound: SoundEnum = SoundEnum.Off;
	public scriptTag: any;

	@ViewChild('container') public container: ElementRef;

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.sound && this.scriptTag) {
			if (changes.sound.currentValue === SoundEnum.On) {
				this.scriptTag.adApi.mute(false);
			} else {
				this.scriptTag.adApi.mute(true);
			}
		}
	}

	public ngAfterViewInit(): void {
		const scriptTagSourceAttributeValue = this.constructUrlFrom(
			this.previewUrl,
		);
		this.createPreviewScriptElement(scriptTagSourceAttributeValue);
		this.triggerMuteAfterRender();
	}

	private constructUrlFrom(previewUrl: string): string {
		const lastPreviewIndex = previewUrl.lastIndexOf('preview');
		if (lastPreviewIndex !== -1) {
			return `${previewUrl.substring(0, lastPreviewIndex)}ad.js${previewUrl.substring(lastPreviewIndex + 'preview'.length)}&responsive=on`;
		}
		return previewUrl;
	}

	private createPreviewScriptElement(source: string): void {
		this.scriptTag = document.createElement('script');
		this.scriptTag.type = 'text/javascript';
		this.scriptTag.src = source;
		this.container.nativeElement.appendChild(this.scriptTag);
	}

	private triggerMuteAfterRender(): void {
		this.scriptTag.addEventListener('render', () => {
			this.scriptTag.adApi.mute(true);
		});
	}
}
