import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ConfigService } from '../../config/config.service';
import { IAdStatus } from '../../models/ad/ad/ad-status.model';
import { IAdsToPublish } from '../../models/ad/ad/ad-to-publish.model';
import { IPublishLogEntry } from '../../models/publish/publish-log-entry';
import { ApiService } from '../internal/api.service';
import { AdScheduleApiService } from './services/ad-schedule-api.service';

@Injectable({
	providedIn: 'root',
})
export class SocialPublishApiService {
	public baseUrl = `${ConfigService?.config?.SPS_URL}/api`;

	constructor(
		private http: HttpClient,
		public adScheduleApi: AdScheduleApiService,
	) {}

	/**
	 * Publish ads
	 * @param ads
	 */
	public publishAds(ads: IAdsToPublish): Observable<void | object> {
		const url = `${this.baseUrl}/publish`;

		return this.http
			.post(url, ads)
			.pipe(catchError(ApiService.handleError));
	}

	/**
	 * Get ad status
	 * @param adId
	 * @param integrationId
	 */
	public getAdStatus(
		adId: string,
		integrationId: string,
	): Observable<IAdStatus[]> {
		const url = `${this.baseUrl}/ads/${adId}/publish-status`;
		const params = new HttpParams().set('integrationId', integrationId);

		return this.http.get<IAdStatus>(url, { params }).pipe(
			map((response) => [
				{
					...response,
					id: adId,
					network: response.network?.toLowerCase(),
				},
			]),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Get publish logs
	 * @param adId
	 */
	public getPublishLogs(adId: string): Observable<IPublishLogEntry[]> {
		const url = `${this.baseUrl}/ads/${adId}/publish-logs`;

		return this.http.get<{ publishLogs: IPublishLogEntry[] }>(url).pipe(
			map((response) =>
				response.publishLogs.map((publishLog) => ({
					...publishLog,
					adId: adId,
				})),
			),
			catchError(ApiService.handleError),
		);
	}
}
