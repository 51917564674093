import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { SOCIAL_SLICE } from 'src/app/state/app.state';
import { socialReducer } from './states/app-state/common-feature.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SocialEffects } from './states/app-state/common-feature.effects';
import { CommonFeatureService } from './common-feature.service';

@NgModule({
	imports: [
		StoreModule.forFeature(SOCIAL_SLICE, socialReducer),
		EffectsModule.forFeature([SocialEffects]),
	],
	declarations: [],
	providers: [CommonFeatureService],
})
export class CommonFeatureModule {}
