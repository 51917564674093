import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UIModule } from '@bannerflow/ui';

@Component({
	selector: 'carousel-dots',
	standalone: true,
	imports: [CommonModule, UIModule],
	templateUrl: './carousel-dots.component.html',
	styleUrls: ['./carousel-dots.component.scss'],
})
export class CarouselDotsComponent {
	@Input({ required: true }) public currentIndex: number;
	@Input({ required: true }) public cardsLength: number;
}
