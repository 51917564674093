import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { Observable } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { IBrandVM } from 'src/app/presentation/view-models';
import { FbMenuDotsIconComponent } from '../../../../../placement-elements/menu-dots-icon/fb-menu-dots-icon.component';

@Component({
	selector: 'fb-instagram-explore-home-placement-header',
	templateUrl: './placement-header.component.html',
	styleUrls: ['./placement-header.component.scss'],
	standalone: true,
	imports: [CommonModule, TextPipe, UIModule, FbMenuDotsIconComponent],
})
export class FbInstagramExploreHomePlacementHeaderComponent {
	@Input() public hasCloseCross?: boolean;
	public brand$: Observable<IBrandVM>;

	constructor(private appFeatureServices: AppFeatureServices) {
		this.brand$ = this.appFeatureServices.accountFeature.brand.load();
	}
}
