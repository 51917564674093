<ng-container>
    <div class="placement-card">
        <div class="creative-content">
            <ng-container
                *ngIf="selectedCustomizedCreative?.id || placementMetaData?.creative?.id || placementMetaData?.creativeUrl">
                <creative-content
                    [sound]="placementSound$ | async"
                    [creativeUrl]="placementMetaData?.creativeUrl"
                    [isCustomized]="placementMetaData?.isCustomized"
                    [creativeId]="selectedCustomizedCreative?.id || placementMetaData?.creative?.id"
                    [creativeSetId]="selectedCustomizedCreative?.setId || placementMetaData?.creative?.setId"
                    [selectedMediaType]="placementMetaData?.selectedMediaType" />
            </ng-container>
        </div>
        <facebook-placement-header [placementId]="placementId.FacebookMarketPlace" />

        <div class="headline">
            <headline
                [headLineValue]="placementMetaData.headLineValue"
                [placementId]="placementId.FacebookMarketPlace"
                [twoDefaultLines]="true" />
        </div>
    </div>
</ng-container>
