import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SocialCampaignPageStore } from './scm-page.component.store';
import { RouteView } from './view-models';
import { UINotificationService } from '@bannerflow/ui';
import { AppFeatureServices } from '../features/app-feature.service';

@Component({
	selector: 'app-campaign-page',
	templateUrl: './scm-page.component.html',
	styleUrls: ['./scm-page.component.scss'],
	providers: [SocialCampaignPageStore],
})
export class SocialCampaignPageComponent {
	public showHeader = true;
	public routeView: string;
	public isAdsSelected$: Observable<boolean>;

	constructor(
		private router: Router,
		private socialCampaignPageStore: SocialCampaignPageStore,
		private notificationService: UINotificationService,
		private appFeatureServices: AppFeatureServices,
	) {
		this.onNavigationEnd(this.router.url);

		this.isAdsSelected$ = this.socialCampaignPageStore
			.loadNumberOfSelectedAds()
			.pipe(map((selectedAdNumber) => selectedAdNumber > 0));

		this.router.events
			.pipe(filter((e) => e instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.onNavigationEnd(event.url);
			});

		this.setupNotificationSub();
	}

	/**
	 * Catch route url changes
	 * @param navigationEvent
	 */
	private onNavigationEnd(url: string): void {
		if (url.indexOf('list') > -1) {
			if (url.indexOf('creativeset') > -1) {
				this.routeView = RouteView.CreativeSet;
				return;
			}
			this.routeView = RouteView.AdList;
		} else if (url.indexOf('preview') > -1) {
			this.routeView = RouteView.AdPreview;
		} else if (url.indexOf('editor') > -1) {
			this.routeView = RouteView.AdEditor;
		} else if (url.indexOf('publish') > -1) {
			this.routeView = RouteView.Publish;
		}
	}

	private setupNotificationSub(): void {
		this.appFeatureServices.commonFeature.common
			.appNotifications()
			.subscribe((notification) => {
				if (notification && notification?.message) {
					this.notificationService.open(`${notification?.message}`, {
						type: notification.type,
						autoCloseDelay: 5000,
						placement: 'top',
						width: notification.width || 31.6,
					});
				}
			});
	}
}
