import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ConfigService } from 'src/app/core/config/config.service';
import { IFacebookAdAccount } from 'src/app/core/models';
import { ApiService } from '../../internal/api.service';

@Injectable({
	providedIn: 'root',
})
export class FacebookAccountApiService extends BaseApiService {
	constructor(public http: HttpClient) {
		super(http, `${ConfigService?.config?.SAS_URL}/api`);
	}

	/**
	 * Gets ad accounts for a given integration
	 */
	public getFacebookAdAccounts(
		integrationId: string,
	): Observable<IFacebookAdAccount[]> {
		const endpoint = `integrations`;
		const url = `${this.baseUrl}/${endpoint}/${integrationId}/ad-accounts`;

		return this.http.get<{ adAccounts: IFacebookAdAccount[] }>(url).pipe(
			map((response) =>
				response.adAccounts.map((adAccount) => ({
					...adAccount,
					integrationId: integrationId,
				})),
			),
			catchError(ApiService.handleError),
		);
	}
}
