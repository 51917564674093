<ng-container *ngIf="placementMetaData$ | async as placementMetaData">
    <div class="placement" data-cy="tt-pangle-placements">
        <div class="placement-creative">
            <creative-content
                *ngIf="placementMetaData.creative?.id || placementMetaData?.creativeUrl"
                [sound]="placementSound$ | async"
                [creativeSetId]="placementMetaData?.creative?.setId"
                [creativeId]="placementMetaData?.creative?.id"
                [creativeUrl]="placementMetaData.creativeUrl"
                [selectedMediaType]="placementMetaData.creative.renderingOption" />

            <div class="labels">
                <div class="labels-item">Feedback</div>
                <div class="labels-item">9s</div>
            </div>

            <div class="ad-label">
                <ui-svg-icon icon="pangle" />
                AD
            </div>
        </div>
        <div class="placement-content">
            <tt-brand-logo />
            <div>
                <tt-brand-name />
                <div class="primary-text">
                    <ng-container *ngIf="placementMetaData.textValue; else textSkeleton">
                        {{ placementMetaData.textValue }}
                    </ng-container>
                    <ng-template #textSkeleton>
                        <div class="primary-text-skeleton">
                            <div class="line"></div>
                            <div class="line"></div>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div class="cta">
                <tt-cta [ctaValue]="placementMetaData.callToAction" />
            </div>
        </div>
    </div>
</ng-container>
