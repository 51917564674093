import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { TextPipe } from '../shared/pipes/text.pipe';
import { PublishLogsComponent } from './publish-logs.component';
import { PublishLogsRoutingModule } from './publish-logs.routing.module';
import { HeaderComponent } from '../../features/layout/header/header.component';
import { PublishLogItemComponent } from '../../features/publish/publish-log-item/publish-log-item.component';
import { AdLogInlineComponent } from '../../features/publish/publish-log-item/ad-log-inline/ad-log-inline.component';

@NgModule({
	imports: [
		UIModule,
		PublishLogsRoutingModule,
		CommonModule,
		TextPipe,
		AdLogInlineComponent,
		PublishLogItemComponent,
		HeaderComponent,
	],
	declarations: [PublishLogsComponent],
})
export class PublishLogsModule {}
