import { Component, Input } from '@angular/core';
import { CreativeDeactivationWarningComponent } from '../../../studio/creative-deactivation-warning/creative-deactivation-warning.component';
import { IAssignedCreativeVM } from 'src/app/presentation/view-models';

@Component({
	selector: 'placement-header',
	styleUrls: ['./placement-header.component.scss'],
	templateUrl: './placement-header.component.html',
	standalone: true,
	imports: [CreativeDeactivationWarningComponent],
})
export class PlacementHeaderComponent {
	@Input() public assignedCreative: IAssignedCreativeVM;
}
