import { Injectable } from '@angular/core';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import {
	IAdGroupVM,
	IPlacementOptionsVM,
} from 'src/app/presentation/view-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FacebookPlacementMappersService } from '../../mappers/fb-placement.mappers';

@Injectable({
	providedIn: 'root',
})
export class FacebookCarouselPlacementService {
	constructor(
		private appEntityServices: AppEntityServices,
		private facebookPlacementMappers: FacebookPlacementMappersService,
	) {}

	public getPlacements(): void {
		this.appEntityServices.facebookEntity.carouselPlacement.getAll();
	}

	public loadPlacements(): Observable<IPlacementOptionsVM[]> {
		return this.appEntityServices.facebookEntity.carouselPlacement.entities$.pipe(
			map((placements) =>
				this.facebookPlacementMappers.mapPlacementsWithRenderingOptions(
					placements,
				),
			),
		);
	}

	/**
	 * Load placements by ad format id that exist in the ad group
	 */
	public loadSupportedPlacements(
		ids: IAdGroupVM['supportedPlacementsIds'],
	): Observable<IPlacementOptionsVM[]> {
		return this.loadPlacements().pipe(
			map((placements) =>
				placements.filter((placement) => ids?.includes(placement.id)),
			),
		);
	}
}
