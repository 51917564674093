import { Component } from '@angular/core';

import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { FacebookPlacementCoreBaseDirective } from '../../../../shared/directives/facebook-placement-core-base.directive';
import { FacebookSupportedPlacements } from '../../../../shared/enums';
import { FacebookPlacementHeaderComponent } from '../../../../shared/placement-header/fb-placement-header.component';
import { CommonModule } from '@angular/common';
import { CreativeContentComponent } from '../../../../../../../../elements/creative-content/creative-content.component';
import { PrimaryTextComponent } from '../../../../shared/primary-text/primary-text.component';
import { StoriesFooterComponent } from '../../../shared/stories-footer/stories-footer.component';

@Component({
	standalone: true,
	imports: [
		FacebookPlacementHeaderComponent,
		CommonModule,
		CreativeContentComponent,
		PrimaryTextComponent,
		StoriesFooterComponent,
	],
	selector: 'facebook-stories-placement-core',
	templateUrl: './fb-stories-placement-core.component.html',
	styleUrls: ['./fb-stories-placement-core.component.scss'],
})
export class FacebookStoriesPlacementCoreComponent extends FacebookPlacementCoreBaseDirective {
	constructor(public appFeatureServices: AppFeatureServices) {
		super(appFeatureServices, FacebookSupportedPlacements.FacebookStories);
	}
}
