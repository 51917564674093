import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { ConfigService } from 'src/app/core/config/config.service';
import { ApiService } from '../../../internal/api.service';
import { ITiktokAdFormat } from 'src/app/core/models/tiktok/tt-ad-format.model';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from 'src/app/core/base-api-service';

@Injectable({
	providedIn: 'root',
})
export class TiktokAdFormatApiService extends BaseApiService {
	constructor(public http: HttpClient) {
		super(http, `${ConfigService?.config?.SCS_URL}/api/ad-formats`);
	}

	/**
	 * Gets adFormats based on titok networkId
	 */
	public getAdFormats(): Observable<ITiktokAdFormat[]> {
		const networkId = 'tiktok';
		const url = `${this.baseUrl}?networkId=${networkId}`;

		return this.http.get<ITiktokAdFormat[]>(url).pipe(
			map((tiktokAdFormats) => tiktokAdFormats),
			catchError(ApiService.handleError),
		);
	}
}
