<ng-container>
    <div class="logo-dimensions" [ngClass]="{'small': isSmall}">
        <ng-container *ngIf="brand?.logoUrl; else fallback">
            <div
                class="logo logo-dimensions"
                [ngClass]="{'small': isSmall}"
                [ngStyle]="{'background-image': 'url('+brand?.logoUrl+')'}"></div>
        </ng-container>

        <ng-template #fallback>
            <div class="logo logo-dimensions fallback" [ngClass]="{'small': isSmall}">
                {{ shortBrandName }}
            </div>
        </ng-template>
    </div>
</ng-container>
