import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UIModule } from '@bannerflow/ui';

@Component({
	selector: 'fb-instagram-feed-social-bar',
	templateUrl: './instagram-feed-social-bar.component.html',
	styleUrls: ['./instagram-feed-social-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, UIModule],
})
export class FbSocialBarComponent {}
