import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { ConnectionStatusEnum } from 'src/app/core/models/account/integration/connection-status.enum';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import {
	IIntegrationConnectionStatusVM,
	NetworkEnum,
} from 'src/app/presentation/view-models';
import { IntegrationMappersService } from '../../mappers';
import { IIntegration } from 'src/app/core/models';

@Injectable({
	providedIn: 'root',
})
export class IntegrationService {
	constructor(
		public appEntityServices: AppEntityServices,
		public integrationMapper: IntegrationMappersService,
	) {}

	public getAll(): void {
		this.appEntityServices.accountEntity.integration.getAll();
	}

	public getConnectionStatus(integrationId: string): void {
		this.appEntityServices.accountEntity.integrationConnectionStatus.getByKey(
			integrationId,
		);
	}

	public isLoadingIntegrations(): Observable<boolean> {
		return this.appEntityServices.accountEntity.integration.loading$;
	}

	public loadAllByNetworkId(networkId: NetworkEnum): any {
		const integrations$: Observable<IIntegration[]> =
			this.appEntityServices.accountEntity.integration.entities$;

		return integrations$.pipe(
			filter((integrations) => !!integrations),
			map((integrations) =>
				this.integrationMapper.toIntegrationsVM(
					integrations.filter(
						(integration) => integration.networkId === networkId,
					),
				),
			),
		);
	}

	public loadConnectionStatus(
		integrationId: string,
	): Observable<IIntegrationConnectionStatusVM> {
		const statuses$ =
			this.appEntityServices.accountEntity.integrationConnectionStatus
				.entities$;
		return statuses$.pipe(
			map((statuses) =>
				statuses.find((status) => status.id === integrationId),
			),
			filter((status) => !!status),
			map((status) =>
				this.integrationMapper.toIntegrationConnectionStatusVM(status),
			),
		);
	}

	public loadIsIntegrationValid(integrationId: string): Observable<boolean> {
		const status$ = this.loadConnectionStatus(integrationId);
		return status$.pipe(
			map(
				(status) =>
					status?.connectionStatus === ConnectionStatusEnum.Connected,
			),
		);
	}
}
