import {
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SocialCampaignFeatureService } from './social-campaign-feature.service';
@NgModule({
	declarations: [],
	imports: [],
	providers: [
		SocialCampaignFeatureService,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class SocialCampaignFeatureModule {}
