<ng-container>
    <div class="placement-card">
        <div class="content">
            <div class="background">
                <ng-container
                    *ngIf="selectedCustomizedCreative?.id || placementMetaData?.creative?.id || placementMetaData?.creativeUrl">
                    <creative-content
                        [sound]="placementSound$ | async"
                        [creativeUrl]="placementMetaData?.creativeUrl"
                        [isCustomized]="placementMetaData?.isCustomized"
                        [creativeId]="selectedCustomizedCreative?.id || placementMetaData?.creative?.id"
                        [creativeSetId]="selectedCustomizedCreative?.setId || placementMetaData?.creative?.setId"
                        [selectedMediaType]="placementMetaData?.selectedMediaType"
                        [fitToHeight]="true"
                        [width]="254"
                        [height]="455"></creative-content>
                </ng-container>
            </div>

            <div class="reels-footer">
                <facebook-placement-header [placementId]="placementId.FacebookReels">
                </facebook-placement-header>
                <primary-text
                    [primaryTextValue]="placementMetaData.primaryTextValue"
                    [placementId]="placementId.FacebookReels"></primary-text>
                <cta-button
                    [ctaValue]="placementMetaData.callToAction"
                    [placementId]="placementId.FacebookReels"></cta-button>
            </div>
            <reels-icons [placementId]="placementId.FacebookReels"></reels-icons>
        </div>
    </div>
</ng-container>
