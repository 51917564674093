import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import {
	IPublishLogItemVM,
	NetworkEnum,
} from 'src/app/presentation/view-models';
import { LanguageItemComponent } from '../../../account/language-item/language-item.component';
import { PreviewPrimaryTextsComponent } from '../../../studio/preview-primary-texts/preview-primary-texts.component';

@Component({
	selector: 'log-summary',
	templateUrl: './log-summary.component.html',
	styleUrls: ['./log-summary.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		UIModule,
		TextPipe,
		LanguageItemComponent,
		PreviewPrimaryTextsComponent,
	],
})
export class LogSummaryComponent {
	@Input() public isExpanded: boolean;
	@Input() public publishLogItem: IPublishLogItemVM;

	public textTitleDictionary = {
		[NetworkEnum.facebook]: 'Primary text',
		[NetworkEnum.tiktok]: 'Text',
		[NetworkEnum.linkedin]: 'Introductory text',
		[NetworkEnum.default]: 'Text',
	};
}
