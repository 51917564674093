import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	AdFormatEnum,
	IFacebookCarouselPlacementsMetaDataMap,
	IFacebookSinglePlacementsMetaDataMap,
	IPublishLogItemVM,
} from '../../../../../view-models';
import { FacebookSinglePlacementsComponent } from '../../placement/single/placements/fb-single-placements.component';
import { FacebookCarouselPlacementsComponent } from '../../placement/carousel/placements/fb-carousel-placements.component';
import { FbSinglePlacementMetaDataMappersService } from '../../placement/single/placements/services/fb-placement-meta.mappers';
import { FbCarouselPlacementMappersService } from '../../placement/carousel/placements/services/fb-carousel-placement.mappers';
import { Observable, of } from 'rxjs';

@Component({
	selector: 'fb-ad-placements-log',
	templateUrl: './fb-ad-placements-log.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		FacebookSinglePlacementsComponent,
		FacebookCarouselPlacementsComponent,
	],
})
export class FacebookAdPlacementsLogComponent implements OnInit {
	@Input() public publishLogItem: IPublishLogItemVM | undefined;
	public adFormatEnum: typeof AdFormatEnum = AdFormatEnum;

	public placementsSingleMetaData$: Observable<IFacebookSinglePlacementsMetaDataMap>;
	public placementsCarouselMetaData$: Observable<IFacebookCarouselPlacementsMetaDataMap>;

	constructor(
		private facebookSinglePlacementsMapper: FbSinglePlacementMetaDataMappersService,
		private facebookCarouselPlacementsMapper: FbCarouselPlacementMappersService,
	) {}

	public ngOnInit(): void {
		if (
			this.publishLogItem.adFormat.id ===
			this.adFormatEnum.singleImageAndVideo
		) {
			this.placementsSingleMetaData$ = of(
				this.facebookSinglePlacementsMapper.fromAdLogtoPlacementsMetaData(
					this.publishLogItem,
				),
			);
		} else {
			this.placementsCarouselMetaData$ = of(
				this.facebookCarouselPlacementsMapper.fromAdLogtoPlacementsMetaData(
					this.publishLogItem,
				),
			);
		}
	}
}
