import { Injectable } from '@angular/core';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import {
	IAdContentTemplateVM,
	ITiktokPlacementVM,
} from 'src/app/presentation/view-models';
import { Observable, switchMap } from 'rxjs';
import { TiktokAdMappersService } from '../../mappers/ad-mapper/tt-ad.mapper';
import { TiktokPlacementService } from '../../../placement/services/tt-placement.service';
import { AdPlacementTemplateService } from 'src/app/features/ad/services/ad-placement-template/ad-placement-template.service';

@Injectable({
	providedIn: 'root',
})
export class TiktokAdContentTemplatesService {
	constructor(
		public appEntityServices: AppEntityServices,
		public ttAdMapper: TiktokAdMappersService,
		public tiktokPlacementService: TiktokPlacementService,
		public adPlacementTemplateService: AdPlacementTemplateService,
	) {}

	public loadAdContentTemplatesByAdId(
		adId: string,
	): Observable<IAdContentTemplateVM[]> {
		const adPlacementTemplates$: Observable<ITiktokPlacementVM[]> =
			this.tiktokPlacementService.loadPlacementsByAdId(adId);

		return adPlacementTemplates$.pipe(
			switchMap((adPlacementTemplates) =>
				this.tiktokPlacementService.loadCombinedContentTemplates(
					adPlacementTemplates,
				),
			),
		);
	}

	public loadAdContentTemplatesByAdsIds(
		adsIds: string[],
	): Observable<IAdContentTemplateVM[]> {
		const placements$: Observable<ITiktokPlacementVM[]> =
			this.adPlacementTemplateService.loadCombinedPlacementsByAdsIds(
				adsIds,
			);

		return placements$.pipe(
			switchMap((placements) =>
				this.tiktokPlacementService.loadCombinedContentTemplates(
					placements,
				),
			),
		);
	}
}
