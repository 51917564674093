import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'ads-on-fb-reels-footer',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './ads-on-fb-reels-footer.component.html',
	styleUrls: ['./ads-on-fb-reels-footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdsOnFacebookReelsFooterComponent {}
