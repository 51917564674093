import { Component, Input } from '@angular/core';

@Component({
	selector: 'slider-arrow-navigation',
	standalone: true,
	template: ` <div class="arrow" [class.left]="direction === 'left'">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="26"
			height="26"
			viewBox="0 0 26 26"
			fill="none"
		>
			<g>
				<path
					d="M9.51472 4.29289C9.12419 3.90237 8.49103 3.90237 8.1005 4.29289C7.70998 4.68342 7.70998 5.31658 8.1005 5.70711L15.1716 12.7782L8.1005 19.8492C7.70998 20.2398 7.70998 20.8729 8.1005 21.2635C8.49103 21.654 9.12419 21.654 9.51472 21.2635L18 12.7782L9.51472 4.29289Z"
					fill="white"
				/>
			</g>
		</svg>
	</div>`,
	styleUrls: ['./arrow-navigation.component.scss'],
})
export class SliderArrowNavigationComponent {
	@Input() public direction: 'left' | 'right' = 'left';
}
