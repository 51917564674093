import { Injectable } from '@angular/core';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import {
	IAdPlacementCustomizationVM,
	IAllowedValueVM,
	IFacebookAdVM,
	IFacebookPlacementSingleMetaDataVM,
	IFacebookPlacementVM,
	IPublishLogItemVM,
	MediaTypeEnum,
} from 'src/app/presentation/view-models';
import { AdContentIdEnum } from '../shared/enums';
import { IFacebookSinglePlacementsMetaDataMap } from '../../../../../../../view-models/facebook/placement/fb-placement-metadata.vm';
import { IAdContentDictionary } from 'src/app/core/models';
import { PlacementTargetUrlService } from '../../../services/placement-target-url.service';

@Injectable({
	providedIn: 'root',
})
export class FbSinglePlacementMetaDataMappersService {
	public adContentId: typeof AdContentIdEnum = AdContentIdEnum;

	constructor(
		private appFeatureService: AppFeatureServices,
		private placementTargetUrlService: PlacementTargetUrlService,
	) {}

	public fromAdLogtoPlacementsMetaData(
		adLog: IPublishLogItemVM,
	): IFacebookSinglePlacementsMetaDataMap {
		const placementsIds = adLog.placements.map((placement) => placement.id);

		return placementsIds.reduce<IFacebookSinglePlacementsMetaDataMap>(
			(
				result: IFacebookSinglePlacementsMetaDataMap,
				placementId: string,
			) => {
				result[placementId] = this.mapFromPublishLog(
					adLog,
					placementId,
				);
				return result;
			},
			{},
		);
	}

	public fromAdToPlacementsMetaData(
		ad: IFacebookAdVM,
	): IFacebookSinglePlacementsMetaDataMap {
		const placementsIds = ad.placements.map(
			(placement) => placement.placementId,
		);

		return placementsIds.reduce<IFacebookSinglePlacementsMetaDataMap>(
			(
				result: IFacebookSinglePlacementsMetaDataMap,
				placementId: string,
			) => {
				result[placementId] = ad.placements.find(
					(placement) => placement.placementId === placementId,
				).isCustomized
					? this.mapFromCustomizedAd(ad, placementId)
					: this.mapFromAd(ad, placementId);
				return result;
			},
			{},
		);
	}

	private mapFromPublishLog(
		publishLogItem: IPublishLogItemVM,
		placementId: string,
	): IFacebookPlacementSingleMetaDataVM {
		const logPlacement = publishLogItem?.placements?.find(
			(placement) => placement.id === placementId,
		);

		return {
			primaryTextValue: logPlacement.content[AdContentIdEnum.primaryTexts]
				? logPlacement.content[AdContentIdEnum.primaryTexts][0]
				: logPlacement.content[AdContentIdEnum.primaryText],
			displayLinkValue: logPlacement.content[AdContentIdEnum.displayLink],
			headLineValue: logPlacement.content[AdContentIdEnum.headlines]
				? logPlacement.content[AdContentIdEnum.headlines][0]
				: logPlacement.content[AdContentIdEnum.headline],
			descriptionValue: logPlacement.content[AdContentIdEnum.description],
			callToAction: {
				id: logPlacement.content[AdContentIdEnum.callToAction],
				name: logPlacement.content[AdContentIdEnum.callToAction],
			},
			websiteUrlValue: logPlacement.content[AdContentIdEnum.websiteUrl],
			selectedMediaType:
				logPlacement?.renderingOptionTypeId as MediaTypeEnum,
			adId: undefined,
			adGroupId: undefined,
			placementId,
			creativeUrl: logPlacement?.creativesReference,
			isCustomized: logPlacement?.isCustomized,
			targetUrl:
				this.placementTargetUrlService.getTargetUrlFromPublishLogItem(
					placementId,
					publishLogItem,
				),
			formatId: publishLogItem?.adFormat?.id,
		};
	}

	private mapFromAd(
		ad: IFacebookAdVM,
		placementId: string,
	): IFacebookPlacementSingleMetaDataVM {
		const primaryAdContentId = this.getCorrectAdContentIdForMultipleContent(
			ad,
			placementId,
			this.adContentId.primaryTexts,
			this.adContentId.primaryText,
		);
		const headlineAdContentId =
			this.getCorrectAdContentIdForMultipleContent(
				ad,
				placementId,
				this.adContentId.headlines,
				this.adContentId.headline,
			);
		const isPlacementCustomized = ad?.placements.find(
			(placement) => placement.placementId === placementId,
		)?.isCustomized;

		const customizedMediaType = ad?.content[placementId]?.customizedContent
			? ad.content[placementId].customizedContent.renderingOptionTypeId
			: null;

		return {
			placementId,
			adId: ad?.id,
			adGroupId: ad?.adGroupId,
			formatId: ad.adFormatId,
			selectedMediaType: isPlacementCustomized
				? MediaTypeEnum[customizedMediaType]
				: ad?.mediaType,
			creative:
				this.appFeatureService.studioFeature.creativePickerService.loadAdCreativeForPlacement(
					placementId,
					ad.adCreatives,
				),
			primaryTextValue: this.getCorrectAdContentValue(
				ad.content[placementId],
				primaryAdContentId,
				ad.defaultContent,
			),
			displayLinkValue: this.getCorrectAdContentValue(
				ad.content[placementId],
				this.adContentId.displayLink,
				ad.defaultContent,
			),
			headLineValue: this.getCorrectAdContentValue(
				ad.content[placementId],
				headlineAdContentId,
				ad.defaultContent,
			),
			descriptionValue: this.getCorrectAdContentValue(
				ad.content[placementId],
				this.adContentId.description,
				ad.defaultContent,
			),
			callToAction: this.getCorrectCallToActionValue(
				ad.content[placementId],
				this.adContentId.callToAction,
				ad.defaultContent,
			),
			websiteUrlValue: this.getCorrectAdContentValue(
				ad.content[placementId],
				this.adContentId.websiteUrl,
				ad.defaultContent,
			),
			isCustomized: isPlacementCustomized,
			targetUrl: this.placementTargetUrlService.getTargetUrlFromAd(
				placementId,
				ad,
			),
		};
	}

	private mapFromCustomizedAd(
		ad: IFacebookAdVM,
		placementId: string,
	): IFacebookPlacementSingleMetaDataVM {
		const primaryAdContentId = this.getCorrectAdContentIdForMultipleContent(
			ad,
			placementId,
			this.adContentId.primaryTexts,
			this.adContentId.primaryText,
		);
		const headlineAdContentId =
			this.getCorrectAdContentIdForMultipleContent(
				ad,
				placementId,
				this.adContentId.headlines,
				this.adContentId.headline,
			);

		return {
			placementId,
			adId: ad.id,
			adGroupId: ad.adGroupId,
			isCustomized: true,
			formatId: ad?.adFormatId,
			selectedMediaType: ad.content
				? (ad.content[placementId].customizedContent
						.renderingOptionTypeId as MediaTypeEnum)
				: undefined,
			creative:
				this.appFeatureService.studioFeature.creativePickerService.loadAdCreativeForPlacement(
					placementId,
					[ad.content[placementId].customizedContent.adCreative],
				) ||
				this.appFeatureService.studioFeature.creativePickerService.loadAdCreativeForPlacement(
					placementId,
					ad.adCreatives,
				),
			primaryTextValue: this.getCorrectCustomizedContentValue(
				ad.content[placementId].customizedContent,
				primaryAdContentId,
				ad.defaultContent,
			),
			displayLinkValue: this.getCorrectCustomizedContentValue(
				ad.content[placementId].customizedContent,
				this.adContentId.displayLink,
				ad.defaultContent,
			),
			headLineValue: this.getCorrectCustomizedContentValue(
				ad.content[placementId].customizedContent,
				headlineAdContentId,
				ad.defaultContent,
			),
			descriptionValue: this.getCorrectAdContentValue(
				ad.content[placementId],
				this.adContentId.description,
				ad.defaultContent,
			),
			callToAction: this.getCorrectCallToActionValue(
				ad.content[placementId],
				this.adContentId.callToAction,
				ad.defaultContent,
			),
			websiteUrlValue: this.getCorrectCustomizedContentValue(
				ad.content[placementId].customizedContent,
				this.adContentId.websiteUrl,
				ad.defaultContent,
			),
			targetUrl: this.placementTargetUrlService.getTargetUrlFromAd(
				placementId,
				ad,
			),
		};
	}

	private getCorrectAdContentIdForMultipleContent(
		ad: IFacebookAdVM,
		placementId: string,
		multipleContentId: AdContentIdEnum,
		singleContentId: AdContentIdEnum,
	): AdContentIdEnum {
		const adPlacementVM = ad.content[placementId];

		if (adPlacementVM.adContentTemplates) {
			const multipleTemplate = adPlacementVM.adContentTemplates.find(
				(content) => content.id === multipleContentId,
			);

			return multipleTemplate ? multipleContentId : singleContentId;
		}

		return ad.defaultContent.hasOwnProperty(multipleContentId)
			? multipleContentId
			: singleContentId;
	}

	private getCorrectAdContentValue(
		adPlacementVM: IFacebookPlacementVM,
		adContentId: string,
		defaultContent: IAdContentDictionary,
	): string {
		if (!adPlacementVM) {
			return '';
		}

		const template = adPlacementVM.adContentTemplates?.find(
			(content) => content.id === adContentId,
		);

		if (template) {
			return this.mapContentValue(template.value);
		} else {
			return this.mapContentValue(defaultContent[adContentId]);
		}
	}

	private getCorrectCustomizedContentValue(
		customizedVM: IAdPlacementCustomizationVM,
		adContentId: string,
		defaultContent: IAdContentDictionary,
	): string {
		let customziedContentValue = '';

		if (customizedVM.content[adContentId]) {
			customziedContentValue = Array.isArray(
				customizedVM.content[adContentId],
			)
				? customizedVM.content[adContentId][0]
				: customizedVM.content[adContentId];
		} else {
			customziedContentValue = defaultContent[adContentId];
		}

		return customziedContentValue;
	}

	private mapContentValue(templateValue: any): string {
		if (!Array.isArray(templateValue)) {
			return templateValue;
		}

		if (templateValue[0]?.hasOwnProperty('isActive')) {
			return templateValue.find((value) => value.isActive)?.value;
		}

		return templateValue[0];
	}

	private getCorrectCallToActionValue(
		adPlacementVM: IFacebookPlacementVM,
		adContentId: string,
		defaultContent: IAdContentDictionary,
	): IAllowedValueVM {
		if (!adPlacementVM) {
			return null;
		}

		if (adPlacementVM.adContentTemplates) {
			return adPlacementVM.adContentTemplates.find(
				(content) => content.id === adContentId,
			)?.selectedValue;
		} else if (defaultContent) {
			return {
				id: defaultContent[adContentId],
				name: defaultContent[adContentId],
			};
		}
	}
}
