<ng-container *ngIf="isCustomized; else emptyBlock">
    <ui-svg-icon
        class="customize-icon"
        [ngClass]="{'in-active': inActive}"
        [fill]="fill"
        icon="customized"
        uiTooltipPosition="right"
        uiTooltipTrigger="hover"
        [uiTooltipInteractive]="true"
        [uiTooltip]="'PlacementTitleComponent.CUSTOMIZED_TOOLTIP_CONTENT' | text">
    </ui-svg-icon>
</ng-container>

<ng-template #emptyBlock>
    <span class="empty-block"></span>
</ng-template>
