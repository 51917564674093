import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AD_LOG_ENTITY } from 'src/app/state/app.state';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { IAdLog } from 'src/app/core/models/ad/ad/ad-log.model';

@Injectable()
export class AdLogDataService extends DefaultDataService<IAdLog> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(AD_LOG_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: { campaignId: string }): Observable<IAdLog[]> {
		return this.appCoreServices.socialCampaignApiService.getAdsByCampaignId(
			params['campaignId'],
		);
	}
}
