import {
	ChangeDetectionStrategy,
	Component,
	Input,
	Output,
	EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { LetDirective } from '@ngrx/component';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { DisplayUniquePropertyPipe } from 'src/app/presentation/pages/shared/pipes/display-unique-property.pipe';
import { LabelTransformPipe } from './transform-collection-label.pipe';
import { FindPipe } from 'src/app/presentation/pages/shared/pipes/find.pipe';
import { AdContentIdEnum } from '../placements/shared/enums';
import {
	CollectionElementVM,
	IAdContentTemplateVM,
	IAdContentValidationVM,
} from 'src/app/presentation/view-models';
import { IMixedAdDefaultContentVM } from 'src/app/presentation/view-models/ad/ad-mixed-content.vm';
import { ValidationResultComponent } from 'src/app/presentation/pages/shared/components/validation-result/validation-result.component';
import { ExpandableItemComponent } from 'src/app/presentation/pages/shared/components/expandable-item/expandable-item.component';

export interface FocusedInput {
	[id: string]: boolean;
}
export interface InputCount {
	[id: string]: number;
}

@Component({
	selector: 'collection-content',
	templateUrl: './collection-content.component.html',
	styleUrls: ['./collection-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		UIModule,
		FindPipe,
		TextPipe,
		LetDirective,
		ValidationResultComponent,
		DisplayUniquePropertyPipe,
		ExpandableItemComponent,
		LabelTransformPipe,
	],
})
export class CollectionContentComponent {
	@Input() public isAdDirty: boolean;
	@Input() public adContentTemplate: IAdContentTemplateVM;
	@Input() public adContentValidation: IAdContentValidationVM;
	@Input() public mixedContent: IMixedAdDefaultContentVM;
	@Output() public onCollectionChanged = new EventEmitter<
		CollectionElementVM[]
	>();
	public minNumberOfElements = 1;
	public adContentId: typeof AdContentIdEnum = AdContentIdEnum;
	public focusedInput: FocusedInput = {};
	public isExpanded: boolean;

	public addContent(): void {
		const collectionElement: CollectionElementVM = {
			value: '',
			isActive: false,
		};

		this.adContentTemplate.value.push(collectionElement);
		this.focusedInput[this.adContentTemplate.value.length] = true;
		this.onCollectionChanged.emit(this.adContentTemplate.value);
	}

	public deleteContentByIndex(index: number): void {
		this.adContentTemplate.value.splice(index, 1);

		const isAnyActive = this.adContentTemplate.value.some(
			({ isActive }) => isActive,
		);

		if (!isAnyActive) {
			this.adContentTemplate.value[0].isActive = true;
		}

		this.onCollectionChanged.emit(this.adContentTemplate.value);
	}

	public toggleVisibility(index: number): void {
		this.adContentTemplate.value.forEach((item, i) => {
			item.isActive = i === index;
		});
		this.onCollectionChanged.emit(this.adContentTemplate.value);
	}

	public onToggle(isExpanded: boolean): void {
		this.isExpanded = isExpanded;
	}

	public onKeyUpChange(event: Event, index: number): void {
		const value = (event.target as HTMLInputElement | HTMLTextAreaElement)
			?.value;

		this.adContentTemplate.value.find((_item, i) => i === index).value =
			value;
		this.onCollectionChanged.emit(this.adContentTemplate.value);
	}

	public valueTrackBy(_index: number): number {
		return _index;
	}
}
