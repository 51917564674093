<ng-container *ngIf="publishLogItem">
    <div [ngSwitch]="publishLogItem.adFormat.id">
        <div *ngSwitchCase="adFormatEnum.singleImageAndVideo">
            <fb-single-placements
                [placementsMetaData$]="placementsSingleMetaData$"
                [inPreview]="true">
            </fb-single-placements>
        </div>

        <div *ngSwitchCase="adFormatEnum.carousel">
            <fb-carousel-placements
                [placementsMetaData$]="placementsCarouselMetaData$"
                [inPreview]="true">
            </fb-carousel-placements>
        </div>
    </div>
</ng-container>
