import { Injectable } from '@angular/core';
import { EntityServicesElements, EntityServicesBase } from '@ngrx/data';

import { AccountEntityServices } from './account/entity-account.services';
import { AdEntityServices } from './ad/entity-ad.service';
import { PublishEntityServices } from './publish/entity-publish.service';
import { TiktokEntityServices } from './tiktok/entity-tiktok.service';
import { CampaignEntityServices } from './campaign/entity-campaign.service';
import { FacebookEntityServices } from './facebook/entity-facebook.service';

@Injectable()
export class AppEntityServices extends EntityServicesBase {
	constructor(
		entityServicesElements: EntityServicesElements,
		public accountEntity: AccountEntityServices,
		public adEntity: AdEntityServices,
		public publishEntity: PublishEntityServices,
		public campaignEntity: CampaignEntityServices,
		public facebookEntity: FacebookEntityServices,
		public tiktokEntity: TiktokEntityServices,
	) {
		super(entityServicesElements);
	}
}
