import { Component, Input, OnChanges } from '@angular/core';
import {
	IUIDialogConfig,
	UIDialogRef,
	UIDialogService,
	UIModule,
} from '@bannerflow/ui';
import { Observable, switchMap } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';

import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import {
	IAssignedCreativeVM,
	IAdGroupVM,
	IFacebookAdVM,
	IPlacementBaseVM,
} from 'src/app/presentation/view-models';
import { CustomizePlacementWizardComponent } from '../customize-placement.wizard';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	imports: [CommonModule, UIModule, TextPipe],
	selector: 'customize-option',
	templateUrl: 'customize-option.component.html',
	styleUrls: [],
})
export class CustomizeOptionComponent implements OnChanges {
	@Input('adId') public adId: string;
	@Input('placementId') public placementId: string;
	@Input('supportedPlacements')
	public supportedPlacements: IPlacementBaseVM[];
	public ad$: Observable<IFacebookAdVM>;
	public adGroup$: Observable<IAdGroupVM>;
	public hasCustomization$: Observable<boolean>;
	public hasAdSavedContent$: Observable<boolean>;
	public adCreative$: Observable<IAssignedCreativeVM>;

	constructor(
		private appFeatureServices: AppFeatureServices,
		private textPipe: TextPipe,
		private uiDialogService: UIDialogService,
	) {}

	public ngOnChanges(): void {
		this.ad$ =
			this.appFeatureServices.facebookFeature.ad.loadAdWithPlacementsByAdId(
				this.adId,
			);
		this.adGroup$ = this.ad$.pipe(
			switchMap((ad) =>
				this.appFeatureServices.adFeature.adGroup.loadById(
					ad?.adGroupId,
				),
			),
		);
		this.hasAdSavedContent$ =
			this.appFeatureServices.facebookFeature.ad.checkIfAdHasSavedContent(
				this.adId,
			);
		this.hasCustomization$ =
			this.appFeatureServices.facebookFeature.ad.checkIfAdHasCustomizedPlacement(
				this.adId,
				this.placementId,
			);
	}

	public async openPlacementCustomizationWizard(
		ad: IFacebookAdVM,
		adGroup: IAdGroupVM,
	): Promise<void> {
		const dialogConfig: IUIDialogConfig = {
			headerText: this.textPipe.transform(
				'CustomizePlacementWizardComponent.TITLE',
			),
			maxWidth: '1000px',
			height: '736px',
			backdropClickClose: false,
			padding: 0,
		};
		const dialogRef: UIDialogRef = this.uiDialogService.openComponent(
			CustomizePlacementWizardComponent,
			dialogConfig,
		);
		await dialogRef.afterViewInit;
		await dialogRef.subComponentRef.instance.initiate(
			ad,
			adGroup,
			this.placementId,
			this.supportedPlacements,
		);
		dialogRef.close();
	}
}
