import { Component, Input } from '@angular/core';
import { IFacebookAdVM } from 'src/app/presentation/view-models';
import { AdContentIdEnum, FacebookSupportedPlacements } from '../enums';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	imports: [CommonModule],
	selector: 'display-link',
	templateUrl: './display-link.component.html',
	styleUrls: ['./display-link.component.scss'],
})
export class DisplayLinkComponent {
	public adContentId: typeof AdContentIdEnum = AdContentIdEnum;
	@Input() public displayLinkValue: string;
	@Input() public ad: IFacebookAdVM;
	@Input() public placementId: FacebookSupportedPlacements;
}
