import { Injectable } from '@angular/core';
import { IAdFormat } from 'src/app/core/models';
import { IFacebookAdFormatVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class AdFormatsMappersService {
	public mapAdFormat(adFormat: IAdFormat): IFacebookAdFormatVM {
		return {
			id: adFormat.id,
			name: adFormat.name,
		};
	}
}
