import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UIModule } from '@bannerflow/ui';

@Component({
	selector: 'fb-stories-footer',
	templateUrl: './stories-footer.component.html',
	styleUrls: ['./stories-footer.component.scss'],
	standalone: true,
	imports: [CommonModule, UIModule],
})
export class FacebookStoriesFooterComponent {
	@Input() public ctaValue: string;
	@Input() public hasSendIcon = true;
}
