<div class="placement-container">
    <div class="content">
        <carousel-slider
            [cards]="placementMetaData?.cards"
            [cardWidth]="180"
            (indexChanged)="onSlideChange($event)">
            <ng-template let-card="card">
                <search-results-video-card [card]="card" />
            </ng-template>
        </carousel-slider>

        <div class="dots-wrapper">
            <carousel-dots
                [cardsLength]="placementMetaData.cards.length"
                [currentIndex]="currentSlide" />
        </div>
    </div>
</div>
