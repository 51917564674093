<ng-container>
    <div
        class="headline"
        [ngClass]="placementId"
        [class.expanded]="isExpanded"
        [class.collapsed]="!isExpanded">
        <div *ngIf="headLineValue; else default">
            <div class="text-info">{{ headLineValue }}</div>
        </div>
        <ng-template #default>
            <div class="text-info default-container">
                <div class="first-line"></div>
                <div *ngIf="twoDefaultLines" class="second-line"></div>
            </div>
        </ng-template>
    </div>
</ng-container>
