import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ConfigService } from 'src/app/core/config/config.service';
import {
	IAdContentValidation,
	IAdContentValidationDictionary,
	IAdContentValidationError,
	IAdContentValidationProperty,
	IAdContentValidationResponse,
	ICarouselAdContentValidation,
	IPlacementTemplate,
} from 'src/app/core/models';
import { IAdPlacementsTemplates } from 'src/app/core/models/ad/ad/ad-placements-templates.model';
import {
	AdContentTemplateScopeEnum,
	AdFormatEnum,
	ValidationTypeEnum,
} from 'src/app/presentation/view-models';
import { ApiService } from '../../internal/api.service';

@Injectable({
	providedIn: 'root',
})
export class AdPlacementTemplateApiService extends BaseApiService {
	constructor(public http: HttpClient) {
		super(http, `${ConfigService?.config?.SCS_URL}/api/ads`);
	}

	/**
	 * Get Placement Templates for Ad
	 * @param adId
	 */
	public getPlacementTemplates(
		adId: string,
	): Observable<IAdPlacementsTemplates> {
		const url = `${this.baseUrl}/${adId}/placement-templates`;

		return this.http.get<IPlacementTemplate[]>(url).pipe(
			map((placements) => ({
				id: adId,
				placementsTemplates: placements,
			})),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Validate the whole ad content
	 */
	public validateAdContent(
		adId: string,
		placementId: string,
		adFormatId: AdFormatEnum,
		adContent: string,
		cardsContents?: string,
	): Observable<IAdContentValidation[]> {
		const url = `${this.baseUrl}/${adId}/placement-templates/${placementId}/ad-content/validate`;
		const body = {
			adFormatId,
			adContent: JSON.parse(adContent),
			cardsContents: cardsContents ? JSON.parse(cardsContents) : [],
		};

		return this.http.post<IAdContentValidationResponse>(url, body).pipe(
			map((response: IAdContentValidationResponse) =>
				this.mapToAdContentValidation(response),
			),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Validate the whole carousel ad content
	 */
	public validateCarouselAdContent(
		adId: string,
		placementId: string,
		adFormatId: AdFormatEnum,
		adContent: string,
		cardsContents?: string,
	): Observable<ICarouselAdContentValidation[]> {
		const url = `${this.baseUrl}/${adId}/placement-templates/${placementId}/ad-content/validate`;
		const body = {
			adFormatId,
			adContent: JSON.parse(adContent),
			cardsContents: cardsContents ? JSON.parse(cardsContents) : [],
		};

		return this.http.post<IAdContentValidationResponse>(url, body).pipe(
			map((response: IAdContentValidationResponse) => [
				{
					id: adId,
					adContentValidation:
						this.mapToAdContentValidation(response),
				},
			]),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Validate a single property in a placement
	 */
	public validatePlacementTemplate(
		adId: string,
		placementId: string,
		adContentTemplateId: string,
		adFormatId: AdFormatEnum,
		scope?: AdContentTemplateScopeEnum,
		value?: any,
	): Observable<IAdContentValidation> {
		const url = `${this.baseUrl}/${adId}/placement-templates/${placementId}/ad-content/${adContentTemplateId}/validate`;
		const body: IAdContentValidationProperty = {
			adFormatId,
			scope,
			value,
		};

		return this.http.post<IAdContentValidationResponse>(url, body).pipe(
			map((response: IAdContentValidationResponse) =>
				this.mapToSingleTemplateValidation(
					adContentTemplateId,
					response,
				),
			),
			catchError(ApiService.handleError),
		);
	}

	private mapToSingleTemplateValidation(
		adContentTemplateId: string,
		response: IAdContentValidationResponse,
	): IAdContentValidation {
		const validationResultsDictionary = this.groupValidationResults(
			response.errors || [],
			response.warnings || [],
		);
		const emptyValidationResult: IAdContentValidation = {
			id: adContentTemplateId,
			adContentValidation: [],
		};

		return (
			validationResultsDictionary[adContentTemplateId] ||
			emptyValidationResult
		);
	}

	private mapToAdContentValidation(
		response: IAdContentValidationResponse,
	): IAdContentValidation[] {
		const validationResultsDictionary = this.groupValidationResults(
			response.errors || [],
			response.warnings || [],
		);

		return Object.values(validationResultsDictionary);
	}

	private groupValidationResults(
		errors: IAdContentValidationError[],
		warnings: IAdContentValidationError[],
	): IAdContentValidationDictionary {
		const validationResults: IAdContentValidationDictionary = {};

		const processValidation = (
			error: IAdContentValidationError,
			type: ValidationTypeEnum,
		): void => {
			const { adContentTemplateId, errors: messages, card } = error;
			const regex = /\[(\d+)\]/;
			const id = adContentTemplateId.replace(regex, '');
			const templateIndexMatch = regex.exec(adContentTemplateId);
			const index = templateIndexMatch
				? parseInt(templateIndexMatch[1], 10)
				: null;

			if (id === '') {
				return;
			}

			if (!validationResults[id]) {
				validationResults[id] = {
					id,
					adContentValidation: [],
				};
			}

			if (
				!validationResults[id].adContentValidation.some(
					(existingValidation) =>
						templateIndexMatch
							? existingValidation.index === index
							: existingValidation.card === card,
				)
			) {
				validationResults[id].adContentValidation.push({
					type,
					card,
					index,
					message: messages?.join(' '),
				});
			}
		};

		errors.forEach((error) =>
			processValidation(error, ValidationTypeEnum.error),
		);
		warnings.forEach((error) =>
			processValidation(error, ValidationTypeEnum.warning),
		);

		return validationResults;
	}
}
