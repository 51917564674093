import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';

@Component({
	selector: 'fb-instagram-explore-home-cta-button',
	templateUrl: './cta-button.component.html',
	styleUrls: ['./cta-button.component.scss'],
	standalone: true,
	imports: [CommonModule, UIModule],
})
export class FbInstagramExploreHomeCtaButtonComponent {
	@Input() public ctaValue: string;
}
