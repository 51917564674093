<ng-container *ngIf="brand$ | async as brand">
    <ng-container [ngSwitch]="placementId">
        <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookNewsFeed">
            <ng-container *ngTemplateOutlet="sharedHeader"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookInstagramFeed">
            <ng-container *ngTemplateOutlet="sharedHeader"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookVideoFeed">
            <ng-container *ngTemplateOutlet="sharedHeader"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookMarketPlace">
            <ng-container *ngTemplateOutlet="facebookMarketPlace"></ng-container>
        </ng-container>

        <ng-container
            *ngSwitchCase="facebookSupportedPlacements.FacebookInstagramExplore">
            <ng-container *ngTemplateOutlet="sharedHeader"></ng-container>
        </ng-container>

        <ng-container
            *ngSwitchCase="facebookSupportedPlacements.FacebookAudienceNetworkNative">
            <ng-container
                *ngTemplateOutlet="facebookAudienceNetworkNative"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookGroupsFeed">
            <ng-container *ngTemplateOutlet="sharedHeader"></ng-container>
        </ng-container>

        <ng-container
            *ngSwitchCase="facebookSupportedPlacements.FacebookInstagramStories">
            <ng-container *ngTemplateOutlet="sharedStoryHeader"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookInStreamVideos">
            <ng-container *ngTemplateOutlet="facebookInStreamVideos"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookMessengerInbox">
            <ng-container *ngIf="isExpanded">
                <ng-container
                    *ngTemplateOutlet="facebookMessengerInboxExpanded"></ng-container>
            </ng-container>
            <ng-container *ngIf="!isExpanded">
                <ng-container
                    *ngTemplateOutlet="facebookMessengerInboxCollapsed"></ng-container>
            </ng-container>
        </ng-container>

        <ng-container
            *ngSwitchCase="facebookSupportedPlacements.FacebookMessengerStories">
            <ng-container *ngTemplateOutlet="sharedStoryHeader"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookSearchResults">
            <ng-container *ngTemplateOutlet="facebookSearchResults"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookStories">
            <ng-container *ngTemplateOutlet="sharedStoryHeader"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookReels">
            <ng-container *ngTemplateOutlet="reelsStoryHeader"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookInstagramReels">
            <ng-container *ngTemplateOutlet="reelsStoryHeader"></ng-container>
        </ng-container>
        <ng-container
            *ngSwitchCase="facebookSupportedPlacements.FacebookInstagramExploreHome">
            <ng-container *ngTemplateOutlet="facebookInstagramExploreHome"></ng-container>
        </ng-container>
    </ng-container>

    <ng-template #sharedHeader>
        <div class="card-header" [ngClass]="placementId">
            <div class="brand-identity">
                <div class="image">
                    <brand-logo [brand]="brand"></brand-logo>
                </div>
                <div class="brand-icons-wrapper">
                    <div class="some-wrapper">
                        <div class="brand-name" [ngClass]="{'dark':dark}">
                            {{ brand?.name }}
                        </div>
                        <div class="sponsor">
                            {{"PlacementHeaderComponent.SPONSORED" | text}}
                        </div>
                    </div>
                    <div class="icon-container">
                        <div class="more-dots">...</div>
                        <ui-svg-icon
                            *ngIf="hasCloseCross"
                            class="close-icon"
                            icon="close"></ui-svg-icon>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #facebookAudienceNetworkNative>
        <div class="card-header" [ngClass]="placementId">
            <div class="close-icon">
                <ui-svg-icon icon="close"></ui-svg-icon>
            </div>
            <div class="image">
                <brand-logo [brand]="brand"></brand-logo>
            </div>
            <div class="brand-identity">
                <div class="brand-name">{{ brand?.name }}</div>
                <div class="sponsor">{{"PlacementHeaderComponent.SPONSORED" | text}}</div>
            </div>
        </div>
    </ng-template>

    <ng-template #facebookSearchResults>
        <div class="card-header" [ngClass]="placementId">
            <div class="image">
                <brand-logo [brand]="brand"></brand-logo>
            </div>

            <div class="brand-identity">
                <div class="some-wrapper">
                    <div class="brand-name">{{ brand?.name }}</div>
                </div>
                <div class="more-dots">...</div>
            </div>
        </div>
    </ng-template>

    <ng-template #sharedStoryHeader>
        <div class="card-header" [ngClass]="placementId">
            <div class="progress-bar-container">
                <div class="progress-bar"></div>
                <div class="progress-bar-progress"></div>
            </div>
            <div class="inner-card-header">
                <div class="title-container">
                    <brand-logo [brand]="brand" [isSmall]="true"></brand-logo>

                    <div class="brand-identity">
                        <div class="brand-name">{{ brand?.name }}</div>
                        <div class="sponsor">
                            {{"PlacementHeaderComponent.SPONSORED" | text}}
                        </div>
                    </div>
                </div>

                <div class="icon-container">
                    <div class="more-dots">...</div>
                    <ui-svg-icon
                        *ngIf="hasCloseCross"
                        class="close-icon"
                        icon="close"></ui-svg-icon>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #facebookMarketPlace>
        <div class="card-header" [ngClass]="placementId">
            <div class="image">
                <brand-logo [brand]="brand" [isSmall]="true"></brand-logo>
            </div>

            <div class="brand-identity">
                <div class="brand-name">{{ brand?.name }}</div>
            </div>
        </div>
    </ng-template>

    <ng-template #facebookInStreamVideos>
        <div class="card-header" [ngClass]="placementId">
            <brand-logo [brand]="brand"></brand-logo>
        </div>
    </ng-template>

    <ng-template #facebookMessengerInboxExpanded>
        <div class="content-container" [ngClass]="placementId">
            <div class="icon-header">
                <ui-svg-icon class="arrow-left" icon="arrow-left"></ui-svg-icon>

                <brand-logo [brand]="brand"></brand-logo>

                <div class="more-dots">...</div>
            </div>
            <div class="header-container">
                <div class="destination-container">
                    <div class="brand-identiy">{{ brand?.name }}</div>
                    <div class="ad">{{"PlacementHeaderComponent.AD" | text}}</div>
                </div>

                <div class="like-info">
                    <span>{{ "PlacementHeaderComponent.123_PEOPLE_LIKE" | text}}</span> {{
                    brand?.name }}
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #facebookMessengerInboxCollapsed>
        <div
            *ngIf="render === things.Logo; else brandName"
            class="image-container"
            [ngClass]="placementId">
            <brand-logo [brand]="brand"></brand-logo>
        </div>
        <ng-template #brandName>
            <div class="destination-container" [ngClass]="placementId">
                <div class="brand-name">{{ brand?.name }}</div>
                <div class="ad">{{"PlacementHeaderComponent.AD" | text}}</div>
            </div>
        </ng-template>
    </ng-template>

    <ng-template #reelsStoryHeader>
        <div class="card-header" [ngClass]="placementId">
            <div class="inner-card-header">
                <div class="title-container">
                    <brand-logo [brand]="brand" [isSmall]="true"></brand-logo>

                    <div class="social-info">
                        <div class="brand-identiy">{{ brand?.name }}</div>
                        <div class="sponsor">
                            {{"PlacementHeaderComponent.SPONSORED" | text}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #facebookInstagramExploreHome>
        <div class="card-header" [ngClass]="placementId">
            <div class="brand-sponsor">
                <span class="brand-name">{{ brand?.name }}</span>
                <span class="dot">.</span>
                <div class="sponsor">{{"PlacementHeaderComponent.SPONSORED" | text}}</div>
            </div>

            <div class="more-dots">...</div>
        </div>
    </ng-template>
</ng-container>
