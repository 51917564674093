import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';

import { AdFormatsMappersService } from '../mappers/ad-formats.mappers';
import { AdFormatsBaseService } from './ad-formats-base.service';

@Injectable({
	providedIn: 'root',
})
export class AdFormatsService extends AdFormatsBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public adFormatsMappers: AdFormatsMappersService,
	) {
		super(appEntityServices, adFormatsMappers);
	}

	public getAll(): void {
		this.appEntityServices.tiktokEntity.adFormat.getAll();
	}

	public isLoadingAdFormats(): Observable<boolean> {
		return this.appEntityServices.tiktokEntity.adFormat.loading$;
	}
}
