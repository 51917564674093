import { Injectable } from '@angular/core';
import {
	ICreativeVersion,
	ILocalization,
	IStudioCreativeSet,
} from 'src/app/core/models';
import {
	ICreativeVersionVM,
	IStudioCreativeSetVM,
} from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class StudioMappersService {
	public toStudioCreativeSetVM(
		creativeSet: IStudioCreativeSet,
		localizations: ILocalization[],
	): IStudioCreativeSetVM {
		return {
			...creativeSet,
			creatives: creativeSet.creatives.map((creative) => ({
				...creative,
				version: this.toCreativeVersionVM(
					creative?.version,
					localizations.find(
						(localizattion) =>
							localizattion?.id ===
							creative?.version?.localization.id,
					),
				),
			})),
		};
	}

	private toCreativeVersionVM(
		creativeVersion: ICreativeVersion,
		localization: ILocalization,
	): ICreativeVersionVM {
		return {
			...creativeVersion,
			localization: {
				...creativeVersion?.localization,
				name: localization?.name,
				cultureCode: localization?.culture?.cultureCode,
			},
		};
	}
}
