import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
@Pipe({
	name: 'labelTransform',
	standalone: true,
})
export class LabelTransformPipe implements PipeTransform {
	public transform(key: string): any {
		switch (key) {
			case 'Headlines': {
				return 'Headline';
			}
			case 'Primary Texts': {
				return 'Primary text';
			}
			default: {
				return key;
			}
		}
	}
}
