import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { INTEGRATION_ENTITY } from 'src/app/state/app.state';
import { IIntegration } from 'src/app/core/models';
import { AppCoreServices } from 'src/app/core/app-core-service';

@Injectable()
export class IntegrationDataService extends DefaultDataService<IIntegration> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(INTEGRATION_ENTITY, http, httpUrlGenerator);
	}

	public getAll(): Observable<IIntegration[]> {
		return this.appCoreServices.socialAccountApiService.getIntegrations();
	}
}
