import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FacebookCarouselBaseDirective } from '../../directives/fb-carousel-base.directive';
import { FacebookCarouselSupportedPlacements } from '../../enums';
import { FacebookCarouselPlacementsComponentStore } from '../../fb-carousel-placements.component.store';
import { FbMenuDotsIconComponent } from '../../../../placement-elements/menu-dots-icon/fb-menu-dots-icon.component';
import { CarouselSliderComponent } from '../../carousel-elements/carousel-slider/carousel-slider.component';
import { FacebookPlacementFooterComponent } from '../../../../placement-elements/placement-footer/fb-placement-footer.component';
import { FbPlacementHeaderComponent } from '../../../../placement-elements/placement-header/fb-placement-header.component';
import { FacebookInStreamVideosCarouselCardComponent } from './carousel-card/carousel-card.component';

@Component({
	selector: 'fb-in-stream-videos',
	templateUrl: './fb-in-stream-videos.component.html',
	styleUrls: ['./fb-in-stream-videos.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		FbMenuDotsIconComponent,
		CarouselSliderComponent,
		FacebookPlacementFooterComponent,
		FbPlacementHeaderComponent,
		FacebookInStreamVideosCarouselCardComponent,
	],
})
export class FacebookCarouselInStreamVideosComponent extends FacebookCarouselBaseDirective {
	constructor(
		carouselPlacementsStore: FacebookCarouselPlacementsComponentStore,
	) {
		super(
			carouselPlacementsStore,
			FacebookCarouselSupportedPlacements.FacebookInStreamVideos,
		);
	}
}
