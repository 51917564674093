import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { ConfigService } from 'src/app/core/config/config.service';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from 'src/app/core/base-api-service';
import { IFacebookPage } from 'src/app/core/models/facebook/fb-page.model';
import { IFacebookInstagramAccount } from 'src/app/core/models/facebook/fb-instagram-account';
import { ApiService } from '../../../internal/api.service';

@Injectable({
	providedIn: 'root',
})
export class FacebookPageApiService extends BaseApiService {
	constructor(public http: HttpClient) {
		super(
			http,
			`${ConfigService?.config?.SCS_URL}/api/facebook/integrations/`,
		);
	}

	/**
	 * Gets pages
	 * @param integrationId
	 */
	public getPages(integrationId: string): Observable<IFacebookPage[]> {
		const url = `${this.baseUrl}${integrationId}/pages`;
		return this.http.get<IFacebookPage[]>(url).pipe(
			map((pages) => pages.map((page) => ({ ...page, integrationId }))),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Gets instagram accounts
	 * @param integrationId
	 * @param pageId
	 */
	public getInstagramAccounts(
		integrationId: string,
		adAccountId: string,
		pageId: string,
	): Observable<IFacebookInstagramAccount[]> {
		const url = `${this.baseUrl}${integrationId}/ad-accounts/${adAccountId}/instagram-accounts?pageId=${pageId}`;
		return this.http.get<IFacebookInstagramAccount[]>(url).pipe(
			map((instagramAccounts) =>
				instagramAccounts.map((instagramAccount) => ({
					...instagramAccount,
					integrationId,
					pageId,
				})),
			),
			catchError(ApiService.handleError),
		);
	}
}
