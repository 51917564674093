export const CACHEBUSTERS_TEAM: string[] = [
	'sameh.george@bannerflow.com',
	'joanna.cinkusz@bannerflow.com',
	'linnea.miderkvist@bannerflow.com',
	'ekaterina.zhigalkina@bannerflow.com',
	'michal.staszewski@bannerflow.com',
	'krzysztof.gwozdz@bannerflow.com',
	'bartosz.pawluk@bannerflow.com',
	'tomasz.gizinski@bannerflow.com',
	'jenny.pileus@bannerflow.com',
	'lars.malm@bannerflow.com',
	'eve@bannerflow.com',
	'polina.belogorodtseva@bannerflow.com',
	'johan.sandberg@bannerflow.com',
	'christopher.lindh@bannerflow.com',
	'mateusz.kajsztura@bannerflow.com',
];
