import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { ILocalizationVM, INetworkVM } from 'src/app/presentation/view-models';
import { BaseFeatureService } from '../../../base-feature.service';
import { NetworkMappersService } from '../../mappers/network.mapper';
import { INetwork } from '../../../../core/models/account/network.model';
import { UserService } from '../user/user.service';

export enum KnownUserFeature {
	SocialCampaignManager = 'SocialCampaignManager',
}

@Injectable({
	providedIn: 'root',
})
export class NetworkBaseService extends BaseFeatureService<INetworkVM> {
	constructor(
		public appEntityServices: AppEntityServices,
		public networkMapper: NetworkMappersService,
		public userService: UserService,
	) {
		super();
	}

	public getAll(): void {
		this.appEntityServices.accountEntity.network.getAll();
	}

	public loadAll(): Observable<INetworkVM[]> {
		const networks$: Observable<INetwork[]> =
			this.appEntityServices.accountEntity.network.entities$;

		return networks$.pipe(
			filter((networks) => !!networks),
			map((networks: INetwork[]) =>
				networks.map((network) =>
					this.networkMapper.mapToNetworkVM(network),
				),
			),
		);
	}

	protected getWithQuery(): void {
		throw new Error('Method not implemented.');
	}

	protected getById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected load(): Observable<ILocalizationVM> {
		throw new Error('Method not implemented.');
	}
	protected loadById(): Observable<ILocalizationVM> {
		throw new Error('Method not implemented.');
	}
	protected loadByIds(_ids: string[]): Observable<ILocalizationVM[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
