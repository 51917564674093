import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { CreativeSetMappersService } from '../../mappers';
import { CreativeSetBaseService } from './creative-set-base.service';

@Injectable({
	providedIn: 'root',
})
export class CreativeSetService extends CreativeSetBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public creativeSetMapper: CreativeSetMappersService,
	) {
		super(appEntityServices, creativeSetMapper);
	}

	public isLoadingCreativeSetFolder(): Observable<boolean> {
		return this.appEntityServices.accountEntity.creativeSet.loading$;
	}
}
