export interface IAdPlacementPreviewMetaData {
	inPreview: boolean;
	adId: string;
	adGroupId: string;
}

import { Directive, Input } from '@angular/core';
import { NavigationOrigin } from 'src/app/presentation/view-models';
import { OpenAdEditorService } from 'src/app/presentation/features/ad/open-ad-editor/open-ad-editor.service';
import { ActivatedRoute } from '@angular/router';

@Directive({
	selector: '[in-ad-placement-preview]',
	host: {
		'(click)': 'openAdForEdit()',
		'[class.item-clickable]': 'adPreviewMetaData.inPreview',
	},
	standalone: true,
})
export class AdPlacementPreviewDirective {
	@Input('in-ad-placement-preview')
	public adPreviewMetaData: IAdPlacementPreviewMetaData;

	constructor(
		private openAdEditorService: OpenAdEditorService,
		private route: ActivatedRoute,
	) {}

	public openAdForEdit(): void {
		if (
			!this.adPreviewMetaData?.inPreview ||
			!this.adPreviewMetaData?.adId ||
			!this.adPreviewMetaData?.adGroupId
		) {
			return;
		}

		this.openAdEditorService.openAdEditor(
			this.adPreviewMetaData.adId,
			this.adPreviewMetaData.adGroupId,
			NavigationOrigin.Preview,
			this.route,
		);
	}
}
