import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'fb-headline',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './fb-headline.component.html',
	styleUrls: ['./fb-headline.component.scss'],
})
export class FbHeadlineComponent {
	@Input({ required: true }) public headLineValue: string;
	@Input() public fontWeight: number;
	@Input() public width: number;
}
