import { Injectable } from '@angular/core';
import { IUIListDataNode } from '@bannerflow/ui';
import {
	BehaviorSubject,
	EMPTY,
	Observable,
	of,
	Subject,
	Subscription,
} from 'rxjs';
import {
	debounceTime,
	delay,
	distinctUntilChanged,
	filter,
	map,
	switchMap,
	takeUntil,
} from 'rxjs/operators';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { IB2CreativeSetVM } from 'src/app/presentation/view-models';
import { CreativeSetPickerVM } from './models/creative-set-picker.vm';

@Injectable({
	providedIn: 'root',
})
export class CreativeSetPickerService {
	private creativeSetPickerVM: CreativeSetPickerVM;

	private listHistory: IUIListDataNode[] = [];
	private rootFolderCache: IB2CreativeSetVM[] = [];

	private searchSubscription$: Subscription;
	private loadCreativeSubscription$: Subscription;
	private loadUserSubscription$: Subscription;
	public unsubscribe$ = new Subject<void>();

	constructor(private appFeatureServices: AppFeatureServices) {}

	public init(): CreativeSetPickerVM {
		this.creativeSetPickerVM = {
			searchInput: new BehaviorSubject<string>(null),
			listItems$: new BehaviorSubject<IB2CreativeSetVM[]>([]),
			currentNode: undefined,
			searchExpanded: false,
			isLoading$: undefined,
			showFolderNav: true,
		};

		this.loadCreativeSubscription$ =
			this.appFeatureServices.accountFeature.creativeSet
				.loadAll()
				.subscribe((results) => {
					if (
						this.creativeSetPickerVM.currentNode === undefined &&
						this.rootFolderCache.length === 0
					) {
						this.rootFolderCache = results;
					}

					this.creativeSetPickerVM.listItems$.next(results);
				});

		this.searchSubscription$ = this.creativeSetPickerVM.searchInput
			.pipe(
				filter((value) => value !== null),
				debounceTime(400),
				distinctUntilChanged(),
				map((value) => value.length > 0),
				switchMap((isSearch) => this.getCreativeSets(isSearch)),
			)
			.subscribe();

		this.creativeSetPickerVM.isLoading$ =
			this.appFeatureServices.accountFeature.creativeSet
				.isLoadingCreativeSetFolder()
				.pipe(
					switchMap((value) => {
						if (value) {
							return of(true).pipe(delay(400));
						} else {
							return of(false);
						}
					}),
				);

		return this.creativeSetPickerVM;
	}

	public onSearchValueChange(value: string): void {
		this.creativeSetPickerVM.searchInput.next(value);
	}

	public onCurrentNodeChange(node: IB2CreativeSetVM): Observable<void> {
		this.creativeSetPickerVM.currentNode = node;

		if (node?.children && node.children.length === 0) {
			this.listHistory.push(this.creativeSetPickerVM.currentNode);
			this.getCreativeSets();
		}

		return EMPTY;
	}

	public toggleSearch(expand: boolean): void {
		this.creativeSetPickerVM.searchExpanded = expand;

		if (!this.creativeSetPickerVM.searchExpanded) {
			this.creativeSetPickerVM.searchInput.next('');
			this.getCreativeSets(false);
			setTimeout(() => {
				this.creativeSetPickerVM.showFolderNav = true;
			}, 100);
		} else {
			this.creativeSetPickerVM.showFolderNav = false;
		}
	}

	public findSelectedCreative(value: string): void {
		this.loadUserSubscription$ = this.getCreative(value, true);
		this.creativeSetPickerVM.showFolderNav = false;
	}

	public goBackInList(): void {
		const index: number = this.listHistory.indexOf(
			this.creativeSetPickerVM.currentNode,
		);
		this.creativeSetPickerVM.currentNode = this.listHistory[index - 1];
		this.listHistory.splice(index, 1);
		this.getCreativeSets();

		if (!this.creativeSetPickerVM.currentNode) {
			this.listHistory = [];
		}
	}

	private getCreativeSets(search?: boolean): Observable<void> {
		if (
			!search &&
			this.creativeSetPickerVM.currentNode === undefined &&
			this.rootFolderCache.length > 0
		) {
			this.creativeSetPickerVM.listItems$.next(this.rootFolderCache);
		} else {
			this.loadUserSubscription$ = this.getCreative(
				this.creativeSetPickerVM.searchInput.value,
				search,
			);
		}

		return EMPTY;
	}

	private getCreative(value: string, search: boolean): Subscription {
		const brand$ = this.appFeatureServices.accountFeature.brand.load();

		return brand$.pipe(takeUntil(this.unsubscribe$)).subscribe((brand) =>
			this.appFeatureServices.accountFeature.creativeSet.getWithQuery({
				accountSlug: brand.accountSlug,
				brandSlug: brand.brandSlug,
				brandId: brand.id,
				search: search,
				queryValue: value,
				folderId: this.creativeSetPickerVM.currentNode?.legacyId,
			}),
		);
	}

	public destroySubscriptions(): void {
		if (this.searchSubscription$) {
			this.searchSubscription$.unsubscribe();
		}
		if (this.loadCreativeSubscription$) {
			this.loadCreativeSubscription$.unsubscribe();
		}
		if (this.loadUserSubscription$) {
			this.loadUserSubscription$.unsubscribe();
		}

		const brand$ = this.appFeatureServices.accountFeature.brand.load();

		brand$.pipe(takeUntil(this.unsubscribe$)).subscribe((brand) =>
			this.appFeatureServices.accountFeature.creativeSet.getWithQuery({
				accountSlug: brand.accountSlug,
				brandSlug: brand.brandSlug,
				brandId: brand.id,
			}),
		);
	}
}
