import { Injectable } from '@angular/core';
import { ICreativeSize } from 'src/app/core/models';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import {
	AdFormatEnum,
	IAssignedCreativeVM,
	IFacebookPlacementVM,
	IPlacementBaseVM,
	IPlacementOptionsVM,
} from 'src/app/presentation/view-models';

import { FacebookCarouselPlacementService } from './carousel-placement/fb-carousel-placement.service';
import { FacebookSinglePlacementService } from './single-placement/fb-single-placement.service';
import { Observable, map, filter } from 'rxjs';
import { DesignMatchingService } from 'src/app/features/studio/services/design-matching/design-matching.service';
import { AdPlacementTemplateService } from 'src/app/features/ad/services/ad-placement-template/ad-placement-template.service';
import { FacebookPlacementMappersService } from '../mappers/fb-placement.mappers';
import { ASPECT_GROUP_KEY_ANY } from 'src/app/presentation/view-models/shared/aspect-group-key-any';

@Injectable({
	providedIn: 'root',
})
export class FacebookPlacementService {
	constructor(
		public carousel: FacebookCarouselPlacementService,
		public single: FacebookSinglePlacementService,
		private textPipe: TextPipe,
		private designMatchingService: DesignMatchingService,
		public adPlacementTemplates: AdPlacementTemplateService,
		private facebookPlacementMappers: FacebookPlacementMappersService,
	) {}

	public getCreativeIdForPlacement(
		placement: IFacebookPlacementVM,
		adCreatives: IAssignedCreativeVM[],
		creativeSizes: ICreativeSize[],
	): string {
		for (const adCreative of adCreatives) {
			const size = creativeSizes.find(
				(creativeSize) => creativeSize.id === adCreative.id,
			)?.size;

			if (
				this.designMatchingService.isDesignMatchingPlacementRecommendations(
					size?.width,
					size?.height,
					placement,
				)
			) {
				return adCreative.id;
			}
		}
	}

	public getAspectRatioGroupName(aspectGroupKey: string): string {
		switch (aspectGroupKey) {
			case '1:1': {
				return this.textPipe.transform(
					'FacebookPlacementService.SELECT_CREATIVE_FOR_FEEDS',
				);
			}
			case '9:16': {
				return this.textPipe.transform(
					'FacebookPlacementService.SELECT_CREATIVE_FOR_STORIES',
				);
			}
			case '1.91:1': {
				return this.textPipe.transform(
					'FacebookPlacementService.SELECT_CREATIVE_FOR_RIGHT_COLUMN',
				);
			}
			case ASPECT_GROUP_KEY_ANY: {
				return this.textPipe.transform(
					'FacebookPlacementService.SELECT_CREATIVE',
				);
			}
			default: {
				return '';
			}
		}
	}

	public getSupportedPlacementsByAdFormat(
		supportedPlacementsIds: string[],
		adFormatId: AdFormatEnum,
	): Observable<IPlacementOptionsVM[]> {
		return (
			adFormatId === AdFormatEnum.singleImageAndVideo
				? this.single.loadPlacements()
				: this.carousel.loadPlacements()
		).pipe(
			map((placements) =>
				placements.filter((placement) =>
					supportedPlacementsIds?.includes(placement.id),
				),
			),
		);
	}

	public filterSupportedPlacementsByAdId(
		supportedPlacementsIds: string[],
		adId: string,
	): Observable<IFacebookPlacementVM[]> {
		return this.loadPlacementsByAdId(adId).pipe(
			map((placements) =>
				this.filterPlacementsBySupportedPlacementIds(
					placements,
					supportedPlacementsIds,
				),
			),
		);
	}

	public filterSupportedPlacementsByAdsIds(
		supportedPlacementsIds: string[],
		adsIds: string[],
	): Observable<IFacebookPlacementVM[]> {
		return this.loadPlacementsByAdsIds(adsIds).pipe(
			map((placements) =>
				this.filterPlacementsBySupportedPlacementIds(
					placements,
					supportedPlacementsIds,
				),
			),
		);
	}

	public loadPlacementsByAdId(
		adId: string,
	): Observable<IFacebookPlacementVM[]> {
		return this.adPlacementTemplates.loadPlacementsByAdId(adId).pipe(
			filter((placements) => !!placements),
			map((placements: IPlacementBaseVM[]) =>
				this.facebookPlacementMappers.mapPlacements(placements),
			),
		);
	}

	public loadPlacementsByAdsIds(
		adsIds: string[],
	): Observable<IFacebookPlacementVM[]> {
		return this.adPlacementTemplates
			.loadCombinedPlacementsByAdsIds(adsIds)
			.pipe(
				filter((placements) => !!placements),
				map((placements: IPlacementBaseVM[]) =>
					this.facebookPlacementMappers.mapPlacements(placements),
				),
			);
	}

	private filterPlacementsBySupportedPlacementIds(
		placements: IFacebookPlacementVM[],
		supportedPlacementsIds: string[],
	): IFacebookPlacementVM[] {
		if (supportedPlacementsIds.length > 0) {
			return placements.filter((placement) =>
				supportedPlacementsIds.includes(placement.id),
			);
		} else {
			return placements;
		}
	}
}
