import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICampaign } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { ICampaignVM } from 'src/app/presentation/view-models';
import { CampaignMappersService } from '../../mappers/campaign.mappers';
import { CampaignBaseService } from './campaign-base.service';

@Injectable({
	providedIn: 'root',
})
export class CampaignService extends CampaignBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public campaignMappersService: CampaignMappersService,
	) {
		super(appEntityServices, campaignMappersService);
	}

	public createCampaign(campaignName: string): Observable<ICampaignVM> {
		const campaign: ICampaign = {
			id: '',
			name: campaignName,
		};

		return this.appEntityServices.campaignEntity.campaign.add(campaign);
	}

	public renameCampaign(
		currentCampaign: ICampaignVM,
	): Observable<ICampaignVM> {
		return this.appEntityServices.campaignEntity.campaign.update(
			currentCampaign,
		);
	}
}
