import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, flatMap, map } from 'rxjs';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ConfigService } from 'src/app/core/config/config.service';
import { ApiService } from '../../internal/api.service';
import { IAdGroup } from 'src/app/core/models';
import { IAdGroupConnection } from 'src/app/core/models/ad/ad-group/ad-group-connection.model';
import { IConnection } from 'src/app/core/models/ad/ad-group/connection.model';

@Injectable({
	providedIn: 'root',
})
export class AdGroupApiService extends BaseApiService {
	constructor(public http: HttpClient) {
		super(http, `${ConfigService?.config?.SCS_URL}/api/ad-groups`);
	}

	/**
	 * Create Ad Group
	 * @param adGroup
	 */
	public addAdGroup(adGroup: IAdGroup): Observable<IAdGroup> {
		const body: any = {
			id: adGroup.id,
			name: adGroup.name,
			networkId: adGroup.networkId,
			socialCampaignId: adGroup.socialCampaignId,
			supportedPlacementsIds: adGroup.supportedPlacementsIds,
			connection: adGroup.connection
				? {
						integrationId: adGroup.connection.integrationId,
						adAccountId: adGroup.connection.adAccountId,
						adSetId: adGroup.connection.adSetId,
						pageId: adGroup.connection.pageId,
						instagramAccountId:
							adGroup.connection.instagramAccountId,
						adGroupId: adGroup.connection.adGroupId,
						identityId: adGroup.connection.identityId,
					}
				: null,
		};

		return this.http
			.post<any>(this.baseUrl, body, { observe: 'response' })
			.pipe(
				flatMap((res: HttpResponse<any>) => {
					const location = res.headers.get('location');
					return this.http.get<IAdGroup>(location);
				}),
				map((group) => group),
				catchError(ApiService.handleError),
			);
	}

	/**
	 * Get Ad Group
	 * @param adGroupId
	 */
	public getById(adGroupId: string): Observable<IAdGroup> {
		const endpoint = adGroupId;
		const url = `${this.baseUrl}/${endpoint}`;

		return this.http.get<IAdGroup>(url).pipe(
			map((adGroup) => adGroup),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Delete ad group
	 * @param adGroupId
	 */
	public delete(adGroupId: string): Observable<string> {
		const endpoint = adGroupId;
		const url = `${this.baseUrl}/${endpoint}`;

		return this.http.delete<string>(url).pipe(
			map((adGroup) => adGroup),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Update selected placements of a single adGroup
	 * @param adGroupId
	 * @param selectedPlacements
	 */
	public updatePlacements(
		adGroupId: string,
		selectedPlacements: string[],
	): Observable<IAdGroup> {
		const endpoint = adGroupId;
		const url = `${this.baseUrl}/${endpoint}`;

		const body = [
			{
				op: 'replace',
				path: '/supportedPlacementsIds',
				value: selectedPlacements,
			},
		];

		return this.http.patch<IAdGroup>(url, body).pipe(
			map((adGroup) => adGroup),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Get ad group connection
	 * @param adGroupId
	 */
	public getConnection(adGroupId: string): Observable<IConnection> {
		const endpoint = `${adGroupId}/connection`;
		const url = `${this.baseUrl}/${endpoint}`;

		return this.http.get<IConnection>(url).pipe(
			map((connection) => ({
				...connection,
				id: adGroupId,
			})),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Update an ad group's ad set connection
	 * @param connection
	 */
	public connect(connection: IConnection): Observable<IConnection> {
		const endpoint = `${connection.id}/connection`;
		const url = `${this.baseUrl}/${endpoint}`;

		const adGroupConnection: IAdGroupConnection = {
			integrationId: connection.integrationId,
			adAccountId: connection.adAccountId,
			adSetId: connection.adSetId,
			pageId: connection.pageId,
			instagramAccountId: connection.instagramAccountId,
			adGroupId: connection.adGroupId,
			identityId: connection.identityId,
		};

		return this.http.put<IAdGroup>(url, adGroupConnection).pipe(
			map(() => connection),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Delete an ad group's ad set connection
	 * @param id
	 */
	public deleteConnection(adGroupId: string): Observable<string> {
		const endpoint = `${adGroupId}/connection`;
		const url = `${this.baseUrl}/${endpoint}`;

		return this.http
			.delete<string>(url)
			.pipe(catchError(ApiService.handleError));
	}
}
