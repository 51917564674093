<ng-container>
    <div class="placement-card">
        <facebook-placement-header
            [placementId]="placementId.FacebookInstagramFeed"></facebook-placement-header>
        <div class="creative-content">
            <ng-container
                *ngIf="selectedCustomizedCreative?.id || placementMetaData?.creative?.id || placementMetaData?.creativeUrl">
                <creative-content
                    [sound]="placementSound$ | async"
                    [creativeUrl]="placementMetaData?.creativeUrl"
                    [isCustomized]="placementMetaData?.isCustomized"
                    [creativeId]="selectedCustomizedCreative?.id || placementMetaData?.creative?.id"
                    [creativeSetId]="selectedCustomizedCreative?.setId || placementMetaData?.creative?.setId"
                    [selectedMediaType]="placementMetaData?.selectedMediaType"></creative-content>
            </ng-container>
        </div>

        <div class="social-info">
            <cta-button
                [ctaValue]="placementMetaData.callToAction"
                [placementId]="placementId.FacebookInstagramFeed">
            </cta-button>
        </div>
        <instagram-feed-footer
            [primaryTextValue]="placementMetaData.primaryTextValue"
            [placementId]="placementId.FacebookInstagramFeed"></instagram-feed-footer>
    </div>
</ng-container>
