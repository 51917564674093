import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TiktokPlacementBaseDirective } from '../directives/tt-placement-base.directive';
import { CreativeContentComponent } from '../../../../elements/creative-content/creative-content.component';
import { TiktokBrandLogoComponent } from '../elements/brand-logo/tt-brand-logo.component';
import { TiktokBrandNameComponent } from '../elements/brand-name/tt-brand-name.component';
import { TiktokCtaComponent } from '../elements/cta/tt-cta.component';
import { UIModule } from '@bannerflow/ui';
import { TiktokSinglePlacementsComponentStore } from '../tt-single-placements.component.store';
import { TiktokSupportedPlacements } from '../../enums/tt-supported-placement.enum';

@Component({
	selector: 'tt-pangle',
	templateUrl: './tt-pangle.component.html',
	styleUrls: ['./tt-pangle.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		UIModule,
		CreativeContentComponent,
		TiktokBrandLogoComponent,
		TiktokBrandNameComponent,
		TiktokCtaComponent,
	],
})
export class TiktokPangleComponent extends TiktokPlacementBaseDirective {
	constructor(
		tiktokSinglePlacementsComponentStore: TiktokSinglePlacementsComponentStore,
	) {
		super(
			tiktokSinglePlacementsComponentStore,
			TiktokSupportedPlacements.TiktokPangle,
		);
	}
}
