import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { IFacebookPlacementCarouselMetaDataVM } from 'src/app/presentation/view-models';
import { FacebookCarouselSupportedPlacements } from '../enums';
import { FacebookCarouselPlacementsComponentStore } from '../fb-carousel-placements.component.store';
import { SliderTypeEnum } from '../carousel-elements/carousel-slider/slider-type';
import { SoundEnum } from 'src/app/presentation/view-models/shared/sound.enum';

@Directive()
export abstract class FacebookCarouselBaseDirective
	implements OnInit, OnDestroy
{
	public placementId: FacebookCarouselSupportedPlacements;
	public placementMetaData$: Observable<IFacebookPlacementCarouselMetaDataVM>;
	public currentSlide = 0;
	public sliderTypeEnum: typeof SliderTypeEnum = SliderTypeEnum;
	public cardSoundSubscription$: Subscription;

	constructor(
		private carouselPlacementsStore: FacebookCarouselPlacementsComponentStore,
		placementId: FacebookCarouselSupportedPlacements,
	) {
		this.placementId = placementId;
	}

	public ngOnInit(): void {
		this.placementMetaData$ =
			this.carouselPlacementsStore.loadPlacementMetaData(
				this.placementId,
			);
		this.changeCardSoundByIndex(0);
	}

	public onSlideChange(index: number): void {
		this.currentSlide = index;

		this.changeCardSoundByIndex(index);
	}

	private changeCardSoundByIndex(index: number): void {
		this.cardSoundSubscription$ = this.carouselPlacementsStore
			.loadPlacementsSound()
			.subscribe((soundPlacements) => {
				if (
					soundPlacements[this.placementId] === SoundEnum.On &&
					this.currentSlide === index
				) {
					this.carouselPlacementsStore.setCardSound({
						placementId: this.placementId,
						sound: SoundEnum.On,
						index: index,
					});
				} else {
					this.carouselPlacementsStore.setCardSound({
						placementId: this.placementId,
						sound: SoundEnum.Off,
						index: index,
					});
				}
			});
	}

	public ngOnDestroy(): void {
		if (this.cardSoundSubscription$) {
			this.cardSoundSubscription$.unsubscribe();
		}
	}
}
