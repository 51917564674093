import { Injectable } from '@angular/core';
import {
	IPlacementOptionsVM,
	IRenderingOptionsVM,
} from 'src/app/presentation/view-models';
import { PlacementDesignRecommendationsService } from '../placement-recommendations/placement-design-recommendations.service';

@Injectable({
	providedIn: 'root',
})
export class DesignMatchingService {
	constructor(
		private placementDesignRecommendationsService: PlacementDesignRecommendationsService,
	) {}

	public isDesignMatchingPlacementRecommendations(
		width: number,
		height: number,
		placement: IPlacementOptionsVM,
	): boolean {
		return this.isDesignMatchingAspectRatioRecommendations(
			width,
			height,
			placement.renderingOptions.aspectRatioGroup,
		);
	}

	public isDesignMatchingAspectRatioRecommendations(
		width: number,
		height: number,
		aspectRatioGroup: IRenderingOptionsVM['aspectRatioGroup'],
	): boolean {
		return this.placementDesignRecommendationsService.isDesignMatchingAspectRatio(
			width,
			height,
			aspectRatioGroup,
		);
	}
}
