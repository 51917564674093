<div class="card">
    <fb-header-inbox
        [headlineValue]="placementMetaData.cards[0]?.headLineValue"
        [ctaValue]="placementMetaData.callToAction">
    </fb-header-inbox>

    <div class="creative-content">
        <ng-container *ngIf="placementMetaData?.cards[0]?.creative?.id">
            <creative-content
                [creativeId]="placementMetaData?.cards[0].creative.id"
                [creativeSetId]="placementMetaData?.cards[0].creative.setId"
                [selectedMediaType]="placementMetaData?.cards[0].mediaType"
                [sound]="placementMetaData?.cards[0].sound" />
        </ng-container>
    </div>
</div>
