import { Injectable } from '@angular/core';
import { Observable, map, filter, zip, mergeMap, switchMap } from 'rxjs';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { IStudioCreativeSet, ICreativeSize } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import {
	IGroupedCreativesByAspectRatioVM,
	IStudioCreativeSetVM,
	IAssignedCreativeVM,
	IGroupedPlacementsAspectRatioVM,
	IPlacementBaseVM,
	IPlacementOptionsVM,
} from 'src/app/presentation/view-models';
import { StudioMappersService } from '../../mappers/studio.mappers';
import { FilterCreativeService } from '../filter-creative/filter-creative.service';
import { StudioCreativeSetBaseService } from './studio-creative-set-base.service';
import { IPlacementBaseDictionary } from 'src/app/presentation/view-models/shared/placement-base-dictionary';

@Injectable({
	providedIn: 'root',
})
export class StudioCreativeSetService extends StudioCreativeSetBaseService {
	constructor(
		public appCoreServices: AppCoreServices,
		public studioMapper: StudioMappersService,
		public appEntityService: AppEntityServices,
		public filterCreativeService: FilterCreativeService,
	) {
		super(
			appCoreServices,
			studioMapper,
			appEntityService,
			filterCreativeService,
		);
	}

	public getCompatibleCreativeSet(
		creativeSetId: string,
		localizationId: string,
		placementsIds: string[],
		placements: IPlacementBaseVM[],
	): Observable<IGroupedCreativesByAspectRatioVM> {
		return this.getById(creativeSetId).pipe(
			switchMap((creativeSet) =>
				this.filterCompatibleCreativesFrom(
					creativeSet,
					localizationId,
					placementsIds,
					placements,
				),
			),
		);
	}

	public loadCompatibleCreativeSet(
		creativeSetId: string,
		localizationId: string,
		placementsIds: string[],
		placements: IPlacementOptionsVM[],
	): Observable<IGroupedCreativesByAspectRatioVM> {
		return this.loadById(creativeSetId).pipe(
			switchMap((creativeSet) =>
				this.filterCompatibleCreativesFrom(
					creativeSet,
					localizationId,
					placementsIds,
					placements,
				),
			),
		);
	}

	public filterCompatibleCreativesFrom(
		creativeSet: IStudioCreativeSet,
		localizationId: string,
		placementsIds: string[],
		placements: IPlacementOptionsVM[],
	): Observable<IGroupedCreativesByAspectRatioVM> {
		return this.appEntityService.accountEntity.localization.entities$.pipe(
			map((localizations) =>
				this.studioMapper.toStudioCreativeSetVM(
					creativeSet,
					localizations,
				),
			),
			map((creativeSetVM) => {
				const supportedPlacements: IPlacementOptionsVM[] =
					placements.filter((currentPlacement) =>
						placementsIds.includes(currentPlacement.id),
					);

				return this.groupPlacementsBasedOnAspectRatio(
					supportedPlacements,
					creativeSetVM,
					localizationId,
				);
			}),
		);
	}

	public loadCreativeSizes(
		creativeSetId: string,
		creatives: IAssignedCreativeVM[],
	): Observable<ICreativeSize[]> {
		const creativeSets$: Observable<IStudioCreativeSet[]> =
			this.appEntityService.accountEntity.creativeSet.entities$;

		return creativeSets$.pipe(
			filter((creativeSets) => !!creativeSets),
			map(this.getFindByIdProjectionFn(creativeSetId)),
			filter((creativeSet) => !!creativeSet),
			map((creativeSet: IStudioCreativeSetVM) =>
				creatives.map((creativeItem) => {
					const creative = creativeSet?.creatives?.find(
						(creativeVM) => creativeVM?.id === creativeItem?.id,
					);
					return {
						id: creativeItem?.id,
						size: creative?.size,
					};
				}),
			),
		);
	}

	public loadCreativeSizesAcrossSets(
		creativeSetIds: string[],
		creatives: IAssignedCreativeVM[],
	): Observable<ICreativeSize[]> {
		const arrayOfObservables = creativeSetIds.map((creativeSetId) =>
			this.loadCreativeSizes(creativeSetId, creatives),
		);

		return zip(arrayOfObservables).pipe(mergeMap((thing) => thing));
	}

	public isCreativeSetHasLanguage(
		creativeSetId: string,
		languageId: string,
	): Observable<boolean> {
		const creativeSets$: Observable<IStudioCreativeSet[]> =
			this.appEntityService.accountEntity.creativeSet.entities$;

		return creativeSets$.pipe(
			filter((creativeSets) => !!creativeSets),
			map(this.getFindByIdProjectionFn(creativeSetId)),
			map((creativeSet) =>
				creativeSet?.versions.some(
					(version) => version.localization.id === languageId,
				),
			),
		);
	}

	public isCreativeDeactivated(
		creativeSetId: string,
		creativeId: string,
	): Observable<boolean> {
		const creativeSets$: Observable<IStudioCreativeSet[]> =
			this.appEntityService.accountEntity.creativeSet.entities$;

		return creativeSets$.pipe(
			filter((creativeSets) => !!creativeSets),
			map(this.getFindByIdProjectionFn(creativeSetId)),
			filter((creativeSet) => !!creativeSet),
			map((creativeSet: IStudioCreativeSetVM) => {
				if (!creativeId) {
					return false;
				}
				const creative = creativeSet.creatives.find(
					(creativeVM) => creativeVM.id === creativeId,
				);
				return creative?.design ? false : true;
			}),
		);
	}

	public getPreviewUrl(
		assignedCreative: IAssignedCreativeVM,
	): Observable<string> {
		return this.appCoreServices.studioApiService.getPreviewUrl(
			assignedCreative?.setId,
			assignedCreative?.id,
		);
	}

	private groupPlacementsBasedOnAspectRatio(
		placements: IPlacementOptionsVM[],
		creativeSet: IStudioCreativeSetVM,
		localizationId: string,
	): IGroupedCreativesByAspectRatioVM {
		const groupedCreativesByPlacments: IGroupedCreativesByAspectRatioVM =
			{};

		const placementsGroupedByAspectRatio: IGroupedPlacementsAspectRatioVM =
			this.groupPlacementsByAspectRatioFromList(placements);

		for (const ratio in placementsGroupedByAspectRatio) {
			const groupedPlacementsAspectRatio =
				placementsGroupedByAspectRatio[ratio];

			if (groupedPlacementsAspectRatio.length) {
				groupedCreativesByPlacments[ratio] =
					this.filterCreativeService.filterCreativesBasedOnPlacementRecommendationsAndLocalization(
						creativeSet,
						groupedPlacementsAspectRatio[0][0],
						localizationId,
					);
			}
		}
		return groupedCreativesByPlacments;
	}

	public groupPlacementsByAspectRatioFromDictionary(
		dictionary: IPlacementBaseDictionary,
	): IGroupedPlacementsAspectRatioVM {
		const placementsList: IPlacementBaseVM[] = Object.keys(dictionary).map(
			function (key) {
				return dictionary[key];
			},
		);

		return this.groupPlacementsByAspectRatioFromList(placementsList);
	}

	public groupPlacementsByAspectRatioFromList(
		placements: IPlacementOptionsVM[],
	): IGroupedPlacementsAspectRatioVM {
		return placements.reduce(
			(result, item) => ({
				...result,
				[item.renderingOptions.aspectRatioGroup]: [
					placements.filter(
						(placement) =>
							placement.renderingOptions.aspectRatioGroup ===
							item.renderingOptions.aspectRatioGroup,
					),
				],
			}),
			{},
		);
	}

	public getCreativesLanguageVersions(
		adCreatives: IAssignedCreativeVM[],
		languageId: string,
	): Observable<IAssignedCreativeVM[]> {
		return this.loadAll().pipe(
			map((creativeSets) =>
				adCreatives.map((adCreative) =>
					this.getCreativeSetLanguageVersion(
						adCreative,
						languageId,
						creativeSets,
					),
				),
			),
		);
	}

	public getCreativeSetLanguageVersion(
		adCreative: IAssignedCreativeVM,
		languageId: string,
		creativeSets: IStudioCreativeSetVM[],
	): IAssignedCreativeVM {
		const creativeSet = creativeSets.find(
			(creative) => creative.id === adCreative.setId,
		);
		const creative = creativeSet?.creatives.find(
			(creativeVM) => creativeVM.id === adCreative.id,
		);
		const creativeDesignId = creative?.design.id;
		const languageCreative = creativeSet?.creatives.find(
			(creativeItem) =>
				creativeItem.design.id === creativeDesignId &&
				creativeItem.version.localization.id === languageId,
		);

		return {
			...adCreative,
			id: languageCreative ? languageCreative.id : adCreative.id,
		};
	}
}
