import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { CreativeContentComponent } from '../../../../../../elements/creative-content/creative-content.component';
import { FbPrimaryTextComponent } from '../../../../placement-elements/primary-text/fb-primary-text.component';
import { CarouselSliderComponent } from '../../carousel-elements/carousel-slider/carousel-slider.component';
import { FacebookCarouselBaseDirective } from '../../directives/fb-carousel-base.directive';
import { FacebookCarouselSupportedPlacements } from '../../enums';
import { FacebookCarouselPlacementsComponentStore } from '../../fb-carousel-placements.component.store';
import { StoriesReelsBrandComponent } from '../elements/stories-reels-brand/stories-reels-brand.component';
import { InstagramReelsCtaComponent } from './instagram-reels-cta/instagram-reels-cta.component';
import { InstagramReelsIconsComponent } from './instagram-reels-icons/instagram-reels-icons.component';
import { InstargamReelsHeaderComponent } from './instargam-reels-header/instargam-reels-header.component';

@Component({
	selector: 'fb-carousel-instagram-reels',
	templateUrl: './instagram-reels.component.html',
	styleUrls: ['./instagram-reels.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		UIModule,
		CarouselSliderComponent,
		CreativeContentComponent,
		FbPrimaryTextComponent,
		StoriesReelsBrandComponent,
		InstagramReelsIconsComponent,
		InstagramReelsCtaComponent,
		InstargamReelsHeaderComponent,
	],
})
export class FacebookCarouselInstagramReelsComponent extends FacebookCarouselBaseDirective {
	constructor(
		carouselPlacementsStore: FacebookCarouselPlacementsComponentStore,
	) {
		super(
			carouselPlacementsStore,
			FacebookCarouselSupportedPlacements.FacebookInstagramReels,
		);
	}
}
