<div
    [ngSwitch]="selectedMediaType"
    class="content"
    [ngClass]="{'content-fit-to-height': size?.fitToHeight, 'content-fit-to-size': size?.fitToSize}"
    [ngStyle]="{'width.px': size?.width, 'height.px': size?.height}">
    <img
        *ngSwitchCase="mediaType.Image"
        class="preload-image"
        [ngClass]="{'fit-to-height': size?.fitToHeight, 'fit-to-size': size?.fitToSize}"
        [src]="creativeUrl"
        alt="preload-image" />
    <img
        *ngSwitchCase="mediaType.Carousel"
        class="preload-image preload-image-carousel"
        [ngClass]="{'fit-to-height': size?.fitToHeight, 'fit-to-size': size?.fitToSize}"
        [src]="creativeUrl"
        alt="preload-image" />
    <ng-container *ngSwitchCase="mediaType.Video">
        <video class="video-size" autoplay muted loop playsline>
            <source [src]="creativeUrl" type="video/mp4" />
            <track
                src="subtitles.vtt"
                kind="subtitles"
                srclang="en"
                label="English"
                default />
        </video>
    </ng-container>
</div>
