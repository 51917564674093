import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, tap } from 'rxjs';
import {
	IFacebookPlacementSingleMetaDataVM,
	IFacebookSinglePlacementsMetaDataMap,
} from 'src/app/presentation/view-models';
import { IPlacementsSoundDictionary } from 'src/app/presentation/view-models/shared/placement-sound.vm';
import { SoundEnum } from 'src/app/presentation/view-models/shared/sound.enum';

export interface IFacebookPlacementsState {
	placementsMetaData: IFacebookSinglePlacementsMetaDataMap;
	placementsSound: IPlacementsSoundDictionary;
}

@Injectable()
export class FacebookSinglePlacementsComponentStore extends ComponentStore<IFacebookPlacementsState> {
	constructor() {
		super({
			placementsMetaData: undefined,
			placementsSound: {},
		});
	}

	public readonly setPlacementsMetaData = this.effect(
		(
			placementsMetaData$: Observable<IFacebookSinglePlacementsMetaDataMap>,
		) =>
			placementsMetaData$.pipe(
				tap(
					(
						placementsMetaData: IFacebookSinglePlacementsMetaDataMap,
					) => {
						this.updateMetaData(placementsMetaData);
					},
				),
			),
	);

	private readonly updateMetaData = this.updater(
		(
			state: IFacebookPlacementsState,
			placementsMetaData: IFacebookSinglePlacementsMetaDataMap,
		) => ({
			...state,
			placementsMetaData: placementsMetaData,
		}),
	);

	public readonly setPlacementsSound = this.effect(
		(placementsSound$: Observable<IPlacementsSoundDictionary>) =>
			placementsSound$.pipe(
				tap((placementsSound: IPlacementsSoundDictionary) => {
					this.updatePlacementsSoundState(placementsSound);
				}),
			),
	);

	public readonly setPlacementSound = this.effect(
		(param$: Observable<{ placementId: string; sound: SoundEnum }>) =>
			param$.pipe(
				tap((param: { placementId: string; sound: SoundEnum }) => {
					this.updatePlacementSoundState(param);
				}),
			),
	);

	private readonly updatePlacementSoundState = this.updater(
		(
			state: IFacebookPlacementsState,
			action: { placementId: string; sound: SoundEnum },
		) => ({
			...state,
			placementsSound: {
				...state.placementsSound,
				[action.placementId]: action.sound,
			},
		}),
	);

	private readonly updatePlacementsSoundState = this.updater(
		(
			state: IFacebookPlacementsState,
			action: IPlacementsSoundDictionary,
		) => ({
			...state,
			placementsSound: {
				...action,
			},
		}),
	);

	public loadPlacementsSound(): Observable<IPlacementsSoundDictionary> {
		return this.select((state) => state.placementsSound);
	}

	public loadPlacementMetaData(
		placementId: string,
	): Observable<IFacebookPlacementSingleMetaDataVM> {
		return this.select((state) => state.placementsMetaData[placementId]);
	}

	public loadPlacementsList(): Observable<string[]> {
		return this.select((state) => Object.keys(state.placementsMetaData));
	}
}
