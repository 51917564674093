import { Injectable, Injector } from '@angular/core';
import { TiktokAdAccountService } from './services/ad-account/tt-ad-account.service';
import { TiktokAdGroupService } from './services/ad-group/tt-ad-group.service';
import { TiktokAdGroupPlacementService } from './services/ad-group-placement/tt-ad-group-placement.service';
import { TiktokCampaignService } from './services/campaign/tt-campaign.service';
import { TiktokIdentityService } from './services/identity/tt-ad-identity.service';

@Injectable()
export class TiktokAccountFeatureService {
	public adAccount: TiktokAdAccountService;
	public campaign: TiktokCampaignService;
	public adGroup: TiktokAdGroupService;
	public adGroupPlacement: TiktokAdGroupPlacementService;
	public identity: TiktokIdentityService;

	constructor(private injector: Injector) {
		this.adAccount = this.injector.get(TiktokAdAccountService);
		this.campaign = this.injector.get(TiktokCampaignService);
		this.adGroup = this.injector.get(TiktokAdGroupService);
		this.adGroupPlacement = this.injector.get(
			TiktokAdGroupPlacementService,
		);
		this.identity = this.injector.get(TiktokIdentityService);
	}
}
