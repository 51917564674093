import {
	createFeatureSelector,
	createSelector,
	MemoizedSelector,
} from '@ngrx/store';
import { SOCIAL_SLICE } from 'src/app/state/app.state';
import { State } from '../app-state';
import {
	AppWindowStatusEnum,
	ISocialState,
	ISocialAppNotification,
} from '../app-state/common-feature.reducer';

export const socialState: MemoizedSelector<State, ISocialState> =
	createFeatureSelector<ISocialState>(SOCIAL_SLICE);

export const selectAppWindowStatus: MemoizedSelector<
	State,
	AppWindowStatusEnum
> = createSelector(socialState, (state: ISocialState) => state.appWindowStatus);

export const selectAppNotification: MemoizedSelector<
	State,
	ISocialAppNotification
> = createSelector(socialState, (state: ISocialState) => state.appNotification);
