<ng-container>
    <div class="placement-card">
        <div class="creative-content">
            <ng-container
                *ngIf="selectedCustomizedCreative?.id || placementMetaData?.creative?.id || placementMetaData?.creativeUrl">
                <creative-content
                    [sound]="placementSound$ | async"
                    [creativeUrl]="placementMetaData?.creativeUrl"
                    [isCustomized]="placementMetaData?.isCustomized"
                    [creativeId]="selectedCustomizedCreative?.id || placementMetaData?.creative?.id"
                    [creativeSetId]="selectedCustomizedCreative?.setId || placementMetaData?.creative?.setId"
                    [selectedMediaType]="placementMetaData?.selectedMediaType"></creative-content>
            </ng-container>
        </div>

        <facebook-placement-header
            [placementId]="placementId.FacebookSearchResults"></facebook-placement-header>

        <div class="body">
            <div class="headline">
                <headline
                    [headLineValue]="placementMetaData.headLineValue"
                    [placementId]="placementId.FacebookSearchResults"
                    [twoDefaultLines]="true"></headline>
            </div>
            <cta-button
                [ctaValue]="placementMetaData.callToAction"
                [placementId]="placementId.FacebookSearchResults">
            </cta-button>
        </div>
    </div>
</ng-container>
