import { Injectable } from '@angular/core';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { AdContentIdEnum } from 'src/app/presentation/features/integrations/facebook/placement/single/placements/shared/enums';
import { AdService } from 'src/app/features/ad/services/ad/ad.service';
import { AdValidationService } from 'src/app/features/ad/services/ad-validation/ad-validation.service';
import { AdMappersService } from 'src/app/features/ad/mappers/ad-mapper/ad.mapper';
import { IAdListVM, ITiktokAdVM } from 'src/app/presentation/view-models';
import { Observable, combineLatest, filter, map, of, switchMap } from 'rxjs';
import { IAd } from 'src/app/core/models';
import { TiktokAdMappersService } from '../../mappers/ad-mapper/tt-ad.mapper';
import { TiktokAdListMappersService } from '../../mappers/ad-mapper/tt-ad-list.mapper';
import { TiktokPlacementService } from '../../../placement/services/tt-placement.service';
import { TiktokAdContentTemplatesService } from './tt-ad-content-templates.service';
import { TiktokBulkAdService } from './tt-bulk-ad.service';
import { BulkAdMapperService } from 'src/app/features/ad/mappers/ad-mapper/bulk-ad-mapper';

@Injectable({
	providedIn: 'root',
})
export class TiktokAdService extends AdService {
	public adContentId: typeof AdContentIdEnum = AdContentIdEnum;

	constructor(
		public appEntityServices: AppEntityServices,
		public adMapper: AdMappersService,
		public ttAdMapper: TiktokAdMappersService,
		public ttAdListMapper: TiktokAdListMappersService,
		public tiktokPlacementService: TiktokPlacementService,
		public adValidationService: AdValidationService,
		public adContentTemplates: TiktokAdContentTemplatesService,
		public bulk: TiktokBulkAdService,
		public bulkMapper: BulkAdMapperService,
	) {
		super(appEntityServices, adValidationService, adMapper, bulkMapper);
	}

	public loadById(adId: string): Observable<ITiktokAdVM> {
		const ads$: Observable<IAd[]> =
			this.appEntityServices.adEntity.ad.entities$;

		const ad$: Observable<IAd> = ads$.pipe(
			filter((ads) => !!ads.length),
			map((ads) => ads.find((ad) => ad.id === adId)),
			filter((ad) => !!ad),
		);

		return ad$.pipe(map((ad) => this.ttAdMapper.toAdVM(ad)));
	}

	public loadByIds(adIds: string[]): Observable<ITiktokAdVM[]> {
		const adIds$: Observable<string[]> = of(adIds);

		if (adIds.length === 0) {
			return of([]);
		}

		return adIds$.pipe(
			switchMap((ids) =>
				combineLatest(ids.map((adId) => this.loadById(adId))),
			),
		);
	}

	public loadAdsByAdGroupId(adGroupId: string): Observable<ITiktokAdVM[]> {
		const adIds$: Observable<string[]> =
			this.loadAdsIdsByAdGroupId(adGroupId);

		return adIds$.pipe(switchMap((ids) => this.loadByIds(ids)));
	}

	public loadAdsListByAdGroupId(adGroupId: string): Observable<IAdListVM[]> {
		const adIds$: Observable<string[]> =
			this.loadAdsIdsByAdGroupId(adGroupId);

		return adIds$.pipe(
			switchMap((ids) => {
				if (!ids.length) {
					return of([]);
				} else {
					return combineLatest(
						ids.map((adId) => this.loadAdListInfo(adId)),
					);
				}
			}),
		);
	}

	public loadAdListInfo(adId: string): Observable<IAdListVM> {
		const ads$: Observable<IAd[]> =
			this.appEntityServices.adEntity.ad.entities$;

		const ad$: Observable<IAd> = ads$.pipe(
			filter((ads) => !!ads.length),
			map((ads) => ads.find((ad) => ad.id === adId)),
			filter((ad) => !!ad),
		);

		return ad$.pipe(map((ad) => this.ttAdListMapper.toAdListVM(ad)));
	}

	public addAd(fromViewModel: ITiktokAdVM): Observable<IAd> {
		const ad: IAd = this.ttAdMapper.toAd(fromViewModel);
		return this.appEntityServices.adEntity.ad.add(ad);
	}

	public saveAd(adVM: ITiktokAdVM): Observable<IAd> {
		const ad = this.ttAdMapper.toAd(adVM);
		return this.appEntityServices.adEntity.ad.update(ad);
	}
}
