import { Injectable, Injector } from '@angular/core';
import { AdFormatsService } from './ad-format/services/ad-formats.service';
import { TiktokAccountFeatureService } from './account/tt-account-feature.service';
import { TiktokAdService } from './ad/services/ad/tt-ad.service';
import { TiktokPlacementService } from './placement/services/tt-placement.service';

@Injectable()
export class TiktokFeatureService {
	public account: TiktokAccountFeatureService;
	public adformat: AdFormatsService;
	public ad: TiktokAdService;
	public placement: TiktokPlacementService;

	constructor(private injector: Injector) {
		this.account = this.injector.get(TiktokAccountFeatureService);
		this.adformat = this.injector.get(AdFormatsService);
		this.ad = this.injector.get(TiktokAdService);
		this.placement = this.injector.get(TiktokPlacementService);
	}
}
