import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { ILocalization } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { ILocalizationVM } from 'src/app/presentation/view-models';
import { BaseFeatureService } from '../../../base-feature.service';
import { LocalizationMappersService } from '../../mappers';

export enum KnownUserFeature {
	SocialCampaignManager = 'SocialCampaignManager',
}

@Injectable({
	providedIn: 'root',
})
export class LocalizationBaseService extends BaseFeatureService<ILocalizationVM> {
	constructor(
		public appEntityServices: AppEntityServices,
		public localizationsMapper: LocalizationMappersService,
	) {
		super();
	}
	public getWithQuery(params: {
		accountSlug: string;
		brandSlug: string;
	}): void {
		this.appEntityServices.accountEntity.localization.getWithQuery({
			accountSlug: params['accountSlug'],
			brandSlug: params['brandSlug'],
		});
	}

	public loadById(localizationId: string): Observable<ILocalizationVM> {
		const localizations$: Observable<ILocalization[]> =
			this.appEntityServices.accountEntity.localization.entities$;

		return localizations$.pipe(
			filter((localizations) => !!localizations),
			map((localizations) =>
				localizations.find(
					(localization) => localization.id === localizationId,
				),
			),
			filter((localization) => !!localization),
			map((localization: ILocalization) =>
				this.localizationsMapper.mapToLocalizationVM(localization),
			),
		);
	}

	public loadAll(): Observable<ILocalizationVM[]> {
		const localizations$: Observable<ILocalization[]> =
			this.appEntityServices.accountEntity.localization.entities$;

		return localizations$.pipe(
			filter((localizations) => !!localizations),
			map((localizations: ILocalization[]) =>
				localizations.map((localization) =>
					this.localizationsMapper.mapToLocalizationVM(localization),
				),
			),
		);
	}

	protected getById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected load(): Observable<ILocalizationVM> {
		throw new Error('Method not implemented.');
	}
	protected loadByIds(_ids: string[]): Observable<ILocalizationVM[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
