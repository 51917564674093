import { state, style, trigger } from '@angular/animations';

export const animationSlider = trigger('slide', [
	state('next', style({ transform: 'translateX(-{{offset}}px)' }), {
		params: { offset: 0 },
	}),
	state('previous', style({ transform: 'translateX(-{{offset}}px)' }), {
		params: { offset: 0 },
	}),
]);
