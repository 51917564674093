import { Injectable } from '@angular/core';
import { IFacebookAdSet } from 'src/app/core/models';
import { IFacebookAdSetVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class FacebookAdSetMapperService {
	public toAdSetViewModel(adSet: IFacebookAdSet): IFacebookAdSetVM {
		return {
			id: adSet.id,
			name: adSet.name,
			pageId: adSet?.pageId,
			useStoreLocation: adSet.useStoreLocation,
			warnings: adSet.warnings,
		};
	}

	public toAdSetsViewModel(adSets: IFacebookAdSet[]): IFacebookAdSetVM[] {
		return adSets.map((adSet) => this.toAdSetViewModel(adSet));
	}
}
