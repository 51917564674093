import { Injectable } from '@angular/core';
import { filter, map, Observable, switchMap } from 'rxjs';

import { IFacebookAdSetPlacements } from 'src/app/core/models/facebook/fb-ad-set-placement';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { FacebookAdSetPlacementBaseService } from './facebook-ad-set-placement-base.service';

@Injectable({
	providedIn: 'root',
})
export class FacebookAdSetPlacementService extends FacebookAdSetPlacementBaseService {
	constructor(public appEntityServices: AppEntityServices) {
		super(appEntityServices);
	}

	public loadAdSetPlacements(adSetId: string): Observable<string[]> {
		const adSetPlacements$: Observable<IFacebookAdSetPlacements[]> =
			this.appEntityServices.facebookEntity.adSetPlacement.entities$;
		return adSetPlacements$.pipe(
			map((adSetPlacements) =>
				adSetPlacements.find(
					(adSetPlacement) => adSetPlacement.id === adSetId,
				),
			),
			filter((adSetPlacements) => !!adSetPlacements),
			map((adSetPlacement) => adSetPlacement.placementsIds),
		);
	}

	public loadAdSetPlacementsByAdGroupId(
		adGroupId: string,
	): Observable<string[]> {
		const adGroupConnection$ =
			this.appEntityServices.adEntity.adGroupConnection.entities$.pipe(
				filter((connections) => !!connections),
				map((connections) =>
					connections.find(
						(connection) => connection.id === adGroupId,
					),
				),
				map((connection) => connection),
			);

		return adGroupConnection$.pipe(
			switchMap((connection) =>
				this.loadAdSetPlacements(connection?.adSetId),
			),
		);
	}
}
