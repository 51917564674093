import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AD_CONTENT_VALIDATION_ENTITY } from 'src/app/state/app.state';
import { map } from 'rxjs/operators';
import { IAdContentValidation } from 'src/app/core/models';
import { AppCoreServices } from 'src/app/core/app-core-service';
import {
	AdContentTemplateScopeEnum,
	AdFormatEnum,
} from 'src/app/presentation/view-models';

@Injectable()
export class AdContentValidationDataService extends DefaultDataService<IAdContentValidation> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(AD_CONTENT_VALIDATION_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery({
		adId,
		placementId,
		adContent,
		cardsContents,
		value,
		adContentPropertyId,
		adFormatId,
		scope,
	}: {
		adId: string;
		placementId: string;
		adContent: string;
		cardsContents: string;
		value: string;
		adContentPropertyId?: string;
		adFormatId: AdFormatEnum;
		scope?: AdContentTemplateScopeEnum;
	}): Observable<IAdContentValidation[]> {
		if (!adContentPropertyId) {
			return this.appCoreServices.socialCampaignApiService.adPlacementTemplateApi.validateAdContent(
				adId,
				placementId,
				adFormatId,
				adContent,
				cardsContents,
			);
		}

		return this.appCoreServices.socialCampaignApiService.adPlacementTemplateApi
			.validatePlacementTemplate(
				adId,
				placementId,
				adContentPropertyId,
				adFormatId,
				scope,
				value,
			)
			.pipe(map((adContentValidation) => [adContentValidation]));
	}
}
