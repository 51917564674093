import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AdContentIdEnum, FacebookSupportedPlacements } from '../enums';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	imports: [CommonModule],
	selector: 'description',
	templateUrl: './description.component.html',
	styleUrls: ['./description.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DescriptionComponent {
	@Input() public placementId: string;
	@Input() public descriptionValue: string;
	@Input() public isExpanded?: boolean;

	public adContentId: typeof AdContentIdEnum = AdContentIdEnum;

	public facebookSupportedPlacements: typeof FacebookSupportedPlacements =
		FacebookSupportedPlacements;
}
