import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { IFacebookCampaign } from 'src/app/core/models';
import { FACEBOOK_CAMPAIGN_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class FacebookCampaignsEntityService extends EntityCollectionServiceBase<IFacebookCampaign> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(FACEBOOK_CAMPAIGN_ENTITY, serviceElementsFactory);
	}
}
