<ng-container *ngIf="(campaign$ | async) as campaign">
    <ng-container *ngIf="(user$ | async) as user">
        <div class="logo" [uiDropdownTarget]="headerPopover">
            <ui-svg-icon icon="hamburger"></ui-svg-icon>
        </div>
        <campaign-editable-name [campaign]="campaign"></campaign-editable-name>

        <ui-dropdown #headerPopover [minWidth]="240">
            <ui-dropdown-item
                id="interaction-rename-campaign"
                (click)="triggerFocusCampaignInput()"
                svgIcon="edit">
                {{'HeaderComponent.RENAME_CAMPAIGN' | text}}
            </ui-dropdown-item>
            <ui-dropdown-item
                id="interaction-delete-campaign"
                (click)="deleteCampaign(campaign?.id, user)"
                svgIcon="delete">
                {{'HeaderComponent.DELETE_CAMPAIGN' | text}}
            </ui-dropdown-item>
            <ui-dropdown-divider></ui-dropdown-divider>
            <ui-dropdown-item
                *ngIf="(user$ | async) as user"
                class="top-divider"
                id="interaction-exit-campaign"
                svgIcon="close"
                (click)="exitCampaign(user)">
                {{'HeaderComponent.CLOSE' | text}}
            </ui-dropdown-item>
        </ui-dropdown>
    </ng-container>
</ng-container>
