import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IFacebookCarouselCardMetaDataVM } from 'src/app/presentation/view-models';
import { CreativeContentComponent } from '../../../../../../../elements/creative-content/creative-content.component';
import { FbHeadlineComponent } from '../../../../../placement-elements/headline/fb-headline.component';

@Component({
	standalone: true,
	imports: [CommonModule, CreativeContentComponent, FbHeadlineComponent],
	selector: 'fb-marketplace-card',
	templateUrl: './fb-marketplace-card.component.html',
	styleUrls: ['./fb-marketplace-card.component.scss'],
})
export class FacebookMarketPlaceCardComponent {
	@Input() public card: IFacebookCarouselCardMetaDataVM;
}
