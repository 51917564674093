import { Injectable } from '@angular/core';
import { FacebookFeatureService } from 'src/app/features/facebook/facebook-feature.service';
import { TiktokFeatureService } from 'src/app/features/tiktok/tt-feature.service';
import {
	IAssignedCreativeVM,
	ICreativeVM,
	IGroupedCreativesByAspectRatioVM,
	IPlacementOptionsVM,
} from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class CreativeAspectRatioService {
	constructor(
		private facebookFeatureService: FacebookFeatureService,
		private tiktokFeatureService: TiktokFeatureService,
	) {}

	public setAspectRatioGroupNames(
		creativeSet: IGroupedCreativesByAspectRatioVM,
		aspectGroupKeys: string[],
		networkId: string,
	): void {
		for (const aspectGroupKey of aspectGroupKeys) {
			creativeSet[aspectGroupKey].groupName = this.aspectRatioGroupName(
				aspectGroupKey,
				networkId,
			);
		}
	}

	private aspectRatioGroupName(
		aspectGroupKey: string,
		networkId: string,
	): string {
		switch (networkId) {
			case 'facebook': {
				return this.facebookFeatureService.placement.getAspectRatioGroupName(
					aspectGroupKey,
				);
			}
			case 'tiktok': {
				return this.tiktokFeatureService.placement.getAspectRatioGroupName(
					aspectGroupKey,
				);
			}
			default: {
				return '';
			}
		}
	}

	public loadAssignedCreativesWithPlacements(
		groupedCreativeSet: IGroupedCreativesByAspectRatioVM,
		placements: IPlacementOptionsVM[],
	): IAssignedCreativeVM[] {
		const creatives =
			this.loadCreativesFromGroupPlacements(groupedCreativeSet);
		const creativeIdToAspectGroupDictionary = {};

		for (const aspectGroup in groupedCreativeSet) {
			creativeIdToAspectGroupDictionary[
				groupedCreativeSet[aspectGroup]?.selectedCreative?.id
			] = aspectGroup;
		}

		const assignedCreatives = [];

		for (const creative of creatives) {
			const aspectGroupKey =
				creativeIdToAspectGroupDictionary[creative?.id];
			const placementsIds = placements
				.filter(
					(placement) =>
						placement.renderingOptions.aspectRatioGroup ===
						aspectGroupKey,
				)
				.map((placement) => placement.id);

			if (creative) {
				assignedCreatives.push({
					id: creative.id,
					setId: Object.values(groupedCreativeSet)[0].id,
					size: creative.size,
					placementsIds,
				});
			}
		}

		return assignedCreatives;
	}

	private loadCreativesFromGroupPlacements(
		groupedCreativeSet: IGroupedCreativesByAspectRatioVM,
	): ICreativeVM[] {
		if (groupedCreativeSet) {
			const selectedCreatives = [];
			for (const aspectGroupKey of Object.keys(groupedCreativeSet)) {
				selectedCreatives.push(
					groupedCreativeSet[aspectGroupKey]?.selectedCreative,
				);
			}
			return selectedCreatives;
		}
	}
}
