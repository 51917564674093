import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { IStudioCreativeSet } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { BaseFeatureService } from 'src/app/features/base-feature.service';
import { IStudioCreativeSetVM } from 'src/app/presentation/view-models';
import { StudioMappersService } from '../../mappers/studio.mappers';
import { FilterCreativeService } from '../filter-creative/filter-creative.service';

@Injectable({
	providedIn: 'root',
})
export class StudioCreativeSetBaseService extends BaseFeatureService<IStudioCreativeSetVM> {
	constructor(
		public appCoreServices: AppCoreServices,
		public studioMapper: StudioMappersService,
		public appEntityService: AppEntityServices,
		public filterCreativeService: FilterCreativeService,
	) {
		super();
	}

	public getById(creativeSetId: string): Observable<IStudioCreativeSet> {
		return this.appEntityService.accountEntity.creativeSet
			.getWithQuery({
				creativeSetsId: [creativeSetId],
			})
			.pipe(map((sets) => sets[0]));
	}

	public loadById(creativeSetId: string): Observable<IStudioCreativeSetVM> {
		return this.loadAll().pipe(
			map(this.getFindByIdProjectionFn(creativeSetId)),
		);
	}

	protected getFindByIdProjectionFn(
		creativeSetId: string,
	): (value: IStudioCreativeSet[], index: number) => IStudioCreativeSet {
		return (creativeSets) =>
			creativeSets.find(
				(creativeSet) => creativeSet.id === creativeSetId,
			);
	}

	public loadByIds(
		creativeSetIds: string[],
	): Observable<IStudioCreativeSetVM[]> {
		return this.loadAll().pipe(
			map((creativeSets) =>
				creativeSets.filter((creativeSet) =>
					creativeSetIds.includes(creativeSet.id),
				),
			),
		);
	}

	public loadAll(): Observable<IStudioCreativeSetVM[]> {
		return this.appEntityService.accountEntity.creativeSet.entities$.pipe(
			filter((creativeSets) => !!creativeSets),
		);
	}

	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected getWithQuery(_params: any): void {
		throw new Error('Method not implemented.');
	}
	protected load(): Observable<IStudioCreativeSetVM> {
		throw new Error('Method not implemented.');
	}
	protected deleteById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
