<svg
    width="111"
    height="15"
    viewBox="0 0 111 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
        d="M69.4345 6.57684V8.66723H73.0955V10.1362H69.4345V13.0175H67.604V5.10791H73.5814V6.57684H69.4345Z"
        fill="white" />
    <path
        d="M77.4345 13.1079C76.7942 13.1079 76.2179 12.9761 75.7057 12.7124C75.201 12.4412 74.8055 12.0684 74.5193 11.5938C74.233 11.1192 74.0899 10.5806 74.0899 9.97797C74.0899 9.37533 74.233 8.83672 74.5193 8.36215C74.8055 7.88757 75.201 7.51846 75.7057 7.2548C76.2179 6.98362 76.7942 6.84802 77.4345 6.84802C78.0748 6.84802 78.6473 6.98362 79.152 7.2548C79.6567 7.51846 80.0522 7.88757 80.3385 8.36215C80.6247 8.83672 80.7678 9.37533 80.7678 9.97797C80.7678 10.5806 80.6247 11.1192 80.3385 11.5938C80.0522 12.0684 79.6567 12.4412 79.152 12.7124C78.6473 12.9761 78.0748 13.1079 77.4345 13.1079ZM77.4345 11.6616C77.8865 11.6616 78.2556 11.5109 78.5419 11.2096C78.8356 10.9008 78.9825 10.4902 78.9825 9.97797C78.9825 9.46572 78.8356 9.05895 78.5419 8.75763C78.2556 8.44878 77.8865 8.29435 77.4345 8.29435C76.9825 8.29435 76.6097 8.44878 76.3159 8.75763C76.0221 9.05895 75.8752 9.46572 75.8752 9.97797C75.8752 10.4902 76.0221 10.9008 76.3159 11.2096C76.6097 11.5109 76.9825 11.6616 77.4345 11.6616Z"
        fill="white" />
    <path
        d="M83.6297 7.74068C83.8406 7.44689 84.1231 7.22467 84.4771 7.07401C84.8387 6.92335 85.253 6.84802 85.7201 6.84802V8.47514C85.5242 8.46008 85.3924 8.45254 85.3246 8.45254C84.8199 8.45254 84.4244 8.59567 84.1382 8.88192C83.8519 9.16064 83.7088 9.58249 83.7088 10.1475V13.0175H81.9461V6.93842H83.6297V7.74068Z"
        fill="white" />
    <path
        d="M93.9333 10.2153V13.0175H92.1028V10.1927L89.0406 5.10791H90.9841L93.0971 8.62203L95.2101 5.10791H97.0067L93.9333 10.2153Z"
        fill="white" />
    <path
        d="M99.8679 13.1079C99.2276 13.1079 98.6513 12.9761 98.139 12.7124C97.6343 12.4412 97.2389 12.0684 96.9526 11.5938C96.6664 11.1192 96.5232 10.5806 96.5232 9.97797C96.5232 9.37533 96.6664 8.83672 96.9526 8.36215C97.2389 7.88757 97.6343 7.51846 98.139 7.2548C98.6513 6.98362 99.2276 6.84802 99.8679 6.84802C100.508 6.84802 101.081 6.98362 101.585 7.2548C102.09 7.51846 102.486 7.88757 102.772 8.36215C103.058 8.83672 103.201 9.37533 103.201 9.97797C103.201 10.5806 103.058 11.1192 102.772 11.5938C102.486 12.0684 102.09 12.4412 101.585 12.7124C101.081 12.9761 100.508 13.1079 99.8679 13.1079ZM99.8679 11.6616C100.32 11.6616 100.689 11.5109 100.975 11.2096C101.269 10.9008 101.416 10.4902 101.416 9.97797C101.416 9.46572 101.269 9.05895 100.975 8.75763C100.689 8.44878 100.32 8.29435 99.8679 8.29435C99.4159 8.29435 99.043 8.44878 98.7492 8.75763C98.4554 9.05895 98.3085 9.46572 98.3085 9.97797C98.3085 10.4902 98.4554 10.9008 98.7492 11.2096C99.043 11.5109 99.4159 11.6616 99.8679 11.6616Z"
        fill="white" />
    <path
        d="M110.504 6.93842V13.0175H108.831V12.2944C108.598 12.558 108.319 12.7614 107.995 12.9045C107.671 13.0401 107.321 13.1079 106.944 13.1079C106.146 13.1079 105.513 12.8782 105.046 12.4186C104.579 11.9591 104.346 11.2774 104.346 10.3734V6.93842H106.108V10.1136C106.108 11.0928 106.519 11.5825 107.34 11.5825C107.762 11.5825 108.101 11.4469 108.357 11.1757C108.613 10.897 108.741 10.4864 108.741 9.94407V6.93842H110.504Z"
        fill="white" />
    <g opacity="0.7">
        <path
            d="M1.3281 6.3678V8.54384H4.78116V9.66762H1.3281V12.4055H0V5.25424H5.22045V6.3678H1.3281Z"
            fill="#DBDBDB" />
        <path
            d="M8.68444 12.4771C8.13277 12.4771 7.63559 12.3579 7.19289 12.1195C6.75019 11.8811 6.40284 11.5508 6.15084 11.1285C5.90565 10.6994 5.78306 10.2159 5.78306 9.67783C5.78306 9.13978 5.90565 8.65962 6.15084 8.23735C6.40284 7.81509 6.75019 7.48477 7.19289 7.24639C7.63559 7.00801 8.13277 6.88882 8.68444 6.88882C9.24293 6.88882 9.74352 7.00801 10.1862 7.24639C10.6289 7.48477 10.9729 7.81509 11.218 8.23735C11.47 8.65962 11.596 9.13978 11.596 9.67783C11.596 10.2159 11.47 10.6994 11.218 11.1285C10.9729 11.5508 10.6289 11.8811 10.1862 12.1195C9.74352 12.3579 9.24293 12.4771 8.68444 12.4771ZM8.68444 11.3839C9.15439 11.3839 9.5426 11.2273 9.84908 10.914C10.1556 10.6007 10.3088 10.1886 10.3088 9.67783C10.3088 9.16702 10.1556 8.75497 9.84908 8.44168C9.5426 8.12838 9.15439 7.97173 8.68444 7.97173C8.2145 7.97173 7.82629 8.12838 7.5198 8.44168C7.22013 8.75497 7.07029 9.16702 7.07029 9.67783C7.07029 10.1886 7.22013 10.6007 7.5198 10.914C7.82629 11.2273 8.2145 11.3839 8.68444 11.3839Z"
            fill="#DBDBDB" />
        <path d="M12.8172 4.82516H14.0942V12.4055H12.8172V4.82516Z" fill="#DBDBDB" />
        <path d="M15.7703 4.82516H17.0473V12.4055H15.7703V4.82516Z" fill="#DBDBDB" />
        <path
            d="M21.1753 12.4771C20.6236 12.4771 20.1264 12.3579 19.6837 12.1195C19.241 11.8811 18.8937 11.5508 18.6417 11.1285C18.3965 10.6994 18.2739 10.2159 18.2739 9.67783C18.2739 9.13978 18.3965 8.65962 18.6417 8.23735C18.8937 7.81509 19.241 7.48477 19.6837 7.24639C20.1264 7.00801 20.6236 6.88882 21.1753 6.88882C21.7338 6.88882 22.2344 7.00801 22.6771 7.24639C23.1198 7.48477 23.4637 7.81509 23.7089 8.23735C23.9609 8.65962 24.0869 9.13978 24.0869 9.67783C24.0869 10.2159 23.9609 10.6994 23.7089 11.1285C23.4637 11.5508 23.1198 11.8811 22.6771 12.1195C22.2344 12.3579 21.7338 12.4771 21.1753 12.4771ZM21.1753 11.3839C21.6452 11.3839 22.0334 11.2273 22.3399 10.914C22.6464 10.6007 22.7996 10.1886 22.7996 9.67783C22.7996 9.16702 22.6464 8.75497 22.3399 8.44168C22.0334 8.12838 21.6452 7.97173 21.1753 7.97173C20.7053 7.97173 20.3171 8.12838 20.0106 8.44168C19.711 8.75497 19.5611 9.16702 19.5611 9.67783C19.5611 10.1886 19.711 10.6007 20.0106 10.914C20.3171 11.2273 20.7053 11.3839 21.1753 11.3839Z"
            fill="#DBDBDB" />
        <path
            d="M33.6684 6.95012L31.6559 12.4055H30.4299L29.0201 8.646L27.5898 12.4055H26.3639L24.3615 6.95012H25.567L27.0075 10.9753L28.5093 6.95012H29.582L31.0531 10.9957L32.5344 6.95012H33.6684Z"
            fill="#DBDBDB" />
        <path
            d="M34.5465 6.95012H35.8235V12.4055H34.5465V6.95012ZM35.1901 6.0511C34.9585 6.0511 34.7644 5.97959 34.6078 5.83656C34.4511 5.68672 34.3728 5.50283 34.3728 5.28489C34.3728 5.06694 34.4511 4.88646 34.6078 4.74343C34.7644 4.59359 34.9585 4.51868 35.1901 4.51868C35.4216 4.51868 35.6157 4.59019 35.7724 4.73322C35.929 4.86943 36.0074 5.04311 36.0074 5.25424C36.0074 5.47899 35.929 5.6697 35.7724 5.82634C35.6226 5.97618 35.4285 6.0511 35.1901 6.0511Z"
            fill="#DBDBDB" />
        <path
            d="M40.5951 6.88882C41.2829 6.88882 41.8346 7.08974 42.2501 7.49158C42.6655 7.89341 42.8733 8.48935 42.8733 9.2794V12.4055H41.5962V9.44286C41.5962 8.96611 41.4839 8.60854 41.2591 8.37016C41.0344 8.12498 40.7142 8.00238 40.2988 8.00238C39.8288 8.00238 39.4577 8.14541 39.1852 8.43146C38.9128 8.7107 38.7766 9.11594 38.7766 9.64718V12.4055H37.4996V6.95012H38.7153V7.65503C38.9264 7.40304 39.192 7.21233 39.5121 7.08293C39.8323 6.95353 40.1932 6.88882 40.5951 6.88882Z"
            fill="#DBDBDB" />
        <path
            d="M49.9577 6.95012V11.578C49.9577 13.4987 48.9769 14.459 47.0154 14.459C46.491 14.459 45.9938 14.3909 45.5239 14.2547C45.0539 14.1253 44.6657 13.9346 44.3592 13.6826L44.9313 12.7222C45.1697 12.9198 45.4694 13.0764 45.8304 13.1922C46.1981 13.3148 46.5693 13.3761 46.9439 13.3761C47.5433 13.3761 47.9826 13.2399 48.2618 12.9674C48.541 12.695 48.6807 12.2795 48.6807 11.7211V11.435C48.4627 11.6734 48.1971 11.8539 47.8838 11.9765C47.5705 12.0991 47.2266 12.1604 46.852 12.1604C46.3344 12.1604 45.8644 12.0514 45.4421 11.8334C45.0267 11.6087 44.6964 11.2954 44.4512 10.8936C44.2128 10.4917 44.0936 10.032 44.0936 9.51437C44.0936 8.99676 44.2128 8.54043 44.4512 8.14541C44.6964 7.74357 45.0267 7.43368 45.4421 7.21574C45.8644 6.9978 46.3344 6.88882 46.852 6.88882C47.247 6.88882 47.6046 6.95353 47.9247 7.08293C48.2516 7.21233 48.524 7.40985 48.742 7.67547V6.95012H49.9577ZM47.0461 11.0774C47.5296 11.0774 47.9247 10.9344 48.2312 10.6484C48.5444 10.3555 48.7011 9.97751 48.7011 9.51437C48.7011 9.05805 48.5444 8.68687 48.2312 8.40081C47.9247 8.11476 47.5296 7.97173 47.0461 7.97173C46.5557 7.97173 46.1539 8.11476 45.8406 8.40081C45.5341 8.68687 45.3808 9.05805 45.3808 9.51437C45.3808 9.97751 45.5341 10.3555 45.8406 10.6484C46.1539 10.9344 46.5557 11.0774 47.0461 11.0774Z"
            fill="#DBDBDB" />
    </g>
    <ellipse cx="52.9852" cy="2.5" rx="2.5" ry="2.5" fill="#EBB70E" />
    <rect opacity="0.5" x="58.8252" y="5.27051" width="0.8" height="7" fill="#DBDBDB" />
</svg>
