<ng-container *ngIf="placementMetaData$ | async as placementMetaData">
    <div class="placement">
        <div class="placement-header">
            <ads-on-fb-reels-header />
        </div>

        <div class="placement-footer">
            <ads-on-fb-reels-footer />
            <carousel-slider
                [cards]="placementMetaData.cards"
                [cardWidth]="235"
                (indexChanged)="onSlideChange($event)">
                <ng-template let-card="card">
                    <ads-on-fb-reels-card
                        [card]="card"
                        [brandName]="(brand$ | async)?.name" />
                </ng-template>
            </carousel-slider>
            <carousel-dots
                [cardsLength]="placementMetaData.cards.length"
                [currentIndex]="currentSlide" />
        </div>
    </div>
</ng-container>
