import { Injectable, Injector } from '@angular/core';
import { FacebookAdAccountService } from './services/ad-account/facebook-ad-account.service';
import { FacebookAdSetPlacementService } from './services/ad-set-placement/facebook-ad-set-placement.service';
import { FacebookAdSetService } from './services/ad-set/facebook-ad-set.service';
import { FacebookAdCampaignService } from './services/campaign/facebook-ad-campaign.service';
import { FacebookAdPageService } from './services/page/facebook-ad-page.service';
import { FacebookInstagramAccountService } from './services/instagram-account/facebook-instagram-account.service';

@Injectable()
export class FacebookAccountFeatureService {
	public adAccount: FacebookAdAccountService;
	public adSet: FacebookAdSetService;
	public adCampaign: FacebookAdCampaignService;
	public adPage: FacebookAdPageService;
	public adSetPlacement: FacebookAdSetPlacementService;
	public instagramAccount: FacebookInstagramAccountService;

	constructor(private injector: Injector) {
		this.adAccount = this.injector.get(FacebookAdAccountService);
		this.adSet = this.injector.get(FacebookAdSetService);
		this.adCampaign = this.injector.get(FacebookAdCampaignService);
		this.adPage = this.injector.get(FacebookAdPageService);
		this.adSetPlacement = this.injector.get(FacebookAdSetPlacementService);
		this.instagramAccount = this.injector.get(
			FacebookInstagramAccountService,
		);
	}
}
