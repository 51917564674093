import {
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { PublishLogDataService } from './publish-log/publish-log-data.service';
import { PublishLogEntityService } from './publish-log/publish-log-entity.service';
import { EntityDataService } from '@ngrx/data';
import { PUBLISHLOG_ENTITY } from 'src/app/state/app.state';

@NgModule({
	declarations: [],
	imports: [],
	providers: [
		PublishLogDataService,
		PublishLogEntityService,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class EntityPublishModule {
	constructor(
		entityDataService: EntityDataService,
		publishLogDataService: PublishLogDataService,
	) {
		entityDataService.registerService(
			PUBLISHLOG_ENTITY,
			publishLogDataService,
		);
	}
}
