import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderTypeEnum } from '../../carousel-elements/carousel-slider/slider-type';
import { FacebookCarouselBaseDirective } from '../../directives/fb-carousel-base.directive';
import { FacebookCarouselSupportedPlacements } from '../../enums';
import { FacebookCarouselPlacementsComponentStore } from '../../fb-carousel-placements.component.store';
import { CarouselSliderComponent } from '../../carousel-elements/carousel-slider/carousel-slider.component';
import { FbInstagramExploreHomeCtaButtonComponent } from './cta-button/cta-button.component';
import { FbInstagramExploreHomePlacementHeaderComponent } from './placement-header/placement-header.component';
import { FbInstagramExploreHomeCarouselCardComponent } from './carousel-card/carousel-card.component';

@Component({
	selector: 'fb-carousel-instagram-explore-home',
	templateUrl: './fb-instagram-explore-home.component.html',
	styleUrls: ['./fb-instagram-explore-home.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		CarouselSliderComponent,
		FbInstagramExploreHomeCtaButtonComponent,
		FbInstagramExploreHomePlacementHeaderComponent,
		FbInstagramExploreHomeCarouselCardComponent,
	],
})
export class FacebookCarouselInstagramExploreHomeComponent extends FacebookCarouselBaseDirective {
	public sliderTypeEnum: typeof SliderTypeEnum = SliderTypeEnum;

	constructor(
		carouselPlacementsStore: FacebookCarouselPlacementsComponentStore,
	) {
		super(
			carouselPlacementsStore,
			FacebookCarouselSupportedPlacements.FacebookInstagramExploreHome,
		);
	}
}
