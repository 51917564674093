import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { ConfigService } from 'src/app/core/config/config.service';
import { AppFeatureServices } from 'src/app/features/app-feature.service';

@Injectable({
	providedIn: 'root',
})
export class NavigationService implements OnDestroy {
	private unsubscribe$ = new Subject<void>();
	constructor(
		private appFeatureServices: AppFeatureServices,
		private router: Router,
	) {}

	public navigateToCreativeSet(brandId: string, creativeSetId: string): void {
		const creativeSetUrl = `${ConfigService.config.STUDIO_URL}/brand/${brandId}/creativeset/${creativeSetId}`;
		window.open(creativeSetUrl, '_blank');
	}

	public navigateToList(): void {
		const campaign$ =
			this.appFeatureServices.campaignFeature.campaign.load();
		const user$ = this.appFeatureServices.accountFeature.user.load();

		combineLatest([campaign$, user$])
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(([campaign, user]) => {
				const url = `/${user.accountSlug}/${user.brandSlug}/campaign/${campaign.id}/list`;
				this.router.navigate([url]);
			});
	}

	public ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
