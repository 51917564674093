import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { TIKTOK_AD_GROUP_ENTITY } from 'src/app/state/app.state';
import { ITiktokAdGroup } from 'src/app/core/models';

@Injectable()
export class TiktokAdGroupDataService extends DefaultDataService<ITiktokAdGroup> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(TIKTOK_AD_GROUP_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: {
		integrationId: string;
		adAccountId: string;
		campaignId: string;
	}): Observable<ITiktokAdGroup[]> {
		return this.appCoreServices.socialCampaignApiService.tiktok.adGroupApi.getAdGroups(
			params.integrationId,
			params.adAccountId,
			params.campaignId,
		);
	}
}
