import { Injectable } from '@angular/core';
import { IPlacementTemplate } from 'src/app/core/models';
import {
	IAdContentTemplateVM,
	IPlacementBaseVM,
} from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class AdPlacementTemplateMapperService {
	public mapPlacements(placements: IPlacementTemplate[]): IPlacementBaseVM[] {
		return placements.map((placement) => this.mapPlacement(placement));
	}

	public mapPlacement(placement: IPlacementTemplate): IPlacementBaseVM {
		return {
			...placement,
		};
	}

	public mapCombinedContentTemplates(
		placements: IPlacementBaseVM[],
	): IAdContentTemplateVM[] {
		const contentTemplates: IAdContentTemplateVM[] = [];

		placements.forEach((placement) => {
			const { adContentTemplates } = placement;

			adContentTemplates.forEach((template) => {
				const existingTemplate = contentTemplates.find(
					({ id }) => id === template.id,
				);

				if (!existingTemplate) {
					const newTemplate = {
						...template,
						placementsIds: [placement.id],
					};

					contentTemplates.push(newTemplate);
				} else {
					existingTemplate.placementsIds?.push(placement.id);
				}
			});
		});

		return contentTemplates;
	}
}
