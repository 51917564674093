<div
    class="primary-text"
    [ngClass]="placementId"
    [class.expanded]="isExpanded"
    [class.collapsed]="!isExpanded">
    <div *ngIf="primaryTextValue; else Primarydefault">
        <div class="text-info">{{primaryTextValue}}</div>
    </div>
    <ng-template #Primarydefault>
        <div class="text-info default-container">
            <div class="first-line"></div>
            <div class="second-line"></div>
            <div class="third-line"></div>
        </div>
    </ng-template>
</div>
