@for (placementId of (placements$ | async); track $index) {
<div
    *ngrxLet="placementsMetaData$ | async as placementsMetaData"
    class="item"
    [ngClass]="{'in-active': !(isPlacementMatchedCampaignObjective(placementId) | async), 'menu-active': activePlacement===placementId}"
    [uiTooltip]="'Shared.PLACEMENT_IS_MISSING' | text"
    [uiTooltipDisabled]="(isPlacementMatchedCampaignObjective(placementId) | async)"
    uiTooltipTrigger="hover"
    uiTooltipPosition="bottom"
    [in-ad-preview]="{inPreview, targetUrl: placementsMetaData?.[placementId]?.targetUrl}"
    [uiTooltipHideArrow]="true"
    [uiTooltipDelay]="0">
    <placement-header>
        <ng-container role="title">
            <div [ngClass]="{'placement-header': !inPreview}">
                <placement-title
                    [placementId]="placementId"
                    [adFormatId]="placementsMetaData?.[placementId]?.formatId" />
            </div>
        </ng-container>
        <ng-container role="actions">
            <ng-container *ngIf="someCardsHasVideo$ | async">
                <placement-sound
                    *ngIf="(placementsSound$ | async) as placementsSound"
                    [ngClass]="placementsSound[placementId] === soundEnum.On ? 'action-visible' : 'action-invisible'"
                    [sound]="placementsSound[placementId]"
                    (onSoundChanged)="onSoundChange($event, placementId)" />
            </ng-container>
        </ng-container>
    </placement-header>

    <div class="placement" *ngIf="placementId">
        <fb-placements-wrapper
            [placementId]="placementId"
            [adFormatId]="placementsMetaData?.[placementId]?.formatId">
            <target-url
                *ngIf="hoveredPlacement === placementId"
                [targetUrl]="placementsMetaData?.[placementId]?.targetUrl" />
        </fb-placements-wrapper>
    </div>
</div>
}
