import { Injectable } from '@angular/core';
import {
	IAd,
	IAdContentDictionary,
	IAssignedCreative,
} from 'src/app/core/models';
import { IAdPlacement } from 'src/app/core/models/ad/ad/ad-placement.model';
import { ICarouselCard } from 'src/app/core/models/ad/ad/carousel-card.model';
import { CreativesAdMapperService } from 'src/app/features/ad/mappers/ad-mapper/creatives-ad.mapper';
import { FacebookAdContentIdEnum } from 'src/app/presentation/features/integrations/facebook/ad/enums/fb-ad-content-id.enum';
import { MIN_CARDS_AMOUNT } from 'src/app/presentation/features/integrations/facebook/placement/carousel/const/carousel-cards-limit.const';
import {
	AdContentTemplateScopeEnum,
	IAdContentTemplateVM,
	IAssignedCreativeVM,
	IFacebookAdVM,
	IFacebookCarouselCardVM,
	IFacebookCarouselTemplateVM,
	IFacebookPlacementVM,
	MediaTypeEnum,
	TemplateTypeEnum,
} from 'src/app/presentation/view-models';
import { IMixedContentVM } from 'src/app/presentation/view-models/ad/ad-mixed-content.vm';
import { IFacebookAdBulkVM } from 'src/app/presentation/view-models/facebook/fb-ad-bulk.vm';

@Injectable({
	providedIn: 'root',
})
export class CarouselAdMapperService {
	constructor(private creativesMapper: CreativesAdMapperService) {}

	public toAd(adVM: IFacebookAdVM): IAd {
		const adContent: IAdContentDictionary =
			this.fromCommonContentVMToAdDictionary(
				adVM.carouselContent.commonContentTemplates,
			);
		const carouselCardContent: ICarouselCard[] = this.fromCardsVMToCards(
			adVM.carouselContent.carouselCards,
		);

		const ad: IAd = {
			id: adVM.id,
			name: adVM.name,
			languageId: adVM.languageId,
			adFormatId: adVM.adFormatId,
			default: {
				content: adContent,
				creatives: [],
				cards: carouselCardContent,
			},
		} as IAd;

		return ad;
	}

	public toContentVM(
		commonContent: IAdContentDictionary,
		placements: IFacebookPlacementVM[],
		allCarouselCards: ICarouselCard[] = [],
	): IFacebookCarouselTemplateVM {
		if (!placements) {
			return undefined;
		}

		const commonContentTemplates = this.getContentTemplates(
			commonContent,
			placements,
			AdContentTemplateScopeEnum.ad,
		);

		const carouselCards = this.toCarouselCardsVM(
			allCarouselCards,
			placements,
		);

		const facebookCarouselTemplate: IFacebookCarouselTemplateVM = {
			commonContentTemplates,
			carouselCards,
		};

		return facebookCarouselTemplate;
	}

	public getContentTemplates(
		content: IAdContentDictionary,
		placements: IFacebookPlacementVM[],
		scope?: AdContentTemplateScopeEnum,
	): IAdContentTemplateVM[] {
		const contentTemplates: IAdContentTemplateVM[] = [];

		placements.forEach((placement) => {
			const { adContentTemplates } = placement;

			adContentTemplates.forEach((template) => {
				const existingTemplate = contentTemplates.find(
					(commonContentTemplate) =>
						commonContentTemplate.id === template.id,
				);

				if (existingTemplate) {
					existingTemplate.placementsIds?.push(placement.id);

					return;
				}

				const newTemplate: IAdContentTemplateVM = {
					id: template.id,
					name: template.name,
					type: template.type,
					value:
						content?.[template.id] ||
						template.metadata?.defaultValue ||
						'',
					metadata: template.metadata,
					scope: template.scope,
					subTemplates: template.subTemplates,
					allowedValues: template.allowedValues,
					placementsIds: [placement.id],
				};

				if (template.type === TemplateTypeEnum.selection) {
					const selectedId =
						content?.[template.id] ??
						template.metadata.defaultValue;
					let selectedValue;

					if (selectedId) {
						selectedValue = { id: selectedId };

						if (template.allowedValues?.length) {
							selectedValue.name = template.allowedValues.find(
								(value) => value.id === selectedId,
							)?.name;
						}
					} else {
						selectedValue = '';
					}

					newTemplate.selectedValue = selectedValue;
				}

				if (template.subTemplates) {
					newTemplate.subTemplates = template.subTemplates.map(
						(subTemplate) => {
							if (
								subTemplate.template.type ===
								TemplateTypeEnum.selection
							) {
								const selectedId =
									content?.[subTemplate.template.id];
								let selectedValue;

								if (selectedId) {
									selectedValue = { id: selectedId };

									if (
										subTemplate.template.allowedValues
											?.length
									) {
										selectedValue.name =
											subTemplate.template.allowedValues.find(
												(value) =>
													value.id === selectedId,
											)?.name;
									}
								} else {
									selectedValue = '';
								}

								return {
									...subTemplate,
									template: {
										...subTemplate.template,
										selectedValue,
										value: selectedId || '',
									},
								};
							}

							return subTemplate;
						},
					);
				}

				contentTemplates.push(newTemplate);
			});
		});

		return scope
			? contentTemplates.filter((template) => template.scope === scope)
			: contentTemplates;
	}

	public toCarouselCardsVM(
		allCarouselCards: ICarouselCard[] = [],
		placements: IFacebookPlacementVM[] = null,
	): IFacebookCarouselCardVM[] {
		return allCarouselCards?.length
			? allCarouselCards.map((card) =>
					this.toCarouselCardVM(card, placements),
				)
			: this.generateMinAmountOfCards(placements);
	}

	public getCreativeIdsFromPlacementCards(placement: IAdPlacement): string[] {
		return placement.cards.map((card) => card.creative?.setId);
	}

	public toBulkAd(adVM: IFacebookAdBulkVM, savedAdVM: IFacebookAdVM): IAd {
		const { id, name, adFormatId, languageId } = savedAdVM;

		const content: IAdContentDictionary =
			this.fromCombinedContentVMToContent(
				adVM.carouselContent.commonContentTemplates,
				savedAdVM.carouselContent.commonContentTemplates,
				adVM.mixedDefaultContent?.content,
			);
		const cards: ICarouselCard[] = this.fromCombinedAdVMToCards(
			adVM,
			savedAdVM,
		);

		const ad: IAd = {
			id,
			name,
			adFormatId,
			languageId,
			default: {
				content,
				creatives: [],
				cards,
			},
		} as IAd;

		return ad;
	}

	private fromCombinedContentVMToContent(
		templates: IAdContentTemplateVM[],
		savedTemplatesVM: IAdContentTemplateVM[],
		mixedContent: IMixedContentVM,
	): IAdContentDictionary {
		const content: IAdContentDictionary =
			this.fromCommonContentVMToAdDictionary(templates);
		const savedContent: IAdContentDictionary =
			this.fromCommonContentVMToAdDictionary(savedTemplatesVM);

		if (mixedContent) {
			Object.entries(mixedContent).forEach(([templateId, isMixed]) => {
				if (!isMixed) {
					return;
				}

				const isMixedMultipleValueUntouched =
					Array.isArray(content[templateId]) &&
					content[templateId].length === 1 &&
					content[templateId][0] === '';
				const isValueEmpty =
					content[templateId] === '' || !content[templateId];

				if (isValueEmpty || isMixedMultipleValueUntouched) {
					content[templateId] = savedContent[templateId];
				}
			});
		}

		return content;
	}

	private fromCombinedAdVMToCards(
		adVM: IFacebookAdBulkVM,
		savedAdVM: IFacebookAdVM,
	): ICarouselCard[] {
		const cards: ICarouselCard[] = [];

		adVM.carouselContent.carouselCards.forEach((cardVM, index) => {
			const savedCardVM = savedAdVM.carouselContent.carouselCards[index];

			if (!savedCardVM) {
				return;
			}

			const isMixedCreativeUntouched =
				adVM.mixedDefaultContent?.cards[index]?.creatives &&
				!cardVM.adCreatives?.length;

			const carouselCard: ICarouselCard = {
				order: index,
				creatives: isMixedCreativeUntouched
					? this.fromCreativesVMToCreatives(
							savedCardVM.adCreatives,
							savedCardVM.mediaType,
						)
					: this.fromCreativesVMToCreatives(
							cardVM.adCreatives,
							cardVM.mediaType,
						),
				content: this.fromCombinedContentVMToContent(
					cardVM.cardContentTemplates,
					savedAdVM.carouselContent.carouselCards[index]
						.cardContentTemplates,
					adVM.mixedDefaultContent?.cards[index]?.content,
				),
			};

			cards.push(carouselCard);
		});

		return cards;
	}

	private toCarouselCardVM(
		card: ICarouselCard,
		placements: IFacebookPlacementVM[],
	): IFacebookCarouselCardVM {
		return {
			mediaType: card?.creatives?.[0]?.renderingOption,
			cardContentTemplates: placements
				? this.getContentTemplates(
						card?.content,
						placements,
						AdContentTemplateScopeEnum.carouselCard,
					)
				: null,
			adCreatives: card?.creatives?.length
				? card.creatives.map((creative) =>
						this.creativesMapper.toCreativeVM(creative),
					)
				: [],
			defaultContent: { ...card.content },
		};
	}

	private generateMinAmountOfCards(
		placements: IFacebookPlacementVM[] = null,
	): IFacebookCarouselCardVM[] {
		return [...Array(MIN_CARDS_AMOUNT)].map(() =>
			this.toCarouselCardVM({}, placements),
		);
	}

	private fromCardsVMToCards(
		carouselCardsVM: IFacebookCarouselCardVM[],
	): ICarouselCard[] {
		const carouselCards: ICarouselCard[] = [];

		carouselCardsVM.forEach((carouselCardVM, index) => {
			const carouselCard: ICarouselCard = {
				order: index,
				creatives: this.fromCreativesVMToCreatives(
					carouselCardVM.adCreatives,
					carouselCardVM.mediaType,
				),
				content: this.fromCommonContentVMToAdDictionary(
					carouselCardVM.cardContentTemplates,
				),
			};

			carouselCards.push(carouselCard);
		});

		return carouselCards;
	}

	private fromCommonContentVMToAdDictionary(
		commonContentTemplates: IAdContentTemplateVM[],
	): IAdContentDictionary {
		const adContent: IAdContentDictionary = {};

		function fromTemplateToValue(template): void {
			adContent[template.id] = template.value;

			if (template.id === FacebookAdContentIdEnum.callToAction) {
				adContent[template.id] = template.selectedValue.id;
			}
		}

		commonContentTemplates.forEach((contentTemplate) => {
			if (contentTemplate.type === TemplateTypeEnum.group) {
				contentTemplate.subTemplates.forEach((subTemplate) => {
					fromTemplateToValue(subTemplate.template);
				});
			} else {
				fromTemplateToValue(contentTemplate);
			}
		});

		return adContent;
	}

	private fromCreativesVMToCreatives(
		assignedCreativesVM: IAssignedCreativeVM[],
		renderingOption: MediaTypeEnum,
	): IAssignedCreative[] {
		const assignedCreatives: IAssignedCreative[] = [];

		assignedCreativesVM.forEach((assignedCreativeVM) => {
			const assignCreative: IAssignedCreative = {};

			assignCreative.setId = assignedCreativeVM.setId;
			assignCreative.id = assignedCreativeVM.id;
			assignCreative.placementsIds = assignedCreativeVM.placementsIds;
			assignCreative.renderingOption = renderingOption;

			assignedCreatives.push(assignCreative);
		});

		return assignedCreatives;
	}
}
