// script-loader.service.ts
import { ConfigService } from '../../config/config.service';
import { NewRelicOptions } from '@bannerflow/sentinel';

export class NewRelicConfig {
	public static init(): NewRelicOptions {
		const isProduction = ConfigService.isProduction();
		const enable =
			ConfigService.isProduction() || ConfigService.isSandbox();
		const allowedOriginsProduction = [
			'https://login.bannerflow.com',
			'https://api.bannerflow.com/list-service',
			'https://studio.bannerflow.com',
			'https://api.bannerflow.com/brand-manager',
			'https://app.bannerflow.com',
			'https://sapi.bannerflow.com',
			'https://acg.bannerflow.com',
			'https://sas.bannerflow.com',
			'https://sps.bannerflow.com',
			'https://scs.bannerflow.com',
		];

		const allowedOriginsSandbox = [
			'https://sandbox-login.bannerflow.com',
			'https://sandbox-api.bannerflow.com/list-service',
			'https://sandbox-studio.bannerflow.com',
			'https://sandbox-api.bannerflow.com/brand-manager',
			'https://sandbox-app.bannerflow.com',
			'https://sandbox-sapi.bannerflow.com',
			'https://sandbox-acg.bannerflow.com',
			'https://sandbox-sas.bannerflow.com',
			'https://sandbox-scs.bannerflow.com',
			'https://sandbox-sps.bannerflow.com',
		];

		return {
			applicationId: isProduction ? '538528753' : '538568843',
			accountId: isProduction ? '4122654' : '4232543',
			agentId: isProduction ? '538528753' : '538568843',
			trustKey: '4122654',
			origins: isProduction
				? allowedOriginsProduction
				: allowedOriginsSandbox,
			licenseKey: isProduction
				? 'NRJS-bd28b6acdc31b77b97c'
				: 'NRJS-d0b27a9b958bc4b281c',
			applicationVersion: ConfigService.config?.buildInfo?.version,
			releaseName: ConfigService.config?.RELEASE_NAME,
			releaseId: ConfigService.config?.buildInfo?.version,
			enabled: enable,
		};
	}
}
