import { Injectable } from '@angular/core';
import {
	ICreative,
	ICreativeVersion,
	ISize,
	IStudioCreativeSet,
} from 'src/app/core/models';
import {
	ICreativeSCS,
	ICreativeSetSCSResponse,
	IDesignSCS,
	ISizeSCS,
	IVersionSCS,
} from './creative-set-scs-response.model';

@Injectable({
	providedIn: 'root',
})
export class CreaitveSetApiResponseMapperService {
	private mapSize(size: ISizeSCS): ISize {
		return {
			id: size.id.toString(),
			width: size.width,
			height: size.height,
			name: size.name,
		};
	}

	private mapVersion(version: IVersionSCS): ICreativeVersion {
		return {
			id: version.id.toString(),
			name: version.name,
			targetUrl: version.targetUrl,
			localization: {
				id: version.localizationId ?? '',
				name: '',
				cultureCode: '',
				cultureName: '',
			},
			injectedLanguage: null,
		};
	}

	private mapCreativeSize(creative: ICreativeSCS, sizes: ISizeSCS[]): ISize {
		const size = sizes.find((sizeOf) => sizeOf.id === creative.sizeId);
		return {
			id: creative.sizeId.toString(),
			width: size?.width ?? 0,
			height: size?.height ?? 0,
			name: size?.name ?? '',
		};
	}

	private mapCreativeVersion(
		creative: ICreativeSCS,
		versions: IVersionSCS[],
	): ICreativeVersion {
		const version = versions.find(
			(versionOf) => versionOf.id === creative.versionId,
		);
		return {
			id: creative.versionId.toString(),
			name: version?.name ?? '',
			localization: {
				id: version.localizationId ?? '',
				name: '',
				cultureCode: '',
				cultureName: '',
			},
			injectedLanguage: null,
			targetUrl: version.targetUrl,
		};
	}

	private mapCreative(
		creative: ICreativeSCS,
		sizes: ISizeSCS[],
		versions: IVersionSCS[],
		designs: IDesignSCS[],
	): ICreative {
		const version = this.mapCreativeVersion(creative, versions);

		return {
			id: creative.id.toString(),
			targetUrl: creative?.targetUrl || version?.targetUrl,
			preloadImageUrl: creative?.preloadImageUrl,
			size: this.mapCreativeSize(creative, sizes),
			version,
			design: designs.find(
				(designOf) => designOf.id === creative?.designId,
			),
			checksum: null,
		};
	}

	public mapSCSReponseToStudioCreativeSet(
		creativeSetSCS: ICreativeSetSCSResponse,
	): IStudioCreativeSet {
		return {
			id: creativeSetSCS.id.toString(),
			name: creativeSetSCS.name,
			originalVersion: undefined,
			sizes: creativeSetSCS.sizes.map(this.mapSize),
			versions: creativeSetSCS.versions.map(this.mapVersion),
			creatives: creativeSetSCS.creatives.map((creative) =>
				this.mapCreative(
					creative,
					creativeSetSCS.sizes,
					creativeSetSCS.versions,
					creativeSetSCS.designs,
				),
			),
		};
	}
}
