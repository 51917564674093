import { Injectable } from '@angular/core';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { AdScheduleBaseService } from './ad-schedule-base.service';
import { AdScheduleMappersService } from '../../mappers/ad-schedule/ad-schedule.mapper';
import { Observable, map } from 'rxjs';
import { IAdScheduleVM } from 'src/app/presentation/view-models/ad/ad-schedule.vm';
import { IAdSchedule } from 'src/app/core/models/ad/ad-schedule/ad-schedule';

@Injectable({
	providedIn: 'root',
})
export class AdScheduleService extends AdScheduleBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public adScheduleMapper: AdScheduleMappersService,
	) {
		super(appEntityServices, adScheduleMapper);
	}

	public saveAdSchedule(adSchedule: IAdSchedule): Observable<IAdScheduleVM> {
		return this.appEntityServices.adEntity.adSchedule
			.add(adSchedule)
			.pipe(
				map(() => this.adScheduleMapper.mapAdScheduleToVM(adSchedule)),
			);
	}
}
