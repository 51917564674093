import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { IIntegration } from 'src/app/core/models';
import { INTEGRATION_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class IntegrationsEntityService extends EntityCollectionServiceBase<IIntegration> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(INTEGRATION_ENTITY, serviceElementsFactory);
	}
}
