<ng-container>
    <p class="title">Select creative</p>
    <div *ngIf="isLoadingCreativeSet$ | async; else content" class="loader-container">
        <ui-loader></ui-loader>
    </div>
    <ng-template #content>
        <div
            class="contents"
            *ngIf="(selectedCreativeSet$ | async)[ratioGroup]?.creatives as creatives; else selectCreativeSet">
            <div class="creatives" *ngIf="creatives.length > 0 ; else noCreatives">
                <customize-creative-card
                    *ngFor="let creative of creatives"
                    [creativeChoice]="creative"
                    [aspectRatioGroupKey]="ratioGroup">
                </customize-creative-card>
            </div>
        </div>
        <ng-template #selectCreativeSet>
            <div class="empty-default">
                {{ "CreativeSetComponent.SELECT_CREATIVE_SET" | text }}
            </div>
        </ng-template>
        <ng-template #noCreatives>
            <div class="empty-default">
                {{ "CreativeSetComponent.NO_CREATIVES" | text }}
            </div>
        </ng-template>
    </ng-template>
</ng-container>
