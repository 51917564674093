import { Component, Input } from '@angular/core';
import { AdContentIdEnum, FacebookSupportedPlacements } from '../enums';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	imports: [CommonModule],
	selector: 'primary-text',
	templateUrl: './primary-text.component.html',
	styleUrls: ['./primary-text.component.scss'],
})
export class PrimaryTextComponent {
	public adContentId: typeof AdContentIdEnum = AdContentIdEnum;

	@Input() public primaryTextValue: string;
	@Input() public placementId: FacebookSupportedPlacements;
	@Input() public isExpanded?: boolean;
}
