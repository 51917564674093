import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ITiktokAdGroup } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { TiktokAdGroupMapperService } from '../../mappers/tt-ad-group.mapper';
import { TiktokAdGroupBaseService } from './tt-ad-group-base.service';
import { ITiktokAdGroupVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class TiktokAdGroupService extends TiktokAdGroupBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public tiktokAdGroupMapper: TiktokAdGroupMapperService,
	) {
		super(appEntityServices);
	}

	public loadAllByCampaignId(
		campaignId: string,
	): Observable<ITiktokAdGroupVM[]> {
		const adGroups$: Observable<ITiktokAdGroup[]> =
			this.appEntityServices.tiktokEntity.adGroup.entities$;

		return adGroups$.pipe(
			map((adGroups) =>
				adGroups.filter(
					(adGroup) => adGroup?.campaignId === campaignId,
				),
			),
			map((adGroups) => this.tiktokAdGroupMapper.toAdGroupsVM(adGroups)),
		);
	}

	public isLoadingAdGroups(): Observable<boolean> {
		return this.appEntityServices.tiktokEntity.adGroup.loading$;
	}
}
