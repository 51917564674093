<ng-container>
    <div class="placement-card">
        <div class="content">
            <facebook-placement-header
                [placementId]="placementId.FacebookInstagramStories"
                [hasCloseCross]="true"></facebook-placement-header>
            <primary-text
                [primaryTextValue]="placementMetaData.primaryTextValue"
                [placementId]="placementId.FacebookInstagramStories"></primary-text>
            <stories-footer
                [ctaValue]="placementMetaData.callToAction"
                [placementId]="placementId.FacebookInstagramStories"></stories-footer>
        </div>

        <div class="background">
            <ng-container
                *ngIf="selectedCustomizedCreative?.id || placementMetaData?.creative?.id || placementMetaData?.creativeUrl">
                <creative-content
                    [sound]="placementSound$ | async"
                    [creativeUrl]="placementMetaData?.creativeUrl"
                    [isCustomized]="placementMetaData?.isCustomized"
                    [creativeId]="selectedCustomizedCreative?.id || placementMetaData?.creative?.id"
                    [creativeSetId]="selectedCustomizedCreative?.setId || placementMetaData?.creative?.setId"
                    [selectedMediaType]="placementMetaData?.selectedMediaType"
                    [fitToHeight]="true"
                    [width]="254"
                    [height]="455"></creative-content>
            </ng-container>
        </div>
    </div>
</ng-container>
