import { Injectable } from '@angular/core';
import { ITiktokIdentity } from 'src/app/core/models';
import { ITiktokIdentityVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class TiktokIdentityMapperService {
	public toIdentityVM(identity: ITiktokIdentity): ITiktokIdentityVM {
		const identityVM: ITiktokIdentityVM = {
			id: identity.id,
			name: identity.name,
		};

		return identityVM;
	}

	public toIdentitiesVM(identities: ITiktokIdentity[]): ITiktokIdentityVM[] {
		return identities.map((identity) => this.toIdentityVM(identity));
	}
}
