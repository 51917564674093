export interface IAdScheduleVM {
	adId: string;
	startDate: number;
	endDate: number;
	timezoneId: string;
}

export interface IAdToScheduleVM {
	id: string;
	integrationId: string;
}

export enum ScheduleRuleEnum {
	StartDateOnly = 'START_DATE_ONLY',
	EndDateOnly = 'END_DATE_ONLY',
	StartEndDate = 'START_END_DATE',
}
