import { Injectable } from '@angular/core';
import {
	IAdContentTemplate,
	IAdFormat,
	IPlacementOptions,
	IPlacementTemplate,
} from 'src/app/core/models';
import {
	FacebookSupportedPlacements,
	PlacementCategoryEnum,
} from 'src/app/presentation/features/integrations/facebook/placement/single/placements/shared/enums';
import {
	IAdContentTemplateVM,
	IFacebookAdFormatVM,
	IFacebookPlacementVM,
	IPlacementOptionsVM,
	TemplateTypeEnum,
} from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class FacebookPlacementMappersService {
	public mapAdFormat(adFormat: IAdFormat): IFacebookAdFormatVM {
		return {
			id: adFormat.id,
			name: adFormat.name,
		};
	}

	public mapAdContentTemplateToVM(
		adContentTemplate: IAdContentTemplate,
	): IAdContentTemplateVM {
		return {
			...adContentTemplate,
			value:
				adContentTemplate.type === TemplateTypeEnum.textCollection
					? [
							{
								isActive: true,
								value: '',
							},
						]
					: '',
		};
	}

	public mapPlacements(
		placements: IPlacementTemplate[],
	): IFacebookPlacementVM[] {
		return placements.map((placement) => this.mapPlacement(placement));
	}

	public mapPlacement(placement: IPlacementTemplate): IFacebookPlacementVM {
		return {
			...placement,
			adContentTemplates: placement.adContentTemplates.map(
				(adContentTemplate) =>
					this.mapAdContentTemplateToVM(adContentTemplate),
			),
		};
	}

	public mapPlacementsWithRenderingOptions(
		placements: IPlacementOptions[],
	): IPlacementOptionsVM[] {
		return placements.map((placement) =>
			this.mapPlacementWithRenderingOptions(placement),
		);
	}

	public mapPlacementWithRenderingOptions(
		placement: IPlacementOptions,
	): IPlacementOptionsVM {
		return {
			...placement,
		};
	}

	public selectPlacementCategory(placementId: string): PlacementCategoryEnum {
		switch (placementId) {
			case FacebookSupportedPlacements.FacebookInstagramExplore:
				return PlacementCategoryEnum.instagram;
			case FacebookSupportedPlacements.FacebookInstagramExploreHome:
				return PlacementCategoryEnum.instagram;
			case FacebookSupportedPlacements.FacebookInstagramFeed:
				return PlacementCategoryEnum.instagram;
			case FacebookSupportedPlacements.FacebookInstagramReels:
				return PlacementCategoryEnum.instagram;
			case FacebookSupportedPlacements.FacebookInstagramStories:
				return PlacementCategoryEnum.instagram;
			case FacebookSupportedPlacements.FacebookMessengerInbox:
				return PlacementCategoryEnum.messenger;
			case FacebookSupportedPlacements.FacebookMessengerStories:
				return PlacementCategoryEnum.messenger;
			case FacebookSupportedPlacements.FacebookAudienceNetworkNative:
				return PlacementCategoryEnum.audience;
			default:
				return PlacementCategoryEnum.facebook;
		}
	}
}
