import { Injectable, Injector } from '@angular/core';
import { BrandService } from './services/brand/brand.service';
import { CreativeSetService } from './services/creative-set/creative-set.service';
import { LocalizationService } from './services/localization/localization.service';
import { UserService } from './services/user/user.service';
import { NetworkService } from './services/network/network.service';
import { IntegrationService } from './services/integration/integration.service';
import { TimeZoneService } from './services/timezone/timezone.service';

@Injectable()
export class SocialAccountFeatureService {
	public user: UserService;
	public localization: LocalizationService;
	public creativeSet: CreativeSetService;
	public brand: BrandService;
	public network: NetworkService;
	public integration: IntegrationService;
	public timezone: TimeZoneService;

	constructor(private injector: Injector) {
		this.user = this.injector.get(UserService);
		this.localization = this.injector.get(LocalizationService);
		this.creativeSet = this.injector.get(CreativeSetService);
		this.brand = this.injector.get(BrandService);
		this.network = this.injector.get(NetworkService);
		this.integration = this.injector.get(IntegrationService);
		this.timezone = this.injector.get(TimeZoneService);
	}
}
