import { Injectable } from '@angular/core';
import { combineLatest, filter, map, Observable, of, switchMap } from 'rxjs';
import { IAd } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { BaseFeatureService } from '../../../base-feature.service';
import { AdMappersService } from '../../mappers/ad-mapper/ad.mapper';
import { AdValidationService } from '../ad-validation/ad-validation.service';
import { IAdVM } from 'src/app/presentation/view-models';
import { BulkAdMapperService } from '../../mappers/ad-mapper/bulk-ad-mapper';

export enum KnownUserFeature {
	SocialCampaignManager = 'SocialCampaignManager',
}

@Injectable({
	providedIn: 'root',
})
export class AdBaseService extends BaseFeatureService<IAdVM> {
	constructor(
		public appEntityServices: AppEntityServices,
		public adValidationService: AdValidationService,
		public adMapper: AdMappersService,
		public bulkMapper: BulkAdMapperService,
	) {
		super();
	}

	public getById(adId: string): void {
		this.appEntityServices.adEntity.ad.getByKey(adId);
		this.appEntityServices.adEntity.adContentValidation.clearCache();
	}

	public getByIds(adIds: string[]): void {
		this.appEntityServices.adEntity.ad.getWithQuery({ ids: adIds });
	}

	public loadById(adId: string): Observable<IAdVM> {
		const ads$: Observable<IAd[]> =
			this.appEntityServices.adEntity.ad.entities$;

		const ad$: Observable<IAd> = ads$.pipe(
			filter((ads) => !!ads.length),
			map((ads) => ads.find((ad) => ad.id === adId)),
			filter((ad) => !!ad),
		);

		return ad$.pipe(map((ad) => this.adMapper.toAdVM(ad)));
	}

	public loadByIds(adIds: string[]): Observable<IAdVM[]> {
		const adIds$: Observable<string[]> = of(adIds);

		if (adIds.length === 0) {
			return of([]);
		}

		return adIds$.pipe(
			switchMap((ids) =>
				combineLatest(ids.map((adId) => this.loadById(adId))),
			),
		);
	}

	public deleteByIds(adIds: string[]): void {
		this.appEntityServices.adEntity.ad.removeManyFromCache(adIds);
		adIds.forEach((adId) =>
			this.appEntityServices.adEntity.ad.delete(adId),
		);
	}

	protected getWithQuery(): void {
		throw new Error('Method not implemented.');
	}
	protected load(): Observable<IAdVM> {
		throw new Error('Method not implemented.');
	}
	protected loadAll(): Observable<IAdVM[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteById(_id: string): void {
		throw new Error('Method not implemented.');
	}
}
