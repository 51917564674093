<expandable-item (onToggle)="onToggle($event)">
    <div role="header">
        <div class="collection-header">
            <div class="collection-label">
                <div class="icon-arrow" [class.is-expanded]="isExpanded">
                    <ui-svg-icon icon="arrow-down" />
                </div>
                <div class="collection-label-name">
                    {{adContentTemplate.name}}
                    <span
                        class="collection-label-required"
                        *ngIf="adContentTemplate.metadata.isRequired"
                        >*</span
                    >
                </div>
                <div class="collection-label-counter">
                    {{adContentTemplate.value.length}} of
                    {{adContentTemplate.metadata.maximumNumberOfElements}}
                </div>
            </div>
        </div>
    </div>
    <div role="body">
        <div
            class="item"
            *ngFor="let value of adContentTemplate.value; trackBy: valueTrackBy let i = index">
            <div class="label">
                <div class="label-content">
                    {{adContentTemplate.name | labelTransform}}
                    <ng-container *ngIf="adContentTemplate.value.length > 1">
                        {{ i + 1 }}
                        <ui-svg-icon
                            class="visibility-icon"
                            icon="visibility-visible"
                            *ngIf="value.isActive" />
                        <ui-svg-icon
                            class="visibility-icon"
                            icon="visibility-hidden"
                            *ngIf="!value.isActive"
                            (click)="toggleVisibility(i)" />
                    </ng-container>
                </div>
                <div
                    class="recommended-max-length"
                    [ngClass]="{'is-exceeded' : value.value?.length > adContentTemplate.metadata.recommendedMaximumLength}">
                    <span>
                        {{value.value?.length ? value.value.length : 0}}<span>/</span>
                    </span>
                    <span>{{ adContentTemplate.metadata.recommendedMaximumLength }}</span>
                </div>
            </div>
            <div
                class="collection-element"
                *ngrxLet="(mixedContent?.content[adContentTemplate.id] ? 'Mixed' : adContentTemplate.metadata.placeholder) as placeholder ">
                <ui-textarea
                    *ngIf="adContentTemplate.metadata.isMultiline else uiInput"
                    [value]="value.value"
                    [placeholder]="placeholder"
                    [autofocus]="focusedInput[i]"
                    (keyup)="onKeyUpChange($event, i)">
                </ui-textarea>

                <ng-template #uiInput>
                    <ui-input
                        class="interaction-placement-content-input"
                        [value]="value.value"
                        [placeholder]="placeholder"
                        [maxlength]="adContentTemplate.metadata.maximumLength"
                        [autofocus]="focusedInput[i]"
                        (keyup)="onKeyUpChange($event, i)">
                    </ui-input>
                </ng-template>

                <div
                    class="icon-delete"
                    [ngClass]="{'icon-delete-disabled': adContentTemplate.value.length <= minNumberOfElements } "
                    (click)="adContentTemplate.value.length > minNumberOfElements ? deleteContentByIndex(i) : null">
                    <ui-svg-icon icon="delete" />
                </div>
            </div>
            <div class="validation-error-container">
                <validation-result
                    *ngIf="isAdDirty && (adContentValidation?.[adContentTemplate.id] | find: 'index' : i) as validationResult"
                    [validationResult]="validationResult">
                </validation-result>
            </div>
        </div>
        <div
            class="collection-element-add"
            [ngClass]="adContentTemplate.value.length < adContentTemplate.metadata.maximumNumberOfElements ? 'collection-element-add-active-text' : 'collection-element-add-disabled-text'">
            <ui-svg-icon icon="plus" />
            <div
                class="collection-element-add-text"
                (click)="adContentTemplate.value.length < adContentTemplate.metadata.maximumNumberOfElements ? addContent() : null">
                ADD ONE MORE {{adContentTemplate.name | labelTransform | uppercase}}
            </div>
        </div>
    </div>
</expandable-item>
