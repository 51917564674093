import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { IFacebookCarouselCardMetaDataVM } from 'src/app/presentation/view-models';
import { CreativeContentComponent } from '../../../../../../../elements/creative-content/creative-content.component';

@Component({
	selector: 'fb-instagram-explore-home-carousel-card',
	templateUrl: './carousel-card.component.html',
	styleUrls: ['./carousel-card.component.scss'],
	standalone: true,
	imports: [CommonModule, UIModule, CreativeContentComponent],
})
export class FbInstagramExploreHomeCarouselCardComponent {
	@Input() public card: IFacebookCarouselCardMetaDataVM;
}
