import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { IPlacementOptions } from 'src/app/core/models';
import { FACEBOOK_CAROUSEL_PLACEMENT_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class FacebookCarouselPlacementsEntityService extends EntityCollectionServiceBase<IPlacementOptions> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(FACEBOOK_CAROUSEL_PLACEMENT_ENTITY, serviceElementsFactory);
	}
}
