import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'fb-cta-button',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './fb-cta-button.component.html',
	styleUrls: ['./fb-cta-button.component.scss'],
})
export class FbCtaButtonComponent {
	@Input({ required: true }) public ctaValue: string;
	@Input() public isPrimary?: boolean;
}
