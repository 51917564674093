<ng-container *ngIf="descriptionValue; else skeleton">
    <div
        class="description-text"
        [style.max-width.px]="width"
        [style.color]="'var('+color+ ')'">
        {{ descriptionValue }}
    </div>
</ng-container>
<ng-template #skeleton>
    <div class="description-default" [style.width.px]="width"></div>
</ng-template>
