import { IFacebookAdStatusVM } from 'src/app/presentation/view-models';
import { Injectable } from '@angular/core';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { BaseFeatureService } from 'src/app/features/base-feature.service';
import { Observable } from 'rxjs';
import { IAdStatus } from 'src/app/core/models';

@Injectable({
	providedIn: 'root',
})
export class PublishBaseService extends BaseFeatureService<IFacebookAdStatusVM> {
	constructor(public appEntityServices: AppEntityServices) {
		super();
	}

	public getWithQuery(params: {
		adId: string;
		integrationId: string;
	}): Observable<IAdStatus[]> {
		return this.appEntityServices.adEntity.adStatus.getWithQuery(params);
	}

	protected getById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected load(): Observable<IFacebookAdStatusVM> {
		throw new Error('Method not implemented.');
	}
	protected loadAll(): Observable<IFacebookAdStatusVM[]> {
		throw new Error('Method not implemented.');
	}
	protected loadById(_id: string): Observable<IFacebookAdStatusVM> {
		throw new Error('Method not implemented.');
	}
	protected loadByIds(_ids: string[]): Observable<IFacebookAdStatusVM[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
