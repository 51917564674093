<div *ngIf="(routeView === 'adlist' && (isAdsSelected$ | async)); else showAppHeader">
    <publish-summary-header></publish-summary-header>
</div>

<ng-template #showAppHeader>
    <app-header
        *ngIf="routeView !== 'adeditor' && routeView !== 'publish' && routeView !== 'creativeset'"
        [routeView]="routeView"></app-header>
</ng-template>

<router-outlet></router-outlet>
