<div class="placement-header">
    <creative-deactivation-warning
        class="creative-deactivated-icon"
        [creativeSetId]="assignedCreative?.setId"
        [creativeId]="assignedCreative?.id" />
    <div class="placement-header-title">
        <ng-content select="[role=title]"></ng-content>
    </div>
    <div class="placement-header-actions">
        <ng-content select="[role=actions]"></ng-content>
    </div>
</div>
