import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { ITiktokCampaign } from 'src/app/core/models';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { TiktokCampaignMapperService } from '../../mappers/tt-campaign.mapper';
import { TiktokCampaignBaseService } from './tt-campaign-base.service';
import { ITiktokCampaignVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class TiktokCampaignService extends TiktokCampaignBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		public tiktokCampaignMapper: TiktokCampaignMapperService,
	) {
		super(appEntityServices, tiktokCampaignMapper);
	}

	public loadAllByAccountId(
		adAccountId: string,
	): Observable<ITiktokCampaignVM[]> {
		const campaigns$: Observable<ITiktokCampaign[]> =
			this.appEntityServices.tiktokEntity.campaign.entities$;

		return campaigns$.pipe(
			filter((campaigns) => !!campaigns),
			map((campaigns) =>
				campaigns.filter(
					(campaign) => campaign.adAccountId === adAccountId,
				),
			),
			map((campaigns) =>
				this.tiktokCampaignMapper.toCampaignsVM(campaigns),
			),
		);
	}

	public isLoadingCampaigns(): Observable<boolean> {
		return this.appEntityServices.tiktokEntity.campaign.loading$;
	}
}
