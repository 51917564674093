<ng-container>
    <div class="placement-card">
        <facebook-placement-header
            [placementId]="placementId.FacebookGroupsFeed"></facebook-placement-header>

        <primary-text
            [primaryTextValue]="placementMetaData.primaryTextValue"
            [placementId]="placementId.FacebookGroupsFeed"></primary-text>

        <div class="creative-content">
            <ng-container
                *ngIf="selectedCustomizedCreative?.id || placementMetaData?.creative?.id || placementMetaData?.creativeUrl">
                <creative-content
                    [sound]="placementSound$ | async"
                    [creativeUrl]="placementMetaData?.creativeUrl"
                    [isCustomized]="placementMetaData?.isCustomized"
                    [creativeId]="selectedCustomizedCreative?.id || placementMetaData?.creative?.id"
                    [creativeSetId]="selectedCustomizedCreative?.setId || placementMetaData?.creative?.setId"
                    [selectedMediaType]="placementMetaData?.selectedMediaType"></creative-content>
            </ng-container>
        </div>

        <div class="social-info">
            <div class="description">
                <display-link
                    [displayLinkValue]="placementMetaData.displayLinkValue"
                    [placementId]="placementId.FacebookGroupsFeed"></display-link>
                <div class="headline">
                    <headline
                        [headLineValue]="placementMetaData.headLineValue"
                        [placementId]="placementId.FacebookGroupsFeed"></headline>
                </div>

                <description
                    [descriptionValue]="placementMetaData.descriptionValue"
                    [placementId]="placementId.FacebookGroupsFeed"></description>
            </div>
            <cta-button
                [ctaValue]="placementMetaData.callToAction"
                [placementId]="placementId.FacebookGroupsFeed">
            </cta-button>
        </div>

        <facebook-placement-footer></facebook-placement-footer>
    </div>
</ng-container>
