import { Component, Input, OnInit } from '@angular/core';
import { UIButtonGroupOption, UIModule } from '@bannerflow/ui';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { FacebookPlacementCoreBaseDirective } from '../../../../shared/directives/facebook-placement-core-base.directive';
import { FacebookSupportedPlacements } from '../../../../shared/enums';
import { CommonModule } from '@angular/common';
import { InboxCollapsedComponent } from '../../inbox-collapsed/inbox-collapsed.component';
import { InboxExpandedComponent } from '../../inbox-expanded/inbox-expanded.component';
import { AdPlacementPreviewDirective } from 'src/app/presentation/pages/shared/directives/ad-placement-preview.directive';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		UIModule,
		InboxCollapsedComponent,
		InboxExpandedComponent,
		AdPlacementPreviewDirective,
	],
	selector: 'facebook-messanger-inbox-core',
	templateUrl: './fb-messanger-inbox-core.component.html',
	styleUrls: ['./fb-messanger-inbox-core.component.scss'],
})
export class FacebookMessangerInboxCoreComponent
	extends FacebookPlacementCoreBaseDirective
	implements OnInit
{
	@Input() public inPreview: boolean;
	@Input() public adId: string;
	@Input() public adGroupId: string;

	constructor(
		public appFeatureServices: AppFeatureServices,
		private textPipe: TextPipe,
	) {
		super(
			appFeatureServices,
			FacebookSupportedPlacements.FacebookMessengerInbox,
		);
	}

	public readonly viewOptions: UIButtonGroupOption[] = [
		{
			id: 'left-button',
			text: this.textPipe.transform(
				'FacebookMessengerInboxComponent.AD_VIEW',
			),
			value: 'ad-view',
		},
		{
			id: 'right-button',
			text: this.textPipe.transform(
				'FacebookMessengerInboxComponent.EXPANDED_VIEW',
			),
			value: 'expanded-view',
		},
	];

	public selectedButton: any;

	public ngOnInit(): void {
		this.selectedButton = 'ad-view';
	}

	public onViewChange(viewName: string): void {
		this.selectedButton = viewName;
	}
}
