<div *ngIf="(placementMetaData$ | async) as placementMetaData">
    <div [ngSwitch]="placementId">
        <div *ngSwitchCase="facebookSupportedPlacements.FacebookNewsFeed">
            <facebook-news-feed-placement-core
                [placementMetaData]="placementMetaData"
                [selectedCustomizedCreative]="(selectedCreative$ | async)"></facebook-news-feed-placement-core>
        </div>
        <div *ngSwitchCase="facebookSupportedPlacements.FacebookGroupsFeed">
            <facebook-groups-feed-placement-core
                [placementMetaData]="placementMetaData"
                [selectedCustomizedCreative]="(selectedCreative$ | async)"></facebook-groups-feed-placement-core>
        </div>
        <div *ngSwitchCase="facebookSupportedPlacements.FacebookSearchResults">
            <facebook-search-result-core
                [placementMetaData]="placementMetaData"
                [selectedCustomizedCreative]="(selectedCreative$ | async)"></facebook-search-result-core>
        </div>
        <div *ngSwitchCase="facebookSupportedPlacements.FacebookInStreamVideos">
            <facebook-in-stream-videos-core
                [placementMetaData]="placementMetaData"
                [selectedCustomizedCreative]="(selectedCreative$ | async)"></facebook-in-stream-videos-core>
        </div>
        <div *ngSwitchCase="facebookSupportedPlacements.FacebookInstagramFeed">
            <facebook-instagram-feed-placement-core
                [placementMetaData]="placementMetaData"
                [selectedCustomizedCreative]="(selectedCreative$ | async)"></facebook-instagram-feed-placement-core>
        </div>
        <div *ngSwitchCase="facebookSupportedPlacements.FacebookAudienceNetworkNative">
            <facebook-audience-network-native-core
                [placementMetaData]="placementMetaData"
                [selectedCustomizedCreative]="(selectedCreative$ | async)"></facebook-audience-network-native-core>
        </div>
        <div *ngSwitchCase="facebookSupportedPlacements.FacebookVideoFeed">
            <facebook-video-feed-placement-core
                [placementMetaData]="placementMetaData"
                [selectedCustomizedCreative]="(selectedCreative$ | async)"></facebook-video-feed-placement-core>
        </div>
        <div *ngSwitchCase="facebookSupportedPlacements.FacebookMarketPlace">
            <facebook-marketplace-feed-placement-core
                [placementMetaData]="placementMetaData"
                [selectedCustomizedCreative]="(selectedCreative$ | async)"></facebook-marketplace-feed-placement-core>
        </div>
        <div *ngSwitchCase="facebookSupportedPlacements.FacebookRightColumn">
            <facebook-right-column-placement-core
                [placementMetaData]="placementMetaData"
                [selectedCustomizedCreative]="(selectedCreative$ | async)"></facebook-right-column-placement-core>
        </div>
        <div *ngSwitchCase="facebookSupportedPlacements.FacebookInstagramExplore">
            <facebook-instagram-explore-placement-core
                [placementMetaData]="placementMetaData"
                [selectedCustomizedCreative]="(selectedCreative$ | async)"></facebook-instagram-explore-placement-core>
        </div>
        <div *ngSwitchCase="facebookSupportedPlacements.FacebookInstagramExploreHome">
            <facebook-instagram-explore-home-placement-core
                [placementMetaData]="placementMetaData"
                [selectedCustomizedCreative]="(selectedCreative$ | async)"></facebook-instagram-explore-home-placement-core>
        </div>
        <div *ngSwitchCase="facebookSupportedPlacements.FacebookMessengerInbox">
            <facebook-messanger-inbox-core
                [placementMetaData]="placementMetaData"
                [selectedCustomizedCreative]="(selectedCreative$ | async)"></facebook-messanger-inbox-core>
        </div>
        <div *ngSwitchCase="facebookSupportedPlacements.FacebookStories">
            <facebook-stories-placement-core
                [placementMetaData]="placementMetaData"
                [selectedCustomizedCreative]="(selectedCreative$ | async)"></facebook-stories-placement-core>
        </div>
        <div *ngSwitchCase="facebookSupportedPlacements.FacebookInstagramStories">
            <facebook-instagram-stories-placement-core
                [placementMetaData]="placementMetaData"
                [selectedCustomizedCreative]="(selectedCreative$ | async)"></facebook-instagram-stories-placement-core>
        </div>
        <div *ngSwitchCase="facebookSupportedPlacements.FacebookMessengerStories">
            <facebook-messenger-stories-placement-core
                [placementMetaData]="placementMetaData"
                [selectedCustomizedCreative]="(selectedCreative$ | async)"></facebook-messenger-stories-placement-core>
        </div>
        <div *ngSwitchCase="facebookSupportedPlacements.FacebookReels">
            <facebook-reels-placement-core
                [placementMetaData]="placementMetaData"
                [selectedCustomizedCreative]="(selectedCreative$ | async)"></facebook-reels-placement-core>
        </div>
        <div *ngSwitchCase="facebookSupportedPlacements.FacebookAdsOnReels">
            <facebook-ads-on-reels-placement-core
                [placementMetaData]="placementMetaData"
                [selectedCustomizedCreative]="(selectedCreative$ | async)">
            </facebook-ads-on-reels-placement-core>
        </div>
        <div *ngSwitchCase="facebookSupportedPlacements.FacebookInstagramReels">
            <facebook-instagram-reels-placement-core
                [placementMetaData]="placementMetaData"
                [selectedCustomizedCreative]="(selectedCreative$ | async)"></facebook-instagram-reels-placement-core>
        </div>
    </div>
</div>
