import { Component, Input } from '@angular/core';
import { FacebookSupportedPlacements } from '../../../shared/enums';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';

@Component({
	standalone: true,
	imports: [CommonModule, UIModule],
	selector: 'reels-icons',
	templateUrl: './reels-icons.component.html',
	styleUrls: ['./reels-icons.component.scss'],
})
export class ReelsIconsComponent {
	@Input() public placementId: string;

	public facebookSupportedPlacements: typeof FacebookSupportedPlacements =
		FacebookSupportedPlacements;
}
