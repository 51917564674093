<ng-container *ngIf="placementMetaData$ | async as metaData">
    <div class="placement">
        <div class="creative-content">
            <ng-container
                *ngIf="metaData.cards[0]?.creative?.id || metaData.cards[0]?.creative?.creativeUrl">
                <creative-content
                    [creativeId]="metaData.cards[0].creative.id"
                    [creativeSetId]="metaData.cards[0].creative.setId"
                    [creativeUrl]="metaData.cards[0].creative.creativeUrl"
                    [selectedMediaType]="metaData.cards[0].mediaType"
                    [sound]="metaData.cards[0].sound" />
            </ng-container>
        </div>
        <div class="info">
            <div class="headline">
                <div
                    *ngIf="metaData.cards[0]?.headLineValue as headline; else headlineSkeleton"
                    class="headline-text">
                    <span> {{headline}} </span>
                </div>
                <ng-template #headlineSkeleton>
                    <div class="headline-skeleton"></div>
                </ng-template>
            </div>
            <div class="url">
                <div
                    *ngIf="(metaData.seeMoreDisplayLink || metaData.seeMoreUrl) as url; else urlSkeleton"
                    class="url-text">
                    {{url}}
                </div>
                <ng-template #urlSkeleton>
                    <div class="url-skeleton"></div>
                </ng-template>
            </div>
        </div>
    </div>
</ng-container>
