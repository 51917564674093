import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { ILocalization } from 'src/app/core/models/account/localization.model';
import { LOCALIZATION_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class LocalizationsEntityService extends EntityCollectionServiceBase<ILocalization> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(LOCALIZATION_ENTITY, serviceElementsFactory);
	}
}
