import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { IFacebookPlacementCarouselMetaDataVM } from 'src/app/presentation/view-models';
import { FbPlacementHeaderComponent } from '../../../../placement-elements/placement-header/fb-placement-header.component';
import { SearchResultsImageCardComponent } from '../carousel-cards/search-results-image/search-results-image-card.component';
import { CarouselSliderComponent } from '../../carousel-elements/carousel-slider/carousel-slider.component';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		FbPlacementHeaderComponent,
		SearchResultsImageCardComponent,
		CarouselSliderComponent,
	],
	selector: 'fb-carousel-search-results-image',
	templateUrl: './fb-search-results-image.component.html',
	styleUrls: ['./fb-search-results-image.component.scss'],
})
export class FacebookCarouselSearchResultsImageComponent {
	@Input() public placementMetaData: IFacebookPlacementCarouselMetaDataVM;
}
