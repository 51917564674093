<ng-container *ngIf="brand$ | async as brand">
    <div class="header-container">
        <div class="brand-container">
            <div class="brand-logo">
                <brand-logo [brand]="brand" [isSmall]="isSmallBrand"></brand-logo>
            </div>
            <div class="brand-content">
                <div class="brand-content-text">
                    <div class="brand-content-text-name">{{ brand?.name }}</div>
                    <div class="brand-content-text-sponsor">
                        {{"PlacementHeaderComponent.SPONSORED" | text}}
                    </div>
                </div>
                <div class="brand-content-icons">
                    <fb-menu-dots-icon></fb-menu-dots-icon>
                    <ui-svg-icon
                        *ngIf="hasCloseCross"
                        class="brand-content-icons-close"
                        icon="close"></ui-svg-icon>
                    <ui-svg-icon
                        *ngIf="hasArrowIcon"
                        class="brand-content-icons-arrow"
                        icon="arrow-up"></ui-svg-icon>
                </div>
            </div>
        </div>
    </div>
</ng-container>
