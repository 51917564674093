export enum TiktokSupportedPlacements {
	// Feeds
	TiktokGlobalAppBundle = 'tiktok-global-app-bundle',
	TiktokPangle = 'tiktok-pangle',
	TiktokTiktok = 'tiktok-tiktok',
}

export const SupportedPlacementIds: string[] = Object.keys(
	TiktokSupportedPlacements,
).map((key) => TiktokSupportedPlacements[key]);

export const SupportedPlacementIdsOrder = {
	// Feeds
	'tiktok-global-app-bundle': 0,
	'tiktok-pangle': 1,
	'tiktok-tiktok': 2,
};
