import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UIModule } from '@bannerflow/ui';

@Component({
	selector: 'fb-reels-header',
	templateUrl: './fb-reels-header.component.html',
	styleUrls: ['./fb-reels-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [UIModule],
})
export class FacebookReelsHeaderComponent {}
