import { Injectable } from '@angular/core';
import { ITiktokAdFormat } from 'src/app/core/models/tiktok/tt-ad-format.model';
import { ITiktokAdFormatVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class AdFormatsMappersService {
	public mapAdFormat(adFormat: ITiktokAdFormat): ITiktokAdFormatVM {
		return {
			id: adFormat.id,
			name: adFormat.name,
		};
	}
}
