import { Injectable } from '@angular/core';
import { IAssignedCreativeVM } from 'src/app/presentation/view-models';
@Injectable({
	providedIn: 'root',
})
export class CreativePickerService {
	public loadAdCreativeForPlacement(
		placementId: string,
		adCreatives: IAssignedCreativeVM[],
	): IAssignedCreativeVM {
		return adCreatives?.find((creative) =>
			creative?.placementsIds?.includes(placementId),
		);
	}
}
