import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IAdLogVM } from 'src/app/presentation/view-models';
import { LanguageItemComponent } from '../../../account/language-item/language-item.component';

@Component({
	selector: 'ad-log-inline',
	templateUrl: './ad-log-inline.component.html',
	styleUrls: ['./ad-log-inline.component.scss'],
	standalone: true,
	imports: [CommonModule, LanguageItemComponent],
})
export class AdLogInlineComponent {
	@Input() public adLog: IAdLogVM;
}
