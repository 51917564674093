import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MediaTypeEnum } from 'src/app/presentation/view-models';
import { StaticCreativeContentComponent } from './static-creative-content/static-creative-content.component';
import { LiveCreativeContentComponent } from './live-creative-content/live-creative-content.component';
import { SoundEnum } from 'src/app/presentation/view-models/shared/sound.enum';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		StaticCreativeContentComponent,
		LiveCreativeContentComponent,
	],
	selector: 'creative-content',
	templateUrl: './creative-content.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreativeContentComponent {
	@Input() public creativeSetId: string;
	@Input() public creativeId: string;
	@Input() public width?: number;
	@Input() public height?: number;
	@Input() public fitToHeight?: boolean = false;
	@Input() public fitToSize?: boolean = false;
	@Input() public selectedMediaType: MediaTypeEnum;
	@Input() public isCustomized: boolean;
	@Input() public creativeUrl?: string;
	@Input() public sound?: SoundEnum;
}
