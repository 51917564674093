import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { IAdPlacementsTemplates } from 'src/app/core/models/ad/ad/ad-placements-templates.model';
import { AD_PLACEMENT_TEMPLATE_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class AdPlacementTemplateEntityService extends EntityCollectionServiceBase<IAdPlacementsTemplates> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(AD_PLACEMENT_TEMPLATE_ENTITY, serviceElementsFactory);
	}
}
