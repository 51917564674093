import {
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { IBrandVM, IUserVM } from 'src/app/presentation/view-models';
import { ConfigService } from '../config/config.service';

interface IHeaders {
	[Key: string]: string;
}

@Injectable()
export class AppRequestInterceptor implements HttpInterceptor {
	private user$: Observable<IUserVM>;
	private brand$: Observable<IBrandVM>;

	constructor(private appFeatureService: AppFeatureServices) {
		this.user$ = this.appFeatureService.accountFeature.user.load();
		this.brand$ = this.appFeatureService.accountFeature.brand.load();
	}

	public intercept(
		request: HttpRequest<HttpHeaders>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		const headersFunctionName = this.checkIsSocial(request.url)
			? 'createAuthHeadersForSocial'
			: 'createAuthHeaders';
		return this[headersFunctionName](request).pipe(
			switchMap((headers) => next.handle(request.clone({ headers }))),
		);
	}

	private checkIsSocial(url): boolean {
		const isCallingSocialCampaignService: boolean = url.includes(
			ConfigService.config.SCS_URL,
		);
		const isCallingSocialAccountService: boolean = url.includes(
			ConfigService.config.SAS_URL,
		);
		const isCallingSocialPublishService: boolean = url.includes(
			ConfigService.config.SPS_URL,
		);

		return (
			isCallingSocialCampaignService ||
			isCallingSocialAccountService ||
			isCallingSocialPublishService
		);
	}

	private createAuthHeaders(
		request: HttpRequest<HttpHeaders>,
	): Observable<HttpHeaders> {
		return of(new HttpHeaders(this.constructHeaders(request)));
	}

	private createAuthHeadersForSocial(
		request: HttpRequest<any>,
	): Observable<HttpHeaders> {
		return this.user$.pipe(
			withLatestFrom(this.brand$),
			map(
				([user, brand]) =>
					new HttpHeaders(
						this.constructHeaders(request, user, brand),
					),
			),
		);
	}

	private constructHeaders(
		request: HttpRequest<HttpHeaders>,
		user?: IUserVM,
		brand?: IBrandVM,
	): IHeaders {
		let contentType = 'application/json';

		if (request.method === 'PATCH') {
			contentType = 'application/json-patch+json';
		}

		const accountIdHeader = user?.isEmployee
			? { 'bf-account-id': user.accountId }
			: {};
		const brandIdHeader = this.checkIsSocial(request.url)
			? { 'bf-brand-id': brand.id }
			: {};

		return {
			'Content-Type': contentType,
			...accountIdHeader,
			...brandIdHeader,
		};
	}
}
