import { Injectable } from '@angular/core';
import {
	AdFormatNameEnum,
	IAdFormatVM,
	IPublishLogCardVM,
	IPublishLogItemVM,
	IPublishLogPlacementVM,
	NetworkEnum,
} from 'src/app/presentation/view-models';
import { IPublishLogPlacement } from 'src/app/core/models/publish/publish-log-placement';
import { IPublishLogAd } from 'src/app/core/models/publish/publish-log-ad';
import { IAdContentDictionary } from 'src/app/core/models';
import { IPublishLogCard } from 'src/app/core/models/publish/publish-log-card';
import { IPublishLogEntry } from 'src/app/core/models/publish/publish-log-entry';

@Injectable({
	providedIn: 'root',
})
export class PublishLogMappersService {
	public toPublishLogItemViewModelWithEmptyAd(
		publishLog: IPublishLogEntry,
	): IPublishLogItemVM {
		return {
			id: publishLog.id,
			adId: publishLog.adId,
			networkId: NetworkEnum.default,
			adFormat: {
				id: '',
				name: '',
			},
			adName: '',
			date: publishLog.publishingStarted,
			languageId: '',
			publishedBy: publishLog.publishedBy,
			status: publishLog.status,
			statusMessage: publishLog.statusMessage,
			creativeAssigned: '',
			texts: [''],
			url: '',
			placements: [],
		};
	}

	public extractAdContent(
		ad: IPublishLogAd,
	): IAdContentDictionary | undefined {
		if (!ad) {
			return undefined;
		}

		const matchingPlacement = ad.placements.find(
			(placement) => !placement.isCustomized,
		);

		if (matchingPlacement) {
			return matchingPlacement.content;
		}

		return undefined;
	}

	public toAdFormatVM(adformatId: string): IAdFormatVM {
		return {
			id: adformatId,
			name: AdFormatNameEnum[adformatId],
		};
	}

	public toPlacementsVM(
		placements: IPublishLogPlacement[],
	): IPublishLogPlacementVM[] {
		return placements.map((placement) => this.toLogPlacementVM(placement));
	}

	private toLogPlacementVM(
		placementLogDetails: IPublishLogPlacement,
	): IPublishLogPlacementVM {
		return {
			id: placementLogDetails.id,
			creativesReference: this.getCreativeRefrenceByRenderingOption(
				placementLogDetails.creativesReferences,
				placementLogDetails.renderingOptionTypeId,
			),
			renderingOptionTypeId: placementLogDetails.renderingOptionTypeId,
			content: placementLogDetails?.content,
			cards: placementLogDetails?.cards?.map((logCard) =>
				this.toLogCardVM(logCard),
			),
			isCustomized: placementLogDetails.isCustomized,
		};
	}

	private toLogCardVM(logCard: IPublishLogCard): IPublishLogCardVM {
		return {
			content: logCard.content,
			creativeReference: logCard.creativeReference,
			renderingOptionTypeId: logCard.renderingOptionTypeId,
		};
	}

	private getCreativeRefrenceByRenderingOption(
		references: string[],
		renderingOption: string,
	): string | undefined {
		for (const reference of references) {
			if (
				renderingOption === 'Video' &&
				reference.toLowerCase().endsWith('.mp4')
			) {
				return reference;
			} else if (
				renderingOption !== 'Video' &&
				reference.toLowerCase().endsWith('.jpg')
			) {
				return reference;
			}
		}

		return undefined;
	}
}
