import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import {
	IAssignedCreativeVM,
	IAdGroupVM,
	IFacebookAdVM,
	IFacebookPlacementSingleMetaDataVM,
	IGroupedCreativesByAspectRatioVM,
	IPlacementBaseVM,
} from 'src/app/presentation/view-models';

import { CustomizePlacementWizardStore } from './customize-placement.component.store';
import { FacebookSupportedPlacements } from '../placements/shared/enums';
import { CustomizeCreativeSetComponent } from './customize-creative-set/customize-creative-set.component';
import { CreativeSetPickerComponent } from 'src/app/presentation/features/studio/creative-set-picker/creative-set-picker.component';
import { UIModule } from '@bannerflow/ui';
import { CommonModule } from '@angular/common';
import { CustomizePlacementContentComponent } from './customize-placement-content/customize-placement-content.component';
import { CustomizePlacementPreviewComponent } from './customize-placement-preview/customize-placement-preview.component';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		UIModule,
		CustomizeCreativeSetComponent,
		CreativeSetPickerComponent,
		CustomizePlacementContentComponent,
		CustomizePlacementPreviewComponent,
	],
	selector: 'app-customize-placement',
	templateUrl: './customize-placement.wizard.html',
	styleUrls: ['./customize-placement.wizard.scss'],
	providers: [CustomizePlacementWizardStore],
})
export class CustomizePlacementWizardComponent {
	private callback: Function;

	public facebookSupportedPlacements = FacebookSupportedPlacements;
	public selectedCreativeSet$: Observable<IGroupedCreativesByAspectRatioVM>;
	public placementAspectRatioGroup$: Observable<string>;
	public selectedAd$: Observable<IFacebookAdVM>;
	public placementId: string;
	public customizePlacementWizardStore: CustomizePlacementWizardStore;
	public placementMetaData$: Observable<IFacebookPlacementSingleMetaDataVM>;

	private customizedPlacement$: Observable<IAssignedCreativeVM>;

	constructor(
		private readonly placementWizardStore: CustomizePlacementWizardStore,
		private appFeatureServices: AppFeatureServices,
	) {}

	public async initiate(
		selectedAd: IFacebookAdVM,
		selectedAdGroup: IAdGroupVM,
		selectedPlacementId: string,
		supportedPlacements: IPlacementBaseVM[],
	): Promise<void> {
		this.customizePlacementWizardStore = this.placementWizardStore;

		this.placementId = selectedPlacementId;

		this.appFeatureServices.adFeature.adCustomizedValidation.clearAdCustomizedValidationCache();

		this.customizePlacementWizardStore.setSelectedAd(selectedAd);

		this.customizePlacementWizardStore.setSupportedPlacements(
			supportedPlacements,
		);

		this.customizedPlacement$ =
			this.appFeatureServices.adFeature.ad.loadAdCreativePerPlacement(
				selectedAd.id,
				selectedPlacementId,
			);

		this.customizedPlacement$
			.pipe(
				filter((placement) => !!placement),
				take(1),
			)
			.subscribe((creative) =>
				this.customizePlacementWizardStore.setSelectedCreative(
					creative,
				),
			);

		this.customizePlacementWizardStore.setSelectedAdGroup(selectedAdGroup);

		this.customizePlacementWizardStore.setSelectedPlacement(
			selectedPlacementId,
		);

		this.customizePlacementWizardStore.initPlacementContentMetaData(
			selectedAd,
		);

		this.selectedCreativeSet$ =
			this.customizePlacementWizardStore.loadCreativeSet();

		this.selectedAd$ = this.customizePlacementWizardStore.loadSelectedAd();

		this.placementAspectRatioGroup$ = this.setupAspectRatioGroup();

		return new Promise<any>((resolve: Function) => {
			this.callback = resolve;
		});
	}

	public setupAspectRatioGroup(): Observable<string> {
		return this.appFeatureServices.facebookFeature.placement.single
			.loadPlacements()
			.pipe(
				map(
					(placements) =>
						placements.find(
							(placement) => placement.id === this.placementId,
						).renderingOptions.aspectRatioGroup,
				),
			);
	}

	public preselectCreative(
		ad: IFacebookAdVM,
		placementId: string,
		currentlyCustomizedCreative?: IAssignedCreativeVM,
	): IAssignedCreativeVM {
		if (currentlyCustomizedCreative) {
			return currentlyCustomizedCreative;
		}
		const preSelectedCreative = ad?.adCreatives.find((creative) =>
			creative?.placementsIds.includes(placementId),
		);

		return preSelectedCreative;
	}

	public onSelectedCreativeSet(selectedCreativeSetId: string): void {
		this.customizePlacementWizardStore.getCreativeSet(
			selectedCreativeSetId,
		);
	}

	public onSave(): void {
		this.callback();
	}
}
