import { Injectable } from '@angular/core';
import { IAssignedCreative } from 'src/app/core/models';
import {
	IAssignedCreativeVM,
	MediaTypeEnum,
} from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class CreativesAdMapperService {
	public toCreativeVM(creative: IAssignedCreative): IAssignedCreativeVM {
		if (!creative) {
			return null;
		}
		return {
			id: creative.id,
			setId: creative.setId,
			placementsIds: creative.placementsIds,
			renderingOption: creative?.renderingOption,
		};
	}

	public toCreative(
		creative: IAssignedCreativeVM,
		renderingOption: MediaTypeEnum,
	): IAssignedCreative {
		if (!creative) {
			return null;
		}
		return {
			id: creative.id,
			setId: creative.setId,
			placementsIds: creative.placementsIds,
			renderingOption: renderingOption || MediaTypeEnum.Image,
		};
	}
}
