import { Injectable, Injector } from '@angular/core';
import { SocialAccountApiService } from './services/social-account-service';
import { SocialCampaignApiService } from './services/social-campaign-service';
import { SocialPublishApiService } from './services/social-publish-service';
import { StudioApiService } from './services/studio-service';
import { CommonListViewApiService } from './services/common-list-view-service/common-list-view-api.service';
import { BrandManagerApiService } from './services/brand-manager/brand-manager-api.service';
import { AccountAccessApiService } from './services/account-access-service/account-access-api.service';

@Injectable({ providedIn: 'root' })
export class AppCoreServices {
	public socialAccountApiService: SocialAccountApiService;
	public socialCampaignApiService: SocialCampaignApiService;
	public socialPublishApiService: SocialPublishApiService;
	public studioApiService: StudioApiService;
	public commonListViewApiService: CommonListViewApiService;
	public brandManagerApiService: BrandManagerApiService;
	public accountAccessApiService: AccountAccessApiService;

	constructor(private injector: Injector) {
		this.socialAccountApiService = this.injector.get(
			SocialAccountApiService,
		);
		this.socialCampaignApiService = this.injector.get(
			SocialCampaignApiService,
		);
		this.socialPublishApiService = this.injector.get(
			SocialPublishApiService,
		);
		this.studioApiService = this.injector.get(StudioApiService);
		this.brandManagerApiService = this.injector.get(BrandManagerApiService);
		this.commonListViewApiService = this.injector.get(
			CommonListViewApiService,
		);
		this.accountAccessApiService = this.injector.get(
			AccountAccessApiService,
		);
	}
}
