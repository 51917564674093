<div class="placement" *ngIf="placementMetaData$ | async as placementMetaData">
    <div class="placement-creative">
        <carousel-slider
            [cards]="placementMetaData.cards"
            (indexChanged)="onSlideChange($event)">
            <ng-template let-card="card">
                <creative-content
                    *ngIf="(card.creative?.id && card.creative.setId) || card?.creative?.creativeUrl"
                    [creativeSetId]="card.creative.setId"
                    [creativeId]="card.creative.id"
                    [creativeUrl]="card?.creative?.creativeUrl"
                    [selectedMediaType]="card.mediaType"
                    [sound]="card.sound" />
            </ng-template>
        </carousel-slider>
    </div>

    <div class="placement-content">
        <div class="header">
            <instargam-reels-header />
        </div>

        <div class="footer">
            <div class="footer-content">
                <div class="footer-content-brand">
                    <fb-stories-reels-brand />
                </div>
                <div class="footer-content-cta">
                    <instagram-reels-cta [ctaValue]="placementMetaData.callToAction" />
                </div>
                <fb-primary-text
                    [primaryTextValue]="placementMetaData.primaryTextValue" />
            </div>
            <div class="footer-icons">
                <instagram-reels-icons></instagram-reels-icons>
            </div>
        </div>
    </div>
</div>
