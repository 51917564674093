import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnChanges } from '@angular/core';
import { UIConfirmDialogResult } from '@bannerflow/ui';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import {
	ModalType,
	SocialConfirmDialogService,
} from 'src/app/presentation/pages/shared/services/social-confirm-dialog.service';
import { IFacebookAdVM } from 'src/app/presentation/view-models';

@Component({
	standalone: true,
	imports: [CommonModule],
	selector: 'revert-customization-option',
	templateUrl: 'revert-customization-option.component.html',
	styleUrls: [],
})
export class RevertCustomizationOptionComponent
	implements OnChanges, OnDestroy
{
	@Input('adId') public adId: string;
	@Input('placementId') public placementId: string;
	public ad$: Observable<IFacebookAdVM>;
	public hasCustomization$: Observable<boolean>;
	public unsubscribe$ = new Subject<void>();

	constructor(
		private appFeatureServices: AppFeatureServices,
		private socialConfirmDialogService: SocialConfirmDialogService,
	) {}

	public ngOnChanges(): void {
		this.ad$ = this.appFeatureServices.facebookFeature.ad.loadById(
			this.adId,
		);
		this.hasCustomization$ =
			this.appFeatureServices.facebookFeature.ad.checkIfAdHasCustomizedPlacement(
				this.adId,
				this.placementId,
			);
	}

	public async revert(): Promise<UIConfirmDialogResult> {
		const confirmResult = await this.socialConfirmDialogService.showDialog(
			ModalType.revertCustomization,
		);

		if (confirmResult === 'confirm') {
			this.appFeatureServices.adFeature.ad
				.revertAdPlacement(this.adId, this.placementId)
				.pipe(takeUntil(this.unsubscribe$))
				.subscribe((adResponse) => {
					this.appFeatureServices.adFeature.ad.getById(adResponse.id);
				});
		}

		return confirmResult;
	}

	public ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
