import { Injectable } from '@angular/core';
import { ITiktokCampaign } from 'src/app/core/models';
import { ITiktokCampaignVM } from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class TiktokCampaignMapperService {
	public toCampaignVM(campaign: ITiktokCampaign): ITiktokCampaignVM {
		const campaignVM: ITiktokCampaignVM = {
			id: campaign.id,
			name: campaign.name,
			objective: campaign.objective,
		};

		return campaignVM;
	}

	public toCampaignsVM(campaigns: ITiktokCampaign[]): ITiktokCampaignVM[] {
		return campaigns.map((campaign) => this.toCampaignVM(campaign));
	}
}
