import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	selector: 'fb-primary-text',
	templateUrl: './fb-primary-text.component.html',
	styleUrls: ['./fb-primary-text.component.scss'],
	standalone: true,
	imports: [CommonModule],
})
export class FbPrimaryTextComponent {
	@Input({ required: true }) public primaryTextValue: string;
	@Input() public numberOfLines: 1 | 2 | 3 = 3;
}
