import { Injectable, Injector } from '@angular/core';
import { FacebookAccountFeatureService } from './account/facebook-account-feature.service';
import { AdFormatsService } from './ad-format/services/ad-formats.service';
import { FacebookPlacementService } from './placement/services/fb-placement.service';
import { FacebookAdService } from './ad/services/ad/fb-ad.service';

@Injectable()
export class FacebookFeatureService {
	public account: FacebookAccountFeatureService;
	public adformat: AdFormatsService;
	public placement: FacebookPlacementService;
	public ad: FacebookAdService;

	constructor(private injector: Injector) {
		this.ad = this.injector.get(FacebookAdService);
		this.account = this.injector.get(FacebookAccountFeatureService);
		this.adformat = this.injector.get(AdFormatsService);
		this.placement = this.injector.get(FacebookPlacementService);
	}
}
