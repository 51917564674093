import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/core/config/config.service';
import { Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { ApiService } from '../internal/api.service';
import { IIntegration } from '../../models/account/integration/integration.model';
import { ConnectionStatusEnum } from '../../models/account/integration/connection-status.enum';
import { IIntegrationConnectionStatus } from '../../models/account/integration/integration-connection-status.model';
import { TiktokAccountApiService } from './tiktok/tiktok-account-api.service';
import { FacebookAccountApiService } from './facebook/facebook-account-api.service';
import { INetwork } from '../../models/account/network.model';

@Injectable({
	providedIn: 'root',
})
export class SocialAccountApiService {
	public baseUrl = `${ConfigService?.config?.SAS_URL}/api`;

	constructor(
		public http: HttpClient,
		public facebook: FacebookAccountApiService,
		public tiktok: TiktokAccountApiService,
	) {}

	/**
	 * Gets all integrations
	 */
	public getIntegrations(): Observable<IIntegration[]> {
		const endpoint = `integrations`;
		const url = `${this.baseUrl}/${endpoint}?featuresScopes=social_campaign_manager`;

		return this.http.get<IIntegration[]>(url).pipe(
			filter((integrations) => !!integrations),
			map((integrations) => integrations),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Gets the connection status of an integration
	 */
	public getConnectionStatus(
		integrationId: string,
	): Observable<IIntegrationConnectionStatus> {
		const endpoint = `integrations`;
		const url = `${this.baseUrl}/${endpoint}/${integrationId}/status`;

		const missingResource = {
			id: integrationId,
			connectionStatus: ConnectionStatusEnum.Invalid,
		} as IIntegrationConnectionStatus;

		const request$ = this.http
			.get<{ connectionStatus: ConnectionStatusEnum }>(url)
			.pipe(
				map(
					(response) =>
						({
							...response,
							id: integrationId,
						}) as IIntegrationConnectionStatus,
				),
				catchError(ApiService.hasResource),
				map((existingResource) => {
					if (existingResource) {
						return existingResource as IIntegrationConnectionStatus;
					} else {
						return missingResource;
					}
				}),
			);

		return request$;
	}

	/**
	 * Gets all networks
	 */
	public getNetworks(): Observable<INetwork[]> {
		const endpoint = `networks`;
		const url = `${this.baseUrl}/${endpoint}`;

		return this.http.get<INetwork[]>(url).pipe(
			filter((networks) => !!networks),
			map((networks) => networks),
			catchError(ApiService.handleError),
		);
	}
}
