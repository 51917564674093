<ng-container *ngIf="placementMetaData$ | async as placementMetaData">
    <div class="container">
        <div class="cross">
            <ui-svg-icon icon="cancel-24"></ui-svg-icon>
        </div>
        <div class="card">
            <fb-audience-card
                [card]="placementMetaData?.cards[0]"
                [primaryTextValue]="placementMetaData?.primaryTextValue">
            </fb-audience-card>
        </div>
    </div>
</ng-container>
