import { AppFeatureServices } from 'src/app/features/app-feature.service';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { PlacementCategoryEnum } from '../enums';
import {
	AdFormatEnum,
	IAssignedCreativeVM,
	IPlacementOptionsVM,
} from 'src/app/presentation/view-models';
import { UIModule } from '@bannerflow/ui';
import { CommonModule } from '@angular/common';
import { CustomizedIconComponent } from '../../../customization/customized-icon/customized-icon.component';
import { CreativeDeactivationWarningComponent } from '../../../../../../../studio/creative-deactivation-warning/creative-deactivation-warning.component';
import { FacebookPlacementMappersService } from 'src/app/features/facebook/placement/mappers/fb-placement.mappers';

@Component({
	selector: 'placement-title',
	templateUrl: './facebook-placement-title.component.html',
	styleUrls: ['./facebook-placement-title.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		CustomizedIconComponent,
		UIModule,
		CreativeDeactivationWarningComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookPlacementTitleComponent implements OnChanges {
	@Input() public placementId: string;
	@Input() public isCustomized: boolean;
	@Input() public adFormatId: string;
	@Input() public assignedCreative: IAssignedCreativeVM;

	public placement$: Observable<IPlacementOptionsVM>;
	public placementCategory$: Observable<PlacementCategoryEnum>;
	public placementCategoryEnum: typeof PlacementCategoryEnum =
		PlacementCategoryEnum;

	constructor(
		private appFeatureServices: AppFeatureServices,
		private facebookPlacementMappers: FacebookPlacementMappersService,
	) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.placementId) {
			const placements$ =
				this.adFormatId === AdFormatEnum.carousel
					? this.appFeatureServices.facebookFeature.placement.carousel.loadPlacements()
					: this.appFeatureServices.facebookFeature.placement.single.loadPlacements();

			this.placement$ = placements$.pipe(
				map((placements) =>
					placements.find(
						(placement) => placement.id === this.placementId,
					),
				),
			);

			this.placementCategory$ = this.placement$.pipe(
				filter((placement) => !!placement),
				map((placement) =>
					this.facebookPlacementMappers.selectPlacementCategory(
						placement.id,
					),
				),
			);
		}
	}
}
