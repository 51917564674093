import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigService } from 'src/app/core/config/config.service';
import { ApiService } from '../internal/api.service';

export interface IPreviewUrlresponse {
	previewUrl: string;
}

@Injectable({
	providedIn: 'root',
})
export class StudioApiService {
	constructor(private http: HttpClient) {}

	public getPreviewUrl(
		creativeSetId: string,
		creativeId: string,
	): Observable<string> {
		const resourcePath = `preview-url?creativeset=${creativeSetId}&creative=${creativeId}`;
		const url = `${ConfigService?.config?.ACG_URL}/${resourcePath}`;

		return this.http.get<IPreviewUrlresponse>(url).pipe(
			map((previewUrl) => previewUrl.previewUrl),
			catchError(ApiService.handleError),
		);
	}
}
