import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/core/config/config.service';
import { Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { ApiService } from '../internal/api.service';
import { ITimezone } from '../../models/account/timezone.model';
import { ILocalization } from '../../models';

@Injectable({
	providedIn: 'root',
})
export class BrandManagerApiService {
	public baseUrl = `${ConfigService?.config?.BM_URL}`;

	constructor(private http: HttpClient) {}

	/**
	 * Get timezones
	 */
	public getTimeZones(): Observable<ITimezone[]> {
		const url = `${this.baseUrl}/timezones`;

		return this.http.get<ITimezone[]>(url).pipe(
			filter((timezones) => !!timezones),
			map((timezones) => timezones),
			catchError(ApiService.handleError),
		);
	}

	/**
	 * Get a brands localizations
	 * @param accountSlug
	 * @param brandSlug
	 */
	public getLocalizations(
		accountSlug: string,
		brandSlug: string,
	): Observable<ILocalization[]> {
		const url = `${this.baseUrl}/${accountSlug}/${brandSlug}/localizations`;

		return this.http.get<ILocalization[]>(url).pipe(
			filter((localizations) => !!localizations),
			map((localizations) => localizations),
			catchError(ApiService.handleError),
		);
	}
}
