import { AdService } from './../../../ad/services/ad/ad.service';
import { Injectable } from '@angular/core';
import {
	combineLatest,
	filter,
	map,
	Observable,
	of,
	switchMap,
	take,
} from 'rxjs';
import { SocialPublishApiService } from 'src/app/core/services/social-publish-service';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import {
	AdDeleteStatusEnum,
	IAdGroupVM,
	IPublishAdsVM,
} from 'src/app/presentation/view-models';
import { PublishBaseService } from './publish-base.service';
import { IAdStatus } from 'src/app/core/models';

@Injectable({
	providedIn: 'root',
})
export class PublishService extends PublishBaseService {
	constructor(
		public publishServiceApi: SocialPublishApiService,
		public appEntityServices: AppEntityServices,
		public adService: AdService,
	) {
		super(appEntityServices);
	}

	public publishAds(adsToPublish: IPublishAdsVM): Observable<void | object> {
		return this.publishServiceApi.publishAds(adsToPublish);
	}

	public getStatusByAdIds(adIds: string[], integrationId: string): void {
		adIds.forEach((adId) => this.getWithQuery({ adId, integrationId }));
	}

	public loadAdStatusByAdId(adId: string): Observable<IAdStatus> {
		return this.appEntityServices.adEntity.adStatus.entities$.pipe(
			map((adStatuses) => adStatuses.find((ad) => ad.id === adId)),
			filter((adStatus) => !!adStatus),
			take(1),
			map((adStatus) => ({
				...adStatus,
			})),
		);
	}

	public loadAdsIdsToUpdate(adIds: string[]): Observable<string[]> {
		return this.appEntityServices.adEntity.adStatus.entities$.pipe(
			map((adStatuses) =>
				adStatuses.filter((adStatus) => adIds.includes(adStatus.id)),
			),
			map((adStatuses) =>
				adStatuses.filter((adStatus) => adStatus.canBeUpdated),
			),
			map((adStatuses) => adStatuses.map((adStatus) => adStatus.id)),
		);
	}

	public loadAdDeleteStatus(adIds: string[]): Observable<AdDeleteStatusEnum> {
		const adStatuses$ = of(adIds).pipe(
			switchMap((ids) =>
				combineLatest(ids.map((adId) => this.loadAdStatusByAdId(adId))),
			),
		);

		return adStatuses$.pipe(
			map((adStatuses) => {
				if (adStatuses.some((adStatus) => !!adStatus.network)) {
					return AdDeleteStatusEnum.AtLeastOneAdPublished;
				} else {
					return AdDeleteStatusEnum.NoAdPublished;
				}
			}),
		);
	}

	public loadDeleteStatusForAdGroup(
		adGroupId: IAdGroupVM['id'],
	): Observable<AdDeleteStatusEnum> {
		return this.adService.loadAdsIdsByAdGroupId(adGroupId).pipe(
			switchMap((ids) => {
				if (ids.length) {
					return this.loadAdDeleteStatus(ids);
				}

				return of(AdDeleteStatusEnum.NoAdPublished);
			}),
		);
	}

	public isLoadingAdStatuses(): Observable<boolean> {
		return this.appEntityServices.adEntity.adStatus.loading$;
	}
}
