import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ADGROUP_ENTITY } from 'src/app/state/app.state';
import { IAdGroup } from 'src/app/core/models';
import { AppCoreServices } from 'src/app/core/app-core-service';

@Injectable()
export class AdGroupDataService extends DefaultDataService<IAdGroup> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(ADGROUP_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: {
		campaignId: string;
	}): Observable<IAdGroup[]> {
		return this.appCoreServices.socialCampaignApiService.getAdGroups(
			params['campaignId'],
		);
	}

	public getById(adGroupId: string): Observable<IAdGroup> {
		return this.appCoreServices.socialCampaignApiService.adGroupApi.getById(
			adGroupId,
		);
	}

	public add(adGroup: IAdGroup): Observable<IAdGroup> {
		return this.appCoreServices.socialCampaignApiService.adGroupApi.addAdGroup(
			adGroup,
		);
	}

	public update(newUpdatedAdGroup: Update<IAdGroup>): Observable<IAdGroup> {
		if (this.isOnlyNameChange(newUpdatedAdGroup)) {
			return this.updateAdGroupName(newUpdatedAdGroup);
		}

		if (this.isOnlySelectedPlacementsChange(newUpdatedAdGroup)) {
			return this.appCoreServices.socialCampaignApiService.adGroupApi.updatePlacements(
				newUpdatedAdGroup.changes.id,
				newUpdatedAdGroup.changes.supportedPlacementsIds,
			);
		}
	}

	private isOnlySelectedPlacementsChange(
		newUpdatedAdGroup: Update<IAdGroup>,
	): boolean {
		return (
			!!newUpdatedAdGroup.changes.id &&
			!!newUpdatedAdGroup.changes.supportedPlacementsIds &&
			Object.keys(newUpdatedAdGroup.changes).length === 2
		);
	}

	private isOnlyNameChange(newUpdatedAdGroup: Update<IAdGroup>): boolean {
		return (
			!!newUpdatedAdGroup.changes.id &&
			!!newUpdatedAdGroup.changes.name &&
			Object.keys(newUpdatedAdGroup.changes).length === 2
		);
	}

	private updateAdGroupName(
		newUpdatedAdGroup: Update<IAdGroup>,
	): Observable<IAdGroup> {
		return this.appCoreServices.socialCampaignApiService.renameAdGroup(
			newUpdatedAdGroup.changes.id,
			newUpdatedAdGroup.changes.name,
		);
	}

	public delete(key: string): Observable<string> {
		return this.appCoreServices.socialCampaignApiService.adGroupApi.delete(
			key,
		);
	}
}
