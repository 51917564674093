<div class="brand" *ngIf="brand$ | async as brand">
    <div class="brand-logo">
        <brand-logo [brand]="brand" [isSmall]="true" />
    </div>
    <div class="brand-info">
        <div class="brand-info-name">{{ brand?.name }}</div>
        <div class="brand-info-sponsored">
            {{"PlacementHeaderComponent.SPONSORED" | text}}
        </div>
    </div>
</div>
