export enum AdContentIdEnum {
	callToAction = 'call-to-action',
	description = 'description',
	displayLink = 'display-link',
	headline = 'headline',
	headlines = 'headlines',
	primaryText = 'primary-text',
	primaryTexts = 'primary-texts',
	websiteUrl = 'website-url',
	seeMoreUrl = 'see-more-url',
	seeMoreDisplayLink = 'see-more-display-link',
	text = 'text',
	instantForm = 'instant-form',
	websiteEvents = 'website-events',
	tracking = 'tracking',
	appUrl = 'app-url',
}
