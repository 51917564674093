export enum FacebookCarouselSupportedPlacements {
	// Feeds
	FacebookNewsFeed = 'facebook-news-feed',
	FacebookVideoFeed = 'facebook-video-feed',
	FacebookInstagramFeed = 'facebook-instagram-feed',
	FacebookInstagramExplore = 'facebook-instagram-explore',
	FacebookInstagramExploreHome = 'facebook-instagram-explore-home',
	FacebookMarketPlace = 'facebook-marketplace',
	FacebookMessengerInbox = 'facebook-messenger-inbox',
	FacebookRightColumn = 'facebook-right-column',
	// Stories & Reels
	FacebookStories = 'facebook-stories',
	FacebookInstagramReels = 'facebook-instagram-reels',
	FacebookInstagramStories = 'facebook-instagram-stories',
	FacebookReels = 'facebook-reels',
	// In-stream
	FacebookInStreamVideos = 'facebook-in-stream-videos',
	// Other
	FacebookAudienceNetworkNative = 'facebook-audience-network-native',
	FacebookAdsOnFacebookReels = 'facebook-ads-on-facebook-reels',
	// Search
	FacebookSearchResults = 'facebook-search-results',
}

export const SupportedPlacementIds: string[] = Object.keys(
	FacebookCarouselSupportedPlacements,
).map((key) => FacebookCarouselSupportedPlacements[key]);

export const CarouselSupportedPlacementIdsOrder = {
	// Feeds
	'facebook-news-feed': 0,
	'facebook-video-feed': 1,
	'facebook-instagram-feed': 2,
	'facebook-instagram-explore': 3,
	'facebook-instagram-explore-home': 4,
	'facebook-marketplace': 5,
	'facebook-messenger-inbox': 6,
	'facebook-right-column': 7,
	// Stories & Reels
	'facebook-stories': 8,
	'facebook-instagram-stories': 9,
	'facebook-instagram-reels': 10,
	'facebook-reels': 11,
	// Other
	'facebook-audience-network-native': 12,
	// In-stream
	'facebook-in-stream-videos': 13,
	'facebook-ads-on-facebook-reels': 14,
	// Search
	'facebook-search-results': 15,
};
