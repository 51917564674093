import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FbPlacementHeaderComponent } from '../../../../placement-elements/placement-header/fb-placement-header.component';
import { FbPrimaryTextComponent } from '../../../../placement-elements/primary-text/fb-primary-text.component';
import { CarouselSliderComponent } from '../../carousel-elements/carousel-slider/carousel-slider.component';
import { FacebookPlacementFooterComponent } from '../../../../placement-elements/placement-footer/fb-placement-footer.component';
import { CommonModule } from '@angular/common';
import { FacebookCarouselPlacementsComponentStore } from '../../fb-carousel-placements.component.store';
import { FacebookCarouselBaseDirective } from '../../directives/fb-carousel-base.directive';
import { FacebookCarouselSupportedPlacements } from '../../../../carousel/placements/enums';
import { FacebookMarketPlaceCardComponent } from './fb-marketplace-card/fb-marketplace-card.component';
import { CarouselDotsComponent } from '../../carousel-elements/carousel-dots/carousel-dots.component';

@Component({
	selector: 'fb-carousel-marketplace',
	templateUrl: './fb-marketplace.component.html',
	styleUrls: ['./fb-marketplace.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		FbPlacementHeaderComponent,
		FbPrimaryTextComponent,
		CarouselSliderComponent,
		FacebookPlacementFooterComponent,
		FacebookMarketPlaceCardComponent,
		CarouselDotsComponent,
	],
})
export class FacebookCarouselMarketPlaceComponent extends FacebookCarouselBaseDirective {
	constructor(
		carouselPlacementsStore: FacebookCarouselPlacementsComponentStore,
	) {
		super(
			carouselPlacementsStore,
			FacebookCarouselSupportedPlacements.FacebookMarketPlace,
		);
	}
}
