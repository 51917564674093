import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SliderTypeEnum } from '../../carousel-elements/carousel-slider/slider-type';
import { FacebookCarouselSupportedPlacements } from '../../enums';
import { FacebookCarouselBaseDirective } from '../../directives/fb-carousel-base.directive';
import { FacebookCarouselPlacementsComponentStore } from '../../fb-carousel-placements.component.store';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { FbAudienceCardComponent } from './audience-card/fb-audience-card.component';

@Component({
	selector: 'fb-audience-network-native-placement',
	templateUrl: './fb-audience-network-native.component.html',
	styleUrls: ['./fb-audience-network-native.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, UIModule, FbAudienceCardComponent],
})
export class FacebookCarouselAudienceNetworkNativeComponent extends FacebookCarouselBaseDirective {
	public sliderTypeEnum: typeof SliderTypeEnum = SliderTypeEnum;
	constructor(
		carouselPlacementsStore: FacebookCarouselPlacementsComponentStore,
	) {
		super(
			carouselPlacementsStore,
			FacebookCarouselSupportedPlacements.FacebookAudienceNetworkNative,
		);
	}
}
