import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AD_ENTITY } from 'src/app/state/app.state';
import { Update } from '@ngrx/entity';
import { IAd } from 'src/app/core/models';
import { AppCoreServices } from 'src/app/core/app-core-service';
import { AdFormatEnum } from 'src/app/presentation/view-models';

@Injectable()
export class AdDataService extends DefaultDataService<IAd> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(AD_ENTITY, http, httpUrlGenerator);
	}

	public getById(adId: string): Observable<IAd> {
		return this.appCoreServices.socialCampaignApiService.adApi.getById(
			adId,
		);
	}

	public getWithQuery({
		ids,
		adGroupId,
	}: {
		ids?: string[];
		adGroupId?: string;
		adId?: string;
		adFormatId?: AdFormatEnum;
	}): Observable<IAd[]> {
		if (ids) {
			return this.appCoreServices.socialCampaignApiService.adApi.getAds(
				ids,
			);
		} else if (adGroupId) {
			return this.appCoreServices.socialCampaignApiService.adApi.getByAdGroupId(
				adGroupId,
			);
		}
	}

	public add(ad: IAd): Observable<IAd> {
		return this.appCoreServices.socialCampaignApiService.adApi.addAd(ad);
	}

	public delete(key: string): Observable<string> {
		return this.appCoreServices.socialCampaignApiService.adApi.delete(key);
	}

	public update(newUpdatedAd: Update<IAd>): Observable<IAd> {
		if (this.isNameChanged(newUpdatedAd)) {
			return this.appCoreServices.socialCampaignApiService.adApi.rename(
				newUpdatedAd.changes.id,
				newUpdatedAd.changes.name,
			);
		}

		if (newUpdatedAd.changes.placementId) {
			return this.appCoreServices.socialCampaignApiService.customizationApi.deleteAdPlacement(
				newUpdatedAd.changes.id,
				newUpdatedAd.changes.placementId,
			);
		}

		return this.appCoreServices.socialCampaignApiService.adApi.put(
			Object.assign(newUpdatedAd.changes),
		);
	}

	private isNameChanged(newUpdatedAd: Update<IAd>): boolean {
		return (
			!!newUpdatedAd.changes.id &&
			!!newUpdatedAd.changes.name &&
			Object.keys(newUpdatedAd.changes).length === 2
		);
	}
}
