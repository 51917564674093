import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { IStudioCreativeSet } from 'src/app/core/models';
import { CREATIVE_SET_ENTITY } from 'src/app/state/app.state';

@Injectable()
export class CreativeSetEntityService extends EntityCollectionServiceBase<IStudioCreativeSet> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(CREATIVE_SET_ENTITY, serviceElementsFactory);
	}
}
