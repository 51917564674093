import { Injectable, Injector } from '@angular/core';
import { FacebookAdAccountsEntityService } from './ad-account/fb-ad-accounts-entity.service';
import { FacebookAdFormatsEntityService } from './ad-format/fb-ad-formats-entity.service';
import { FacebookAdSetsEntityService } from './ad-set/fb-ad-sets-entity.service';
import { FacebookAdSetPlacementsEntityService } from './ad-set-placement/fb-ad-set-placements-entity.service';
import { FacebookCampaignsEntityService } from './campaign/fb-campaigns-entity.service';
import { FacebookPageEntityService } from './page/fb-pages-entity.service';
import { FacebookSinglePlacementsEntityService } from './single-placement/fb-single-placements-entity.service';
import { FacebookCarouselPlacementsEntityService } from './carousel-placement/fb-carousel-placements-entity.service';
import { FacebookInstagramAccountEntityService } from './instagram-account/fb-instagram-accounts-entity.service';

@Injectable()
export class FacebookEntityServices {
	public adAccount: FacebookAdAccountsEntityService;
	public adFormat: FacebookAdFormatsEntityService;
	public adSet: FacebookAdSetsEntityService;
	public adSetPlacement: FacebookAdSetPlacementsEntityService;
	public campaign: FacebookCampaignsEntityService;
	public page: FacebookPageEntityService;
	public instagramAcccount: FacebookInstagramAccountEntityService;
	public singlePlacement: FacebookSinglePlacementsEntityService;
	public carouselPlacement: FacebookCarouselPlacementsEntityService;

	constructor(private injector: Injector) {
		this.adAccount = this.injector.get(FacebookAdAccountsEntityService);
		this.adFormat = this.injector.get(FacebookAdFormatsEntityService);
		this.adSet = this.injector.get(FacebookAdSetsEntityService);
		this.adSetPlacement = this.injector.get(
			FacebookAdSetPlacementsEntityService,
		);
		this.campaign = this.injector.get(FacebookCampaignsEntityService);
		this.page = this.injector.get(FacebookPageEntityService);
		this.instagramAcccount = this.injector.get(
			FacebookInstagramAccountEntityService,
		);
		this.singlePlacement = this.injector.get(
			FacebookSinglePlacementsEntityService,
		);
		this.carouselPlacement = this.injector.get(
			FacebookCarouselPlacementsEntityService,
		);
	}
}
