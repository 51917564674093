import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { IBrandVM } from 'src/app/presentation/view-models';
import { BrandTemplateFragment } from '../enums/brand-template-fragment';
import { FacebookSupportedPlacements } from '../enums';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { BrandLogoComponent } from 'src/app/presentation/features/account/brand-logo/brand-logo.component';

@Component({
	standalone: true,
	imports: [CommonModule, UIModule, TextPipe, BrandLogoComponent],
	selector: 'facebook-placement-header',
	templateUrl: './fb-placement-header.component.html',
	styleUrls: ['./fb-placement-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookPlacementHeaderComponent {
	@Input() public dark: boolean;
	@Input() public hasCloseCross?: boolean;
	@Input() public placementId: string;
	@Input() public isExpanded?: boolean;
	@Input() public render?: BrandTemplateFragment;

	public brand$: Observable<IBrandVM>;

	constructor(private appFeatureServices: AppFeatureServices) {
		this.brand$ = this.appFeatureServices.accountFeature.brand.load();
	}

	public things: typeof BrandTemplateFragment = BrandTemplateFragment;

	public facebookSupportedPlacements: typeof FacebookSupportedPlacements =
		FacebookSupportedPlacements;
}
