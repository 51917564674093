<ng-container *ngIf="adContentTemplates$ | async as adContentTemplates">
    <ng-container *ngrxLet="isAdDirty$ | async as isAdDirty">
        <ng-container *ngrxLet="ad$ | async as ad">
            <div *ngIf="placementMetaData$ | async as placementMetaData">
                <div class="item">
                    <div class="label-container-content">
                        {{"AdContentPanelComponent.MEDIA_TYPE" | text}}
                    </div>
                    <ui-button-group
                        class="media-button"
                        id="interaction-select-media-type"
                        [options]="mediaTypeOptions"
                        [value]="placementMetaData.selectedMediaType || fallbackMediaType"
                        (valueChange)="onMediaTypeChange($event, placementMetaData)">
                    </ui-button-group>
                </div>

                <div class="content-templates">
                    <ng-container *ngFor="let adContentTemplate of adContentTemplates">
                        <ng-container
                            *ngIf="adContentTemplate.metadata.isCustomizable"
                            [ngSwitch]="adContentTemplate.type">
                            <ng-container *ngSwitchCase="templateType.textCollection">
                                <collection-content
                                    [adContentTemplate]="adContentTemplate"
                                    [isAdDirty]="isAdDirty"
                                    [adContentValidation]="(adContentValidation$ | async)"
                                    (onCollectionChanged)="onCollectionChange(adContentTemplate.id, placementMetaData,  $event, ad)" />
                            </ng-container>
                            <div
                                *ngSwitchDefault
                                [ngSwitch]="adContentTemplate.id"
                                class="item">
                                <div class="label-container">
                                    <div class="label-container-content">
                                        {{ adContentTemplate.name }}<span
                                            *ngIf="adContentTemplate.metadata.isRequired"
                                            class="label-container-required"
                                            >*</span
                                        >
                                    </div>
                                    <div
                                        class="recommended-max-length"
                                        [ngClass]="{'is-exceeded' : isExceeded(adContentTemplate)}"
                                        *ngIf="adContentTemplate.id === adContentId.headline || adContentTemplate.id === adContentId.primaryText">
                                        <span>
                                            {{inputCount[adContentTemplate.id]}}<span
                                                >/</span
                                            >
                                        </span>
                                        <span
                                            >{{
                                            adContentTemplate.metadata.recommendedMaximumLength
                                            }}</span
                                        >
                                    </div>
                                </div>

                                <div *ngSwitchCase="adContentId.primaryText">
                                    <ui-textarea
                                        [(value)]="adContentTemplate.value"
                                        [placeholder]="adContentTemplate.metadata.placeholder"
                                        [autofocus]="focusedInput[adContentTemplate.id]"
                                        (keydown.tab)="onTabChange(adContentTemplate, $event, ad)"
                                        (keyup)="onKeyUpChange(adContentTemplate, $event, placementMetaData, adContentTemplates, ad)">
                                    </ui-textarea>
                                </div>
                                <div *ngSwitchCase="adContentId.headline">
                                    <ui-input
                                        class="interaction-placement-content-input"
                                        [(value)]="adContentTemplate.value"
                                        [placeholder]="adContentTemplate.metadata.placeholder"
                                        [maxlength]="adContentTemplate.metadata.maximumLength"
                                        [autofocus]="focusedInput[adContentTemplate.id]"
                                        (keydown.tab)="onTabChange(adContentTemplate, $event, ad)"
                                        (keyup)="onKeyUpChange(adContentTemplate, $event, placementMetaData, adContentTemplates, ad)">
                                    </ui-input>
                                </div>
                                <div *ngSwitchCase="adContentId.websiteUrl">
                                    <div
                                        [uiTooltip]="placementMetaData.websiteUrlValue"
                                        uiTooltipTrigger="hover"
                                        uiTooltipPosition="left">
                                        <ui-input
                                            class="interaction-placement-content-input"
                                            [(value)]="placementMetaData.websiteUrlValue"
                                            [placeholder]="adContentTemplate.metadata.placeholder"
                                            [maxlength]="adContentTemplate.metadata.maximumLength"
                                            [autofocus]="focusedInput[adContentTemplate.id]"
                                            (keydown.tab)="onTabChange(adContentTemplate, $event, ad)"
                                            (keyup)="onKeyUpChange(adContentTemplate, $event, placementMetaData, adContentTemplates, ad)">
                                        </ui-input>
                                    </div>
                                </div>
                                <div *ngSwitchCase="adContentId.displayLink">
                                    <ui-input
                                        class="interaction-placement-content-input"
                                        [(value)]="placementMetaData.displayLinkValue"
                                        [placeholder]="adContentTemplate.metadata.placeholder"
                                        [maxlength]="adContentTemplate.metadata.maximumLength"
                                        [autofocus]="focusedInput[adContentTemplate.id]"
                                        (keydown.tab)="onTabChange(adContentTemplate, $event, ad)"
                                        (keyup)="onKeyUpChange(adContentTemplate, $event, placementMetaData, adContentTemplates, ad)">
                                    </ui-input>
                                </div>

                                <div class="validation-error-container">
                                    <validation-result
                                        *ngIf="(adContentValidation$ | async) as adContentValidation"
                                        [validationResult]="adContentValidation[adContentTemplate?.id]?.[0]">
                                    </validation-result>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>

                <ng-container *ngIf="ad">
                    <div class="content-buttons" *ngIf="isAdDirty">
                        <ui-button
                            id="interaction-cancel"
                            type="default"
                            [text]="'Shared.CANCEL' | text"
                            class="save-cancel-button"
                            (click)="cancel()">
                        </ui-button>
                        <ui-button
                            *ngIf="(selectedCreative$ | async) as selectedCreative"
                            id="interaction-cancel"
                            type="primary"
                            [text]="'Shared.SAVE' | text"
                            (click)="save(ad, selectedCreative, placementMetaData.selectedMediaType)"
                            class="save-cancel-button"
                            [disabled]="!(isValid$ | async)">
                        </ui-button>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
