import {
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TiktokFeatureService } from './tt-feature.service';
import { TiktokAccountFeatureService } from './account/tt-account-feature.service';

@NgModule({
	declarations: [],
	imports: [],
	providers: [
		TiktokFeatureService,
		TiktokAccountFeatureService,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class TiktokFeatureModule {}
