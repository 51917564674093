<ng-container>
    <div class="placement-card">
        <div class="creative-content">
            <ng-container
                *ngIf="selectedCustomizedCreative?.id || placementMetaData?.creative?.id || placementMetaData?.creativeUrl">
                <creative-content
                    [sound]="placementSound$ | async"
                    [creativeUrl]="placementMetaData?.creativeUrl"
                    [isCustomized]="placementMetaData?.isCustomized"
                    [creativeId]="selectedCustomizedCreative?.id || placementMetaData?.creative?.id"
                    [creativeSetId]="selectedCustomizedCreative?.setId || placementMetaData?.creative?.setId"
                    [selectedMediaType]="placementMetaData?.selectedMediaType"></creative-content>
            </ng-container>
        </div>
        <div class="info-container">
            <div class="headline">
                <headline
                    [headLineValue]="placementMetaData.headLineValue"
                    [placementId]="placementId.FacebookRightColumn"
                    [twoDefaultLines]="true"></headline>
            </div>
            <div class="link">
                <div *ngIf="placementMetaData.displayLinkValue; else displayDefault">
                    <div class="value">{{placementMetaData.displayLinkValue}}</div>
                </div>
                <ng-template #displayDefault>
                    <div class="default"></div>
                </ng-template>
            </div>
        </div>
    </div>
</ng-container>
