import { Injectable } from '@angular/core';
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { ICarouselAdContentValidation } from 'src/app/core/models';
import { AD_CONTENT_CAROUSEL_VALIDATION_ENTITY } from 'src/app/state/app.state';

@Injectable()
// eslint-disable-next-line max-len
export class AdContentCarouselValidationEntityService extends EntityCollectionServiceBase<ICarouselAdContentValidation> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
	) {
		super(AD_CONTENT_CAROUSEL_VALIDATION_ENTITY, serviceElementsFactory);
	}
}
