import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { FacebookSinglePlacementsComponentStore } from '../../fb-single-placements.component.store';
import { FacebookPlacementComponentBaseDirective } from '../../shared/directives/facebook-placement-component-base.directive';
import { FacebookSupportedPlacements } from '../../shared/enums';
import { CommonModule } from '@angular/common';
import { FacebookMessangerInboxCoreComponent } from './core/facebook-messanger-inbox-core/fb-messanger-inbox-core.component';

@Component({
	standalone: true,
	imports: [CommonModule, FacebookMessangerInboxCoreComponent],
	selector: 'facebook-messenger-inbox',
	templateUrl: './fb-messenger-inbox.component.html',
})
export class FacebookMessengerInboxComponent extends FacebookPlacementComponentBaseDirective {
	@Input() public inPreview: boolean;

	constructor(
		public appFeatureServices: AppFeatureServices,
		public changeDetectorRef: ChangeDetectorRef,
		public facebookPlacementsComponentStore: FacebookSinglePlacementsComponentStore,
	) {
		super(
			appFeatureServices,
			FacebookSupportedPlacements.FacebookMessengerInbox,
			changeDetectorRef,
			facebookPlacementsComponentStore,
		);
	}
}
