import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FACEBOOK_ADACCOUNT_ENTITY } from 'src/app/state/app.state';
import { IFacebookAdAccount } from 'src/app/core/models';
import { AppCoreServices } from 'src/app/core/app-core-service';

@Injectable()
export class FacebookAdAccountDataService extends DefaultDataService<IFacebookAdAccount> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(FACEBOOK_ADACCOUNT_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: {
		integrationId: string;
	}): Observable<IFacebookAdAccount[]> {
		return this.appCoreServices.socialAccountApiService.facebook.getFacebookAdAccounts(
			params['integrationId'],
		);
	}
}
