import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'truncate',
	standalone: true,
})
export class TruncatePipe implements PipeTransform {
	public transform(value: string, maxLength: number = 50): string {
		if (value.length <= maxLength) {
			return value;
		} else {
			return `${value.substring(0, maxLength)}...`;
		}
	}
}
