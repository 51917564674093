import { Injectable } from '@angular/core';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { TimeZoneMappersService } from '../../mappers/timezone.mapper';
import { TimeZoneBaseService } from './timezone-base.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TimeZoneService extends TimeZoneBaseService {
	private static readonly AllDayMiliseconds = 24 * 60 * 60 * 1000;

	constructor(
		public appEntityServices: AppEntityServices,
		public timeZonesMapper: TimeZoneMappersService,
	) {
		super(appEntityServices, timeZonesMapper);
	}

	public getAll(): void {
		this.appEntityServices.accountEntity.timezone.getAll();
	}

	public isLoading(): Observable<boolean> {
		return this.appEntityServices.accountEntity.timezone.loading$;
	}

	public toLocalTime(timestamp: number, timeZoneOffset: number): number {
		const localOffset: number = new Date(timestamp).getTimezoneOffset();
		const localTime: number =
			timestamp + timeZoneOffset * 60000 + localOffset * 60000;
		return localTime;
	}

	public toTimeZoneTime(timestamp: number, timeZoneOffset: number): number {
		const localOffset: number = new Date(timestamp).getTimezoneOffset();
		const utcTimeWithTimezone: number =
			timestamp - timeZoneOffset * 60000 - localOffset * 60000;

		return utcTimeWithTimezone;
	}

	public static getStartOfLocalDate(date: Date): Date {
		const localStartDate = new Date(date);
		localStartDate.setHours(0, 0, 0, 0);
		return localStartDate;
	}

	public static getLocalDateSummary(
		localDate: Date,
		timezoneOffset: number,
	): string {
		const month = this.getFormatedDigits(localDate.getMonth() + 1);
		const day = this.getFormatedDigits(localDate.getDate());
		const year = localDate.getFullYear();

		const currentHours = this.getFormatedDigits(localDate.getHours());
		const currentMinutes = this.getFormatedDigits(localDate.getMinutes());

		const formattedTimezoneOffset =
			this.getTimeZoneOffsetDigitSummary(timezoneOffset);

		return `${year}-${month}-${day} ${formattedTimezoneOffset} ${currentHours}:${currentMinutes}`;
	}

	public static getFormatedDigits(digit: number): string {
		return `0${digit}`.slice(-2);
	}

	public static getTimeZoneOffsetDigitSummary(
		timeZoneOffset: number,
	): string {
		const sign = timeZoneOffset < 0 ? '-' : '+';
		const hours = Math.abs(Math.floor(timeZoneOffset / 60));
		const minutes = Math.abs(timeZoneOffset % 60);
		const formattedTimezoneOffset = `(UTC${sign}${this.getFormatedDigits(
			hours,
		)}:${this.getFormatedDigits(minutes)})`;

		return formattedTimezoneOffset;
	}

	public static getDifferenceBetweenTwoDates(
		timestampA: number,
		timestampB: number,
	): string {
		const secondsInHour = 60 * 60;
		const secondsInDay = 24 * secondsInHour;
		let secondsDelta = Math.abs(timestampA - timestampB) / 1000;

		// calculate (and subtract) whole days
		const daysAmount = Math.floor(secondsDelta / secondsInDay);
		secondsDelta -= daysAmount * secondsInDay;

		// calculate (and subtract) whole hours
		const hoursAmount = Math.floor(secondsDelta / secondsInHour) % 24;
		secondsDelta -= hoursAmount * secondsInHour;

		// calculate (and subtract) whole minutes
		const minutesAmount = Math.floor(secondsDelta / 60) % 60;

		const minutes = this.getFormatedDigits(minutesAmount);
		const hours = this.getFormatedDigits(hoursAmount);

		let days = '';
		if (daysAmount === 1) {
			days = '1 day';
		} else if (daysAmount > 1) {
			days = `${daysAmount} days`;
		}

		if (0 === hoursAmount && 0 === minutesAmount) {
			return days || 'Empty';
		}

		return days
			? `${days} and ${hours}:${minutes}h`
			: `${hours}:${minutes}h`;
	}

	public static transformDateStringToLocalDate(dateString: string): Date {
		const dateRegexPattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;

		const matchingResult = dateString.match(dateRegexPattern);

		if (!matchingResult) {
			throw new Error(`Invalid date string to parse: ${dateString}`);
		}

		const sanitizedDateString = matchingResult[0];

		return new Date(sanitizedDateString);
	}

	public static transformLocalDateToDateString(date: Date): string {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');

		return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
	}
}
