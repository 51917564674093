import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FACEBOOK_ADSET_ENTITY } from 'src/app/state/app.state';
import { IFacebookAdSet } from 'src/app/core/models';
import { AppCoreServices } from 'src/app/core/app-core-service';

@Injectable()
export class FacebookAdSetDataService extends DefaultDataService<IFacebookAdSet> {
	constructor(
		http: HttpClient,
		httpUrlGenerator: HttpUrlGenerator,
		private appCoreServices: AppCoreServices,
	) {
		super(FACEBOOK_ADSET_ENTITY, http, httpUrlGenerator);
	}

	public getWithQuery(params: {
		integrationId: string;
		campaignId: string;
	}): Observable<IFacebookAdSet[]> {
		return this.appCoreServices.socialCampaignApiService.facebook.adSetApi.getAdSets(
			params['integrationId'],
			params['campaignId'],
		);
	}
}
