import { Injectable } from '@angular/core';
import { IAd, IAdContentDictionary } from 'src/app/core/models';
import { IAdPlacement } from 'src/app/core/models/ad/ad/ad-placement.model';
import { CreativesAdMapperService } from 'src/app/features/ad/mappers/ad-mapper/creatives-ad.mapper';
import { TiktokAdContentIdEnum } from 'src/app/presentation/features/integrations/tiktok/ad/enums/tt-ad-content-id.enum';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import {
	AdFormatEnum,
	IAdListVM,
	IAdPlacementChildVM,
	IAssignedCreativeVM,
	MediaTypeEnum,
} from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class TiktokAdListMappersService {
	constructor(
		public creativesMapper: CreativesAdMapperService,
		private textPipe: TextPipe,
	) {}

	public toAdListVM(ad: IAd): IAdListVM {
		const adListVM: IAdListVM = {} as IAdListVM;

		adListVM.id = ad.id;
		adListVM.adFormatId = ad.adFormatId;
		adListVM.adGroupId = ad.adGroupId;
		adListVM.languageId = ad.languageId;
		adListVM.name = ad.name;

		adListVM.primaryText = ad.default.content?.[TiktokAdContentIdEnum.text];
		adListVM.url = ad.default.content?.[TiktokAdContentIdEnum.targetUrl];
		adListVM.urlMessage = this.mapUrlMessage(ad.default.content);

		adListVM.adCreatives = ad.default.creatives?.map((creative) =>
			this.creativesMapper.toCreativeVM(creative),
		);

		adListVM.children = this.fromAdToChildrenVM(ad);

		return adListVM;
	}

	private fromAdToChildrenVM(ad: IAd): IAdPlacementChildVM[] {
		return (
			ad.placements?.map((placement) =>
				this.toAdChildVM(ad, placement),
			) || []
		);
	}

	private toAdChildVM(ad: IAd, placement: IAdPlacement): IAdPlacementChildVM {
		return {
			adId: ad.id,
			placementId: placement.placementId,
			isCustomized: placement?.isCustomized,
			languageId: ad.languageId,
			mediaType: this.mapMediaType(ad.adFormatId),
			adFormatId: ad.adFormatId,
			adCreatives: this.mapCreatives(placement),
			primaryText: placement.content?.[TiktokAdContentIdEnum.text],
			url: placement.content?.[TiktokAdContentIdEnum.targetUrl],
			urlMessage: this.mapUrlMessage(placement.content),
		};
	}

	private mapUrlMessage(content: IAdContentDictionary): string {
		return content?.[TiktokAdContentIdEnum.tiktokInstantPage]
			? this.textPipe.transform('Shared.INSTANT_PAGE_WAS_USED')
			: undefined;
	}

	private mapMediaType(adFormatId: AdFormatEnum): MediaTypeEnum {
		switch (adFormatId) {
			case AdFormatEnum.singleImage:
				return MediaTypeEnum.Image;
			case AdFormatEnum.singleVideo:
				return MediaTypeEnum.Video;
			default:
				return;
		}
	}

	private mapCreatives(placement: IAdPlacement): IAssignedCreativeVM[] {
		if (placement.creative) {
			const creativeVM = this.creativesMapper.toCreativeVM(
				placement.creative,
			);

			return creativeVM ? [creativeVM] : [];
		}

		return [];
	}
}
