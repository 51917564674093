import { Injectable } from '@angular/core';
import {
	IFacebookAdVM,
	IFacebookCarouselCardMetaDataVM,
	IFacebookCarouselCardVM,
	IFacebookCarouselPlacementsMetaDataMap,
	IPublishLogCardVM,
	IPublishLogItemVM,
	MediaTypeEnum,
} from 'src/app/presentation/view-models';
import { AdContentIdEnum } from '../../../single/placements/shared/enums';
import { PlacementTargetUrlService } from '../../../services/placement-target-url.service';

@Injectable({ providedIn: 'root' })
export class FbCarouselPlacementMappersService {
	constructor(private placementTargetUrlService: PlacementTargetUrlService) {}
	public fromAdToPlacementsMetaData(
		ad: IFacebookAdVM,
	): IFacebookCarouselPlacementsMetaDataMap {
		const commonAdProperties = {
			adId: ad?.id,
			primaryTextValue: this.mapContentValue(
				AdContentIdEnum.primaryText,
				ad,
			),
			descriptionValue: this.mapContentValue(
				AdContentIdEnum.description,
				ad,
			),
			headLineValue: this.mapContentValue(AdContentIdEnum.headline, ad),
			websiteUrlValue: this.mapContentValue(
				AdContentIdEnum.websiteUrl,
				ad,
			),
			displayLinkValue: this.mapContentValue(
				AdContentIdEnum.displayLink,
				ad,
			),
			seeMoreUrl: this.mapContentValue(AdContentIdEnum.seeMoreUrl, ad),
			seeMoreDisplayLink: this.mapContentValue(
				AdContentIdEnum.seeMoreDisplayLink,
				ad,
			),
			callToAction: this.mapCallToAction(ad),
		};
		const placementIds = ad.placements.map(
			(placement) => placement.placementId,
		);

		return placementIds.reduce<IFacebookCarouselPlacementsMetaDataMap>(
			(
				result: IFacebookCarouselPlacementsMetaDataMap,
				placementId: string,
			) => {
				result[placementId] = {
					...commonAdProperties,
					adGroupId: ad.adGroupId,
					placementId,
					formatId: ad?.adFormatId,
					cards: this.mapCarouselCardsMetaData(
						ad,
						placementId,
						commonAdProperties.callToAction,
					),
					targetUrl:
						this.placementTargetUrlService.getTargetUrlFromAd(
							placementId,
							ad,
						),
				};
				return result;
			},
			{},
		);
	}

	public fromAdLogtoPlacementsMetaData(
		adLogItem: IPublishLogItemVM,
	): IFacebookCarouselPlacementsMetaDataMap {
		const placementIds = adLogItem.placements.map(
			(placement) => placement.id,
		);

		return placementIds.reduce<IFacebookCarouselPlacementsMetaDataMap>(
			(
				result: IFacebookCarouselPlacementsMetaDataMap,
				placementId: string,
			) => {
				const placement = adLogItem?.placements?.find(
					({ id }) => id === placementId,
				);

				result[placementId] = {
					placementId,
					adId: adLogItem.id,
					adGroupId: undefined,
					formatId: adLogItem.adFormat.id,
					descriptionValue:
						placement?.content[AdContentIdEnum.description],
					displayLinkValue:
						placement?.content[AdContentIdEnum.displayLink],
					headLineValue: placement?.content[AdContentIdEnum.headline],
					websiteUrlValue:
						placement?.content[AdContentIdEnum.websiteUrl],
					primaryTextValue:
						placement?.content[AdContentIdEnum.primaryText],
					seeMoreDisplayLink:
						adLogItem?.placements[0]?.content[
							AdContentIdEnum.seeMoreDisplayLink
						],
					seeMoreUrl: placement?.content[AdContentIdEnum.seeMoreUrl],
					callToAction:
						placement?.content[AdContentIdEnum.callToAction],
					cards: placement?.cards?.map((carouselCard) =>
						this.mapCarouselCardMetaDataFromLogs(
							carouselCard,
							adLogItem?.placements[0]?.content[
								AdContentIdEnum.callToAction
							],
						),
					),
					targetUrl:
						this.placementTargetUrlService.getTargetUrlFromPublishLogItem(
							placementId,
							adLogItem,
						),
				};
				return result;
			},
			{},
		);
	}

	private mapContentValue(
		adContentId: AdContentIdEnum,
		ad: IFacebookAdVM,
	): string | undefined {
		if (ad?.carouselContent?.commonContentTemplates) {
			return ad.carouselContent.commonContentTemplates.find(
				(content) => content.id === adContentId,
			)?.value;
		}

		return ad?.defaultContent?.[adContentId];
	}

	private mapCallToAction(ad: IFacebookAdVM): string {
		if (ad?.carouselContent?.commonContentTemplates) {
			return ad?.carouselContent?.commonContentTemplates.find(
				(content) => content.id === AdContentIdEnum.callToAction,
			)?.selectedValue?.name;
		}

		return ad?.defaultContent?.[AdContentIdEnum.callToAction];
	}

	private mapCarouselCardsMetaData(
		ad: IFacebookAdVM,
		placementId: string,
		callToAction: string,
	): IFacebookCarouselCardMetaDataVM[] {
		if (ad?.carouselContent) {
			return ad.carouselContent.carouselCards.map((carouselCard) =>
				this.mapCarouselCardMetaData(
					carouselCard,
					placementId,
					callToAction,
				),
			);
		}

		return ad?.defaultCards?.map((card) =>
			this.mapCarouselCardMetaData(card, placementId, callToAction),
		);
	}

	private mapCarouselCardMetaData(
		carouselCard: IFacebookCarouselCardVM,
		placementId: string,
		commonCallToAction: string,
	): IFacebookCarouselCardMetaDataVM {
		const hasCardTemplates = !!carouselCard.cardContentTemplates;

		return {
			creative: carouselCard.adCreatives?.find((assignedCreative) =>
				assignedCreative.placementsIds.includes(placementId),
			),
			mediaType: carouselCard.mediaType,
			callToActionValue: commonCallToAction,
			headLineValue: hasCardTemplates
				? carouselCard.cardContentTemplates.find(
						(content) => content.id === AdContentIdEnum.headline,
					)?.value
				: carouselCard.defaultContent?.[AdContentIdEnum.headline],
			descriptionValue: hasCardTemplates
				? carouselCard.cardContentTemplates.find(
						(content) => content.id === AdContentIdEnum.description,
					)?.value
				: carouselCard.defaultContent?.[AdContentIdEnum.description],
			websiteUrlValue: hasCardTemplates
				? carouselCard.cardContentTemplates.find(
						(content) => content.id === AdContentIdEnum.websiteUrl,
					)?.value
				: carouselCard.defaultContent?.[AdContentIdEnum.websiteUrl],
		};
	}

	private mapCarouselCardMetaDataFromLogs(
		logCard: IPublishLogCardVM,
		callToAction: string,
	): IFacebookCarouselCardMetaDataVM {
		return {
			creative: {
				creativeUrl: logCard.creativeReference,
			},
			mediaType: MediaTypeEnum[logCard.renderingOptionTypeId],
			headLineValue: logCard?.content[AdContentIdEnum.headline],
			descriptionValue: logCard?.content[AdContentIdEnum.description],
			websiteUrlValue: logCard?.content[AdContentIdEnum.websiteUrl],
			callToActionValue: callToAction,
		};
	}
}
