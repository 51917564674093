import { Injectable } from '@angular/core';
import {
	IAd,
	IAdContentDictionary,
	IAssignedCreative,
} from 'src/app/core/models';
import {
	CollectionElementVM,
	IAdContentTemplateVM,
	IAdPlacementCustomizationVM,
	IAssignedCreativeVM,
	IFacebookAdVM,
	IFacebookPlacementDictionary,
	IFacebookPlacementVM,
	IPlacementOptionsVM,
	MediaTypeEnum,
	TemplateTypeEnum,
} from 'src/app/presentation/view-models';
import { AdContentIdEnum } from 'src/app/presentation/features/integrations/facebook/placement/single/placements/shared/enums';
import { IAdPlacement } from 'src/app/core/models/ad/ad/ad-placement.model';
import { CreativesAdMapperService } from 'src/app/features/ad/mappers/ad-mapper/creatives-ad.mapper';
import { IFacebookAdBulkVM } from 'src/app/presentation/view-models/facebook/fb-ad-bulk.vm';

@Injectable({
	providedIn: 'root',
})
export class SingleAdMapperService {
	constructor(private creativesMapper: CreativesAdMapperService) {}

	public toAd(
		adViewModel: IFacebookAdVM,
		placements: IPlacementOptionsVM[],
	): IAd {
		const adContent: IAdContentDictionary = this.fromAdContentVMToAdContent(
			adViewModel,
			placements,
		);

		const ad: IAd = {
			id: adViewModel.id,
			name: adViewModel.name,
			adFormatId: adViewModel.adFormatId,
			languageId: adViewModel.languageId,
			adGroupId: adViewModel.adGroupId,
			default: {
				content: !Object.keys(adContent).length ? null : adContent,
				creatives: this.toCreatives(
					adViewModel.adCreatives,
					adViewModel.mediaType,
				),
			},
		} as IAd;

		return ad;
	}

	public toBulkAd(
		adVM: IFacebookAdBulkVM,
		savedAdVM: IFacebookAdVM,
		placements: IPlacementOptionsVM[],
	): IAd {
		const { id, name, adFormatId, languageId, adGroupId } = savedAdVM;

		const isMixedCreativeUntouched =
			adVM.mixedDefaultContent?.creatives && !adVM.adCreatives?.length;

		const creatives = isMixedCreativeUntouched
			? this.toCreatives(savedAdVM.adCreatives, savedAdVM.mediaType)
			: this.toCreatives(adVM.adCreatives, adVM.mediaType);

		const content: IAdContentDictionary =
			this.fromCombinedAdContentVMToAdContent(
				adVM,
				savedAdVM,
				placements,
			);

		const ad: IAd = {
			id,
			name,
			adFormatId,
			languageId,
			adGroupId,
			default: {
				content,
				creatives,
			},
		} as IAd;

		return ad;
	}

	private toCreatives(
		adCreatives: IAssignedCreativeVM[],
		mediaType: MediaTypeEnum = MediaTypeEnum.Image,
	): IAssignedCreative[] {
		return adCreatives
			? adCreatives.map((creative) =>
					this.creativesMapper.toCreative(creative, mediaType),
				)
			: [];
	}

	private fromCombinedAdContentVMToAdContent(
		adVM: IFacebookAdBulkVM,
		savedAdVM: IFacebookAdVM,
		placements: IPlacementOptionsVM[],
	): IAdContentDictionary {
		const content: IAdContentDictionary = this.fromAdContentVMToAdContent(
			adVM,
			placements,
		);

		if (adVM.mixedDefaultContent?.content) {
			Object.entries(adVM.mixedDefaultContent.content).forEach(
				([templateId, isMixed]) => {
					if (!isMixed) {
						return;
					}

					const isMixedMultipleValueUntouched =
						Array.isArray(content[templateId]) &&
						content[templateId].length === 1 &&
						content[templateId][0] === '';

					if (
						content[templateId] === '' ||
						isMixedMultipleValueUntouched
					) {
						content[templateId] =
							savedAdVM.defaultContent[templateId];
					}
				},
			);
		}

		return content;
	}

	/**
	 * Map ad content VM to model
	 * @param placements Used only to take placements ids
	 */
	private fromAdContentVMToAdContent(
		adViewModel: IFacebookAdVM,
		placements: IPlacementOptionsVM[],
	): IAdContentDictionary {
		const adContent: IAdContentDictionary = {};

		function fromTemplateToValue(template: IAdContentTemplateVM): void {
			if (template.type === TemplateTypeEnum.textCollection) {
				adContent[template.id] = template.value.map(
					({ value }) => value,
				);
			} else {
				adContent[template.id] = template?.value?.name
					? template.value?.id
					: template.value || template.metadata.defaultValue || '';
			}
		}

		placements.forEach((placement) => {
			const hasSomethingToMap =
				!!adViewModel?.content?.[placement.id]?.adContentTemplates;

			if (hasSomethingToMap) {
				adViewModel.content[placement.id].adContentTemplates.forEach(
					(adContentTemplate) => {
						if (adContentTemplate.type === TemplateTypeEnum.group) {
							adContentTemplate.subTemplates.forEach(
								({ template: subTemplate }) => {
									fromTemplateToValue(subTemplate);
								},
							);
						} else {
							fromTemplateToValue(adContentTemplate);
						}
					},
				);
			}
		});

		return adContent;
	}

	public fromAdToContentVM(
		ad: IAd,
		placements: IFacebookPlacementVM[],
	): IFacebookPlacementDictionary {
		if (!placements) {
			return this.toContentVMFromAdPlacements(ad);
		}

		const content: IFacebookPlacementDictionary = {};

		placements.forEach((placement) => {
			content[placement.id] = this.toPlacementViewModel(ad, placement);
		});

		return content;
	}

	public toContentVMFromAdPlacements(ad: IAd): IFacebookPlacementDictionary {
		const content: IFacebookPlacementDictionary = {};

		ad.placements.forEach((placement) => {
			content[placement.placementId] = {
				id: placement.placementId,
				name: null,
				type: null,
				renderingOptions: null,
				adContentTemplates: null,
				customizedContent: placement.isCustomized
					? this.toCustomizedContent(placement, ad)
					: null,
			};
		});

		return content;
	}

	public toPlacementViewModel(
		ad: IAd,
		placement: IFacebookPlacementVM,
	): IFacebookPlacementVM {
		const placementWithValues: IFacebookPlacementVM = placement;
		const placementContent: IAdPlacement = ad.placements?.find(
			(adPlacement) => adPlacement.placementId === placement.id,
		);
		const adContent = ad?.default?.content || {};

		placementWithValues.adContentTemplates.forEach((template) => {
			template.value = adContent[template.id] || '';

			if (template.subTemplates) {
				template.subTemplates = template.subTemplates.map(
					(subTemplate) => {
						if (
							subTemplate.template.type ===
							TemplateTypeEnum.selection
						) {
							const selectedId =
								adContent[subTemplate.template.id] ??
								subTemplate.template.metadata.defaultValue;
							let selectedValue;

							if (selectedId) {
								selectedValue = { id: selectedId };

								if (
									subTemplate.template.allowedValues?.length
								) {
									selectedValue.name =
										subTemplate.template.allowedValues.find(
											(value) => value.id === selectedId,
										)?.name;
								}
							} else {
								selectedValue = '';
							}

							return {
								...subTemplate,
								template: {
									...subTemplate.template,
									selectedValue,
									value: selectedId || '',
								},
							};
						}

						return subTemplate;
					},
				);
			}

			if (template.type === TemplateTypeEnum.selection) {
				const selectedId =
					adContent[template.id] ?? template.metadata.defaultValue;
				let selectedValue;

				if (selectedId) {
					selectedValue = { id: selectedId };

					if (template.allowedValues?.length) {
						selectedValue.name = template.allowedValues.find(
							(value) => value.id === selectedId,
						)?.name;
					}
				} else {
					selectedValue = '';
				}

				template.selectedValue = selectedValue;
			} else if (
				template.id === AdContentIdEnum.headlines ||
				template.id === AdContentIdEnum.primaryTexts
			) {
				template.value = this.getMultipleValuesWithActiveFlag(template);
			}
		});

		if (placementContent) {
			placementWithValues.customizedContent =
				placementContent.isCustomized
					? this.toCustomizedContent(placementContent, ad)
					: null;
		}

		return placementWithValues;
	}

	private toCustomizedContent(
		placementContent: IAdPlacement,
		ad: IAd,
	): IAdPlacementCustomizationVM {
		const customizedPlacementVM: IAdPlacementCustomizationVM = {
			content: {
				...placementContent.content,
				'primary-texts': placementContent.content[
					AdContentIdEnum.primaryTexts
				] || [''],
				headlines: placementContent.content[
					AdContentIdEnum.headlines
				] || [''],
			},
			adId: ad.id,
			placementId: placementContent.placementId,
			adCreative: placementContent.creative
				? {
						...this.creativesMapper.toCreativeVM(
							placementContent.creative,
						),
						placementsIds: [placementContent.placementId],
					}
				: null,
			renderingOptionTypeId: placementContent?.creative?.renderingOption,
		};

		return customizedPlacementVM;
	}

	private getMultipleValuesWithActiveFlag(
		adDataDefinition,
	): CollectionElementVM[] {
		const collectionElements: CollectionElementVM[] = [];
		if (adDataDefinition.value.length) {
			adDataDefinition.value?.forEach((value, i) => {
				if (i === 0) {
					collectionElements.push({ value: value, isActive: true });
				} else {
					collectionElements.push({ value: value, isActive: false });
				}
			});
		} else {
			collectionElements.push({
				value: adDataDefinition.value,
				isActive: true,
			});
		}

		return collectionElements;
	}

	public getCreativeIdsFromPlacements(placements: IAdPlacement[]): string[] {
		return placements
			.filter((placement) => placement.creative)
			.map((placement) => placement.creative.setId);
	}
}
