<ng-container [ngSwitch]="placementId">
    <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookReels">
        <ng-container *ngTemplateOutlet="facebookReels"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookInstagramReels">
        <ng-container *ngTemplateOutlet="instagramReels"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="facebookSupportedPlacements.FacebookAdsOnReels">
        <ng-container *ngTemplateOutlet="facebookAdsOnReels"></ng-container>
    </ng-container>
</ng-container>

<ng-template #facebookReels>
    <div class="icons-container">
        <ui-svg-icon class="icon-reels icon-fb-reels" icon="like-outline"></ui-svg-icon>
        <ui-svg-icon class="icon-reels icon-fb-reels" icon="comments"></ui-svg-icon>
        <ui-svg-icon class="icon-reels icon-fb-reels" icon="send_fb"></ui-svg-icon>
        <ui-svg-icon
            class="icon-reels icon-fb-reels icon-kebab"
            icon="kebab"></ui-svg-icon>
    </div>
</ng-template>
<ng-template #instagramReels>
    <div class="icons-container">
        <ui-svg-icon class="icon-reels" icon="heart-outline"></ui-svg-icon>
        <ui-svg-icon class="icon-reels" icon="comments"></ui-svg-icon>
        <ui-svg-icon class="icon-reels" icon="send"></ui-svg-icon>
        <ui-svg-icon class="icon-reels icon-kebab" icon="kebab"></ui-svg-icon>
    </div>
</ng-template>
<ng-template #facebookAdsOnReels>
    <div class="icons-container">
        <ui-svg-icon class="icon-reels icon-fb-reels" icon="like-outline"></ui-svg-icon>
        <ui-svg-icon class="icon-reels icon-fb-reels" icon="comments"></ui-svg-icon>
        <ui-svg-icon class="icon-reels icon-fb-reels" icon="send_fb"></ui-svg-icon>
        <ui-svg-icon
            class="icon-reels icon-fb-reels icon-kebab"
            icon="kebab"></ui-svg-icon>
    </div>
</ng-template>
