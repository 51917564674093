import { Component } from '@angular/core';

import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { FacebookPlacementCoreBaseDirective } from '../../../shared/directives/facebook-placement-core-base.directive';
import { FacebookSupportedPlacements } from '../../../shared/enums';
import { CommonModule } from '@angular/common';
import { CreativeContentComponent } from '../../../../../../../elements/creative-content/creative-content.component';
import { FacebookPlacementFooterComponent } from '../../../../../placement-elements/placement-footer/fb-placement-footer.component';
import { CtaButtonComponent } from '../../../shared/cta-button/cta-button.component';
import { DescriptionComponent } from '../../../shared/description/description.component';
import { DisplayLinkComponent } from '../../../shared/display-link/display-link.component';
import { HeadlineComponent } from '../../../shared/headline/headline.component';
import { FacebookPlacementHeaderComponent } from '../../../shared/placement-header/fb-placement-header.component';
import { PrimaryTextComponent } from '../../../shared/primary-text/primary-text.component';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		CreativeContentComponent,
		HeadlineComponent,
		FacebookPlacementHeaderComponent,
		FacebookPlacementFooterComponent,
		PrimaryTextComponent,
		CtaButtonComponent,
		DisplayLinkComponent,
		DescriptionComponent,
	],
	selector: 'facebook-groups-feed-placement-core',
	templateUrl: './fb-groups-feed-placement-core.component.html',
	styleUrls: ['./fb-groups-feed-placement-core.component.scss'],
})
export class FacebookGroupsFeedPlacementCoreComponent extends FacebookPlacementCoreBaseDirective {
	constructor(public appFeatureServices: AppFeatureServices) {
		super(
			appFeatureServices,
			FacebookSupportedPlacements.FacebookGroupsFeed,
		);
	}
}
